import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import ReactTooltip from "react-tooltip";
import { getYesNoFormat } from "../../../../utils/common";

class SesCdContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { intl, sescd, sescdDesc, endoscopicRemission, endoscopicResponse } = this.props;
    const { formatMessage } = intl;
    const res = sescdDesc.split(" ");
    const sescdDescData = res[0];

    return (
      <div className="mucosal-healing-content">
        <div className="ses-cd">
          <div className="ses-cd-container1">
            <div className="sescd-desc endo-activity">
              <div className="data">{formatMessage({ id: sescdDescData })}</div>
              <div className="desc">
                {formatMessage({id: "dashboard.content.mucosal.healing.tooltip2"})}
              </div>
            </div>

            <div className="score-data">
              <div className="data">{sescd}</div>
              <div className="tooltip">
                <div>
                  {formatMessage({id: "dashboard.content.clinical.assessment.tooltip1"})}
                </div>
                <a
                  data-tip
                  data-for="ses-cd-2"
                  data-event-off="scroll"
                  data-iscapture="true"
                  data-scroll-hide={true}
                >
                  <i className="material-icons">info</i>
                </a>
                <ReactTooltip
                  id="ses-cd-2"
                  type="dark"
                  aria-haspopup="true"
                  place="right"
                  scrollHide={true}
                  eventOff="scroll"
                >
                  <span>
                    <div className="tooltip-container">
                      <div className="tooltip-title">
                        {formatMessage({ id: "dashboard.content.tooltip.title" })}
                      </div>
                      <div className="tooltip-content">
                        <div>
                          {formatMessage({id: "dashboard.content.tooltip.detail1"})}
                        </div>
                        <div>
                          {formatMessage({id: "dashboard.content.tooltip.detail2"})}
                        </div>
                        <div>
                          {formatMessage({id: "dashboard.content.tooltip.quality1"})}
                        </div>
                        <div>
                          {formatMessage({id: "dashboard.content.tooltip.quality2"})}
                        </div>
                        <div>
                          {formatMessage({id: "dashboard.content.tooltip.quality3"})}
                        </div>
                        <div>
                          {formatMessage({id: "dashboard.content.tooltip.quality4"})}
                        </div>
                        <div>
                          {formatMessage({id: "dashboard.content.tooltip.bottom"})}
                        </div>
                      </div>
                    </div>
                  </span>
                </ReactTooltip>
              </div>
            </div>
            <div className="sescd-desc endo-remission">
              <div className="data">{getYesNoFormat(endoscopicRemission, formatMessage)}</div>
              <div className="desc">
                {formatMessage({
                  id: "persona.sidebar.objective.outcome.endoscopic-remission"
                })}
              </div>
            </div>

            <div className="sescd-desc sescd-endresponse">
              <div className="data">{getYesNoFormat(endoscopicResponse, formatMessage)}</div>
              <div className="desc">
                {formatMessage({
                  id: "persona.sidebar.objective.outcome.endoscopic-response"
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SesCdContent.defaultProps = {};

SesCdContent.propTypes = {
  title: PropTypes.string,
  treatmentMonth: PropTypes.array,
  sescd: PropTypes.number,
  sescdDesc: PropTypes.array,
  intl: intlShape.isRequired,
  endoscopicRemission: PropTypes.string,
  endoscopicResponse: PropTypes.string
};

export default injectIntl(SesCdContent);



