import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
// import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import actions from "../../../flux/action";
import StepperProgress from "../../../components/stepperProgress";
import Demographics from "./demographics";
import DiseaseState from "./diseaseState";
import ResponderProfile from "./responderProfile";
import TreatmentHistory from "./treatmentHistory";
import NewPersonaSidebar from "../newSidebar";
import classnames from "classnames";
import { personaSteps, localOptionsIntl} from "../../../model";
import {validateLogin} from "../../../utils";
//import {injectIntl, intlShape} from "react-intl";
//import {changeLanguageAction} from "../flux/action";
import AppFooter from "../../../container/appFooter";

class NewPersona extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      steps: ["Demographics", "Disease State", "Treatment History", "Responder Profile"],
      activeStep: 0
    };
  }

  componentDidMount() {
    validateLogin();
  }

  setActiveStep(value, action){
    const {personaState, createPersona} = this.props;
    this.setState({
      action: action
    });
    createPersona(
      Object.assign({}, personaState, {
        activeStep: value,
      })
    );
    console.log(personaState);
  }

  getActiveStepContent(){
    // const {activeStep} = this.state;
    const {personaState} = this.props;
    const {activeStep} = personaState;
    switch (activeStep) {
    case 0:
      return (
        <Demographics
          activeStep={activeStep}
          onChange={this.setActiveStep.bind(this)}
        />
      );
    case 1:
      return (
        <DiseaseState
          activeStep={activeStep}
          onChange={this.setActiveStep.bind(this)}
        />
      );
    case 2:
      return (
        <TreatmentHistory
          activeStep={activeStep}
          onChange={this.setActiveStep.bind(this)}
        />
      );
    case 3:
      return (
        <ResponderProfile
          activeStep={activeStep}
          onChange={this.setActiveStep.bind(this)}
        />
      );
    }
  }

  render() {
    // const {action} = this.state;
    const {personaState, intl} = this.props;
    const {activeStep, stepAction} = personaState;
    const {formatMessage} = intl;
    const [personaStepsIntl] = localOptionsIntl(
      [personaSteps],
      formatMessage
    );
    return (
      <div className="persona">
        <div className="persona-navigation">
          <StepperProgress
            classes={{
              root: "persona-stepper"
            }}
            steps={personaStepsIntl}
            activeStep={activeStep}
          />
        </div>
        <div className="persona-main new-persona">
          {this.getActiveStepContent()}
          <div className={classnames({
            "app-sidebar": true,
            "new": true
          })}>
            <NewPersonaSidebar activePersonaSidebar={activeStep} progressAction={stepAction}/>
          </div>
          <AppFooter />
        </div>
      </div>
    );
  }
}

NewPersona.defaultProps = {
  subroutes: [],
  basePath: "",
  history: {},
  label: ""
};

NewPersona.propTypes = {
  classes: PropTypes.object,
  subroutes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  createPersona: PropTypes.func,
  personaState: PropTypes.object
};

const mapStateToProps = (state) => {
  const {newPersona: personaState} = state;

  return {
    personaState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    createPersona: (persona) => {
      dispatch(actions.createPersona(persona));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPersona));
