import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import InfoSection from "../../../../components/infoSection";
import FiledLabel from "../../../../components/fieldLabel";
import { CommonInput, SelectMenu } from "ui-toolkit";
import { injectIntl, intlShape } from "react-intl";
import {
  // diseasePropensity as diseasePropensityOptions,
  genderOptions,
  tobaccoUse as tobaccoUseOptions,
  // numberInputValidation,
  // integerInputValidation,
  selectValidation,
  getRangeValidation,
  loadIntl,
  localOptionsIntl
} from "../../../../model";
import classnames from "classnames";

class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      age: React.createRef(),
      weight: React.createRef(),
      gender: React.createRef(),
      bmi: React.createRef(),
      // diseasePropensity: React.createRef(),
      tobaccoUse: React.createRef()
    };
    const {patientInfo}= props;
    this.patientInfo = _.cloneDeep(patientInfo);
  }

  handleChange(name, value) {
    const { patientInfo, createPersona } = this.props;
    this.patientInfo = _.cloneDeep(patientInfo);
    this.patientInfo[name] = value;
    if(this.patientInfo.gender == "female"){
      this.patientInfo["bmi"] = 23;
    } else if(this.patientInfo.gender == "male"){
      this.patientInfo["bmi"] = 26;
    }
    if(!_.isEmpty(this.patientInfo.age) && !_.isEmpty(this.patientInfo.weight) && !_.isEmpty(this.patientInfo.gender)){
      this.patientInfo.completed = true;
    }
    createPersona({
      patientInfo: Object.assign({}, patientInfo, this.patientInfo)
    });
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = ref.current.getStatus();

        return result && (status === "init" ? false : status);
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, ref => {
      ref && ref.current && ref.current.reset && ref.current.reset();
    });
  }

  checkIsActiveStep(activeStep) {
    return (activeStep == 0) ? true : false;
  }

  render() {
    const { patientInfo, intl } = this.props;
    const { age, weight, gender, bmi, tobaccoUse } = patientInfo;
    const { formatMessage } = intl;
    const ageValidation = getRangeValidation([20, 75]);
    const weightValidation = getRangeValidation([35, 150], "number");
    const bmiValidation = getRangeValidation([15, 35], "number");
    const [
      // numberInputValidationIntl,
      // integerInputValidationIntl,
      selectValidationIntl,
      ageValidationIntl,
      weightValidationIntl,
      bmiValidationIntl
    ] = loadIntl(
      [
        // numberInputValidation,
        // integerInputValidation,
        selectValidation,
        ageValidation,
        weightValidation,
        bmiValidation
      ],
      formatMessage
    );
    const [genderOptionsIntl, tobaccoUseOptionsIntl] = localOptionsIntl(
      [genderOptions, tobaccoUseOptions],
      formatMessage
    );

    return (
      <div className={classnames({
        "patient-info": true
      })}>
        <InfoSection
          title={formatMessage({ id: "persona.sidebar.patientInfo.title" })}
        >
          <FiledLabel
            label={formatMessage({ id: "persona.sidebar.patientInfo.age" })}
          >
            <CommonInput
              ref={this.inputRefs.age}
              value={age}
              validation={ageValidationIntl}
              onChange={this.handleChange.bind(this, "age")}
            />
          </FiledLabel>
          <FiledLabel
            label={formatMessage({
              id: "persona.sidebar.patientInfo.gender"
            })}
          >
            <SelectMenu
              ref={this.inputRefs.gender}
              value={gender}
              config={genderOptionsIntl}
              validation={selectValidationIntl}
              onSelect={this.handleChange.bind(this, "gender")}
            />
          </FiledLabel>
          <FiledLabel
            label={formatMessage({ id: "persona.sidebar.patientInfo.weight" })}
          >
            <CommonInput
              ref={this.inputRefs.weight}
              value={weight}
              validation={weightValidationIntl}
              onChange={this.handleChange.bind(this, "weight")}
            />
          </FiledLabel>
          <FiledLabel
            label={formatMessage({
              id: "persona.sidebar.patientInfo.tobaccoUse"
            })}
          >
            <SelectMenu
              ref={this.inputRefs.tobaccoUse}
              value={tobaccoUse}
              config={tobaccoUseOptionsIntl}
              validation={selectValidationIntl}
              onSelect={this.handleChange.bind(this, "tobaccoUse")}
            />
          </FiledLabel>
          <FiledLabel
            label={formatMessage({ id: "persona.sidebar.patientInfo.bmi" })}
          >
            <CommonInput
              ref={this.inputRefs.bmi}
              value={bmi}
              validation={bmiValidationIntl}
              onChange={this.handleChange.bind(this, "bmi")}
            />
          </FiledLabel>
          {/*<FiledLabel
            label={formatMessage({
              id: "persona.sidebar.patientInfo.diseasePropensity"
            })}
          >
            <SelectMenu
              ref={this.inputRefs.diseasePropensity}
              value={diseasePropensity}
              config={diseasePropensityOptions}
              validation={selectValidationIntl}
              onSelect={this.handleChange.bind(this, "diseasePropensity")}
            />
          </FiledLabel>*/}
        </InfoSection>
      </div>
    );
  }
}

PatientInfo.defaultProps = {};

PatientInfo.propTypes = {
  patientInfo: PropTypes.object,
  createPersona: PropTypes.func,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number
};

export default injectIntl(PatientInfo, {
  withRef: true
});
