import { createActions } from "redux-actions";
import { actionTypes } from "./actionTypes";
import { createRequest, naviTo } from "../../utils";

const {
  EDIT_ADMIN,
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILED,
  LOAD_SIMULATE_RESULT,
  LOAD_SIMULATE_RESULT_SUCCESS,
  LOAD_SIMULATE_RESULT_FAILED,
  LOAD_SIMULATE_DETAIL,
  LOAD_SIMULATE_DETAIL_SUCCESS,
  LOAD_SIMULATE_DETAIL_FAILED
} = actionTypes;

const commonActions = createActions(
  {},
  EDIT_ADMIN,
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILED,
  LOAD_SIMULATE_RESULT,
  LOAD_SIMULATE_RESULT_SUCCESS,
  LOAD_SIMULATE_RESULT_FAILED,
  LOAD_SIMULATE_DETAIL,
  LOAD_SIMULATE_DETAIL_SUCCESS,
  LOAD_SIMULATE_DETAIL_FAILED
);

const loadUsers = () => {
  return dispatch => {
    dispatch(commonActions.loadUsers());

    const successCallback = res => {
      dispatch(commonActions.loadUsersSuccess(res));
    };

    const failedCallback = () => {
      dispatch(commonActions.loadUsersFailed());
      naviTo("/error");
    };

    const opts = {
      pathName: "loadUsers",
      method: "GET"
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};

const loadSimulateResult = options => {
  return dispatch => {
    dispatch(commonActions.loadSimulateResult());

    const successCallback = res => {
      dispatch(commonActions.loadSimulateResultSuccess(res));
    };

    const failedCallback = () => {
      dispatch(commonActions.loadSimulateResultFailed());
      naviTo("/error");
    };

    const opts = {
      pathName: "loadSimulateResult",
      method: "POST",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};

const loadSimulateDetail = options => {
  return dispatch => {
    dispatch(commonActions.loadSimulateDetail());

    const successCallback = res => {
      dispatch(commonActions.loadSimulateDetailSuccess(res));
    };

    const failedCallback = () => {
      dispatch(commonActions.loadSimulateDetailFailed());
      naviTo("/error");
    };

    const opts = {
      pathName: "loadSimulateDetail",
      method: "GET",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};



export default Object.assign({}, commonActions, {
  loadUsers,
  loadSimulateResult,
  loadSimulateDetail
});
