import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { naviTo } from "../../utils";
import { Button } from "@material-ui/core";
import { CommonInput } from "ui-toolkit";
import action from "../../flux/action";
import LanguageSelect from "../../components/languageSelect";
import { languages } from "../../model";
import { injectIntl, intlShape } from "react-intl";
import Takeda from "../../../assets/takeda_logo.svg";
import "../../../styles/login/forgot-password.scss";
import { emailValidation, loadIntl } from "../../model";
import { getWrappedInstance } from "../../utils";
import reset from "../../../assets/new_reset.jpg";
// const validation = {
//   notEmpty: {
//     errorText: "Please enter a valid email address."
//   },
//   format: {
//     //reg: /^\w+([\ .-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
//     reg: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
//     errorText: "Please enter a valid email address."
//   }
// };

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      emailRef: React.createRef()
    };

    this.state = {
      disabled: false
    };
  }

  handleFieldChange(field, value) {
    this.setState({
      [field]: value
    });
  }
  // handleValidate(options) {
  //   this.valid = options.isValid;
  // }

  gotoLogin(e) {
    this.handleNavi("login", e);
  }

  handleNavi(pathname, e) {
    e.preventDefault();
    naviTo(pathname);
  }

  handleForgotPassword(e) {
    e.preventDefault();
    //const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const { forgotPassword } = this.props;
    const { userName } = this.state;
    //let isValid = false;
    //this.emailRef.current.validate();
    //isValid = this.valid;
    if (this.getStatus()) {
      forgotPassword({
        email: userName
      });
    }
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();
        //const status = ref.current.getStatus();
        return result && status;
        //return result && status === "init" ? false : status;
      },
      true
    );
  }

  handleLanguageChange(language) {
    const { changeLanguage } = this.props;

    changeLanguage(language);
  }

  getContent() {
    const { isForgotPasswordEmailSended, intl } = this.props;
    const { formatMessage } = intl;
    const { disabled, userName } = this.state;
    const [emailInputValidation] = loadIntl([emailValidation], formatMessage);

    if (isForgotPasswordEmailSended) {
      return (
        <div className="content">
          <div className="forget-password-title">
            {formatMessage({ id: "forget.password.title.forget" })}
          </div>
          <p className="info-text">
            {formatMessage({ id: "forget.password.info.text1" })}
          </p>
        </div>
      );
    } else {
      return (
        <div className="content">
          <div className="forget-password-title">
            {formatMessage({ id: "forget.password.title.forget" })}
          </div>
          <p className="info-text">
            {formatMessage({ id: "forget.password.info.text" })}
          </p>
          <div className="email">
            {formatMessage({ id: "login.email.title" })}
          </div>
          <CommonInput
            type="text"
            placeholder={formatMessage({ id: "login.email.input" })}
            className="common-input"
            value={userName}
            ref={this.inputRefs.emailRef}
            validation={emailInputValidation}
            onChange={this.handleFieldChange.bind(this, "userName")}
            disabled={disabled}
          />
          <div className="buttons">
            <Button
              classes={{
                root: "reset-password-button"
              }}
              variant="contained"
              type="submit"
              color="primary"
              disabled={disabled}
            >
              {formatMessage({ id: "forget.password.botton.reset.password" })}
            </Button>
          </div>
          {/* <div className="signInNow">
            {formatMessage({ id: "forget.password.suddenly.remember" })}
            <span onClick={this.gotoLogin.bind(this)} className="sign-in">
              {formatMessage({ id: "forget.password.sign.in.now" })}
            </span>
          </div> */}
        </div>
      );
    }
  }

  render() {
    const { language } = this.props;
    // const { formatMessage } = intl;
    return (
      <div className="login-container">
        <div className="left-card">
          <form onSubmit={this.handleForgotPassword.bind(this)}>
            <div className="forgot-password">
              <div className="header-title">
                <img src={Takeda} />
                {/* <span>{formatMessage({ id: "login.header.title" })}</span> */}
                <LanguageSelect
                  config={languages}
                  value={language}
                  onChange={this.handleLanguageChange.bind(this)}
                />
              </div>
              {this.getContent()}
            </div>
          </form>
        </div>
        <div className="right-card">
          <img src={reset} />
        </div>
      </div>
    );
  }
}

ForgotPassword.defaultProps = {};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func,
  isForgotPasswordEmailSended: PropTypes.bool,
  intl: intlShape.isRequired,
  language: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => {
  const { login, common } = state;
  const { language } = common;
  const { isForgotPasswordEmailSended } = login;
  return {
    language,
    isForgotPasswordEmailSended
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: options => {
      dispatch(action.forgotPassword(options));
    },
    changeLanguage: language => {
      dispatch(action.changeLanguage(language));
    }
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      forwardRef: true
    }
  )(ForgotPassword),
  { withRef: true }
);
