import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import {
  // CommonInput,
  // RadioGroup,
  SelectMenu,
  // EnhancedSlider,
  CheckExpansionPanel
} from "ui-toolkit";
import FiledLabel from "../../../../../components/fieldLabel";
import {
  // yesNoOptions,
  ulcerTypeOptions,
  selectValidation,
  loadIntl,
  localOptionsIntl,
  surfaceAreaWithUlcers,
  surfaceInvolved
} from "../../../../../model";

const marks = [];

for (let i = 0; i < 11; i++) {
  marks.push({
    label: i,
    value: i
  });
}

class Intestine extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      ulcerArea: React.createRef(),
      diseaseArea: React.createRef(),
      ulcerType: React.createRef()
      // type: React.createRef()
    };
  }

  handleChange(name, value) {
    const { data, onChange } = this.props;

    onChange(
      Object.assign({}, data, {
        [name]: value
      })
    );
  }

  handleSwitchChange(name, value) {
    const { data, onChange } = this.props;

    onChange(
      Object.assign({}, data, {
        [name]: value
      })
    );
  }

  getStatus() {
    const { data } = this.props;
    const { checked } = data;

    if (checked) {
      return _.reduce(
        this.inputRefs,
        (result, ref) => {
          const status = ref.current.getStatus();

          return result && (status === "init" ? false : status);
        },
        true
      );
    } else {
      return true;
    }
  }

  getDisabledStatus() {
    return true;
  }

  reset() {
    _.each(this.inputRefs, ref => {
      ref && ref.current && ref.current.reset && ref.current.reset();
    });
  }

  render() {
    const { label, data, intl } = this.props;
    const {
      checked,
      ulcerArea,
      diseaseArea,
      ulcerType,
      // coverageArea,
      // type,
      // penetrating,
      // stenosis
      // subScore
    } = data;
    const { formatMessage } = intl;
    const [selectValidationIntl] = loadIntl(
      [selectValidation],
      formatMessage
    );
    const [ulcerTypeOptionsIntl, surfaceAreaWithUlcersIntl, surfaceInvolvedIntl] = localOptionsIntl(
      [ulcerTypeOptions, surfaceAreaWithUlcers, surfaceInvolved],
      formatMessage
    );
    return (
      <div className="intestine">
        <CheckExpansionPanel
          color="primary"
          label={label}
          checked={checked}
          onChange={this.handleSwitchChange.bind(this, "checked")}
        >
          <FiledLabel
            label={formatMessage({
              id: "persona.sidebar.diseaseState.intestine.ulcerArea"
            })}
          >
            {/* <CommonInput
              ref={this.inputRefs.ulcerArea}
              value={ulcerArea}
              validation={numberInputValidationIntl}
              onChange={this.handleChange.bind(this, "ulcerArea")}
            /> */}
            <SelectMenu
              ref={this.inputRefs.ulcerArea}
              value={ulcerArea}
              config={surfaceAreaWithUlcersIntl}
              validation={selectValidationIntl}
              onSelect={this.handleChange.bind(this, "ulcerArea")}
            />
          </FiledLabel>
          <FiledLabel
            label={formatMessage({
              id: "persona.sidebar.diseaseState.intestine.diseaseArea"
            })}
          >
            {/* <CommonInput
              ref={this.inputRefs.diseaseArea}
              value={diseaseArea}
              validation={numberInputValidationIntl}
              onChange={this.handleChange.bind(this, "diseaseArea")}
            /> */}
            <SelectMenu
              ref={this.inputRefs.diseaseArea}
              value={diseaseArea}
              config={surfaceInvolvedIntl}
              validation={selectValidationIntl}
              onSelect={this.handleChange.bind(this, "diseaseArea")}
            />
          </FiledLabel>
          <FiledLabel
            label={formatMessage({
              id: "persona.sidebar.diseaseState.intestine.ulcerType"
            })}
          >
            <SelectMenu
              ref={this.inputRefs.ulcerType}
              value={ulcerType}
              config={ulcerTypeOptionsIntl}
              validation={selectValidationIntl}
              onSelect={this.handleChange.bind(this, "ulcerType")}
            />
          </FiledLabel>
          {/*<FiledLabel
            label={formatMessage({
              id: "persona.sidebar.diseaseState.intestine.type"
            })}
          >
            <CommonInput
              ref={this.inputRefs.type}
              value={type}
              validation={numberInputValidationIntl}
              onChange={this.handleChange.bind(this, "type")}
            />
          </FiledLabel>*/}
          {/* <FiledLabel
            label={formatMessage({
              id: "persona.sidebar.diseaseState.intestine.penetrating"
            })}
          >
            <RadioGroup
              color="primary"
              config={yesNoOptionsIntl}
              value={penetrating}
              onChange={this.handleChange.bind(this, "penetrating")}
            />
          </FiledLabel>
          <FiledLabel
            label={formatMessage({
              id: "persona.sidebar.diseaseState.intestine.stenosis"
            })}
          >
            <RadioGroup
              color="primary"
              config={yesNoOptionsIntl}
              value={stenosis}
              onChange={this.handleChange.bind(this, "stenosis")}
            />
          </FiledLabel> */}
          {/*<FiledLabel
            label={formatMessage({
              id: "persona.sidebar.diseaseState.intestine.subScore"
            })}
          >
            <EnhancedSlider
              value={subScore}
              range={[0, 10]}
              onChange={this.handleChange.bind(this, "subScore")}
              marks={marks}
            />
          </FiledLabel>*/}
        </CheckExpansionPanel>
      </div>
    );
  }
}

Intestine.defaultProps = {
  label: "",
  data: {},
  onChange: () => { }
};

Intestine.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
  onChange: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(Intestine, {
  withRef: true
});
