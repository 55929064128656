import _ from "lodash";
import enLocaleData from "react-intl/locale-data/en";
import jaLocaleData from "react-intl/locale-data/ja";
import login from "./login";
import common from "./common";
import personaSidebar from "./personaSidebar";
import personaMain from "./personaMain";
import dashboardSidebar from "./dashboardSidebar";
import dashboardMain from "./dashboardMain";
import model from "./model";

const messages = _.reduce(
  [
    common,
    personaSidebar,
    personaMain,
    dashboardSidebar,
    dashboardMain,
    model,
    login
  ],
  (result, languages) => {
    _.each(languages, (messages, language) => {
      if (!result[language]) {
        result[language] = {};
      }

      Object.assign(result[language], messages);
    });

    return result;
  },
  {}
);

const locales = {
  en: {
    messages: Object.assign({}, messages["en"]),
    locales: ["en", "en-US", "en-GB"],
    data: enLocaleData
  },
  ja: {
    messages: Object.assign({}, messages["ja"]),
    locales: ["ja"],
    data: jaLocaleData
  }
};

function getLocale(selectedLanguage) {
  let result;
  const language = selectedLanguage || navigator.language;

  _.each(locales, locale => {
    if (locale.locales.indexOf(language) > -1) {
      result = locale;
    }
  });

  return result || locales.en;
}

export { getLocale, locales };
