import React, { Component } from "react";
import PropTypes from "prop-types";
import ChartContainer from "../../components/chartContainer";
import { injectIntl, intlShape } from "react-intl";
import { SwitchExpansionPanel } from "ui-toolkit";

class Biomarkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleChange() {
    this.setState({
      isOpen: !this.state.isOpen
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "event" : "biomarkers_toggle_event",
      "biomarker_toggle_status" : "TRUE",
    });
  }

  render() {

    const { data, plotConfig, treatmentMonth, intl } = this.props;
    const { formatMessage } = intl;
    const { calprotectin, crp } = data;
    const { isOpen } = this.state;
    const labels = [
      formatMessage({ id: "dashboard.content.chart.label.baseline" }),
      formatMessage({ id: "dashboard.content.chart.label.treatment" })
    ];
    const [minCalprotectin, maxCalprotectin] = plotConfig["calprotectin"] || [];
    const [minCrp, maxCrp] = plotConfig["crp"] || [];

    return (
      <div className="biomarkers">

        <SwitchExpansionPanel
          color="primary"
          label={formatMessage({ id: "dashboard.content.biomarker.title" })}
          checked={isOpen}
          onToggle={this.handleChange.bind(this)}
        >

          {/* <div className="header">
          <div className="title">
            {formatMessage({ id: "dashboard.content.biomarker.title" })}
          </div>
        </div> */}

          <div className="content">
            <ChartContainer
              min={minCalprotectin}
              max={maxCalprotectin}
              labels={labels}
              name={"calprotectin"}
              data={calprotectin}
              treatmentMonth={treatmentMonth}
              title={"dashboard.content.biomarker.calprotectin.chart.header"}
            />
            <ChartContainer
              min={minCrp}
              max={maxCrp}
              labels={labels}
              name={"crp"}
              data={crp}
              treatmentMonth={treatmentMonth}
              title={"dashboard.content.biomarker.crp.chart.header"}
            />
          </div>
        </SwitchExpansionPanel>
      </div>
    );
  }
}

Biomarkers.defaultProps = {};

Biomarkers.propTypes = {
  data: PropTypes.object,
  plotConfig: PropTypes.object,
  treatmentMonth: PropTypes.number,
  intl: intlShape.isRequired
};

export default injectIntl(Biomarkers);
