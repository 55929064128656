import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { naviTo } from "../../utils";
import { Button/*, MenuItem */} from "@material-ui/core";
import { CommonInput } from "ui-toolkit";
import LanguageSelect from "../../components/languageSelect";
import { languages } from "../../model";
//import { countries } from "../../assets/files/countries";
import action from "../../flux/action";
import Takeda from "../../../assets/takeda_logo.svg";
import { injectIntl, intlShape } from "react-intl";
import "../../../styles/login/create-account.scss";
import {
  emailValidation,
  userNameValidation,
  passValidation,
  loadIntl
} from "../../model";
import { getWrappedInstance } from "../../utils";
import signup from "../../../assets/new_signup.jpg";

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      emailRef: React.createRef(),
      passwordRef: React.createRef(),
      userNameRef: React.createRef()
    };
    this.state = {
      disabled: false,
      userName: "",
      password: "",
      password2: "",
      name: ""
    };
  }

  handleFieldChange(field, value) {
    this.setState({
      [field]: value
    });
  }

  handleValidate(options) {
    _.each(options, (item, key) => {
      key == "isValid"
        ? item == true
          ? (this.valid = true)
          : (this.valid = false)
        : null;
    });
  }

  handleCreateAccount(e) {
    e.preventDefault();
    //let isValid = false;
    // this.inputRefs.emailRef.current.validate();
    // this.inputRefs.passwordRef.current.validate();
    //this.rePasswordRef.current.validate();
    //isValid = this.valid;
    //isValid = this.handleValidateCountry();
    // _.each(this.refs, ref => {
    //   const isRefValid = ref.isValid();

    //   if (!isRefValid) isValid = false;
    // });
    if (this.getStatus()) {
      const { userName, password } = this.state;
      const { createUser } = this.props;
      createUser({
        email: _.trim(userName),
        //password: rsaKey.encrypt(password, "base64"),
        password: password
      });
    }
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();
        return result && status;
      },
      true
    );
  }

  // menuItems(countries) {
  //   return countries.map(country => (
  //     <MenuItem
  //       key={country.country}
  //       insetChildren={true}
  //       checked={this.state.country.indexOf(country.country) > -1}
  //       value={country.country}
  //       primaryText={country.country}
  //       onBlur={this.handleValidateCountry.bind(this)}
  //     />
  //   ));
  // }

  handleNavi(pathname, e) {
    e.preventDefault();
    naviTo(pathname);
  }

  gotoLogin(e) {
    this.handleNavi("login", e);
  }

  getContent() {
    const { isUserCreated, intl } = this.props;
    const { formatMessage } = intl;
    const [emailInputValidation] = loadIntl([emailValidation], formatMessage);
    const [passInputValidation] = loadIntl([passValidation], formatMessage);
    const [userNameInputValidation] = loadIntl(
      [userNameValidation],
      formatMessage
    );
    const { userName, password, name, disabled } = this.state;
    // const password2Validation = validation.password2;

    //password2Validation["equal"].value = password;

    if (isUserCreated) {
      return (
        <div className="content">
          <div className="title">
            <div className="line1">
              {formatMessage({ id: "create.account.title.Account.created" })}
            </div>
          </div>
          <p>{formatMessage({ id: "create.account.info.text2" })}</p>
        </div>
      );
    } else {
      return (
        <div className="content">
          <div className="title">
            <div className="line1">
              {formatMessage({ id: "create.account.title.sign.up" })}
            </div>
          </div>
          <div className="email-content">
            <div className="email">
              {formatMessage({ id: "create.account.name.title" })}
            </div>
            <CommonInput
              classes={{ root: "email-root-input" }}
              placeholder={formatMessage({ id: "create.account.email.adress" })}
              type="text"
              ref={this.inputRefs.userNameRef}
              validation={userNameInputValidation}
              value={name}
              disabled={disabled}
              onChange={this.handleFieldChange.bind(this, "name")}
            />
          </div>
          <div className="email-content">
            <div className="password">
              {formatMessage({ id: "login.email.title" })}
            </div>
            <CommonInput
              classes={{ root: "password-root-input" }}
              placeholder={formatMessage({
                id: "create.account.password.input"
              })}
              type="text"
              ref={this.inputRefs.emailRef}
              validation={emailInputValidation}
              value={userName}
              disabled={disabled}
              onChange={this.handleFieldChange.bind(this, "userName")}
            />
          </div>
          <div className="email-content">
            <div className="re-password">
              {formatMessage({ id: "create.account.repassword.title" })}
            </div>
            <CommonInput
              classes={{ root: "password-root-input" }}
              placeholder={formatMessage({
                id: "create.account.repassword.input"
              })}
              type="password"
              ref={this.inputRefs.passwordRef}
              value={password}
              validation={passInputValidation}
              disabled={disabled}
              onChange={this.handleFieldChange.bind(this, "password")}
            />
          </div>
          <div className="buttons">
            <Button
              classes={{
                root: "create-account-button"
              }}
              variant="contained"
              type="submit"
              color="primary"
            >
              {formatMessage({ id: "create.account.button.create.account" })}
            </Button>
          </div>
          <div className="signIn">
            {formatMessage({ id: "create.account.have.account" })}
            <span onClick={this.gotoLogin.bind(this)} className="sign-in">
              {formatMessage({ id: "create.account.sign.in" })}
            </span>
          </div>
        </div>
      );
    }
  }

  handleLanguageChange(language) {
    const { changeLanguage } = this.props;

    changeLanguage(language);
  }

  render() {
    const { /*intl, */ language } = this.props;
    //const { formatMessage } = intl;
    return (
      <div className="login-container">
        <div className="left-card">
          <div className="header-title">
            <img src={Takeda} />
            {/* <span>{formatMessage({ id: "login.header.title" })}</span> */}
            <LanguageSelect
              config={languages}
              value={language}
              onChange={this.handleLanguageChange.bind(this)}
            />
          </div>
          <form onSubmit={this.handleCreateAccount.bind(this)}>
            <div className="create-account">
              {/* <div className="title">
                <div className="line1">
                  {formatMessage({ id: "create.account.title.sign.up" })}
                </div>
              </div> */}
              {this.getContent()}
            </div>
          </form>
        </div>
        <div className="right-card">
          <img className="creat-account" src={signup} />
        </div>
      </div>
    );
  }
}

CreateAccount.defaultProps = {};

CreateAccount.propTypes = {
  createUser: PropTypes.func,
  isUserCreated: PropTypes.bool,
  intl: intlShape.isRequired,
  language: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => {
  const { login, common } = state;
  const { isUserCreated } = login;
  const { language } = common;
  return { language, isUserCreated };
};

const mapDispatchToProps = dispatch => {
  return {
    createUser: options => {
      dispatch(action.createUser(options));
    },
    changeLanguage: language => {
      dispatch(action.changeLanguage(language));
    }
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      forwardRef: true
    }
  )(CreateAccount),
  { withRef: true }
);
