// import BaseModel from "./model";
import _ from "lodash";
import { pharmacologyConfig } from "./pharmacologyConfig";
import { intestineOptions, historicInterventionOptions } from "./detailOptions";

/**************************************************************
const dataStructure = {
  patientInfo: {
    age: "",
    weight: "",
    gender: 0,
    bmi: "",
    --diseasePropensity: "",
    tobaccoUse: ""
  },
  diseaseState: {
    biomarkers: {
      calprotectin: "",
      --lactoferrin: "",
      crp: "",
      --il_6: "",
      albumin: "",
      --lrg: ""
    },
    location: {
      isOpen: false,
      jejunum: {
        checked: false,
        ulcerArea: "",
        diseaseArea: "",
        ulcerType: "",
        --coverageArea: "",
        --type: "",
        penetrating: true,
        stenosis: true,
        --subScore: 1
      },
      ileum: "as above",
      ileocolonic: "as above",
      colon: "as above"
    },
    diseaseHistory: {
      isOpen: false,
      perianal: true,
      diseaseDuraction: "",
      previousSurgery: {}
    }
  },
  historicIntervention: {
    "anti-tnf": {
      checked: false,
      initResponse: true,
      developedResistance: true,
      current: true,
      duractionTreatment: [],
      frequency: "",
      troughLevel: "",
      antibodyTiter: ""
    },
    "anti-il": "as above",
    "anti-integrin": "as above",
    steroids: "as above"
  }
};
**************************************************************/

// const defaultPatientInfo = {
//   age: "",
//   weight: "",
//   gender: "",
//   bmi: "",
//   // diseasePropensity: "",
//   tobaccoUse: ""
// };

// const defaultBiomarkers = {
//   calprotectin: "",
//   // lactoferrin: "",
//   crp: "",
//   // il_6: "",
//   albumin: ""
//   // lrg: ""
// };
//
// const defaultIntestine = {
//   checked: false,
//   ulcerArea: "",
//   diseaseArea: "",
//   ulcerType: "",
//   // coverageArea: "",
//   // type: "",
//   penetrating: "yes",
//   stenosis: "yes"
//   // subScore: 0
// };

// const defaultLocation = {
//   isOpen: false,
//   jejunum: Object.assign({}, defaultIntestine, {}),
//   ileum: Object.assign({}, defaultIntestine, {}),
//   ileocolonic: Object.assign({}, defaultIntestine, {}),
//   colon: Object.assign({}, defaultIntestine, {})
//   // rectum: Object.assign({}, defaultIntestine, {})
// };

// const defaultDiseaseHistory = {
//   isOpen: false,
//   perianal: "yes",
//   diseaseDuraction: "",
//   previousSurgery: {}
// };
//
// const defaultDiseaseState = {
//   biomarkers: Object.assign({}, defaultBiomarkers, {}),
//   location: Object.assign({}, defaultLocation, {}),
//   diseaseHistory: Object.assign({}, defaultDiseaseHistory, {})
// };

const getDefaultPatientInfo = () => {
  return {
    age: "",
    weight: "",
    gender: "",
    bmi: "",
    tobaccoUse: "never_smoker",
    completed: false
  };
};

const getDefaultBiomarkers = () => {
  return {
    calprotectin: "1250",
    crp: "1.5",
    albumin: "40"
  };
};

const getDefaultIntestine = () => {
  return {
    checked: false,
    ulcerArea: "",
    diseaseArea: "",
    ulcerType: "",
    penetrating: "no",
    stenosis: "no"
  };
};

const getDefaultLocation = () => {
  const location = {
    isOpen: false
  };

  _.each(intestineOptions, item => {
    const { value } = item;

    Object.assign(location, {
      [value]: getDefaultIntestine()
    });
  });

  return location;
};

const getDefaultDiseaseHistory = () => {
  return {
    isOpen: false,
    perianal: "yes",
    diseaseDuraction: "",
    previousSurgery: {}
  };
};

const getDefaultDiseaseState = () => {
  return {
    biomarkers: getDefaultBiomarkers(),
    location: getDefaultLocation(),
    diseaseHistory: getDefaultDiseaseHistory(),
    completed: false
  };
};

const getDefaultResponderProfile = () => {
  return {
    "steroid": "",
    "immunomodulator": "",
    "anti-tnf": "",
    "anti-il": "",
    "anti-integrin": "",
    completed: false,
    isSteroidModified: false,
    isImmunoModified: false,
    isAntiTNFModified: false,
    isAntiILModified: false,
    isAntiIntegrinModified: false
  };
};

// const defaultPharmacology = {
//   checked: false,
//   duractionTreatment: "",
//   timeLastDose: "",
//   responsive: "yes",
//   current: "yes",
//   treatment: "",
//   timeTreatmentEnd: "",
//   clinicalOutcome: "",
//   objectiveOutcome: ""
// };

const getDefaultPharmacology = name => {
  const config = pharmacologyConfig[name] || [];
  const pharmacology = {
    checked: false
  };

  _.each(config, field => {
    const { value } = field;

    Object.assign(pharmacology, {
      [value]: ""
    });
  });

  return pharmacology;
};

const getDefaultHistoricIntervention = () => {
  const historicIntervention = {
    biologyTreatmentStatus: "biologic-naive",
    isOpen: false,
    completed: true
  };

  _.each(historicInterventionOptions, item => {
    const { value } = item;

    Object.assign(historicIntervention, {
      [value]: getDefaultPharmacology(value)
    });
  });

  return historicIntervention;
};

// const defaultHistoricIntervention = {
//   isOpen: false,
//   "anti-tnf": getDefaultPharmacology("anti-tnf"),
//   "anti-il": Object.assign({}, defaultPharmacology, {}),
//   "anti-integrin": Object.assign({}, defaultPharmacology, {}),
//   steroids: Object.assign({}, defaultPharmacology, {}),
//   immunomodulator: Object.assign({}, defaultPharmacology, {})
// };

const getDefaultPersona = () => {
  return {
    patientInfo: getDefaultPatientInfo(),
    diseaseState: getDefaultDiseaseState(),
    historicIntervention: getDefaultHistoricIntervention(),
    responderProfile: getDefaultResponderProfile(),
    completed: false,
    activeStep: 0,
    stepAction: ""
  };
};

// const defaultPersona = {
//   patientInfo: Object.assign({}, defaultPatientInfo, {}),
//   diseaseState: Object.assign({}, defaultDiseaseState, {}),
//   historicIntervention: getDefaultHistoricIntervention()
// };

export {
  getDefaultPatientInfo,
  getDefaultBiomarkers,
  getDefaultIntestine,
  getDefaultLocation,
  getDefaultPharmacology,
  getDefaultDiseaseState,
  getDefaultHistoricIntervention,
  getDefaultPersona,
  getDefaultResponderProfile
};
