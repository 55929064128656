import { connect } from "react-redux";
import DashboardSidebar from "./dashboardSidebar";
import action from "../../../flux/action";

const mapStateToProps = state => {
  const { persona: personaState, dashboard: dashboardState, login:loginState  } = state;
  const {
    archetypeId,
    personaSelected,
    patientInfo,
    diseaseState,
    historicIntervention,
    responderProfile
  } = personaState;
  const { proposedTreatment } = dashboardState;

  return {
    archetypeId,
    personaSelected,
    patientInfo,
    diseaseState,
    historicIntervention,
    proposedTreatment,
    responderProfile,
    loginState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editDashboard: options => {
      dispatch(action.editDashboard(options));
    },
    resetDashboard: () => {
      dispatch(action.resetDashboard());
    },
    simulate: options => {
      dispatch(action.simulate(options));
    },
    showMessage: options => {
      dispatch(action.showMessage(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardSidebar);
