import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import InfoSection from "../../../../components/infoSection";
import Pharmacology from "./pharmacology";
import { historicInterventionOptions } from "../../../../model";
import { getWrappedInstance } from "../../../../utils";

class HistoricIntervention extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }

  handleChange(name, value) {
    const { historicIntervention, editPersona } = this.props;

    editPersona({
      historicIntervention: Object.assign({}, historicIntervention, {
        [name]: value
      }),
      personaSelected: "custom",
      isSelected: false
    });
  }

  componentDidMount() {
    this.getStatus();
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();

        return result && status;
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, ref => {
      getWrappedInstance(ref.current).reset();
    });
  }

  render() {
    const { historicIntervention, intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="historic-intervention">
        {/* Removed based ryoko change request(28thJune-2021)
        <InfoSection
          title={formatMessage({
            id: "persona.sidebar.historicIntervention.title"
          })}
        > */}
        <InfoSection
          title={formatMessage({
            id: "persona.sidebar.treatment.history"
          })}
        >
          <div className="desc">
            {formatMessage({ id: "persona.sidebar.historicIntervention.desc" })}
          </div>
          {_.map(historicInterventionOptions, (item, index) => {
            const { label, value, disabled } = item;
            const pharmacology = historicIntervention[value];
            this.inputRefs[value] = React.createRef();
            return (
              <Pharmacology
                ref={this.inputRefs[value]}
                key={`pharmacology-${index}`}
                label={formatMessage({ id: label })}
                disabled={disabled}
                name={value}
                data={pharmacology}
                onChange={this.handleChange.bind(this, value)}
              />
            );
          })}
        </InfoSection>
      </div>
    );
  }
}

HistoricIntervention.defaultProps = {};

HistoricIntervention.propTypes = {
  historicIntervention: PropTypes.object,
  editPersona: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(HistoricIntervention, {
  withRef: true
});
