import _ from "lodash";

export * from "./common";
export * from "./patientInfo";
export * from "./diseaseState";
export * from "./historicIntervention";
export * from "./proposedTreatment";

export const localOptionsIntl = (optionsArr, formatMessage) => {
  if (_.isFunction(formatMessage)) {
    return _.map(optionsArr, options => {
      return _.map(options, option => {
        const { label, value, desc } = option;

        return {
          desc: desc ? formatMessage({ id: desc }) : "",
          label: formatMessage({ id: label }),
          value
        };
      });
    });
  }
};
