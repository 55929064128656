import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import InfoSection from "../../../../components/infoSection";
import Biomarkers from "./biomarkers";
import Location from "./location";
import DiseaseHistory from "./diseaseHistory";
import {getWrappedInstance} from "../../../../utils";

class DiseaseState extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      biomarkers: React.createRef(),
      location: React.createRef(),
      diseaseHistory: React.createRef()
    };
  }

  handleChange(name, value) {
    const { editPersona, diseaseState } = this.props;

    editPersona({
      diseaseState: Object.assign({}, diseaseState, {
        [name]: value
      }),
      personaSelected: "custom",
      isSelected: false
    });
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();

        return result && status;
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, (ref) => {
      getWrappedInstance(ref.current).reset();
    });
  }

  render() {
    const { diseaseState, intl } = this.props;
    const { formatMessage } = intl;
    const { biomarkers, location, diseaseHistory } = diseaseState;

    return (
      <div className="disease-state">
        <InfoSection title={formatMessage({id: "persona.sidebar.diseaseState.title"})}>
          <div className="desc">{formatMessage({id: "persona.sidebar.diseaseState.desc"})}</div>
          <Biomarkers
            ref={this.inputRefs.biomarkers}
            data={biomarkers}
            onChange={this.handleChange.bind(this, "biomarkers")}
          />
          <div className="desc">{formatMessage({id: "persona.sidebar.diseaseState.diseaseHistory"})}</div>
          <DiseaseHistory
            ref={this.inputRefs.diseaseHistory}
            data={diseaseHistory}
            onChange={this.handleChange.bind(this, "diseaseHistory")}
          />
          <Location
            ref={this.inputRefs.location}
            data={location}
            onChange={this.handleChange.bind(this, "location")}
          />
        </InfoSection>
      </div>
    );
  }
}

DiseaseState.defaultProps = {};

DiseaseState.propTypes = {
  diseaseState: PropTypes.object,
  editPersona: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(DiseaseState, {
  withRef: true
});
