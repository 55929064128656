import { handleActions } from "redux-actions";
import action from "../action";
import dState from "./defaultState";

const { actionTypes } = action;
const { SHOW_MESSAGE, HIDE_MESSAGE, CHANGE_LANGUAGE } = actionTypes;

const defaultState = Object.assign(
  {
    message: "",
    showMessage: false,
    language: "ja"
  },
  dState
);

export default handleActions(
  {
    [SHOW_MESSAGE]: (state, action) => {
      const { message } = action.payload;

      return Object.assign({}, state, {
        showMessage: true,
        message
      });
    },
    [HIDE_MESSAGE]: state => {
      return Object.assign({}, state, {
        showMessage: false,
        message: ""
      });
    },
    [CHANGE_LANGUAGE]: (state, action) => {
      return Object.assign({}, state, {
        language: action.payload
      });
    }
  },
  defaultState
);
