import {connect} from "react-redux";
import Biomarkers from "./biomarkers";
import action from "../../../../flux/action";

const mapStateToProps = (state) => {
  const {dashboard: dashboardState} = state;
  const {forecasted, plotConfig, treatmentMonth} = dashboardState;

  const {calprotectin, crp} = forecasted;

  return {
    data: {
      calprotectin,
      crp
    },
    plotConfig: {
      calprotectin: plotConfig["calprotectin"],
      crp: plotConfig["crp"]
    },
    treatmentMonth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editDashboard: (options) => {
      dispatch(action.editDashboard(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Biomarkers);
