import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import {
  // SelectMenu,
  // CommonInput,
  // RadioGroup,
  CheckExpansionPanel
  // MultiDatePicker
} from "ui-toolkit";
// import CommonField from "../../../../components/commonField";
// import FiledLabel from "../../../../components/fieldLabel";
import {
  // drugConfig,
  // numberInputValidation,
  biologicOther,
  // integerInputValidation,
  // loadIntl,
  // localOptionsIntl
} from "../../../../model";
import Drug from "./drug";
import { getWrappedInstance } from "../../../../utils";

class OtherBiologics extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      // duractionTreatment: React.createRef(),
      // doseFrequency: React.createRef(),
      // frequency: React.createRef(),
      // immunogenicity: React.createRef()
      // increase: React.createRef()
    };
    this.state = {
      checked: false
    };
    this.treatmentInitial = _.cloneDeep(props.treatment);
  }

  handleChange(name, value) {
    const {treatment, onChange } = this.props;
    // onChange(treatment, name, value);
    onChange(
      Object.assign({}, treatment, {
        [name]: value
      })
    );
  }

  handleSwitchChange(){
    const {checked} = this.state;
    const {treatment, onChange } = this.props;
    this.treatmentInitial = _.cloneDeep(treatment);
    if(checked){
      this.treatmentInitial["anti-tnf-adalimumab"]["checked"] = false;
      this.treatmentInitial["anti-tnf-infliximab"]["checked"] = false;
      this.treatmentInitial["anti-il-ustekinumab"]["checked"] = false;
    }
    onChange(
      Object.assign({}, treatment, this.treatmentInitial)
    );
    this.setState({
      checked: !checked
    });
    this.reset();
  }

  reset() {
    _.each(this.inputRefs, ref => {
      if (ref && ref.current) {
        getWrappedInstance(ref.current).reset();
      }
    });
  }

  getDrugs() {
    const { treatment, intl } = this.props;
    const { formatMessage } = intl;
    const tooltipContent = [];
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail1" }));
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail2" }));
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail3" }));
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail4" }));


    if (treatment) {
      return (
        <div className="drugs">
          {_.map(biologicOther, (otherDrug, index) => {
            const { label, value } = otherDrug;
            this.inputRefs[value] = React.createRef();
            return (
              <Drug
                ref={this.inputRefs[value]}
                key={`d-drug-other-${index}`}
                label={formatMessage({ id: label })}
                name={value}
                data={treatment[value]}
                onChange={this.handleChange.bind(this, value)}
                tooltipContent={tooltipContent}
              />
            );
          })}
        </div>
      );
    }
  }

  render() {
    const {checked} = this.state;
    const {label, intl} = this.props;
    const { formatMessage } = intl;
    return (
      <div className="drug">
        <CheckExpansionPanel
          color="primary"
          label={formatMessage({id: label})}
          checked={checked}
          onChange={this.handleSwitchChange.bind(this)}
        >
          {this.getDrugs()}
        </CheckExpansionPanel>
      </div>
    );
  }
}

OtherBiologics.defaultProps = {
  name: "",
  label: "",
  data: {},
  onChange: () => {}
};

OtherBiologics.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  onChange: PropTypes.func,
  tooltipContent: PropTypes.array,
  intl: intlShape.isRequired,
  treatment: PropTypes.object
};

export default injectIntl(OtherBiologics, {
  withRef: true
});
