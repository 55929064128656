import React, {Component} from "react";
import PropTypes from "prop-types";
// import {List, ListItem, ListItemText} from "@material-ui/core";
import { injectIntl, intlShape } from "react-intl";
// import { SwitchExpansionPanel } from "ui-toolkit";
// import Icon from "@material-ui/core/Icon";
class Disclaimer extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const {className, intl} = this.props;
    const {formatMessage} = intl;
    return (
      <div className="disclaimer">
        {/* <List className={className}>
          <ListItem>
            <ListItemText>{formatMessage({id: "common.disclaimer.point1"})}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>{formatMessage({id: "common.disclaimer.point2"})}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>{formatMessage({id: "common.disclaimer.point3"})}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>{formatMessage({id: "common.disclaimer.point4"})}</ListItemText>
          </ListItem>
        </List> */}
        <ul className={className}>
          <li>{formatMessage({id: "common.disclaimer.point1"})}</li>
          <li>{formatMessage({id: "common.disclaimer.point2"})}</li>
          <li>{formatMessage({id: "common.disclaimer.point3"})}</li>
          <li>{formatMessage({id: "common.disclaimer.point4"})}</li>
          <li>{formatMessage({id: "common.disclaimer.point5"})}</li>
        </ul>
      </div>
    );
  }
}

Disclaimer.defaultProps = {};

Disclaimer.propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(Disclaimer);