import React, { Component } from "react";
import PropTypes from "prop-types";
import errorPic from "../../../assets/image-oops@3x.png";
import { naviTo } from "../../utils";
import { injectIntl, intlShape } from "react-intl";
class Error extends Component {
  constructor(props) {
    super(props);
  }

  handleClick() {
    naviTo("/app/dashboard");
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="error-container">
        <i className="material-icons" onClick={this.handleClick.bind(this)}>
          close
        </i>
        <div className="error-text">
          <div className="error-title">
            {formatMessage({ id: "error.title" })}
          </div>
          <div className="error-message">
            {formatMessage({ id: "error.message" })}
          </div>
          <div className="contact">
            {formatMessage({ id: "error.contact" })}
            <a
              href="mailto:US_bodylogical_info@pwc.com?Subject=Learn%20more%20about%20Bodylogical%20publications"
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage({ id: "error.contact.us" })}
            </a>
            {formatMessage({ id: "error.contact.us.period" })}
          </div>
        </div>
        <img
          className="error-img"
          src={errorPic}
          onClick={this.handleClick.bind(this)}
        />
      </div>
    );
  }
}

Error.defaultProps = {};

Error.propTypes = {
  editPersona: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired
};

export default injectIntl(Error, { withRef: true });
