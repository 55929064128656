// import _ from "lodash";

const isValuesEqual = (propNames, nextProps, props) => {
  for (let i = 0, len = propNames.length; i < len; i++) {
    const propName = propNames[i];

    if (nextProps[propName] !== props[propName]) {
      return true;
    }
  }

  return false;
};

const getWrappedInstance = instance => {
  if (
    instance &&
    instance.constructor &&
    instance.getWrappedInstance
  ) {
    return instance.getWrappedInstance();
  } else {
    return instance;
  }
};

const getYesNoFormat = (value, formatMessage) => {
  if(value == "yes" || value == "Yes"){
    return formatMessage({id: "persona.options.yesno.yes"});
  } else if(value == "no" || value == "No"){
    return formatMessage({id: "persona.options.yesno.no"});
  }
};

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};


export { isValuesEqual, getWrappedInstance, getYesNoFormat, validateEmail };
