import {connect} from "react-redux";
import ProposedTreatment from "./proposedTreatment";
import action from "../../../../flux/action";

const mapStateToProps = (state) => {
  const {dashboard: dashboardState} = state;
  const {treatment, proposedTreatment} = dashboardState;

  return {
    treatment,
    proposedTreatment
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editDashboard: (options) => {
      dispatch(action.editDashboard(options));
    },
    showMessage: (options) => {
      dispatch(action.showMessage(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(ProposedTreatment);
