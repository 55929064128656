const en = {
  "persona.sidebar.control.panel.title": "Patient's Condition",
  "persona.sidebar.action.label": "Select",
  "persona.sidebar.action.back": "Back",
  "persona.sidebar.action.next": "Next",
  "persona.sidebar.action.confirm": "Confirm",
  "persona.sidebar.action.save": "Save",
  "persona.sidebar.action.cancel": "Cancel",
  "persona.sidebar.reset.label": "Reset",
  "persona.sidebar.patientInfo.title": "Patient information",
  "persona.sidebar.patientInfo.age": "AGE",
  "persona.sidebar.patientInfo.agerange": "PERSONA AGE RANGE",
  "persona.sidebar.patientInfo.weight": "WEIGHT (kg)",
  "persona.sidebar.patientInfo.gender": "SEX",
  "persona.sidebar.patientInfo.bmi": "BMI (kg/m^2)",
  "persona.sidebar.patientInfo.diseasePropensity": "DISEASE PROPENSITY",
  "persona.sidebar.patientInfo.tobaccoUse": "TOBACCO USE",
  "persona.sidebar.patientInfo.option.below": "BELOW 55kg",
  "persona.sidebar.patientInfo.option.above": "ABOVE 85kg",
  "persona.sidebar.patientInfo.option.normal": "55kg - 85kg",
  "persona.sidebar.diseaseState.title": "Disease state",
  "persona.sidebar.diseaseState.desc": "Biomarkers",
  "persona.sidebar.diseaseState.location": "Location",
  "persona.sidebar.diseaseState.biomarker.calprotectin": "CALPROTECTIN (μg/g)",
  "persona.sidebar.diseaseState.biomarker.lactoferrin": "LACTOFERRIN (mg/dL)",
  "persona.sidebar.diseaseState.biomarker.crp": "CRP (mg/dL)",
  "persona.sidebar.diseaseState.biomarker.il_6": "IL-6 (mg/L)",
  "persona.sidebar.diseaseState.biomarker.albumin": "ALBUMIN (g/L)",
  "persona.sidebar.diseaseState.biomarker.lrg": "LRG (mg/L)",
  "persona.sidebar.diseaseState.intestine.ulcerArea": "SURFACE AREA WITH ULCERS(%)",
  "persona.sidebar.diseaseState.intestine.diseaseArea": "SURFACE AREA INVOLVED (%)",
  "persona.sidebar.diseaseState.intestine.ulcerArea.noper": "SURFACE AREA WITH ULCERS",
  "persona.sidebar.diseaseState.intestine.diseaseArea.noper": "SURFACE INVOLVED",
  "persona.sidebar.diseaseState.intestine.ulcerArea.lower": "Surface area with ulcers",
  "persona.sidebar.diseaseState.intestine.diseaseArea.lower": "Surface involved",
  "persona.sidebar.diseaseState.intestine.ulcerType.lower": "Ulcer type",
  // "persona.sidebar.diseaseState.intestine.ulcerArea": "ULCER AREA(%)",
  // "persona.sidebar.diseaseState.intestine.diseaseArea": "DISEASE AREA (%)",
  "persona.sidebar.diseaseState.intestine.ulcerType": "ULCER TYPE",
  "persona.sidebar.diseaseState.intestine.coverageAge": "COVERAGE AREA (%)",
  "persona.sidebar.diseaseState.intestine.type": "TYPE",
  "persona.sidebar.diseaseState.intestine.penetrating": "PENETRATING",
  "persona.sidebar.diseaseState.intestine.stenosis": "STENOSIS",
  "persona.sidebar.diseaseState.intestine.subScore": "SES-CD SUB-SCORE",
  "persona.sidebar.diseaseState.diseaseHistory": "Disease history",
  "persona.sidebar.diseaseState.diseaseHistory.perianal":
    "PERIANAL MANIFESTATION",
  "persona.sidebar.diseaseState.diseaseHistory.diseaseDuraction":
    "CROHN'S DISEASE DURATION (Years)",
  "persona.sidebar.diseaseState.diseaseHistory.diseaseDuration":
    "Disease duration",
  "persona.sidebar.diseaseState.diseaseHistory.previousSurgery":
    "PREVIOUS RESECTION",
  "persona.sidebar.diseaseState.diseaseDuration.option1": "Less than 1 year",
  "persona.sidebar.diseaseState.diseaseDuration.option2": "1 to 5 years",
  "persona.sidebar.diseaseState.diseaseDuration.option3": "5 - 10 years",
  "persona.sidebar.diseaseState.diseaseDuration.option4": "10+ years",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option1": "None",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option2": "< 10%",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option3": "10% - 30%",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option4": "> 30%",
  "persona.sidebar.diseaseState.surfaceInvolved.option1": "Unaffected",
  "persona.sidebar.diseaseState.surfaceInvolved.option2": "< 50%",
  "persona.sidebar.diseaseState.surfaceInvolved.option3": "50% - 75%",
  "persona.sidebar.diseaseState.surfaceInvolved.option4": "> 75%",
  "persona.sidebar.historicIntervention.biologic.treatment.status": "Biologic Treatment Status",
  "persona.sidebar.historicIntervention.biologic.naive": "BIOLOGIC NAIVE",
  "persona.sidebar.historicIntervention.previous.biologic.treatment": "PREVIOUS BIOLOGIC TREATMENT",
  "persona.sidebar.historicIntervention.title": "Historic intervention",
  "persona.sidebar.historicIntervention.desc": "Pharmacology",
  "persona.sidebar.historicIntervention.initResponse": "INITIAL RESPONSE",
  "persona.sidebar.historicIntervention.initialResponse.lower": "Initial Response",
  "persona.sidebar.historicIntervention.lossOfResponse": "LOSS OF RESPONSE",
  "persona.sidebar.historicIntervention.lossOfResponse.lower": "Loss of Response",
  "persona.sidebar.historicIntervention.developedResistance":
    "DEVELOPED RESISTANCE",
  "persona.sidebar.historicIntervention.treatment.history":
    "Treatment History",
  "persona.sidebar.historicIntervention.current": "CURRENT",
  "persona.sidebar.historicIntervention.duractionTreatment":
    "DURATION OF TREATMENT (weeks)",
  "persona.sidebar.historicIntervention.duractionTreatment.lower":
    "Duration of Treatment (weeks)",
  "persona.sidebar.historicIntervention.frequency": "DOSE AND FREQUENCY",
  "persona.sidebar.historicIntervention.troughLevel": "TROUGH LEVEL",
  "persona.sidebar.historicIntervention.antibodyTiter": "ANTIBODY TITER",

  "persona.sidebar.historicIntervention.timeLastDose": "TIME SINCE LAST DOSE (weeks)",
  "persona.sidebar.historicIntervention.responsive": "RESPONSIVE",
  "persona.sidebar.historicIntervention.treatment": "TREATMENT",
  "persona.sidebar.historicIntervention.initialResponse": "INITIAL RESPONSE",
  "persona.sidebar.historicIntervention.timeTreatmentEnd": "TIME SINCE TREATMENT END (weeks)",
  "persona.sidebar.historicIntervention.clinicalOutcome": "CLINICAL OUTCOME",
  "persona.sidebar.historicIntervention.objectiveOutcome": "OBJECTIVE OUTCOME",

  "persona.sidebar.treatment.history":"Treatment history",

  "persona.sidebar.duractionTreatment.1w": "1 week",
  "persona.sidebar.duractionTreatment.2w": "2 weeks",
  "persona.sidebar.duractionTreatment.>2w": ">2 weeks",
  "persona.sidebar.duractionTreatment.4w~8w": "4 weeks to 8 weeks",
  "persona.sidebar.duractionTreatment.8w~24w": "8 weeks to 24 weeks",
  "persona.sidebar.duractionTreatment.>24w": ">24 weeks",
  "persona.sidebar.time.last.dose.<1w": "<1 week",
  "persona.sidebar.time.last.dose.1w~2w": "1 week to 2 weeks",
  "persona.sidebar.time.last.dose.>2w": ">2 weeks",
  "persona.sidebar.time.last.dose.<4w": "<4 weeks",
  "persona.sidebar.time.last.dose.4w~24w": "4 weeks to 24 weeks",
  "persona.sidebar.time.last.dose.>24w": ">24 weeks",
  "persona.sidebar.time.treatment.end.<5w": "<5 weeks",
  "persona.sidebar.time.treatment.end.4w~8w": "4 weeks to 8 weeks",
  "persona.sidebar.time.treatment.end.8w~1y": "8 weeks to 1 year",
  "persona.sidebar.time.treatment.end.>1y": ">1 year",
  "persona.sidebar.treatment.budensonide": "Budensonide",
  "persona.sidebar.treatment.prednisone": "Prednisone",
  "persona.sidebar.treatment.azathioprine": "Azathioprine",
  "persona.sidebar.treatment.mercaptopurine": "Mercaptopurine",
  "persona.sidebar.treatment.methotrexate": "Methotrexate",
  "persona.sidebar.treatment.adalimumab": "Adalimumab induction",
  "persona.sidebar.treatment.adalimumab+maintnance": "Adalimumab induction + maintenance",
  "persona.sidebar.treatment.infliximab": "Infliximab induction",
  "persona.sidebar.treatment.infliximab+maintenance": "Infliximab induction + maintenance",
  "persona.sidebar.treatment.vedolizumab": "Vedolizumab induction",
  "persona.sidebar.treatment.vedolizumab+maintnance": "Vedolizumab induction + maintenance",
  "persona.sidebar.treatment.ustekinumab": "Ustekinumab induction",
  "persona.sidebar.treatment.ustekinumab+maintnance": "Ustekinumab induction + maintenance",
  "persona.sidebar.treatment.other": "Other",
  "persona.sidebar.initial.response.title": "Responder Profile",
  "persona.sidebar.initial.response.response": "Response",
  "persona.sidebar.initial.response.non-responder": "Non-responder",
  "persona.sidebar.initial.response.presponder": "Responder",
  "persona.sidebar.initial.response.responder": "Responder",
  "persona.sidebar.initial.response.fast-responder": "Early responder",
  "persona.sidebar.initial.response.loss-of-response": "Loss of response",
  "persona.sidebar.clinical.outcome.none": "None",
  "persona.sidebar.clinical.outcome.response": "Response",
  "persona.sidebar.clinical.outcome.no-response": "No response",
  "persona.sidebar.clinical.outcome.loss-of-response": "Loss of response",
  "persona.sidebar.objective.outcome.none": "No response",
  "persona.sidebar.objective.outcome.endoscopic-response": "Endoscopic response",
  "persona.sidebar.objective.outcome.endoscopic-remission": "Endoscopic remission",
  "persona.sidebar.objective.outcome.loss-of-response": "Loss of response",

  "persona.sidebar.content.tooltip.detail1": "‘Yes’ indicates that the system will match virtual patients that did respond to the drug.",
  "persona.sidebar.content.tooltip.detail2": "‘No’ indicates virtual patients that did not show a response are selected. ",
  "persona.sidebar.content.tooltip.detail3": "For biologicals, the options reflect different types of responses.",

  "persona.sidebar.save.persona.name": "Persona Name",
  "persona.sidebar.save.persona.desc": "Persona Description",
  "persona.sidebar.save.persona": "SAVE PERSONA",
  "persona.sidebar.save.persona.error":"Special characters % ' \"\" - _ @ = [] & + , .  are only allowed"
};

const ja = {
  "persona.sidebar.control.panel.title": "患者の状態",
  "persona.sidebar.action.label": "選択",
  "persona.sidebar.action.back": "戻る",
  "persona.sidebar.action.next": "次へ",
  "persona.sidebar.action.confirm": "選択",
  "persona.sidebar.action.save": "保存",
  "persona.sidebar.action.cancel": "キャンセル",
  "persona.sidebar.reset.label": "リセット",
  "persona.sidebar.patientInfo.title": "患者情報",
  "persona.sidebar.patientInfo.age": "年齢",
  "persona.sidebar.patientInfo.agerange": "ペルソナ 年代",
  "persona.sidebar.patientInfo.weight": "体重 (kg)",
  "persona.sidebar.patientInfo.gender": "性別",
  "persona.sidebar.patientInfo.persona.gender": "ペルソナ 性別",
  "persona.sidebar.patientInfo.bmi": "BMI (kg/m2)",
  "persona.sidebar.patientInfo.diseasePropensity": "体質",
  "persona.sidebar.patientInfo.tobaccoUse": "喫煙習慣",
  "persona.sidebar.patientInfo.option.below": "未満 55kg",
  "persona.sidebar.patientInfo.option.above": "85kg 以上",
  "persona.sidebar.patientInfo.option.normal": "55kg - 85kg",
  "persona.sidebar.diseaseState.title": "病状",
  "persona.sidebar.diseaseState.desc": "バイオマーカー",
  "persona.sidebar.diseaseState.location": "炎症部位",
  "persona.sidebar.diseaseState.biomarker.calprotectin":
    "カルプロテクチン (μg/g)",
  "persona.sidebar.diseaseState.biomarker.lactoferrin": "ラクトフェリン (mg/L)",
  "persona.sidebar.diseaseState.biomarker.crp": "CRP (mg/dL)",
  "persona.sidebar.diseaseState.biomarker.il_6": "IL-6 (mg/L)",
  "persona.sidebar.diseaseState.biomarker.albumin": "アルブミン (g/L)",
  "persona.sidebar.diseaseState.biomarker.lrg": "LRG (mg/L)",
  "persona.sidebar.diseaseState.intestine.ulcerArea": "潰瘍面積 (%)",
  "persona.sidebar.diseaseState.intestine.diseaseArea": "病変面積 (%)",
  "persona.sidebar.diseaseState.intestine.ulcerArea.noper": "潰瘍面積",
  "persona.sidebar.diseaseState.intestine.diseaseArea.noper": "病変面積",
  "persona.sidebar.diseaseState.intestine.ulcerType": "潰瘍の大きさ",
  "persona.sidebar.diseaseState.intestine.ulcerArea.lower": "潰瘍面積",
  "persona.sidebar.diseaseState.intestine.diseaseArea.lower": "病変面積",
  "persona.sidebar.diseaseState.intestine.ulcerType.lower": "潰瘍の大きさ",
  "persona.sidebar.diseaseState.intestine.coverageAge": "カバー範囲 (%)",
  "persona.sidebar.diseaseState.intestine.type": "TYPE",
  "persona.sidebar.diseaseState.intestine.penetrating": "貫通性",
  "persona.sidebar.diseaseState.intestine.stenosis": "狭窄",
  "persona.sidebar.diseaseState.intestine.subScore": "Ses-CD サブスコア",
  "persona.sidebar.diseaseState.diseaseHistory": "病歴",
  "persona.sidebar.diseaseState.diseaseHistory.perianal": "肛門周辺の症状",
  "persona.sidebar.diseaseState.diseaseHistory.diseaseDuraction":
    "罹患期間 (年)",
  "persona.sidebar.diseaseState.diseaseHistory.diseaseDuration":
    "罹患歴",
  "persona.sidebar.diseaseState.diseaseHistory.previousSurgery": "手術経験",
  "persona.sidebar.diseaseState.diseaseDuration.option1": "1年未満",
  "persona.sidebar.diseaseState.diseaseDuration.option2":"1 - 5年",
  "persona.sidebar.diseaseState.diseaseDuration.option3":"5 - 10年",
  "persona.sidebar.diseaseState.diseaseDuration.option4": "10年以上",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option1": "なし",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option2": "< 10%",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option3": "10% - 30%",
  "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option4": "> 30%",
  "persona.sidebar.diseaseState.surfaceInvolved.option1": "なし",
  "persona.sidebar.diseaseState.surfaceInvolved.option2": "< 50%",
  "persona.sidebar.diseaseState.surfaceInvolved.option3": "50% - 75%",
  "persona.sidebar.diseaseState.surfaceInvolved.option4": "> 75%",
  "persona.sidebar.historicIntervention.title": "介入歴 / プログラム",
  "persona.sidebar.historicIntervention.treatment.history":
    "治療歴",
  "persona.sidebar.historicIntervention.biologic.treatment.status": "生物製剤投与歴",
  "persona.sidebar.historicIntervention.biologic.naive": "生物製剤歴なし",
  "persona.sidebar.historicIntervention.previous.biologic.treatment":"生物製剤歴あり",
  "persona.sidebar.historicIntervention.desc": "薬理学",
  "persona.sidebar.historicIntervention.initResponse": "初期対応",
  "persona.sidebar.historicIntervention.developedResistance":
    "開発された抵抗力",
  "persona.sidebar.historicIntervention.current": "現在",
  "persona.sidebar.historicIntervention.duractionTreatment":
    "治療期間 (週)",
  "persona.sidebar.historicIntervention.duractionTreatment.lower":
    "治療期間 (週)",
  "persona.sidebar.historicIntervention.frequency": "線量と頻度",
  "persona.sidebar.historicIntervention.troughLevel": "トラフ濃度",
  "persona.sidebar.historicIntervention.antibodyTiter": "抗体力価",

  "persona.sidebar.historicIntervention.timeLastDose": "最終投与からの年月 (週)",
  "persona.sidebar.historicIntervention.responsive": "反応",
  "persona.sidebar.historicIntervention.treatment": "治療",
  "persona.sidebar.historicIntervention.initialResponse": "初期反応",
  "persona.sidebar.historicIntervention.initialResponse.lower": "初期反応",
  "persona.sidebar.historicIntervention.lossOfResponse": " 効果減弱",
  "persona.sidebar.historicIntervention.lossOfResponse.lower": "効果減弱",
  "persona.sidebar.historicIntervention.timeTreatmentEnd": "治療終了からの年月 (週)",
  "persona.sidebar.historicIntervention.clinicalOutcome": "数値の変化",
  "persona.sidebar.historicIntervention.objectiveOutcome": "内視鏡所見の変化",

  "persona.sidebar.treatment.history":"治療歴",

  "persona.sidebar.duractionTreatment.1w": "1 week",
  "persona.sidebar.duractionTreatment.2w": "2 weeks",
  "persona.sidebar.duractionTreatment.>2w": ">2 weeks",
  "persona.sidebar.duractionTreatment.4w~8w": "4 weeks to 8 weeks",
  "persona.sidebar.duractionTreatment.8w~24w": "8 weeks to 24 weeks",
  "persona.sidebar.duractionTreatment.>24w": ">24 weeks",
  "persona.sidebar.time.last.dose.<1w": "<1 week",
  "persona.sidebar.time.last.dose.1w~2w": "1 week to 2 weeks",
  "persona.sidebar.time.last.dose.>2w": ">2 weeks",
  "persona.sidebar.time.last.dose.<4w": "<4 weeks",
  "persona.sidebar.time.last.dose.4w~24w": "4 weeks to 24 weeks",
  "persona.sidebar.time.last.dose.>24w": ">24 weeks",
  "persona.sidebar.time.treatment.end.<5w": "<5 weeks",
  "persona.sidebar.time.treatment.end.4w~8w": "4 weeks to 8 weeks",
  "persona.sidebar.time.treatment.end.8w~1y": "8 weeks to 1 year",
  "persona.sidebar.time.treatment.end.>1y": ">1 year",
  "persona.sidebar.treatment.budensonide": "ブデソニド",
  "persona.sidebar.treatment.prednisone": "プレドニゾン",
  "persona.sidebar.treatment.azathioprine": "アザチオプリン",
  "persona.sidebar.treatment.mercaptopurine": "メルカプトプリン",
  "persona.sidebar.treatment.methotrexate": "メトトレキサート",
  "persona.sidebar.treatment.adalimumab": "アダリムマブ導入",
  "persona.sidebar.treatment.adalimumab+maintnance": "アダリムマブ導入+維持",
  "persona.sidebar.treatment.infliximab": "インフリキシマブ導入",
  "persona.sidebar.treatment.infliximab+maintenance": "インフリキシマブ導入+維持",
  "persona.sidebar.treatment.vedolizumab": "ベドリズマブ導入",
  "persona.sidebar.treatment.vedolizumab+maintnance": "ベドリズマブ導入+維持",
  "persona.sidebar.treatment.ustekinumab": "ウステキヌマブ導入",
  "persona.sidebar.treatment.ustekinumab+maintnance": "ウステキヌマブ導入+維持",
  "persona.sidebar.treatment.other": "その他",
  "persona.sidebar.initial.response.title": "治療反応性",
  "persona.sidebar.initial.response.response": "反応あり",
  "persona.sidebar.initial.response.non-responder": "反応なし",
  "persona.sidebar.initial.response.responder": "遅い反応",
  "persona.sidebar.initial.response.fast-responder": "速い反応",
  "persona.sidebar.initial.response.loss-of-response": "反応消失",
  "persona.sidebar.clinical.outcome.none": "情報なし",
  "persona.sidebar.clinical.outcome.response": "反応あり",
  "persona.sidebar.clinical.outcome.no-response": "反応なし",
  "persona.sidebar.clinical.outcome.loss-of-response": "反応消失",
  "persona.sidebar.objective.outcome.none": "情報なし",
  "persona.sidebar.objective.outcome.endoscopic-response": "内視鏡的反応",
  "persona.sidebar.objective.outcome.endoscopic-remission": "内視鏡的寛解",
  "persona.sidebar.objective.outcome.loss-of-response": "反応消失",

  "persona.sidebar.content.tooltip.detail1": "「はい」を選択すると治療反応性のあるバーチャル患者を適用します。",
  "persona.sidebar.content.tooltip.detail2": "「いいえ」を選択すると治療反応性のないバーチャル患者を適用します。",
  "persona.sidebar.content.tooltip.detail3": "生物学的製剤については、選択によって反応の種類が異なります。",

  "persona.sidebar.save.persona.name": "ペルソナの名前",
  "persona.sidebar.save.persona.desc": "説明",
  "persona.sidebar.save.persona": "ペルソナを保存",
  "persona.sidebar.save.persona.confirm": "選択",
  "persona.sidebar.save.persona.error":"特殊文字は使用できません % ' \"\" - _ @ = [] & + , ."
};

export default {
  en,
  ja
};
