import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Tabs, Tab} from "@material-ui/core";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import actions from "../flux/action";
import buildNewPatientGreyIcon from "../../assets/icons/ico_build_new_patient_grey.png";
import buildNewPatientRedIcon from "../../assets/icons/ico_build_new_patient_red.png";
import customPatientsGreyIcon from "../../assets/icons/ico_custom_patients_grey.png";
import customPatientsRedIcon from "../../assets/icons/ico_custom_patients_red.png";
import presetPersonaGreyIcon from "../../assets/icons/ico_preset_persona_grey.png";
import presetPersonaRedIcon from "../../assets/icons/ico_preset_persona_red.png";
import classnames from "classnames";
//import {injectIntl, intlShape} from "react-intl";
//import {changeLanguageAction} from "../flux/action";

class AppSubHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedTab: ""
    };
  }

  static getDerivedStateFromProps(props, state){
    const {label} = props;
    let {selectedTab} = state;
    if(!selectedTab || (selectedTab !== label)){
      return {
        selectedTab: label || "presetPersona"
      };
    }
    return state;
  }

  handleTabChange(e, value) {
    const {basePath, history, onChange} = this.props;
    this.setState({
      selectedTab: value
    });
    onChange(value);
    history.push(`${basePath}/persona/${value}`);
  }

  getTabIcon(name, selectedTab){
    if(name == "presetPersona"){
      if(selectedTab == "presetPersona"){
        return(
          <img src={presetPersonaRedIcon} className="subheader-tab-icon"/>
        );
      } else {
        return(
          <img src={presetPersonaGreyIcon} className="subheader-tab-icon"/>
        );
      }
    } else if(name == "customPatients"){
      if(selectedTab == "customPatients"){
        return(
          <img src={customPatientsRedIcon} className="subheader-tab-icon"/>
        );
      } else {
        return(
          <img src={customPatientsGreyIcon} className="subheader-tab-icon"/>
        );
      }
    } else if(name == "newPatient"){
      if(selectedTab == "newPatient"){
        return(
          <img src={buildNewPatientRedIcon} className="subheader-tab-icon"/>
        );
      } else {
        return(
          <img src={buildNewPatientGreyIcon} className="subheader-tab-icon"/>
        );
      }
    }
  }
  getLabel(label){
    const {intl} = this.props;
    const { formatMessage } = intl;
    return(
      <div className="subheader-label-text">{formatMessage({id: label})}</div>
    );
  }

  isSidebarOpened(){
    const {isSelected} = this.props;
    return isSelected;
  }

  render() {
    const {subroutes, intl, personaSelected} = this.props;
    const {selectedTab} = this.state;
    const { formatMessage } = intl;

    let route = _.find(subroutes, item=>{
      return item.path == selectedTab;
    });

    return (
      <div className="app-subheader">
        <div className={classnames({
          "subheader-desc": true,
          "sidebar": !_.isEmpty(personaSelected)
        })}>
          {formatMessage({id: route.desc})}
        </div>
        <Tabs
          classes={{
            root: "app-subtabs",
            indicator: "transparent-background"
          }}
          indicatorColor="primary"
          value={selectedTab}
          onChange={this.handleTabChange.bind(this)}
        >
          {_.map(subroutes, (subroute, i) => {
            const {path, label} = subroute;
            return (
              <Tab
                classes={{
                  selected: "app-tab-selected",
                  wrapper: "flex-row"
                }}
                key={i}
                wrapped={true}
                icon={this.getTabIcon(path, selectedTab)}
                label={this.getLabel(label)}
                value={path}
              />
            );
          })}
        </Tabs>
      </div>
    );
  }
}

AppSubHeader.defaultProps = {
  subroutes: [],
  basePath: "",
  history: {},
  label: ""
};

AppSubHeader.propTypes = {
  classes: PropTypes.object,
  subroutes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  onChange: PropTypes.func,
  location: PropTypes.object,
  personaSelected: PropTypes.string,
  isSelected: PropTypes.bool
};

const mapStateToProps = (state) => {
  const {common: commonState} = state;
  const {language} = commonState;
  const { persona: personaState } = state;
  const {
    isSelected,
    personaSelected
  } = personaState;

  return {language,
    isSelected,
    personaSelected};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSubHeader));
