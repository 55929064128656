import React from "react";

function biologicalContent(formatMessage) {
  return (
    <div>
      <div className="biological-content">
        <div className="title">{formatMessage({id:"model.content.biological.content.title1"})}</div>
        <ul>
          <li className="first-level">
            {formatMessage({id:"model.content.biological.content1"})}
          </li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="biological-content">
        <div className="title">{formatMessage({id:"model.content.biological.content.title2"})}</div>
        <ul>
          <li className="first-level">
            {formatMessage({id:"model.content.biological.content2"})}
          </li>
        </ul>
        <ul>
          <li className="first-level">
            {formatMessage({id:"model.content.biological.content3"})}
          </li>
        </ul>
        <ul>
          <li className="first-level">
            {formatMessage({id:"model.content.biological.content4"})}
          </li>
        </ul>
        <ul>
          <li className="first-level">
            {formatMessage({id:"model.content.biological.content5"})}
          </li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="biological-content">
        <div className="title">
          {formatMessage({id:"model.content.biological.content.title3"})}
        </div>
        <ul>
          <li className="first-level">{formatMessage({id:"model.content.biological.content6"})}</li>
        </ul>
        <ul>
          <li className="first-level">{formatMessage({id:"model.content.biological.content7"})}</li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="biological-content">
        <div className="title">
          {formatMessage({id:"model.content.biological.content.title4"})}
        </div>
      </div>
      <div className="dashed-divider" />
      <div className="biological-content">
        <div className="title">
          {formatMessage({id:"model.content.biological.content.title5"})}
        </div>
      </div>
      <div className="dashed-divider" />
      <div className="biological-content">
        <div className="title">
          {formatMessage({id:"model.content.biological.content.title6"})}
        </div>
      </div>
      <div className="dashed-divider" />
      <div className="biological-content">
        <div className="title">
          {formatMessage({id:"model.content.biological.content.title7"})}
        </div>
      </div>
    </div>
  );
}

export { biologicalContent };
