import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import InfoSection from "../../../../components/infoSection";
import Pharmacology from "./pharmacology";
import { historicInterventionOptions } from "../../../../model";
import { getWrappedInstance } from "../../../../utils";
import classnames from "classnames";

class HistoricIntervention extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.historicIntervention = _.cloneDeep(props.historicIntervention);
    this.responderProfile = _.cloneDeep(props.responderProfile);
  }

  handleChange(name, value) {
    const { historicIntervention, createPersona } = this.props;
    this.historicIntervention = _.cloneDeep(historicIntervention);
    this.historicIntervention[name] = value;
    if((name == "steroids" && this.historicIntervention["steroids"]["checked"]) || (name == "immunomodulator" && this.historicIntervention["immunomodulator"]["checked"])){
      this.historicIntervention[name]["duractionTreatment"] = 52;
    } else if(
      (name == "anti-tnf" && this.historicIntervention["anti-tnf"]["checked"]) ||
      (name == "anti-il" && this.historicIntervention["anti-il"]["checked"]) ||
      (name == "anti-integrin" && this.historicIntervention["anti-integrin"]["checked"])){
      this.historicIntervention[name]["duractionTreatment"] = 104;
      this.historicIntervention[name]["regime"] = "induction + maintenance";
    } else {
      this.historicIntervention[name]["duractionTreatment"] = "";
      this.historicIntervention[name]["regime"] = "";
    }
    if(this.historicIntervention[name]["initialResponse"] == "no"){
      this.historicIntervention[name]["lossOfResponse"] = "";
    }
    createPersona({
      historicIntervention: Object.assign({}, historicIntervention, this.historicIntervention)
    });
    setTimeout(()=>{
      this.updateResponderProfile();
    },100);
  }

  updateResponderProfile(){
    const {historicIntervention, responderProfile, createPersona} = this.props;
    this.responderProfile = _.cloneDeep(responderProfile);
    let allkeys = Object.keys(responderProfile);
    let keys = allkeys.slice(0,5);
    _.forEach(keys, type=>{
      let drugType = (type == "steroid") ? "steroids" : type;
      if((type == "steroid" && this.responderProfile["isSteroidModified"]) ||
        (type == "immunomodulator" && this.responderProfile["isImmunoModified"]) ||
        (type == "anti-tnf" && this.responderProfile["isAntiTNFModified"]) ||
        (type == "anti-integrin" && this.responderProfile["isAntiIntegrinModified"]) ||
        (type == "anti-il" && this.responderProfile["isAntiILModified"])
      ){
        return;
      }
      if(historicIntervention[drugType]["checked"]){
        if((historicIntervention[drugType]["initialResponse"] == "yes" && historicIntervention[drugType]["lossOfResponse"] == "yes") ||
        (historicIntervention[drugType]["initialResponse"] == "no")){
          this.responderProfile[type] = "non-responder";
        } else if(historicIntervention[drugType]["initialResponse"] == "yes" && historicIntervention[drugType]["lossOfResponse"] == "no"){
          this.responderProfile[type] = "responder";
        } else {
          this.responderProfile[type] = "non-responder";
        }
      } else {
        this.responderProfile[type] = "responder";
      }
      this.responderProfile.completed = true;
      if(this.responderProfile[type] !== responderProfile[type]) {
        createPersona({
          responderProfile: Object.assign({}, responderProfile, this.responderProfile)
        });
      }
    });
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();

        return result && status;
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, ref => {
      getWrappedInstance(ref.current).reset();
    });
  }

  checkIsActiveStep(activeStep){
    return (activeStep == 2) ? true : false;
  }

  render() {
    const { historicIntervention, intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div className={classnames({
        "historic-intervention": true
      })}>
        <InfoSection
          title={formatMessage({
            id: "persona.sidebar.historicIntervention.title"
          })}
        >
          <div className="desc">
            {formatMessage({ id: "persona.sidebar.historicIntervention.desc" })}
          </div>
          {_.map(historicInterventionOptions, (item, index) => {
            const { label, value } = item;
            const pharmacology = historicIntervention[value];
            this.inputRefs[value] = React.createRef();
            return (
              <Pharmacology
                ref={this.inputRefs[value]}
                key={`pharmacology-${index}`}
                label={formatMessage({ id: label })}
                name={value}
                data={pharmacology}
                forceDisable={pharmacology["initialResponse"] == "no"}
                onChange={this.handleChange.bind(this, value)}
                biologyTreatmentStatus={historicIntervention["biologyTreatmentStatus"]}
              />
            );
          })}
        </InfoSection>
      </div>
    );
  }
}

HistoricIntervention.defaultProps = {};

HistoricIntervention.propTypes = {
  historicIntervention: PropTypes.object,
  createPersona: PropTypes.func,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  responderProfile: PropTypes.object
};

export default injectIntl(HistoricIntervention, {
  withRef: true
});
