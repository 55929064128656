const en = {
  "persona.main.container.header": "PERSONA",
  "persona.main.container.subHeader": "Personas are virtual (not real) patients",
  "persona.main.container.title": "Choose from virtual patients",
  "persona.main.container.introduce":
    "These are hypothetical patients that represent commonly observed cases in the clinic, select one to explore the effect of different treatments.",
  "persona.main.container.or": "Or",
  "persona.main.container.custom": "Create custom profile",
  "persona.main.container.custom.introduce":
    "Set a specific patient information, disease state, historic intervention",
  "persona.main.container.userName1": "Jiro Takahashi",
  "persona.main.container.userName2": "Ayumi Tanaka",
  "persona.main.container.userName3": "Masaki Ito",
  "persona.main.container.userName4": "Hanako Yamada",
  "persona.main.container.userName5": "Sakura Suzuki",
  "persona.main.container.userName6": "Taro Sato",

  "persona.main.container.demographics": "Demographics",
  "persona.main.container.disease.state": "Disease State",

  "persona.main.container.disease.state.diseaseDuration":
  "Disease duration (Years)",

  "persona.main.container.detail1": "A young male with moderately severe ileocolonic disease and no complications. Diagnosed 3 years ago.",
  "persona.main.container.detail2": "Female with moderately severe ileocolonic disease and no complications. Diagnosed 5 years ago.",
  "persona.main.container.detail3": "Male with moderately severe ileocolonic disease and no complications. Diagnosed 4 years ago.",
  "persona.main.container.detail4": "Young female with mild ileal disease. Just diagnosed.",
  "persona.main.container.detail5": "Female with moderately aggressive colonic disease and no complications. Diagnosed 5 years ago.",
  "persona.main.container.detail6": "Male with extensive ileocolonic disease. Very large ulcers and constriction in Ileum. Successful surgery for complications.",

  "persona.main.container.title.patientInfo": "PATIENT INFORMATION",
  "persona.main.container.title.diseaseState": "DISEASE STATE",
  "persona.main.container.title.historicIntervention": "HISTORICAL INTERVENTION/PROGRAM",
  "persona.main.container.new.persona.request": "Request New Persona",
  "persona.main.container.title.symptoms": "SYMPTOMS",

  "persona.main.container.profile1.detail.patientInfo": "Mild severity in Ileum and moderate severity in Colon. Steroid non-responder",
  "persona.main.container.profile1.detail.diseaseState": "Ileocolonic disease, 30% ileum impacted with aphthous ulcers, 60% colon impacted with large ulcers",
  "persona.main.container.profile1.detail.historicIntervention": "Treated with Steroids for 2 weeks followed by Vedolizumab induction for 14 weeks.",
  "persona.main.container.profile1.detail.symptoms": "Frequent bowel movements / diarrhea",

  "persona.main.container.profile2.detail.patientInfo": "Mild severity in Ileum and moderate severity in Colon. Steroid non-responder",
  "persona.main.container.profile2.detail.diseaseState": "Ileocolonic disease, 30% ileum impacted with aphthous ulcers, 60% colon impacted with large ulcers",
  "persona.main.container.profile2.detail.historicIntervention": "Treated with Steroids for 2 weeks followed by Vedolizumab induction for 14 weeks.",
  "persona.main.container.profile2.detail.symptoms": "Frequent bowel movements / diarrhea",

  "persona.main.container.profile3.detail.patientInfo": "Mild severity in Ileum and moderate severity in Colon. Steroid non-responder",
  "persona.main.container.profile3.detail.diseaseState": "Ileocolonic disease, 30% ileum impacted with aphthous ulcers, 60% colon impacted with large ulcers",
  "persona.main.container.profile3.detail.historicIntervention": "Treated with Steroids for 2 weeks followed by Vedolizumab induction for 14 weeks.",
  "persona.main.container.profile3.detail.symptoms": "Frequent bowel movements / diarrhea",

  "persona.main.container.profile4.detail.patientInfo": "Nutritional deficiency. Limited tissue damage but elevated inflammatory activity",
  "persona.main.container.profile4.detail.diseaseState": "Mild ileal disease with apthous ulcers",
  "persona.main.container.profile4.detail.historicIntervention": "None.",
  "persona.main.container.profile4.detail.symptoms": "Normal bowel movement",

  "persona.main.container.profile5.detail.patientInfo": "Moderate tissue damage and inflammation",
  "persona.main.container.profile5.detail.diseaseState": "Moderate damage in Colon with aphthous ulcers",
  "persona.main.container.profile5.detail.historicIntervention": "None.",
  "persona.main.container.profile5.detail.symptoms": "Mild discomfort after meals",

  "persona.main.container.profile6.detail.patientInfo": "Significant inflammation with extensive tissue damage and constriction in small intestine. No prior history of biologics.",
  "persona.main.container.profile6.detail.diseaseState": "Extensive damage of small intestine with constriction. Moderate damage in Colon with large ulcers.",
  "persona.main.container.profile6.detail.historicIntervention": "Steroids with last dose more than 12 weeks ago.",
  "persona.main.container.profile6.detail.symptoms": "Frequent bowel movements/diarrhea",
};

const ja = {
  "persona.main.container.header": "ペルソナ・患者例",
  "persona.main.container.subHeader": "これは架空の患者であり、現実には存在しません",
  "persona.main.container.title": "仮想患者から選択",
  "persona.main.container.demographics": "基本情報",
  "persona.main.container.introduce":
    "これは一般的に観察される仮想の患者です ペルソナを選択し最適な治療法を比較します。",
  "persona.main.container.or": "または",
  "persona.main.container.custom": "患者プロフィールを作成",
  "persona.main.container.custom.introduce":
    "特定の患者情報、病状、病歴の設定",
  "persona.main.container.userName1": "高橋　次郎",
  "persona.main.container.userName2": "田中　歩美",
  "persona.main.container.userName3": "伊藤　正樹",
  "persona.main.container.userName4": "山田 花子",
  "persona.main.container.userName5": "鈴木 さくら",
  "persona.main.container.userName6": "佐藤 太郎",

  "persona.main.container.detail1": "回腸結腸に中等度の症状がある若年男性。合併症はなし。３年前にCDと診断。",
  "persona.main.container.detail2": "回腸結腸に中等度の症状がある女性。合併症はなし。５年前にCDと診断。",
  "persona.main.container.detail3": "回腸結腸に中等度の症状がある男性。合併症はなし。４年前にCDと診断。",
  "persona.main.container.detail4": "回腸に軽等度の症状がある若年女性。診断されたばかり。",
  "persona.main.container.detail5": "結腸に中等度の症状がある女性。合併症はなし。５年前にCDと診断。",
  "persona.main.container.detail6": "回腸結腸に重等度の症状がある男性。回腸に非常に大きな潰瘍と狭窄あり。合併症は手術済。",


  "persona.main.container.title.patientInfo": "患者情報",
  "persona.main.container.title.diseaseState": "内視鏡所見",
  "persona.main.container.disease.state": "内視鏡所見",

  "persona.main.container.disease.state.diseaseDuration":
  "罹患歴（年)",

  "persona.main.container.title.historicIntervention": "治療介入歴",
  "persona.main.container.title.symptoms": "症状",
  "persona.main.container.new.persona.request": "新しいペルソナを申請",

  "persona.main.container.profile1.detail.patientInfo": "回腸に軽等度の症状、結腸に中等度の症状。ステロイド効果なし",
  "persona.main.container.profile1.detail.diseaseState": "回腸結腸病変。回腸の30%にアフタ性潰瘍、結腸の60%に大きな潰瘍あり。",
  "persona.main.container.profile1.detail.historicIntervention": "ステロイドを2週間投与した後、14週間VDZ導入。",
  "persona.main.container.profile1.detail.symptoms": "頻繁な排便・下痢",

  "persona.main.container.profile2.detail.patientInfo": "回腸に軽等度の症状、結腸に中等度の症状。ステロイド効果なし。",
  "persona.main.container.profile2.detail.diseaseState": "回腸結腸病変。回腸の30%にアフタ性潰瘍、結腸の60%に大きな潰瘍あり。",
  "persona.main.container.profile2.detail.historicIntervention": "ステロイドを2週間投与した後、14週間VDZ導入。",
  "persona.main.container.profile2.detail.symptoms": "頻繁な排便・下痢",

  "persona.main.container.profile3.detail.patientInfo": "回腸に軽等度の症状、結腸に中等度の症状。ステロイド効果なし。",
  "persona.main.container.profile3.detail.diseaseState": "回腸結腸病変。回腸の30%にアフタ性潰瘍、結腸の60%に大きな潰瘍あり。",
  "persona.main.container.profile3.detail.historicIntervention": "ステロイドを2週間投与した後、14週間VDZ導入。",
  "persona.main.container.profile3.detail.symptoms": "頻繁な排便・下痢",

  "persona.main.container.profile4.detail.patientInfo": "栄養不足、組織損傷は限られているが、炎症活性が高い",
  "persona.main.container.profile4.detail.diseaseState": "回腸に軽等度のアフタ性潰瘍。",
  "persona.main.container.profile4.detail.historicIntervention": "なし",
  "persona.main.container.profile4.detail.symptoms": "通常の排便",

  "persona.main.container.profile5.detail.patientInfo": "中等度の組織損傷と潰瘍あり。",
  "persona.main.container.profile5.detail.diseaseState": "結腸に中等度のアフタ性潰瘍",
  "persona.main.container.profile5.detail.historicIntervention": "なし",
  "persona.main.container.profile5.detail.symptoms": "食後の軽度の不快感",

  "persona.main.container.profile6.detail.patientInfo": "広範囲な組織損傷を伴う重度の潰瘍と小腸に狭窄あり。生物製剤経験なし。",
  "persona.main.container.profile6.detail.diseaseState": "小腸に広範囲な組織損傷と狭窄あり。結腸に大きな潰瘍を伴う中等度の組織損傷。",
  "persona.main.container.profile6.detail.historicIntervention": "12週間以上前を最後にステロイド投与。",
  "persona.main.container.profile6.detail.symptoms": "頻繁な排便・下痢",
};

export default {
  en,
  ja
};
