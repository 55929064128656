import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ControlPanel from "../../../components/controlPanel";
import { injectIntl, intlShape } from "react-intl";
import ProposedTreatment from "./proposedTreatment";
import { getWrappedInstance } from "../../../utils";

import "../../../../styles/dashboard/sidebar.scss";

class DashboardSidebar extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      proposedTreatment: React.createRef()
    };
  }

  handleAction() {
    const {
      archetypeId,
      personaSelected,
      patientInfo,
      diseaseState,
      historicIntervention,
      proposedTreatment,
      responderProfile,
      simulate,
      showMessage,
      editDashboard,
      intl,
      loginState
    } = this.props;
    const { formatMessage } = intl;
    const { practiceMode } = loginState;

    const totalTreatmentDuration = _.reduce(
      proposedTreatment,
      (result, treatment) => {
        const { duractionTreatment } = treatment;
        const value = duractionTreatment ? parseInt(duractionTreatment) : 0;

        return result + value;
      },
      0
    );

    if (totalTreatmentDuration > 1200) {
      showMessage({
        message: formatMessage({
          id: "dashboard.sidebar.treatment.dutaction.no.more.1200"
        })
      });
      return;
    }

    if (this.getStatus()) {
      simulate({
        archetypeId,
        personaSelected,
        patientInfo,
        diseaseState,
        historicIntervention,
        responderProfile,
        proposedTreatment,
        practiceMode
      });
    } else {
      const instance = getWrappedInstance(
        this.inputRefs.proposedTreatment.current
      );
      const treatment = instance.getInvalidatedTreatment();

      editDashboard({
        treatment
      });
    }
  }

  handleReset() {
    const { resetDashboard } = this.props;

    resetDashboard();
    this.reset();
  }

  checkIsTreatmentSelected(){
    let checkedArr = [];
    const {proposedTreatment} = this.props;
    if(proposedTreatment.length){
      proposedTreatment.forEach(items=>{
        if(typeof(items) == "object"){
          _.forIn(items, (item)=>{
            if(typeof(item) == "object" && item.checked){
              checkedArr.push(item.checked);
            }
          });
        }
      });
    }
    return checkedArr.length;
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();

        return result && status;
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, ref => {
      getWrappedInstance(ref.current).reset();
    });
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="dashboard-sidebar">
        <ControlPanel
          closable={false}
          title={formatMessage({ id: "dashboard.sidebar.control.panel.title" })}
          actionLabel={formatMessage({ id: "dashboard.sidebar.action.label" })}
          resetLabel={formatMessage({ id: "dashboard.sidebar.reset.label" })}
          onAction={this.handleAction.bind(this)}
          onReset={this.handleReset.bind(this)}
          actionDisabled={!(this.checkIsTreatmentSelected())}
        />
        <div className="contition-details">
          <ProposedTreatment ref={this.inputRefs.proposedTreatment} />
        </div>
      </div>
    );
  }
}

DashboardSidebar.defaultProps = {};

DashboardSidebar.propTypes = {
  archetypeId: PropTypes.string,
  personaSelected: PropTypes.string,
  patientInfo: PropTypes.object,
  diseaseState: PropTypes.object,
  historicIntervention: PropTypes.object,
  proposedTreatment: PropTypes.array,
  editDashboard: PropTypes.func,
  resetDashboard: PropTypes.func,
  simulate: PropTypes.func,
  showMessage: PropTypes.func,
  intl: intlShape.isRequired,
  responderProfile: PropTypes.object,
  loginState: PropTypes.object
};

export default injectIntl(DashboardSidebar, { withRef: true });
