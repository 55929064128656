import _ from "lodash";
import "chartjs-plugin-annotation";

const getChartOptions = (options = {}) => {
  const { scaleLabelX, scaleLabelY,  min, max,treatmentMonth } = options;
  const chartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        boxWidth: 5,
        usePointStyle: true,
        fontSize: 16
      }
    },
    annotation: {
      annotations: [{
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: treatmentMonth,
        borderColor: "#EE1100",
        borderWidth: 1
      }],
    },
    scales: {
      yAxes: [
        {
          ticks: {
            // max: "",
            // min: "",
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: [],
            lineWidth: 2
          }
        }
      ]
    },
    title: {
      display: false,
      fontStyle: "normal",
      text: "Weight"
    }
  };

  if (scaleLabelX) {
    chartOptions["scales"]["xAxes"][0]["scaleLabel"] = {
      display: true,
      fontStyle: "bold",
      fontSize: "12",
      labelString: scaleLabelX,
      padding: "1"
    };
  }
  if (scaleLabelY) {
    chartOptions["scales"]["yAxes"][0]["scaleLabel"] = {
      display: true,
      fontStyle: "bold",
      fontSize: "12",
      labelString: scaleLabelY
    };
  }

  // if (gridLinesColor) {
  //   chartOptions.scales.xAxes[0].gridLines.color = gridLinesColor;
  // }
  _.assign(chartOptions.scales.yAxes[0].ticks, { min: min, max: max });

  return chartOptions;
};

const getChartConfig = (configs = {}) => {
  const chartConfig = {
    labels: [],
    datasets: [
      {
        label: "",
        fill: false,
        data: [],
        backgroundColor: "#898989",
        borderColor: "#898989",
        borderWidth: 1,
        radius:0,
        hoverRadius:3
      },
      {
        label: "",
        fill: false,
        data: [],
        backgroundColor: "#EE1100",
        borderColor: "#EE1100",
        borderWidth: 1,
        radius:0,
        hoverRadius:3
      }
    ]
  };
  if (_.isEmpty(configs)) {
    return chartConfig;
  }
  const { no_treatment, treatment, labels } = configs;
  chartConfig.datasets[0].label = labels[0];
  chartConfig.datasets[1].label = labels[1];
  chartConfig.datasets[0].data = no_treatment;
  chartConfig.datasets[1].data = treatment;
  return chartConfig;
};

const getDetailOptions = formatMessage => {
  const chartDetailOptions = {
    plasmaDrugConcentration: {
      scaleLabelY: "ug/ml",
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" })
    },
    calprotectin: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: "µg/g"
    },
    crp: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: "mg/dL"
    },
    serumTNF: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: formatMessage({ id: "dashboard.chart.y.label.changes" })
    },
    th1: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: formatMessage({ id: "dashboard.chart.y.label.changes" })
    },
    serumIL12: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: formatMessage({ id: "dashboard.chart.y.label.changes" })
    },
    th17: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: formatMessage({ id: "dashboard.chart.y.label.changes" })
    },
    serumil22: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: formatMessage({ id: "dashboard.chart.y.label.changes" })
    },
    macrophages: {
      scaleLabelX: formatMessage({ id: "dashboard.chart.x.label.weeks" }),
      scaleLabelY: formatMessage({ id: "dashboard.chart.y.label.changes" })
    }
  };

  return chartDetailOptions;
};

export { getChartOptions, getChartConfig, getDetailOptions };
