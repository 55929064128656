import React, { Component } from "react";
import PropTypes from "prop-types";
import Selection from "./selection";
import _ from "lodash";
// import moment from "moment";
import DataList from "./dataList";
import UserGroupChart from "./userGroupChart";
import PersonaGroupChart from "./personaGroupChart";
import CodeBox from "./codeBox";

import "../../../../styles/admin/adminMain.scss";

class AdminMain extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {simulateDetail} = this.props;
    const params = _.isEmpty(simulateDetail) ? "" : JSON.stringify(simulateDetail);

    return (
      <div className="admin-main">
        <Selection />
        <div className="data-show">
          <UserGroupChart />
          <PersonaGroupChart />
        </div>
        <DataList />
        <div className="detail-show">
          <CodeBox code={params} />
        </div>
      </div>
    );
  }
}

AdminMain.defaultProps = {
};

AdminMain.propTypes = {
  loadSimulateDetail: PropTypes.func,
  simulateDetail: PropTypes.object
};

export default AdminMain;
