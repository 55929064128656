import { connect } from "react-redux";
import DashboardMain from "./dashboardMain";
import action from "../../../flux/action";

const mapStateToProps = state => {
  console.log("dashboardstate",state);
  const { dashboard } = state;
  const { treatmentMonth, forecasted, plotConfig, imageSignedUrls} = dashboard;
  return {
    treatmentMonth,
    forecasted,
    plotConfig,
    imageSignedUrls
  };
};

const mapDispatchToProps = (dispatch) => {
  //return {};
  return {
    getImageSignedURL : options => {
      dispatch(action.getImageSignedURL(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardMain);
