import { handleActions } from "redux-actions";
import action from "../action";
import dState from "./defaultState";
// import _ from "lodash";
import moment from "moment";

const { actionTypes } = action;
const {
  EDIT_ADMIN,
  LOAD_USERS_SUCCESS,
  LOAD_SIMULATE_RESULT_SUCCESS,
  LOAD_SIMULATE_DETAIL_SUCCESS
} = actionTypes;

const defaultState = Object.assign(dState, {
  selectedUser: [],
  persona: [],
  dateRange: [moment().subtract(2, "M"), moment()],
  users: [],
  totalSimulations: [],
  personaGroup: [],
  userGroup: [],
  simulateDetail: {}
});

export default handleActions(
  {
    [EDIT_ADMIN]: (state, action) => {
      const { payload } = action;

      return Object.assign({}, state, payload);
    },
    [LOAD_USERS_SUCCESS]: (state, action) => {
      const { payload } = action;
      const { users } = payload;

      return Object.assign({}, state, {
        users
      });
    },
    [LOAD_SIMULATE_RESULT_SUCCESS]: (state, action) => {
      const { payload } = action;
      const { personaGroup, userGroup, totalData } = payload;

      return Object.assign({}, state, {
        totalSimulations: totalData,
        userGroup,
        personaGroup,
      });
    },
    [LOAD_SIMULATE_DETAIL_SUCCESS]: (state, action) => {
      const { payload } = action;
      const { data } = payload;
      const { params } = data;

      return Object.assign({}, state, {
        simulateDetail: params
      });
    }
  },
  defaultState
);
