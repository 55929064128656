const intestineOptions = [
  // {
  //   label: "persona.sidebar.intestine.jejunum",
  //   value: "jejunum"
  // },
  {
    label: "persona.sidebar.intestine.ileum",
    value: "ileum"
  },
  // {
  //   label: "persona.sidebar.intestine.ileocolonic",
  //   value: "ileocolonic"
  // },
  {
    label: "persona.sidebar.intestine.colon",
    value: "colon"
  }
  // {
  //   label: "Rectum",
  //   value: "rectum"
  // }
];

const personaIntestineOptions = [
  {
    label: "persona.sidebar.intestine.ileum",
    value: "ileum"
  },
  {
    label: "persona.sidebar.intestine.colon",
    value: "colon"
  }
];

const ulcerTypeOptions = [
  {
    label: "persona.sidebar.intestine.ulcerType.none",
    value: "none"
  },
  {
    label: "persona.sidebar.intestine.ulcerType.aphthous",
    value: "aphthous"
  },
  {
    label: "persona.sidebar.intestine.ulcerType.large",
    value: "large"
  },
  {
    label: "persona.sidebar.intestine.ulcerType.veryLarge",
    value: "veryLarge"
  }
];

const previousSurgery = [
  {
    label: "persona.options.previousSurgery.ileal",
    value: "ileal"
  },
  {
    label: "persona.options.previousSurgery.colonic",
    value: "colonic"
  }
];
const diseaseDuration = [
  {label: "persona.sidebar.diseaseState.diseaseDuration.option1", value: 1},
  {label:"persona.sidebar.diseaseState.diseaseDuration.option2", value: 3},
  {label: "persona.sidebar.diseaseState.diseaseDuration.option3", value: 7},
  {label: "persona.sidebar.diseaseState.diseaseDuration.option4", value: 10}
];
const diseaseDurationPreset = [
  {label: "persona.sidebar.diseaseState.diseaseDuration.option1", value: 1},
  {label:"persona.sidebar.diseaseState.diseaseDuration.option2", value: 3},
  {label:"persona.sidebar.diseaseState.diseaseDuration.option2", value: 4},
  {label:"persona.sidebar.diseaseState.diseaseDuration.option2", value: 5},
  {label: "persona.sidebar.diseaseState.diseaseDuration.option3", value: 7},
  {label: "persona.sidebar.diseaseState.diseaseDuration.option4", value: 10}
];
const surfaceAreaWithUlcers = [
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option1", value: "0"},
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option2", value: "5"},
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option3", value: "15"},
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option4", value: "50"}
];
const surfaceAreaWithUlcersPreset = [
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option1", value: "0"},
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option2", value: "5"},
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option3", value: "15"},
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option3", value: "20"},
  {label: "persona.sidebar.diseaseState.surfaceAreaWithUlcers.option4", value: "50"}
];
const surfaceInvolved= [
  {label: "persona.sidebar.diseaseState.surfaceInvolved.option1", value: "0"},
  {label:"persona.sidebar.diseaseState.surfaceInvolved.option2", value: "30"},
  {label:"persona.sidebar.diseaseState.surfaceInvolved.option3", value: "60"},
  {label: "persona.sidebar.diseaseState.surfaceInvolved.option4", value: "80"}
];

export {
  intestineOptions,
  ulcerTypeOptions,
  previousSurgery,
  personaIntestineOptions,
  diseaseDuration,
  diseaseDurationPreset,
  surfaceAreaWithUlcers,
  surfaceAreaWithUlcersPreset,
  surfaceInvolved
};
