import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import actions from "../../../flux/action";
import Section from "../../../components/section";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import maleIconDisabled from "../../../../assets/icons/Persona-Male-Not-Selected.png";
import femaleIconDisabled from "../../../../assets/icons/Persona-Female-Not-Selected.png";
import maleIconSelected from "../../../../assets/icons/Persona-Male-Selected.png";
import femaleIconSelected from "../../../../assets/icons/Persona-Female-Selected.png";
import personaSex from "../../../../assets/icons/ico_personal_sex.png";
import personaAgeRange from "../../../../assets/icons/ico_personal_age_range.png";
import personaWeight from "../../../../assets/icons/ico_personal_weight.png";
// import {Button} from "@material-ui/core";
import {
  genderOptionsWithIcon,
  ageRanges,
  weights,
  localOptionsIntl} from "../../../model";
import StepperButtons from "./stepperButtons";
// import AppFooter from "../../../container/appFooter";
// import {
//   loadIntl,
//   localOptionsIntl
// } from "../../../../model";
// import {changeLanguageAction} from "../flux/action";

class Demographics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGender: "",
      selectedAgeRange: "",
      selectedWeight: "",
      gender: [{value:"female", label: "persona.options.gender.female",icon: femaleIconDisabled}, {value:"male", label: "persona.options.gender.male", icon: maleIconDisabled}],
      ageRanges: [{label:"18 - 30", value: 25}, {label:"31 - 40", value: 35} , {label:"41 - 50", value: 45}, {label:"51 - 60", value: 55}, {label:"61+", value: 65}],
      weights: [{label:"below 55kg", value: 50}, {label:"55kg - 85kg", value: 65}, {label:"above 85kg", value: 90}],
      // ageRanges: ["18 - 30", "31 - 40", "41 - 50", "51 - 60", "61+"],
      // weights: ["below 120kg", "above 120kg"],
      selectedPatientInfo: {age: null, weight: null, gender: null},
      completed: false
    };
    const {patientInfo}= props;
    this.patientInfo = _.cloneDeep(patientInfo);
  }

  componentDidUpdate(){
    const {patientInfo} = this.props;
    this.patientInfo = _.cloneDeep(patientInfo);
  }

  // nextStepperProgress(e, value) {
  //   const {onChange, activeStep, personaState} = this.props;
  //   // this.setState({
  //   //   activeStep: value
  //   // });
  //   const nextActiveStep = (activeStep == 3) ? 3 : activeStep + 1;
  //   createPersona(
  //     Object.assign({}, personaState, {
  //       activeStep: nextActiveStep,
  //     })
  //   );
  //   onChange(nextActiveStep, "next");
  // }

  // prevStepperProgress(e, value) {
  //   const {onChange, activeStep} = this.props;
  //   this.setState({
  //     activeStep: value
  //   });
  //   const prevActiveStep = activeStep ? activeStep - 1 : 0;
  //   onChange(prevActiveStep, "back");
  // }

  getGenderIcon(value){
    const { patientInfo } = this.props;
    if(value == "female"){
      return (patientInfo["gender"] === "female") ? femaleIconSelected : femaleIconDisabled;
    } else {
      return (patientInfo["gender"] === "male") ? maleIconSelected : maleIconDisabled;
    }
  }

  handleOnChange(name, event){
    const {gender} = this.state;
    const { patientInfo, createPersona } = this.props;
    this.patientInfo = _.cloneDeep(patientInfo);
    if(name == "gender"){
      if(event.currentTarget.value == "female"){
        gender[0]["icon"] = femaleIconSelected;
        this.patientInfo["bmi"] = 23;
      } else {
        gender[0]["icon"] = femaleIconDisabled;
      }
    }
    if(name == "gender"){
      if(event.currentTarget.value == "male"){
        gender[1]["icon"] = maleIconSelected;
        this.patientInfo["bmi"] = 26;
      } else {
        gender[1]["icon"] = maleIconDisabled;
      }
    }
    this.patientInfo[name] = event.currentTarget.value;
    if(!_.isEmpty(this.patientInfo.age) && !_.isEmpty(this.patientInfo.weight) && !_.isEmpty(this.patientInfo.gender)){
      this.patientInfo.completed = true;
    }
    createPersona({
      patientInfo: Object.assign({}, patientInfo, this.patientInfo)
    });
  }

  getToggleButtonWidth(items){
    let size = items.length;
    let width = size ? (100/size) + "% !important" : "0%";
    return {width: width};
  }

  checkIfSelected(type, item){
    const {patientInfo} = this.props;
    if(type == "ageRange"){
      return (parseInt(patientInfo.age) === item.value);
    } else if(type == "weight"){
      return (parseInt(patientInfo.weight) === item.value);
    } else if(type == "gender"){
      return (parseInt(patientInfo.gender) === item.value);
    }
  }

  checkIfCompleted(){
    const { patientInfo } = this.props;
    return patientInfo.completed;
  }

  onNextStep(){
    const { patientInfo } = this.props;
    console.log("patientInfo.completed", patientInfo.completed);
  }

  render() {
    const { intl, patientInfo } = this.props;
    const { formatMessage } = intl;
    const iconClass = "persona-section-icon";
    const [weightsIntl] = localOptionsIntl(
      [weights],
      formatMessage
    );
    // e4rhyconst {gender, ageRanges, weights} = this.state;
    return (
      <div className="persona-container">
        <div className="header">
          <div className="header-title">{formatMessage({id: "persona.main.container.demographics"})}</div>
          <StepperButtons onNextStepChange={this.onNextStep.bind(this)}/>
        </div>
        <div className="persona-content">
          <Section
            className="align-items-center"
            title={formatMessage({id : "persona.sidebar.patientInfo.gender"})}
            icon={personaSex}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group"
              onChange={this.handleOnChange.bind(this, "gender")}
              value={patientInfo["gender"]}
              aria-label="text formatting"
            >
              {_.map(genderOptionsWithIcon, (obj, i) => {
                const {value, label} = obj;
                return (
                  <ToggleButton value={value} key={i} selected={this.checkIfSelected("gender", obj)} className="full-height persona-gender-radio" aria-label="italic">
                    <img src={this.getGenderIcon(value)} style={{"height": "50px", width: "50px"}}/>
                    <span className="toggle-button-text">{formatMessage({id: label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id : "persona.sidebar.patientInfo.age"})}
            icon={personaAgeRange}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChange.bind(this, "age")} value={patientInfo["age"]} aria-label="text formatting">
              {_.map(ageRanges, (ageRange, i) => {
                // const {value, label} = ageRange;
                return (
                  <ToggleButton value={ageRange.value} key={i} selected={this.checkIfSelected("ageRange", ageRange)} className="full-height toggle-button-large ageRange" classes={this.getToggleButtonWidth.bind(this, ageRanges)} aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: ageRange.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id : "persona.sidebar.patientInfo.weight"})}
            icon={personaWeight}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChange.bind(this, "weight")} value={patientInfo["weight"]} aria-label="text formatting">
              {_.map(weightsIntl, (weight, i) => {
                return (
                  <ToggleButton value={weight.value} key={i} selected={this.checkIfSelected("weight", weight)} className="full-height toggle-button-large weight" aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: weight.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    );
  }
}

Demographics.defaultProps = {
  subroutes: [],
  basePath: "",
  history: {},
  label: ""
};

Demographics.propTypes = {
  classes: PropTypes.object,
  subroutes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  changeLanguage: PropTypes.func,
  createPersona: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  patientInfo: PropTypes.object
};

const mapStateToProps = (state) => {
  const {newPersona: personaState} = state;
  const {patientInfo} = personaState;

  return {
    patientInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    createPersona: (persona) => {
      dispatch(actions.createPersona(persona));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(Demographics));
