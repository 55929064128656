import _ from "lodash";

const userNameValidation = {
  notEmpty: {
    errorText: "validation.input.enter.userName"
  }
};

const numberInputValidation = {
  notEmpty: {
    errorText: "validation.input.not.empty"
  },
  number: {
    reg: /^(\d+\.)?\d+$/,
    errorText: "validation.input.number.only"
  }
};
const integerInputValidation = {
  notEmpty: {
    errorText: "validation.input.not.empty"
  },
  number: {
    reg: /^\d+$/,
    errorText: "validation.input.integer.only"
  }
};
const rePasswordValidation = {
  notEmpty: {
    errorText: "validation.repassword.input.not.empty"
  },
  equal: {
    value: "",
    errorText: "validation.input.password.not.match"
  }
};

const emailValidation = {
  notEmpty: {
    errorText: "validation.input.enter.address"
  },
  format: {
    //reg: /^\w+([\ .-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    reg: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    errorText: "validation.input.enter.address"
  }
};
const passValidation = {
  notEmpty: {
    errorText: "validation.input.enter.password"
  },
  blank: {
    reg: /^[^ ]+$/,
    errorText: "validation.input.not.blank"
  },
  number: {
    reg: /\d+/,
    errorText: "validation.input.atLeast.number"
  },
  upper: {
    reg: /[A-Z]+/,
    errorText: "validation.input.upper.case"
  },
  eight: {
    reg: /.{8,}/,
    errorText: "validation.input.more.character"
  }
};

const durationDiseaseValidation = {
  ...numberInputValidation,
  range: {
    func: value => {
      if (value > 20) {
        return false;
      } else {
        return true;
      }
    },
    errorText: "validation.input.duration.disease"
  }
};

const getRangeValidation = (range = [], type = "integer") => {
  const validation =
    type === "integer" ? integerInputValidation : numberInputValidation;
  return {
    ...validation,
    range: {
      func: value => {
        if (range.length != 2) {
          console.log(range.length);
          return true;
        }
        if (value === "00") {
          return false;
        }
        if (value > range[1] || value < range[0]) {
          return false;
        } else {
          return true;
        }
      },
      errorText: "validation.input.range",
      errorParams: {
        from: range[0],
        to: range[1]
      }
    }
  };
};

const selectValidation = {
  notEmpty: {
    errorText: "validation.select.not.empty"
  }
};

const loadIntl = (validations, formatMessage) => {
  if (_.isFunction(formatMessage)) {
    return _.map(validations, validation => {
      const newValidation = {};
      _.each(validation, (value, name) => {
        const { errorText, errorParams, ...other } = value;

        newValidation[name] = {
          errorText: formatMessage({ id: errorText }, errorParams),
          ...other
        };
      });

      return newValidation;
    });
  } else {
    return validations;
  }
};

export {
  numberInputValidation,
  integerInputValidation,
  rePasswordValidation,
  passValidation,
  emailValidation,
  selectValidation,
  userNameValidation,
  durationDiseaseValidation,
  getRangeValidation,
  loadIntl
};
