import { createActions } from "redux-actions";
import { actionTypes } from "./actionTypes";
import { createRequest, naviTo } from "../../utils";

const {
  EDIT_DASHBOARD,
  RESET_DASHBOARD,
  SIMULATE,
  SIMULATE_SUCCESS,
  SIMULATE_FAILED,
  SESSION_EXPRIRED,
  CLEAR_MESSAGE,
  CHECK_SESSION,
  SIGNEDURL_SUCCESS,
  SIGNEDURL_FAILED,
  GET_IMAGE_SIGNED_URL
} = actionTypes;

const commonActions = createActions(
  {},
  EDIT_DASHBOARD,
  RESET_DASHBOARD,
  SIMULATE,
  SIMULATE_SUCCESS,
  SIMULATE_FAILED,
  SESSION_EXPRIRED,
  CLEAR_MESSAGE,
  CHECK_SESSION,
  SIGNEDURL_SUCCESS,
  SIGNEDURL_FAILED,
  GET_IMAGE_SIGNED_URL
);

const getImageSignedURL = options => {
  return dispatch => {
    dispatch(commonActions.getImageSignedUrl(options));

    const successCallback = res => {
      dispatch(commonActions.signedurlSuccess(res));
    };

    const failedCallback = (res) => {
      dispatch(commonActions.signedurlFailed());
      if(res.contains("Session expired, please login again.")){
        naviTo("/app/getImageSignedUrl");
      }
      naviTo("/error");
    };

    const opts = {
      pathName: "getImageSignedUrl",
      method: "POST",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};

const simulate = options => {
  return dispatch => {
    dispatch(commonActions.simulate(options));
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "event" : "forecast-form-submission",
      "form-data" : options
    });

    const successCallback = res => {
      dispatch(commonActions.simulateSuccess(res));
    };

    const failedCallback = (res) => {
      dispatch(commonActions.simulateFailed());
      if(res.contains("Session expired, please login again.")){
        naviTo("/app/persona");
      }
      naviTo("/error");
    };

    const opts = {
      pathName: "simulate",
      method: "POST",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};

const sessionExpriedAction = () => {
  return {
    type: SESSION_EXPRIRED
  };
};

const clearAction = () => {
  localStorage.clear();
  sessionStorage.clear();
  return {
    type: CLEAR_MESSAGE
  };
};

// const checkSession = ()=>{
//   return dispatch => {
//     dispatch(commonActions.checkSession());

//     const successCallback = res => {
//       return res.success;
//     };

//     const failedCallback = (res) => {
//       if(res.contains("Session expired, please login again.")){
//         naviTo("/app/dashboard");
//       }
//       naviTo("/error");
//     };

//     const opts = {
//       pathName: "checkSession",
//       method: "GET",
//     };

//     return createRequest(opts, dispatch)
//       .then(successCallback)
//       .catch(failedCallback);
//   };
// };

export default Object.assign({}, commonActions, {
  simulate,
  sessionExpriedAction,
  clearAction,
  getImageSignedURL
  // checkSession
});
