import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import actions from "../../../flux/action";
import Section from "../../../components/section";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import steroidIcon from "../../../../assets/icons/ico_steroid.png";
import immunomodulatorIcon from "../../../../assets/icons/ico_immunomodulator.png";
import treatmentHistoryIcon from "../../../../assets/icons/ico_treatment_history.png";
import {steroidResponderOptions,
  immunoResponderOptions,
  antiTnfResponderOptions,
  antiilResponderOptions,
  antiIntegrinResponderOptions,} from "../../../model/detailOptions";
// import {Button} from "@material-ui/core";
import SavePersona from "../../../components/savePersona";
import StepperButtons from "./stepperButtons";
import {validateLogin} from "../../../utils";

//import {injectIntl, intlShape} from "react-intl";
//import {changeLanguageAction} from "../flux/action";

class ResponderProfile extends Component {
  constructor(props, context) {
    super(props, context);
    this.inputRefs = {
      personaName: React.createRef(),
      personaDesc: React.createRef(),
    };
    // this.responderProfile = {"anti-il": null, "anti-integrin": null, "anti-tnf": null, "immunomodulator": null, "steroid": null, completed: false};
    const {responderProfile}= props;
    this.responderProfile = _.cloneDeep(responderProfile);
    this.personaState = _.cloneDeep(props.personaState);
  }

  componentDidMount() {
    this.checkIfCompleted();
  }

  getSaveActionDialog(){
    validateLogin();
    return (
      <SavePersona />
    );
  }

  checkIfCompleted(){
    const {responderProfile, createPersona} = this.props;
    this.responderProfile.completed = responderProfile.completed;
    if(!_.isEmpty(responderProfile["steroid"]) &&
      !_.isEmpty(responderProfile["immunomodulator"]) &&
      !_.isEmpty(responderProfile["anti-tnf"]) &&
      !_.isEmpty(responderProfile["anti-integrin"]) &&
      !_.isEmpty(responderProfile["anti-il"])){
      this.responderProfile.completed = true;
    } else {
      this.responderProfile.completed = false;
    }
    if(this.responderProfile.completed == responderProfile.completed){
      return;
    }
    createPersona({
      responderProfile: Object.assign({}, responderProfile, this.responderProfile)
    });
  }

  // nextStepperProgress(e, value) {
  //   const {onChange, activeStep} = this.props;
  //   this.setState({
  //     activeStep: value
  //   });
  //   const nextActiveStep = (activeStep == 3) ? 3 : activeStep + 1;
  //   onChange(nextActiveStep, "next");
  // }

  // prevStepperProgress(e, value) {
  //   const {onChange, activeStep} = this.props;
  //   this.setState({
  //     activeStep: value
  //   });
  //   const prevActiveStep = activeStep ? activeStep - 1 : 0;
  //   onChange(prevActiveStep, "back");
  // }

  handleOnChange(type, event){
    const { responderProfile, createPersona } = this.props;
    this.responderProfile[type] = event.currentTarget.value;
    if(!_.isEmpty(responderProfile["steroid"]) &&
      !_.isEmpty(responderProfile["immunomodulator"]) &&
      !_.isEmpty(responderProfile["anti-tnf"]) &&
      !_.isEmpty(responderProfile["anti-il"]) &&
      !_.isEmpty(responderProfile["anti-inegrin"])){
      this.responderProfile.completed = true;
    }
    if(type == "steroid"){
      this.responderProfile["isSteroidModified"] = true;
    } else if(type == "immunomodulator"){
      this.responderProfile["isImmunoModified"] = true;
    } else if(type == "anti-tnf"){
      this.responderProfile["isAntiTNFModified"] = true;
    } else if(type == "anti-il"){
      this.responderProfile["isAntiILModified"] = true;
    } else if(type == "anti-inegrin"){
      this.responderProfile["isAntiIntegrinModified"] = true;
    }
    createPersona({
      responderProfile: Object.assign({}, responderProfile, this.responderProfile)
    });
  }

  checkForDisableOption(type, options){
    const {historicIntervention} = this.props;
    if(historicIntervention[type]["checked"] && historicIntervention[type]["initialResponse"] == "yes" && historicIntervention[type]["lossOfResponse"] == "no"){
      if(type == "steroids") type = "steroid";
      options[0].disabled = true;
      options[1].disabled = false;
      options[2].disabled = false;
    } else if(historicIntervention[type]["checked"] && ((historicIntervention[type]["initialResponse"] == "no" && historicIntervention[type]["lossOfResponse"] == "no") ||
      (historicIntervention[type]["initialResponse"] == "no" && historicIntervention[type]["lossOfResponse"] == "yes") ||
      (historicIntervention[type]["initialResponse"] == "yes" && historicIntervention[type]["lossOfResponse"] == "yes"))){
      if(type == "steroids") type = "steroid";
      options[0].disabled = true;
      options[1].disabled = true;
      options[2].disabled = true;
    } else {
      if(type == "steroids") type = "steroid";
      options[0].disabled = false;
      options[1].disabled = false;
      options[2].disabled = false;
    }
  }

  saveActionPopup(){
    const { createPersona, personaState } = this.props;
    createPersona(
      Object.assign({}, personaState, {
        showSaveDialog: true
      })
    );
  }

  onNextStep(){
    const { personaState } = this.props;
    if((personaState["stepAction"] == "next" || personaState["stepAction"] == "save") && personaState.activeStep == 3){
      this.saveActionPopup();
    }
  }

  render() {
    const {intl, responderProfile} = this.props;
    const { formatMessage} = intl;
    const iconClass = "persona-section-icon";
    this.checkForDisableOption("steroids", steroidResponderOptions);
    this.checkForDisableOption("immunomodulator", immunoResponderOptions);
    this.checkForDisableOption("anti-tnf", antiTnfResponderOptions);
    this.checkForDisableOption("anti-il", antiilResponderOptions);
    this.checkForDisableOption("anti-integrin", antiIntegrinResponderOptions);
    // const {selectedSteroid,selectedImmunomodulator,selectedAntiTnf,selectedAntiIntegrin,selectedAntiIl} = this.state;
    return (
      <div className="persona-container">
        <div className="header">
          <div className="header-title">{formatMessage({id: "persona.sidebar.initial.response.title"})}</div>
          {/* <div className="stepper-buttons">
            <Button
              classes={{
                root: "stepper-back-button"
              }}
              color="primary"
              variant="contained"
              onClick={this.prevStepperProgress.bind(this)}
            >Back</Button>
            <Button
              classes={{
                root: "stepper-next-button"
              }}
              color="primary"
              variant="contained"
              onClick={this.saveActionPopup.bind(this)}
            >Save</Button>
          </div> */}
          <StepperButtons onNextStepChange={this.onNextStep.bind(this)}/>
        </div>
        <div className="persona-content">
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.steroids"})}
            icon={steroidIcon}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group"
              onChange={this.handleOnChange.bind(this, "steroid")}
              value={responderProfile["steroid"]}
              aria-label="text formatting"
            >
              {_.map(steroidResponderOptions, (obj, i) => {
                // this.checkForDisableOption("steroids", responderResponseOptions);
                return (
                  <ToggleButton value={obj.value} key={i} disabled={obj.disabled} className="full-height toggle-button-large responder" aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.immunomodulator.noex"})}
            icon={immunomodulatorIcon}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChange.bind(this, "immunomodulator")} value={responderProfile["immunomodulator"]} aria-label="text formatting">
              {_.map(immunoResponderOptions, (obj, i) => {
                // this.checkForDisableOption("immunomodulator", responderResponseOptions);
                return (
                  <ToggleButton value={obj.value} key={i} disabled={obj.disabled} className="full-height toggle-button-large responder" aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.anti-tnf.noex"})}
            icon={treatmentHistoryIcon}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChange.bind(this, "anti-tnf")} value={responderProfile["anti-tnf"]} aria-label="text formatting">
              {_.map(antiTnfResponderOptions, (obj, i) => {
                // this.checkForDisableOption("anti-tnf", responderResponseOptions);
                return (
                  <ToggleButton value={obj.value} key={i} disabled={obj.disabled} className="full-height toggle-button-large responder" aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.anti-integrin.noex"})}
            icon={treatmentHistoryIcon}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChange.bind(this, "anti-integrin")} value={responderProfile["anti-integrin"]} aria-label="text formatting">
              {_.map(antiIntegrinResponderOptions, (obj, i) => {
                // this.checkForDisableOption("anti-integrin", responderResponseOptions);
                return (
                  <ToggleButton value={obj.value} key={i} disabled={obj.disabled} className="full-height toggle-button-large responder" aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.anti-il.noex"})}
            icon={treatmentHistoryIcon}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChange.bind(this, "anti-il")} value={responderProfile["anti-il"]} aria-label="text formatting">
              {_.map(antiilResponderOptions, (obj, i) => {
                // this.checkForDisableOption("anti-il", responderResponseOptions);
                return (
                  <ToggleButton value={obj.value} key={i} disabled={obj.disabled} className="full-height toggle-button-large responder" aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
        {this.getSaveActionDialog()}
      </div>
    );
  }
}

ResponderProfile.defaultProps = {
  subroutes: [],
  basePath: "",
  history: {},
  label: ""
};

ResponderProfile.propTypes = {
  classes: PropTypes.object,
  subroutes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  createPersona: PropTypes.func,
  responderProfile: PropTypes.object,
  historicIntervention: PropTypes.object,
  personaState: PropTypes.object,
  addNewPersona: PropTypes.func
};

const mapStateToProps = (state) => {
  // const {common: commonState} = state;
  // const {language} = commonState;
  const {newPersona: personaState} = state;
  const {responderProfile, historicIntervention} = personaState;

  return {
    responderProfile, historicIntervention, personaState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    addNewPersona: options => {
      dispatch(actions.addNewPersona(options));
    },
    createPersona: (persona) => {
      dispatch(actions.createPersona(persona));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponderProfile));
