import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import bssType1 from "../../../../../assets/BSS/Type1.svg";
import bssType2 from "../../../../../assets/BSS/Type2.svg";
import bssType3 from "../../../../../assets/BSS/Type3.svg";
import bssType4 from "../../../../../assets/BSS/Type4.svg";
import bssType5 from "../../../../../assets/BSS/Type5.svg";
import bssType6 from "../../../../../assets/BSS/Type6.svg";
import bssType7 from "../../../../../assets/BSS/Type7.svg";
import bssTypeJp1 from "../../../../../assets/BSS/JP/Type1JP.svg";
import bssTypeJp2 from "../../../../../assets/BSS/JP/Type2JP.svg";
import bssTypeJp3 from "../../../../../assets/BSS/JP/Type3JP.svg";
import bssTypeJp4 from "../../../../../assets/BSS/JP/Type4JP.svg";
import bssTypeJp5 from "../../../../../assets/BSS/JP/Type5JP.svg";
import bssTypeJp6 from "../../../../../assets/BSS/JP/Type6JP.svg";
import bssTypeJp7 from "../../../../../assets/BSS/JP/Type7JP.svg";
import stoolChartImage from "../../../../../assets/BristolStoolChart.png";
import stoolChartImageJa from "../../../../../assets/BristolStoolChartJa.png";
import { SwitchExpansionPanel } from "ui-toolkit";

class DiarrheaSymptoms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleChange() {
    this.setState({
      isOpen: !this.state.isOpen
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "event" : "symptoms_toggle_event",
      "symptoms_toggle_status" : "TRUE",
    });
  }

  getData(data, index) {
    if (_.isArray(data) && data[index] != undefined) {
      return data[index];
    } else {
      return "";
    }
  }

  getImage(data) {
    const { locale } = this.props.intl;
    switch (locale) {
    case "en":
      switch (data) {
      case 1:
        return <img className="stool-image" src={bssType1} />;
      case 2:
        return <img className="stool-image" src={bssType2} />;
      case 3:
        return <img className="stool-image" src={bssType3} />;
      case 4:
        return <img className="stool-image" src={bssType4} />;
      case 5:
        return <img className="stool-image" src={bssType5} />;
      case 6:
        return <img className="stool-image" src={bssType6} />;
      case 7:
        return <img className="stool-image" src={bssType7} />;
      }
      break;
    case "ja":
      switch (data) {
      case 1:
        return <img className="stool-image" src={bssTypeJp1} />;
      case 2:
        return <img className="stool-image" src={bssTypeJp2} />;
      case 3:
        return <img className="stool-image" src={bssTypeJp3} />;
      case 4:
        return <img className="stool-image" src={bssTypeJp4} />;
      case 5:
        return <img className="stool-image" src={bssTypeJp5} />;
      case 6:
        return <img className="stool-image" src={bssTypeJp6} />;
      case 7:
        return <img className="stool-image" src={bssTypeJp7} />;
      }

    }
  }

  render() {

    const { intl, forecasted, treatmentMonth } = this.props;
    const { isOpen } = this.state;
    const { formatMessage } = intl;
    const { stool_quality, stool_symptom } = forecasted;
    const stoolData = this.getData(stool_quality, treatmentMonth);
    const stoolSymptom = this.getData(stool_symptom, treatmentMonth);
    return (
      <div className="symptoms">
        <SwitchExpansionPanel
          color="primary"
          label={formatMessage({ id: "dashboard.content.symptoms.title" })}
          checked={isOpen}
          onToggle={this.handleChange.bind(this)}
        >
          {/* <div className="header">
            {formatMessage({ id: "dashboard.content.symptoms.title" })}
          </div> */}
          <div className="content">

            {!stoolData ? (
              <span className="no-data">
                {formatMessage({ id: "dashboard.content.nodata" })}
              </span>
            ) : (
              <div className="quality">
                <div className="stool-symptom">
                  {formatMessage({ id: stoolSymptom })}
                  <div className="symptom-label">
                    {formatMessage({
                      id: "dashboard.content.symptoms.stool.label"
                    })}
                  </div>
                </div>
                <div className="tooltip">
                  <div className="data">
                    {stoolData}
                  </div>
                  <div className="stool-quality">
                    {formatMessage({
                      id: "dashboard.content.clinical.assessment.tooltip2"
                    })}

                    <a data-tip data-for="quality-1" data-scroll-hide={true} data-iscapture="true" data-event-off="scroll">
                      <i className="material-icons">info</i>
                    </a>
                  </div>
                  <ReactTooltip id="quality-1" type="dark" scrollHide={true} eventOff="scroll">
                    <span>
                      <div className="tooltip-content">
                        <img src={intl.locale == "en" ? stoolChartImage : stoolChartImageJa} height="300" />
                      </div>
                      {/* <div className="tooltip-container">
                        <div className="tooltip-title">
                          {formatMessage({ id: "dashboard.content.tooltip2.title" })}
                        </div>
                        <div className="tooltip-content">
                          <div>
                            {formatMessage({
                              id: "dashboard.content.tooltip2.detail1"
                            })}
                          </div>
                          <div>
                            {formatMessage({
                              id: "dashboard.content.tooltip2.quality1"
                            })}
                          </div>
                          <div>
                            {formatMessage({
                              id: "dashboard.content.tooltip2.quality2"
                            })}
                          </div>
                          <div>
                            {formatMessage({
                              id: "dashboard.content.tooltip2.quality6"
                            })}
                          </div>
                        </div>
                      </div> */}
                    </span>
                  </ReactTooltip>
                </div>
                {this.getImage(stoolData)}
              </div>
            )}
          </div>
        </SwitchExpansionPanel>
      </div>
    );
  }
}

DiarrheaSymptoms.defaultProps = {};

DiarrheaSymptoms.propTypes = {
  data: PropTypes.object,
  plotConfig: PropTypes.object,
  forecasted: PropTypes.object,
  treatmentMonth: PropTypes.number,
  intl: intlShape.isRequired
};

export default injectIntl(DiarrheaSymptoms);
