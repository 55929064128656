import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import FiledLabel from "../../../../components/fieldLabel";
import { CommonInput} from "ui-toolkit";
import Drug from "./drug";
import OtherBiologics from "./otherBiologics";
import { Button } from "@material-ui/core";
import {
  // drugs,
  drugsGrouping,
  // biologicOther,
  // integerInputValidation,
  getRangeValidation,
  loadIntl
} from "../../../../model";
import { getWrappedInstance } from "../../../../utils";

class Treatment extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      duractionTreatment: React.createRef()
    };
  }

  handleChangeBiologics(value) {
    const { treatment, onChange } = this.props;
    onChange(
      Object.assign({}, treatment, value)
    );
  }

  handleChange(name, value) {
    const { treatment, onChange } = this.props;
    onChange(
      Object.assign({}, treatment, {
        [name]: value
      })
    );
  }

  handleDeleteTreatment() {
    const { onDeleteTreatment } = this.props;

    onDeleteTreatment();
  }

  getDrugs() {
    const { treatment, intl } = this.props;
    const { formatMessage } = intl;
    const tooltipContent = [];
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail1" }));
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail2" }));
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail3" }));
    tooltipContent.push(formatMessage({ id: "dashboard.sidebar.content.tooltip.detail4" }));
    if (treatment) {
      return (
        <div className="drugs">
          {_.map(drugsGrouping, (drug, index) => {
            const { label, value } = drug;
            this.inputRefs[value] = React.createRef();
            if(value == "other"){
              return (
                <OtherBiologics
                  ref={this.inputRefs[value]}
                  treatment={treatment}
                  label={label}
                  onChange={this.handleChangeBiologics.bind(this)}
                />
              );
            } else {
              return (
                <Drug
                  ref={this.inputRefs[value]}
                  key={`d-drug-${index}`}
                  label={formatMessage({ id: label })}
                  name={value}
                  data={treatment[value]}
                  onChange={this.handleChange.bind(this, value)}
                  tooltipContent={tooltipContent}
                />
              );
            }
          })}
        </div>
      );
    }
  }

  getStatus() {
    // return _.reduce(
    //   this.inputRefs,
    //   (result, ref) => {
    //     const status = ref.current && getWrappedInstance(ref.current) && getWrappedInstance(ref.current).getStatus();
    //     return result && (status === "init" ? false : status);
    //   },
    //   true
    // );
    return true;
  }

  reset() {
    _.each(this.inputRefs, (ref, key) => {
      if(key == "other"){
        getWrappedInstance(ref.current).state.checked = false;
      }
      getWrappedInstance(ref.current).reset();
    });
  }

  render() {
    const { treatment, intl } = this.props;
    const { formatMessage } = intl;
    const { duractionTreatment } = treatment;
    const treatmentDuractionValidation = getRangeValidation([1, 1200]);
    const [treatmentDuractionValidationIntl] = loadIntl(
      [treatmentDuractionValidation],
      formatMessage
    );

    return (
      <div className="treatment">
        <FiledLabel
          label={formatMessage({
            id:
              "dashboard.sidebar.proposedTreatment.pharmacology.duractionTreatment"
          })}
        >
          <CommonInput
            ref={this.inputRefs.duractionTreatment}
            type={"number"}
            value={duractionTreatment}
            validation={treatmentDuractionValidationIntl}
            onChange={this.handleChange.bind(this, "duractionTreatment")}
          />
        </FiledLabel>
        {this.getDrugs()}
        <div className="actions">
          <Button
            color="primary"
            classes={{
              root: "delete-treatment-button-root"
            }}
            onClick={this.handleDeleteTreatment.bind(this)}
          >
            {formatMessage({
              id: "dashboard.sidebar.proposedTreatment.deleteTreatment"
            })}
          </Button>
        </div>
      </div>
    );
  }
}

Treatment.defaultProps = {
  treatment: {},
  onChange: () => { },
  onDeleteTreatment: () => { }
};

Treatment.propTypes = {
  treatment: PropTypes.object,
  onChange: PropTypes.func,
  onDeleteTreatment: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(Treatment, {
  withRef: true
});
