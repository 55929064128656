const en = {
  //validation
  "validation.input.not.empty": "Please enter numeric value",
  "validation.repassword.input.not.empty":"Please enter your confirm password",
  "validation.input.enter.userName":"Please enter your name",
  "validation.input.enter.password":"Please enter your password",
  "validation.input.number.only": "Please enter numeric value",
  "validation.input.integer.only": "Please enter a valid integer",
  "validation.select.not.empty": "Please select one of the above options",
  "validation.input.duration.disease": "Please input number between 0 and 20",
  "validation.input.enter.address": "Please enter a valid email address",
  "validation.input.password.not.match": "Passwords do not match",
  "validation.input.atLeast.number": "Passwords must include at least one number",
  "validation.input.upper.case":
    "Passwords must include at least one upper case letter",
  "validation.input.more.character":
    "Passwords must be eight or more characters",
  "validation.input.range": "Please enter numeric value between {from} and {to}",
  "validation.input.not.blank": "Password should not include whitespace",
  //header
  "header.tab.persona": "Persona",
  "header.tab.dashboard": "Dashboard",
  "header.tab.model": "Model",
  "header.tab.admin": "Admin",
  "header.logout":"Logout",
  "header.practise.mode.on": "ON",
  "header.practise.mode.off": "OFF",
  //footer
  "footer.version": "Version",
  //Persona
  "persona.header.subtab.preset": "Preset Personas",
  "persona.header.subtab.desc.preset": "Choose from preset personas",
  "persona.header.subtab.custom.patiets": "Custom Patients",
  "persona.header.subtab.desc.custom.patiets": "Choose from custom patients",
  "persona.header.subtab.new.patient": "Build New patient",
  "persona.header.subtab.desc.new.preset": "Create custom patient",
  "persona.options.gender.male": "Male",
  "persona.options.gender.female": "Female",
  "persona.options.years.old": "years old",
  "persona.options.tobaccoUse.current": "Current Smoker",
  "persona.options.tobaccoUse.previous": "Previous Smoker",
  "persona.options.tobaccoUse.never": "Non Smoker",
  "persona.options.yesno.yes": "Yes",
  "persona.options.yesno.no": "No",
  "persona.options.yesno.na": "N/A",
  "persona.sidebar.intestine.jejunum": "Jejunum",
  "persona.sidebar.intestine.ileum": "Ileum",
  "persona.sidebar.intestine.ileocolonic": "Ileocolonic",
  "persona.sidebar.intestine.colon": "Colon",
  "persona.options.previousSurgery.ileal": "Ileal",
  "persona.options.previousSurgery.colonic": "Colonic",
  "persona.sidebar.treatment.steroids": "Steroids",
  "persona.sidebar.treatment.immunomodulator":
    "Immunomodulator (e.g. Azathioprine)",
  "persona.sidebar.treatment.anti-tnf":
    "Anti-TNF (e.g. Adalimumab, Infliximab)",
  "persona.sidebar.treatment.anti-integrin": "Anti-Integrin (Vedolizumab)",
  "persona.sidebar.treatment.anti-il": "Anti-IL12/23 (Ustekinumab)",
  "persona.sidebar.treatment.immunomodulator.noex":
    "Immunomodulator",
  "persona.sidebar.treatment.anti-tnf.noex":
    "Anti-TNF",
  "persona.sidebar.treatment.anti-integrin.noex": "Anti-Integrin",
  "persona.sidebar.treatment.anti-il.noex": "Anti-IL12/23",
  "persona.options.frequency.months": "Months",
  "persona.options.frequency.weeks": "Weeks",
  "persona.sidebar.intestine.ulcerType.aphthous": "Aphthous",
  "persona.sidebar.intestine.ulcerType.none": "None",
  "persona.sidebar.intestine.ulcerType.large": "Large",
  "persona.sidebar.intestine.ulcerType.veryLarge": "Very Large",
  //dashboard
  "dashboard.sidebar.treatment.no.more.five": "The maximum amount of treatment is 5",
  "dashboard.sidebar.treatment.leave.one": "Please leave at least one treatment",
  "dashboard.sidebar.treatment.dutaction.no.more.1200":
    "Total treatment duration should not exceed 1200 weeks",
  "dashboard.options.doseFrequency.300mg_week": "300mg per week",
  "dashboard.options.developmemt.immunogenicity.none": "None",
  "dashboard.options.developmemt.immunogenicity.above": "Above average rate",
  "dashboard.options.developmemt.immunogenicity.average": "Average rate",
  "dashboard.options.developmemt.immunogenicity.below": "Below average rate",
  "dashboard.options.regime.recommended": "Recommended",
  "dashboard.options.regime.induction": "Induction",
  "dashboard.options.regime.maintenance": "Maintenance",
  "dashboard.options.regime.custom": "Custom",
  "dashboard.options.regime.induction.maintenance": "Induction + Maintenance",
  "dashboard.options.years.old": "years old",
  //error
  "error.title": "Looks like our robots are asleep on the job…",
  "error.message":
    "            Sorry for the delay - we’ll wake them up and get your request processed! Please wait a moment and then resubmit by clicking 'Simulate'.",
  "error.contact":"If this issue persists, please ",
  "error.contact.us":"contact us",
  "error.contact.us.period": ".",
  //loading
  "common.loading.desc": "Forecasting the future...",
  "common.loading.desc1": "Building digital twin...",
  "common.loading.desc2": "Administering treatment...",
  "common.loading.desc3": "Simulating response...",
  "common.loading.desc4": "Compiling your results...",
  "common.disclaimer.point1": "This application is not for diagnosis or treatment purposes.",
  "common.disclaimer.point2": "This application does not advocate efficacy of any particular drugs.",
  "common.disclaimer.point3": "This application technically cannot perform any simulation with regards to safety endpoints, such as adverse events of any particular drugs. Therefore it is not possible to provide any safety information through this application",
  "common.disclaimer.point4": "The algorithm used in the simulation is based only on the published science and known mechanisms, and the results are inferred by deductive calculation. We do not exclude the possibility that other unknown factors may be involved in the actual disease and treatment options in the real world.",
  "common.disclaimer.point5": "Therefore all the simulations run in this application are on virtual patients with the limitations above. There is no guarantee that the behavior of virtual patients and simulation results can reflect those of real patients.",
  "common.session.expired.error": "Your session is expired, please login again",
  "common.back.login.button.desc": "Back to Login"
};

const ja = {
  //validation
  "validation.input.not.empty": "半角数字を入力",
  "validation.repassword.input.not.empty":"新しいパスワードの確認を入力",
  "validation.input.enter.userName":"氏名を入力",
  "validation.input.enter.password":"パスワードを入力",
  "validation.input.number.only": "半角数字を入力",
  "validation.input.integer.only": "整数を入力",
  "validation.select.not.empty": "上記より１つ選択",
  "validation.input.duration.disease": "0以上20以下の半角数字を入力",
  "validation.input.enter.address": "メールアドレスを入力",
  "validation.input.password.not.match": "パスワードが一致しません",
  "validation.input.atLeast.number": "１文字以上の数字を含めてください",
  "validation.input.upper.case": "半角の大文字を含めてください",
  "validation.input.more.character": "８文字以上の半角英数字を含めてください",
  "validation.input.range": "{from}から{to}の間の整数を入力",
  "validation.input.not.blank": "パスワードにスペースを含めることはできません",
  //header
  "header.tab.persona": "ペルソナ",
  "header.tab.dashboard": "ダッシュボード",
  "header.tab.model": "モデル",
  "header.tab.admin": "管理者",
  "header.logout":"ログアウト",
  "header.practise.mode.on": "オン",
  "header.practise.mode.off": "オフ",
  //footer
  "footer.version": "バージョン",
  //Persona
  "persona.header.subtab.preset": "初期設定のペルソナ",
  "persona.header.subtab.desc.preset": "初期設定のペルソナから選ぶ",
  "persona.header.subtab.custom.patiets": "以前作ったペルソナ",
  "persona.header.subtab.desc.custom.patiets": "以前作ったペルソナから選ぶ",
  "persona.header.subtab.new.patient": "新しいペルソナを作る",
  "persona.header.subtab.desc.new.preset": "新しいペルソナを作る",
  "persona.options.gender.male": "男",
  "persona.options.gender.female": "女",
  "persona.options.years.old": "歳",
  "persona.options.tobaccoUse.current": "喫煙者",
  "persona.options.tobaccoUse.previous": "元喫煙者",
  "persona.options.tobaccoUse.never": "喫煙経験なし",
  "persona.options.yesno.yes": "あり",
  "persona.options.yesno.no": "なし",
  "persona.options.yesno.na": "該当なし",
  "persona.sidebar.intestine.jejunum": "空腸",
  "persona.sidebar.intestine.ileum": "回腸",
  "persona.sidebar.intestine.ileocolonic": "回結腸",
  "persona.sidebar.intestine.colon": "結腸",
  "persona.options.previousSurgery.ileal": "回腸",
  "persona.options.previousSurgery.colonic": "結腸",
  "persona.sidebar.treatment.steroids": "ステロイド",
  "persona.sidebar.treatment.immunomodulator": "免疫調節剤（アザチオプリン）",
  "persona.sidebar.treatment.anti-tnf": "抗TNF（アダリムマブ、インフリキシマブ)",
  "persona.sidebar.treatment.anti-integrin": "抗インテグリン（ベドリズマブ）",
  "persona.sidebar.treatment.anti-il": "抗 IL 12 / 23（ウステキヌマブ）",
  "persona.sidebar.treatment.immunomodulator.noex":
    "免疫調節剤",
  "persona.sidebar.treatment.anti-tnf.noex":
    "抗TNF",
  "persona.sidebar.treatment.anti-integrin.noex": "抗インテグリン",
  "persona.sidebar.treatment.anti-il.noex": "抗 IL 12 / 23",
  "persona.options.frequency.months": "月",
  "persona.options.frequency.weeks": "週",
  "persona.sidebar.intestine.ulcerType.aphthous": "アフタ性潰瘍",
  "persona.sidebar.intestine.ulcerType.none": "なし",
  "persona.sidebar.intestine.ulcerType.large": " 潰瘍",
  "persona.sidebar.intestine.ulcerType.veryLarge": "大潰瘍",
  //dashboard
  "dashboard.sidebar.treatment.no.more.five": "治療法は５種まで選択可能",
  "dashboard.sidebar.treatment.leave.one": "１つ以上の治療法を選択",
  "dashboard.sidebar.treatment.dutaction.no.more.1200":
    "治療期間の合計が1200週を超えることはできません",
  "dashboard.options.doseFrequency.300mg_week": "300mg per week",
  "dashboard.options.developmemt.immunogenicity.none": "なし",
  "dashboard.options.developmemt.immunogenicity.above": "平均より高い",
  "dashboard.options.developmemt.immunogenicity.average": "平均",
  "dashboard.options.developmemt.immunogenicity.below": "平均未満",
  "dashboard.options.regime.recommended": "推奨",
  "dashboard.options.regime.induction": "導入療法",
  "dashboard.options.regime.maintenance": "維持療法",
  "dashboard.options.regime.custom": "カスタム",
  "dashboard.options.regime.induction.maintenance": "導入療法 + 維持療法",
  "dashboard.options.years.old": "years old",
  //error
  "error.title": "現在リクエストを処理しています",
  "error.message":
    " しばらく時間をおいてから[シミュレーション]ボタンをもう一度クリックしてください。",
  "error.contact":"この問題が解決しない場合は",
  "error.contact.us":"お問い合わせ",
  "error.contact.us.period": "。",
  //loading
  "common.loading.desc": "計算中です...",
  "common.loading.desc1": "デジタルツイン作成...",
  "common.loading.desc2": "薬剤治療を適応中...",
  "common.loading.desc3": "治療効能を予測...",
  "common.loading.desc4": "結果をまとめ中...",
  "common.disclaimer.point1": "本アプリケーションは、疾患の診断、治療を目的としたものではありません。",
  "common.disclaimer.point2": "本アプリケーションは、特定の薬剤の効果・効能を主張するものではありません。",
  "common.disclaimer.point3": "本アプリケーション内のシミュレーションでは、技術上、特定の薬剤の副作用を含む安全性を取り扱うことはできません。従って、本アプリケーションを通じた安全性情報の提供・取得は不可能です。",
  "common.disclaimer.point4": "シミュレーションに用いられるアルゴリズムは論文化された知見および既知のメカニズムのみに基づくものであり、結果は演繹的な計算により推論されます。現実の疾患および治療オプションには、他の未知の要因が影響する可能性は排除されません。",
  "common.disclaimer.point5": "従って、本アプリケーション内の疾患シミュレーションは上記の制限を伴う仮想の患者を用いて実行されるものとなります。これらの仮想患者およびシミュレーション結果が実在の患者における挙動を反映することは保証されません。",
  "common.session.expired.error": "セッションがタイムアウトしました。再度ログインしてください。",
  "common.back.login.button.desc": "ログイン画面に戻る"
};

export default {
  en,
  ja
};
