import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import InfoSection from "../../../../components/infoSection";
import FiledLabel from "../../../../components/fieldLabel";
// import { RadioGroup } from "ui-toolkit";
import { FormControlLabel, Radio, FormControl, RadioGroup as RadioGroupCore } from "@material-ui/core";
import { injectIntl, intlShape } from "react-intl";
import { localOptionsIntl, steroidResponderOptions,
  immunoResponderOptions,
  antiTnfResponderOptions,
  antiilResponderOptions,
  antiIntegrinResponderOptions } from "../../../../model";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const CustomRadio = withStyles({
  root: {
    "&$disabled": {
      color: "gray !important"
    },
    "&$checked": {
      color: "#ee1100",
    }
  },
  checked: {},
  disabled: {}
})((props) => <Radio color="default" {...props} />);
class ResponderProfile extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      "steroid": React.createRef(),
      "immunomodulator": React.createRef(),
      "anti-tnf": React.createRef(),
      "anti-il": React.createRef(),
      "anti-integrin": React.createRef(),
    };
    this.state = {
      selectedSteroidInitialResponse: "",
      selectedImmunoInitialResponse: "",
      selectedAntiTnfInitialResponse: "",
      selectedAntiIntegrinInitialResponse: "",
      selectedAntIlInitialResponse: ""
    };
  }

  handleChange(name, event) {
    // console.log(name + ".."+ event.curretTarget.value);
    const { responderProfile, createPersona } = this.props;
    let modifiedKey = "";
    if(name == "steroid"){
      modifiedKey = "isSteroidModified";
    } else if(name == "immunomodulator"){
      modifiedKey = "isImmunoModified";
    } else if(name == "anti-tnf"){
      modifiedKey = "isAntiTNFModified";
    } else if(name == "anti-il"){
      modifiedKey = "isAntiILModified";
    } else if(name == "anti-integrin"){
      modifiedKey = "isAntiIntegrinModified";
    }

    createPersona({
      responderProfile: Object.assign({}, responderProfile, {
        [name]: event.target.value,
        [modifiedKey]: true
      })
    });
  }

  checkForDisableOption(type, options){
    const {historicIntervention} = this.props;
    if(historicIntervention[type]["checked"] && historicIntervention[type]["initialResponse"] == "yes" && historicIntervention[type]["lossOfResponse"] == "no"){
      if(type == "steroids") type = "steroid";
      options[0].disabled = true;
      options[1].disabled = false;
      options[2].disabled = false;
    } else if(historicIntervention[type]["checked"] && ((historicIntervention[type]["initialResponse"] == "no" && historicIntervention[type]["lossOfResponse"] == "no") ||
      (historicIntervention[type]["initialResponse"] == "no" && historicIntervention[type]["lossOfResponse"] == "yes") ||
      (historicIntervention[type]["initialResponse"] == "yes" && historicIntervention[type]["lossOfResponse"] == "yes"))){
      if(type == "steroids") type = "steroid";
      options[0].disabled = true;
      options[1].disabled = true;
      options[2].disabled = true;
    } else {
      if(type == "steroids") type = "steroid";
      options[0].disabled = false;
      options[1].disabled = false;
      options[2].disabled = false;
    }
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = ref.current.getStatus();

        return result && (status === "init" ? false : status);
      },
      true
    );
  }

  reset() {
    // _.each(this.inputRefs, ref => {
    //   ref.current.reset();
    // });
  }

  checkIsActiveStep(activeStep){
    return (activeStep == 3) ? true : false;
  }

  render() {
    const { intl, responderProfile } = this.props;
    const { formatMessage } = intl;
    const [steroidResponderOptionsIntl,
      immunoResponderOptionsIntl,
      antiTnfResponderOptionsIntl,
      antiilResponderOptionsIntl,
      antiIntegrinResponderOptionsIntl]  = localOptionsIntl(
      [steroidResponderOptions,
        immunoResponderOptions,
        antiTnfResponderOptions,
        antiilResponderOptions,
        antiIntegrinResponderOptions],
      formatMessage
    );
    this.checkForDisableOption("steroids", steroidResponderOptionsIntl);
    this.checkForDisableOption("immunomodulator", immunoResponderOptionsIntl);
    this.checkForDisableOption("anti-tnf", antiTnfResponderOptionsIntl);
    this.checkForDisableOption("anti-il", antiilResponderOptionsIntl);
    this.checkForDisableOption("anti-integrin", antiIntegrinResponderOptionsIntl);

    return (
      <div className={classnames({
        "responder-profile": true
      })}>
        <InfoSection
          title={formatMessage({ id: "persona.sidebar.initial.response.title" })}
        >
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.steroids"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <FormControl component="fieldset">
              <RadioGroupCore ref={this.inputRefs.steroid} value={responderProfile["steroid"]} onChange={this.handleChange.bind(this, "steroid")} row>
                {_.map(steroidResponderOptionsIntl, (obj, i) => {
                  return (
                    <FormControlLabel key={i} value={obj.value} disabled={obj.disabled} control={<CustomRadio />} label={obj.label} />
                  );
                })}
              </RadioGroupCore>
            </FormControl>
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.immunomodulator"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <FormControl component="fieldset">
              <RadioGroupCore ref={this.inputRefs.immunomodulator} value={responderProfile["immunomodulator"]} onChange={this.handleChange.bind(this, "immunomodulator")} row>
                {_.map(immunoResponderOptionsIntl, (obj, i) => {
                  return (
                    <FormControlLabel key={i} value={obj.value} disabled={obj.disabled} control={<CustomRadio />} label={obj.label} />
                  );
                })}
              </RadioGroupCore>
            </FormControl>
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.anti-tnf"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <FormControl component="fieldset">
              <RadioGroupCore ref={this.inputRefs["anti-tnf"]} value={responderProfile["anti-tnf"]} onChange={this.handleChange.bind(this, "anti-tnf")} row>
                {_.map(antiTnfResponderOptionsIntl, (obj, i) => {
                  return (
                    <FormControlLabel key={i} value={obj.value} disabled={obj.disabled} control={<CustomRadio />} label={obj.label} />
                  );
                })}
              </RadioGroupCore>
            </FormControl>
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.anti-integrin"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <FormControl component="fieldset">
              <RadioGroupCore ref={this.inputRefs["anti-integrin"]} value={responderProfile["anti-integrin"]} onChange={this.handleChange.bind(this, "anti-integrin")} row>
                {_.map(antiIntegrinResponderOptionsIntl, (obj, i) => {
                  return (
                    <FormControlLabel key={i} value={obj.value} disabled={obj.disabled} control={<CustomRadio />} label={obj.label} />
                  );
                })}
              </RadioGroupCore>
            </FormControl>
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.anti-il"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <FormControl component="fieldset">
              <RadioGroupCore ref={this.inputRefs["anti-il"]} value={responderProfile["anti-il"]} onChange={this.handleChange.bind(this, "anti-il")} row>
                {_.map(antiilResponderOptionsIntl, (obj, i) => {
                  return (
                    <FormControlLabel key={i} value={obj.value} disabled={obj.disabled} control={<CustomRadio />} label={obj.label} />
                  );
                })}
              </RadioGroupCore>
            </FormControl>
          </FiledLabel>
        </InfoSection>
      </div>
    );
  }
}

ResponderProfile.defaultProps = {};

ResponderProfile.propTypes = {
  createPersona: PropTypes.func,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  responderProfile: PropTypes.object,
  historicIntervention: PropTypes.object
};

export default injectIntl(ResponderProfile, {
  withRef: true
});
