const actionTypes = {
  //common
  LOGIN: "LOGIN",
  CHECK_SESSION: "CHECK_SESSION",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  SHOW_MESSAGE: "SHOW_MESSAGE",
  HIDE_MESSAGE: "HIDE_MESSAGE",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILED: "CREATE_USER_FAILED",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILED: "VERIFY_EMAIL_FAILED",
  FORGOT_PASSWORD_VERIFY: "FORGOT_PASSWORD_VERIFY",
  FORGOT_PASSWORD_VERIFY_SUCCESS: "FORGOT_PASSWORD_VERIFY_SUCCESS",
  FORGOT_PASSWORD_VERIFY_FAILED: "FORGOT_PASSWORD_VERIFY_FAILED",
  CONFIRM_RESET_PASSWORD: "CONFIRM_RESET_PASSWORD",
  CONFIRM_RESET_PASSWORD_SUCCESS: "CONFIRM_RESET_PASSWORD_SUCCESS",
  CONFIRM_RESET_PASSWORD_FAILED: "CONFIRM_RESET_PASSWORD_FAILED",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILED: "LOGOUT_FAILED",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  //Persona
  EDIT_PERSONA: "EDIT_PERSONA",
  CREATE_PERSONA: "CREATE_PERSONA",
  GET_PRESET_PERSONAS: "GET_PRESET_PERSONAS",
  GET_PRESET_PERSONAS_SUCCESS: "GET_PRESET_PERSONAS_SUCCESS",
  GET_PRESET_PERSONAS_FAILED: "GET_PRESET_PERSONAS_FAILED",
  GET_CUSTOM_PERSONAS: "GET_CUSTOM_PERSONAS",
  GET_CUSTOM_PERSONAS_SUCCESS: "GET_CUSTOM_PERSONAS_SUCCESS",
  GET_CUSTOM_PERSONAS_FAILED: "GET_CUSTOM_PERSONAS_FAILED",
  ADD_NEW_PERSONA: "ADD_NEW_PERSONA",
  ADD_NEW_PERSONA_SUCCESS: "ADD_NEW_PERSONA_SUCCESS",
  ADD_NEW_PERSONA_FAILED: "ADD_NEW_PERSONA_FAILED",
  RESET_NEW_PERSONA: "RESET_NEW_PERSONA",
  //Dashboard
  EDIT_DASHBOARD: "EDIT_DASHBOARD",
  RESET_DASHBOARD: "RESET_DASHBOARD",
  SIMULATE: "SIMULATE",
  SIMULATE_SUCCESS: "SIMULATE_SUCCESS",
  SIMULATE_FAILED: "SIMULATE_FAILED",
  SIGNEDURL_SUCCESS: "SIGNEDURL_SUCCESS",
  SIGNEDURL_FAILED: "SIGNEDURL_FAILED",
  GET_IMAGE_SIGNED_URL : "GET_IMAGE_SIGNED_URL",

  //Admin
  EDIT_ADMIN: "EDIT_ADMIN",
  LOAD_USERS: "LOAD_USERS",
  LOAD_USERS_SUCCESS: "LOAD_USERS_SUCCESS",
  LOAD_USERS_FAILED: "LOAD_USERS_FAILED",
  LOAD_SIMULATE_RESULT: "LOAD_SIMULATE_RESULT",
  LOAD_SIMULATE_RESULT_SUCCESS: "LOAD_SIMULATE_RESULT_SUCCESS",
  LOAD_SIMULATE_RESULT_FAILED: "LOAD_SIMULATE_RESULT_FAILED",
  LOAD_SIMULATE_DETAIL: "LOAD_SIMULATE_DETAIL",
  LOAD_SIMULATE_DETAIL_SUCCESS: "LOAD_SIMULATE_DETAIL_SUCCESS",
  LOAD_SIMULATE_DETAIL_FAILED: "LOAD_SIMULATE_DETAIL_FAILED",

  //message
  SESSION_EXPRIRED: "SESSION_EXPRIRED",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",

};

export { actionTypes };
