import { connect } from "react-redux";
import NewPersonaSidebar from "./newPersonaSidebar";
import action from "../../../flux/action";

const mapStateToProps = state => {
  const { newPersona: personaState } = state;
  const {
    isSelected,
    personaSelected
  } = personaState;

  return {
    isSelected,
    personaSelected,
    personaState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPersona: options => {
      dispatch(action.createPersona(options));
    },
    resetDashboard: options => {
      dispatch(action.resetDashboard(options));
    },
    addNewPersona: options => {
      dispatch(action.addNewPersona(options));
    },
    resetNewPersona: options => {
      dispatch(action.resetNewPersona(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPersonaSidebar);
