import {
  yesNoOptions,
  // steroidsDuractionTreatmentOptions,
  // steroidsTimeLastDoseOptions,
  //steroidsTreatmentOptions,
  // immunomodulatorDurationTreatmentOptions,
  // immunomodulatorTimeLastDoseOptions,
  //immunomodulatorTreatmentOptions,
  // anitTnfDurationTreatmentOptions,
  // anitTnfInitialResponseOptions,
  anitTnfTreatmentOptions,
  // anitTnfTimeTreatmentEndOptions,
  anitTnfClinicalOutcomeOptions,
  anitTnfObjectiveOutcomeOptions,
  // antiIntegrinDuractionTreatmentOptions,
  // antiIntegrinInitialResponseOptions,
  antiIntegrinTreatmentOptions,
  // antiIntegrinTimeTreatmentEndOptions,
  antiIntegrinClinicalOutcomeOptions,
  antiIntegrinObjectiveOutcomeOptions,
  // antiIlDuractionTreatmentOptions,
  // antiIlInitialResponseOptions,
  antiIlTreatmentOptions,
  // antiIlTimeTreatmentEndOptions,
  antiIlClinicalOutcomeOptions,
  antiIlObjectiveOutcomeOptions,
  antiIlUstekinumabOptions,
  antiIntegrinVedolizumabOptions,
  antiTnfInfliximabOptions
} from "./detailOptions";
import {
  // numberInputValidation,
  integerInputValidation,
  selectValidation
} from "./validation";

const steroidsConfig = [
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.timeLastDose",
    value: "timeLastDose",
    // options: steroidsTimeLastDoseOptions,
    validation: integerInputValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.responsive",
    value: "responsive",
    options: yesNoOptions,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.initialResponse.lower",
    value: "initialResponse",
    options: yesNoOptions
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.lossOfResponse.lower",
    value: "lossOfResponse",
    options: yesNoOptions,
    disable: true,
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.duractionTreatment",
    value: "duractionTreatment",
    // options: steroidsDuractionTreatmentOptions,
    validation: integerInputValidation
  },
  // ,
  // {
  //   type: "select",
  //   label: "persona.sidebar.historicIntervention.treatment",
  //   value: "treatment",
  //   options: steroidsTreatmentOptions,
  //   validation: selectValidation
  // }
];

const immunomodulatorConfig = [
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.timeLastDose",
    value: "timeLastDose",
    // options: immunomodulatorTimeLastDoseOptions,
    validation: integerInputValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.responsive",
    value: "responsive",
    options: yesNoOptions,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.initialResponse.lower",
    value: "initialResponse",
    options: yesNoOptions
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.lossOfResponse.lower",
    value: "lossOfResponse",
    options: yesNoOptions,
    disable: true,
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.duractionTreatment",
    value: "duractionTreatment",
    // options: immunomodulatorDurationTreatmentOptions,
    validation: integerInputValidation,
  },
  // ,
  // {
  //   type: "select",
  //   label: "persona.sidebar.historicIntervention.treatment",
  //   value: "treatment",
  //   options: immunomodulatorTreatmentOptions,
  //   validation: selectValidation
  // }
];

const antiTnfConfig = [
  // {
  //   type: "select",
  //   label: "persona.sidebar.historicIntervention.initialResponse",
  //   value: "initialResponse",
  //   options: anitTnfInitialResponseOptions,
  //   validation: selectValidation
  // },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.treatment",
    value: "treatment",
    options: anitTnfTreatmentOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.current",
    value: "current",
    options: yesNoOptions,
    hide: true
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.timeTreatmentEnd",
    value: "timeTreatmentEnd",
    // options: anitTnfTimeTreatmentEndOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.clinicalOutcome",
    value: "clinicalOutcome",
    options: anitTnfClinicalOutcomeOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.objectiveOutcome",
    value: "objectiveOutcome",
    options: anitTnfObjectiveOutcomeOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.initialResponse.lower",
    value: "initialResponse",
    options: yesNoOptions,
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.lossOfResponse.lower",
    value: "lossOfResponse",
    options: yesNoOptions,
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.duractionTreatment",
    value: "duractionTreatment",
    // options: anitTnfDurationTreatmentOptions,
    validation: integerInputValidation,
  },
  {
    type: "select",
    label: "persona.sidebar.proposedTreatment.regime",
    value: "regime",
    options: antiTnfInfliximabOptions,
    validation: selectValidation
  }
];

const antiIntegrinConfig = [
  // {
  //   type: "select",
  //   label: "persona.sidebar.historicIntervention.initialResponse",
  //   value: "initialResponse",
  //   options: antiIntegrinInitialResponseOptions,
  //   validation: selectValidation
  // },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.treatment",
    value: "treatment",
    options: antiIntegrinTreatmentOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.current",
    value: "current",
    options: yesNoOptions,
    hide: true
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.timeTreatmentEnd",
    value: "timeTreatmentEnd",
    // options: antiIntegrinTimeTreatmentEndOptions,
    validation: integerInputValidation,
    hide: true
  },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.clinicalOutcome",
    value: "clinicalOutcome",
    options: antiIntegrinClinicalOutcomeOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.objectiveOutcome",
    value: "objectiveOutcome",
    options: antiIntegrinObjectiveOutcomeOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.initialResponse.lower",
    value: "initialResponse",
    options: yesNoOptions,
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.lossOfResponse.lower",
    value: "lossOfResponse",
    options: yesNoOptions,
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.duractionTreatment",
    value: "duractionTreatment",
    // options: antiIntegrinDuractionTreatmentOptions,
    validation: integerInputValidation
  },
  {
    type: "select",
    label: "persona.sidebar.proposedTreatment.regime",
    value: "regime",
    options: antiIntegrinVedolizumabOptions,
    validation: selectValidation
  }
];

const antiIlConfig = [
  // {
  //   type: "select",
  //   label: "persona.sidebar.historicIntervention.initialResponse",
  //   value: "initialResponse",
  //   options: antiIlInitialResponseOptions,
  //   validation: selectValidation
  // },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.treatment",
    value: "treatment",
    options: antiIlTreatmentOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.current",
    value: "current",
    options: yesNoOptions,
    hide: true
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.timeTreatmentEnd",
    value: "timeTreatmentEnd",
    // options: antiIlTimeTreatmentEndOptions,
    validation: integerInputValidation,
    hide: true
  },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.clinicalOutcome",
    value: "clinicalOutcome",
    options: antiIlClinicalOutcomeOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "select",
    label: "persona.sidebar.historicIntervention.objectiveOutcome",
    value: "objectiveOutcome",
    options: antiIlObjectiveOutcomeOptions,
    validation: selectValidation,
    hide: true
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.initialResponse.lower",
    value: "initialResponse",
    options: yesNoOptions,
  },
  {
    type: "radio",
    label: "persona.sidebar.historicIntervention.lossOfResponse.lower",
    value: "lossOfResponse",
    options: yesNoOptions,
  },
  {
    type: "input",
    label: "persona.sidebar.historicIntervention.duractionTreatment",
    value: "duractionTreatment",
    // options: antiIlDuractionTreatmentOptions,
    validation: integerInputValidation
  },
  {
    type: "select",
    label: "persona.sidebar.proposedTreatment.regime",
    value: "regime",
    options: antiIlUstekinumabOptions,
    validation: selectValidation
  }
];

const pharmacologyConfig = {
  steroids: steroidsConfig,
  immunomodulator: immunomodulatorConfig,
  "anti-tnf": antiTnfConfig,
  "anti-integrin": antiIntegrinConfig,
  "anti-il": antiIlConfig
};

export  {
  pharmacologyConfig
};
