import React, { Component } from "react";
import ModelContainer from "./modelContainer";
import {validateLogin} from "../../../utils";

import "../../../../styles/model/main.scss";
import AppFooter from "../../../container/appFooter";

class ModelMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    validateLogin();
  }

  handleClick() {}

  render() {
    return (
      <div className="model-main">
        <ModelContainer />
        <AppFooter />
      </div>
    );
  }
}

ModelMain.defaultProps = {};

ModelMain.propTypes = {};

export default ModelMain;
