import React, { Component } from "react";
import PropTypes from "prop-types";
// import "../../../styles/components/model-content.scss";
class ModelContent extends Component {
  constructor(props) {
    super(props);
  }
  getIcon(){
    const {titleImgUrl} =this.props;
    if(titleImgUrl){
      return (
        <img className="image" src={titleImgUrl} />
      );
    }
    else{
      return(
        <i className="material-icons">help</i>
      );
    }
  }
  render() {
    const {children, title} = this.props;
    return (
      <div className="models-container">
        <div className="title">
          {/* <img className="image" src={titleImgUrl} /> */}
          {this.getIcon()}
          <div className="text">{title}</div>
          <div className="divder"></div>
        </div>
        <div className="content">
          {children}
        </div>
      </div>
    );
  }
}

ModelContent.defaultProps = {};

ModelContent.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  titleImgUrl: PropTypes.string
};

export default ModelContent;
