import { handleActions } from "redux-actions";
import action from "../action";
import dState from "./defaultState";

const { actionTypes } = action;
const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  FORGOT_PASSWORD_VERIFY,
  FORGOT_PASSWORD_VERIFY_SUCCESS,
  FORGOT_PASSWORD_VERIFY_FAILED,
  CONFIRM_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAILED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
} = actionTypes;
const isLogin = localStorage.getItem("isLogin") === "true" ? true : false;
const isAdmin = localStorage.getItem("isAdmin") === "true" ? true : false;
const defaultState = Object.assign({ isLogin, isAdmin}, dState);

export default handleActions(
  {
    [LOGIN]: (state, action) => {
      const { payload } = action;

      return Object.assign({ isLoading: true }, state, payload);
    },
    [LOGIN_SUCCESS]: (state, action) => {
      const { payload } = action;
      return Object.assign(
        {},
        state,
        {
          isLoading: false
        },
        payload
      );
    },
    [LOGIN_FAILED]: (state, action) => {
      const { payload } = action;
      return Object.assign({}, state, {
        isLoading: false,
        errMessage: payload
      });
    },
    [LOGOUT]: (state, action) => {
      const {payload} = action;

      return Object.assign({isLoading: true}, state, payload);
    },
    [LOGOUT_SUCCESS]: (state, action) => {
      const {payload} = action;
      return Object.assign(
        {},
        state,
        {
          isLoading: false
        },
        payload
      );
    },
    [LOGOUT_FAILED]: (state, action) => {
      const {payload} = action;
      return Object.assign({}, state, {
        isLoading: false,
        errMessage: payload
      });
    },
    [CREATE_USER]: (state, action) => {
      const { payload } = action;

      return Object.assign({}, state, { isLoading: true }, payload);
    },
    [CREATE_USER_SUCCESS]: (state /*, action*/) => {
      return Object.assign({}, state, {
        isLoading: false,
        isUserCreated: true
      });
    },
    [CREATE_USER_FAILED]: state => {
      return Object.assign({}, state, { isLoading: false });
    },
    [VERIFY_EMAIL]: state => {
      return Object.assign({}, state, { isLoading: true });
    },
    [VERIFY_EMAIL_SUCCESS]: state => {
      return Object.assign({}, state, {
        isLoading: false,
        isEmailVerified: true
      });
    },
    [VERIFY_EMAIL_FAILED]: state => {
      return Object.assign({}, state, { isLoading: false });
    },
    [FORGOT_PASSWORD_VERIFY]: state => {
      return Object.assign({}, state, { isLoading: true });
    },
    [FORGOT_PASSWORD_VERIFY_SUCCESS]: state => {
      return Object.assign({}, state, {
        isLoading: false,
        forgotPasswordCodeVerified: true
      });
    },
    [FORGOT_PASSWORD_VERIFY_FAILED]: state => {
      return Object.assign({}, state, { isLoading: false });
    },
    [CONFIRM_RESET_PASSWORD]: state => {
      return Object.assign({}, state, { isLoading: true });
    },
    [CONFIRM_RESET_PASSWORD_SUCCESS]: state => {
      return Object.assign({}, state, {
        isLoading: false,
        forgotPasswordCodeVerified: false,
        confirmResetPassSuccess: true
      });
    },
    [CONFIRM_RESET_PASSWORD_FAILED]: state => {
      return Object.assign({}, state, { isLoading: false });
    },
    [FORGOT_PASSWORD]: state => {
      return Object.assign({}, state, { isLoading: true });
    },
    [FORGOT_PASSWORD_SUCCESS]: state => {
      return Object.assign({}, state, {
        isLoading: false,
        isForgotPasswordEmailSended: true
      });
    },
    [FORGOT_PASSWORD_FAILED]: state => {
      return Object.assign({}, { isLoading: false }, state);
    },
  },
  defaultState
);
