import maleIconDisabled from "../../../assets/icons/ico_male_disabled.png";
import femaleIconDisabled from "../../../assets/icons/ico_female_disabled.png";

const genderOptions = [
  {
    label: "persona.options.gender.male",
    value: "male"
  },
  {
    label: "persona.options.gender.female",
    value: "female"
  }
];

const tobaccoUse = [
  {
    label: "persona.options.tobaccoUse.current",
    value: "current_smoker"
  },
  {
    label: "persona.options.tobaccoUse.previous",
    value: "previous_smoker"
  },
  {
    label: "persona.options.tobaccoUse.never",
    value: "never_smoker"
  }
];

const genderOptionsWithIcon = [
  {value:"female", label: "persona.options.gender.female",icon: femaleIconDisabled},
  {value:"male", label: "persona.options.gender.male", icon: maleIconDisabled}
];
const ageRanges = [
  {label:"18 - 30", value: 25},
  {label:"31 - 40", value: 35},
  {label:"41 - 50", value: 45},
  {label:"51 - 60", value: 55},
  {label:"61+", value: 65}
];
const ageRangesPreset = [
  {label:"18 - 30", value: 20},
  {label:"18 - 30", value: 25},
  {label:"18 - 30", value: 30},
  {label:"31 - 40", value: 35},
  {label:"41 - 50", value: 45},
  {label:"51 - 60", value: 55},
  {label:"61+", value: 65}
];
const weights = [
  {label:"persona.sidebar.patientInfo.option.below", value: 50},
  {label:"persona.sidebar.patientInfo.option.normal", value: 65},
  {label:"persona.sidebar.patientInfo.option.above", value: 90}
];

export {
  genderOptions,
  tobaccoUse,
  genderOptionsWithIcon,
  ageRanges,
  ageRangesPreset,
  weights
};
