import React, { Component } from "react";
import PropTypes from "prop-types";
import FieldLabel from "../../../components/fieldLabel";
import { /*SelectMenu,*/ MultiSelect, MultiDatePicker } from "ui-toolkit";
import { Button } from "@material-ui/core";
import _ from "lodash";
import { connect } from "react-redux";
import action from "../../../flux/action";

// const selectConfig = [
//   {
//     name: "All",
//     value: ""
//   }
// ];
const dateFormat = "YYYY-MM-DD";
const personaConfig = [
  {
    name: "persona1",
    value: "persona1"
  },
  {
    name: "persona2",
    value: "persona2"
  },
  {
    name: "persona3",
    value: "persona3"
  },
  {
    name: "custom",
    value: "custom"
  }
];

class Selection extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { loadUsers } = this.props;

    loadUsers();
  }

  handleChange(name, value) {
    const { editAdmin } = this.props;

    editAdmin({
      [name]: value
    });
  }

  handleFilter() {
    const { loadSimulateResult, selectedUser, persona, dateRange } = this.props;
    const [dateFrom, dateTo] = dateRange;

    loadSimulateResult({
      user: selectedUser,
      persona,
      date: [
        dateFrom ? dateFrom.format(dateFormat) : "",
        dateFrom ? dateTo.format(dateFormat) : ""
      ]
    });
  }

  render() {
    const { users, selectedUser, persona, dateRange } = this.props;
    const config = _.concat(
      _.map(users, user => {
        return {
          name: _.toLower(user),
          value: user
        };
      })
    );
    return (
      <div className="selection">
        <FieldLabel label="Select User">
          {/* <SelectMenu
            value={selectedUser}
            config={config}
            onSelect={this.handleChange.bind(this, "selectedUser")}
          /> */}
          <MultiSelect
            value={selectedUser}
            options={config}
            onChange={this.handleChange.bind(this, "selectedUser")}
          />
        </FieldLabel>
        <FieldLabel label="Persona">
          <MultiSelect
            value={persona}
            options={personaConfig}
            onChange={this.handleChange.bind(this, "persona")}
          />
        </FieldLabel>
        <FieldLabel label="Date Filter">
          <MultiDatePicker
            date={dateRange}
            onChange={this.handleChange.bind(this, "dateRange")}
          />
        </FieldLabel>
        <div className="filter-button">
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleFilter.bind(this)}
          >
            filter
          </Button>
        </div>
      </div>
    );
  }
}

Selection.defaultProps = {};

Selection.propTypes = {
  users: PropTypes.array,
  selectedUser: PropTypes.array,
  persona: PropTypes.array,
  dateRange: PropTypes.array,
  editAdmin: PropTypes.func,
  loadUsers: PropTypes.func,
  loadSimulateResult: PropTypes.func
};

const mapStateToProps = state => {
  const { admin: adminState } = state;
  const { selectedUser, persona, dateRange, users } = adminState;

  return {
    selectedUser,
    persona,
    dateRange,
    users
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editAdmin: options => {
      dispatch(action.editAdmin(options));
    },
    loadUsers: () => {
      dispatch(action.loadUsers());
    },
    loadSimulateResult: options => {
      dispatch(action.loadSimulateResult(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Selection);
