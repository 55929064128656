import "whatwg-fetch";
import _ from "lodash";
import {url as baseUrl} from "../../config";

import commonActions from "../flux/action/dashboard";


const apiMap = {
  login: "/api/login",
  logout: "/api/logout",
  createUser: "/api/createUser",
  verifyEmail: "/api/verifyEmail",
  verifyPasswordReset: "/api/verifyPasswordReset",
  confirmPasswordReset: "/api/confirmPasswordReset",
  forgetPassword: "/api/forgetPassword",
  userMatch: "/api/userMatch",
  simulate: "/api/simulate",
  addNewPersona: "/api/addNewPersona",
  getPresetPersonas: "/api/getPresetPersonas",
  getCustomPersonas: "/api/getCustomPersonas",
  loadUsers: "/api/getUsers",
  loadSimulateResult: "/api/getSimulateResult",
  loadSimulateDetail: "/api/getSimulateDetail",
  getImageSignedUrl: "/api/getImageSignedUrl"
};

const createRequest = (options , dispatch = () => {}) => {
  const {pathName, method, isFileUpload, ...other} = options;
  const requestPath = apiMap[pathName];
  const fullPath = `${baseUrl}${requestPath}`;
  let promise = null;
  if (!requestPath) {
    throw new Error("Path name not exists");
  }

  if (isFileUpload) {
    const formData = new FormData();

    _.each(other, (value, name) => {
      formData.append(name, value);
    });
    promise = fetch(fullPath, {
      method: "POST",
      body: formData
    });
  } else if (!method || _.toUpper(method) === "GET") {
    const params = _.reduce(
      other,
      (result, value, name) => {
        if (value.toString) {
          result.push(`${name}=${value.toString()}`);
        }

        return result;
      },
      []
    );
    const paramStr = params.length ? `?${params.join("&")}` : "";
    promise = fetch(`${fullPath}${paramStr}`);
  } else {
    promise = fetch(fullPath, {
      method,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(other)
    });
  }

  return promise
    .then((response) => {
      if(response.status == 403){
        dispatch(commonActions.sessionExpriedAction());
      }
      return response.json();
    })
    .then((response) => {
      const {success, message, ...other} = response;
      if (success) {
        return {
          success,
          message,
          ...other
        };
      } else {
        return Promise.reject({
          message
        });
      }
    });
};

export {createRequest};
