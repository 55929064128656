import _ from "lodash";
import { useHistory } from "react-router-dom";

const routerProps = {
  match: null,
  location: null,
  history: null
};

function initRouter(props) {
  if (props && !routerProps.history) {
    _.each(props, (value, name) => {
      routerProps[name] = value;
    });
  }
}

function navigateTo(path){
  const history = useHistory();
  history.push(path);
}

function naviTo(path) {
  const {history} = routerProps;

  if (history) {
    history.push(path);
  } else {
    throw new Error("No history object");
  }
}

function validateLogin(path = "/login") {
  const isLogin = localStorage.getItem("isLogin");

  if (!isLogin || isLogin == "false" ) {
    naviTo(path);
  }
}

function checkLogin(path = "/app") {
  const isLogin = localStorage.getItem("isLogin");

  if (isLogin == "true") {
    naviTo(path);
  }
}

export {initRouter, naviTo, validateLogin, checkLogin, navigateTo};
