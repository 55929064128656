import React from "react";

function frequentlyAskedContent(formatMessage) {
  return (
    <div>
      <div className="faq-content">
        <div className="title">
          {formatMessage({ id: "model.content.frequently.asked.content.title1" })}
        </div>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.frequently.asked.content.content1" })}
          </li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="faq-content">
        <div className="title">
          {formatMessage({ id: "model.content.frequently.asked.content.title2" })}
        </div>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.frequently.asked.content.content2" })}
          </li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="faq-content">
        <div className="title">
          {formatMessage({ id: "model.content.frequently.asked.content.title3" })}
        </div>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.frequently.asked.content.content3" })}
          </li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="faq-content">
        <div className="title">
          {formatMessage({ id: "model.content.frequently.asked.content.title4" })}
        </div>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.frequently.asked.content.content4" })}
          </li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="faq-content">
        <div className="title">
          {formatMessage({ id: "model.content.frequently.asked.content.title5" })}
        </div>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.frequently.asked.content.content5" })}
          </li>
        </ul>
      </div>
      <div className="dashed-divider" />
      <div className="faq-content">
        <div className="title">
          {formatMessage({ id: "model.content.frequently.asked.content.title6" })}
        </div>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.frequently.asked.content.content6" })}
          </li>
        </ul>
      </div>
    </div>
  );
}

export { frequentlyAskedContent };
