import { handleActions } from "redux-actions";
import action from "../action";
import dState from "./defaultState";
import _ from "lodash";
import {
  // getInitItem,
  getDefaultPatientInfo,
  getDefaultDiseaseState,
  getDefaultHistoricIntervention,
  getDefaultResponderProfile
} from "../../model";

const { actionTypes } = action;
const { EDIT_PERSONA, LOGIN_SUCCESS } = actionTypes;

const defaultState = Object.assign(dState, {
  archetypeId: "",
  personaSelected: "",
  isSelected: false,
  patientInfo: getDefaultPatientInfo(),
  diseaseState: getDefaultDiseaseState(),
  historicIntervention: getDefaultHistoricIntervention(),
  responderProfile: getDefaultResponderProfile()
});

export default handleActions(
  {
    [EDIT_PERSONA]: (state, action) => {
      const { payload } = action;

      return Object.assign({}, state, payload);
    },
    [LOGIN_SUCCESS]: () => {
      return _.defaultsDeep({}, defaultState);
    }
  },
  defaultState
);
