import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import {
  // SelectMenu,
  // CommonInput,
  // RadioGroup,
  CheckExpansionPanel
  // MultiDatePicker
} from "ui-toolkit";
import CommonField from "../../../../components/commonField";
// import FiledLabel from "../../../../components/fieldLabel";
import {
  drugConfig,
  // numberInputValidation,
  integerInputValidation,
  loadIntl,
  localOptionsIntl
} from "../../../../model";

class Drug extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      // duractionTreatment: React.createRef(),
      // doseFrequency: React.createRef(),
      // frequency: React.createRef(),
      // immunogenicity: React.createRef()
      // increase: React.createRef()
    };
  }

  handleChange(name, value) {
    const { data, onChange } = this.props;
    onChange(
      Object.assign({}, data, {
        [name]: value
      })
    );
  }

  showDesc(isShow, value, options) {
    if (!isShow) {
      return null;
    }

    const selectedOption = _.find(options, {
      value
    });

    return (
      <div className="regime-desc">
        {selectedOption ? selectedOption["desc"] : ""}
      </div>
    );
  }

  getCustomFields(index, name) {
    const { data, intl } = this.props;

    if (data["regime"] === "custom") {
      const { formatMessage } = intl;
      const [integerInputValidationIntl] = loadIntl(
        [integerInputValidation],
        formatMessage
      );

      this.inputRefs["dose"] = React.createRef();
      this.inputRefs["frequency"] = React.createRef();

      return (
        <Fragment>
          <CommonField
            ref={this.inputRefs["dose"]}
            key={`common-field-dose${index}`}
            type="input"
            value={data["dose"]}
            label={formatMessage(
              {
                id: "dashboard.sidebar.proposedTreatment.dose"
              },
              { unit: name === "anti-tnf-infliximab" ? "mg/kg" : "mg" }
            )}
            validation={integerInputValidationIntl}
            onChange={this.handleChange.bind(this, "dose")}
          />
          <CommonField
            ref={this.inputRefs["frequency"]}
            key={`common-field-frequency${index}`}
            type="input"
            value={data["frequency"]}
            label={formatMessage({
              id: "dashboard.sidebar.proposedTreatment.frequency"
            })}
            validation={integerInputValidationIntl}
            onChange={this.handleChange.bind(this, "frequency")}
          />
        </Fragment>
      );
    } else {
      return null;
    }
  }

  getFields() {
    const { data, name, intl,tooltipContent } = this.props;
    const { formatMessage } = intl;
    const config = drugConfig[name];
    const fields = [];

    _.each(config, (field, i) => {
      const {
        type,
        label,
        value,
        options,
        validation,
        showDesc,
        showCustom
      } = field;
      const [validationIntl] = loadIntl([validation], formatMessage);
      const [optionsIntl] = localOptionsIntl([options], formatMessage);

      this.inputRefs[value] = React.createRef();

      fields.push(
        <CommonField
          ref={this.inputRefs[value]}
          key={`common-field${i}`}
          type={type}
          label={formatMessage({ id: label })}
          value={data[value]}
          options={optionsIntl}
          validation={validationIntl}
          onChange={this.handleChange.bind(this, value)}
          tooltipContent={tooltipContent}
        >
          {this.showDesc(showDesc, data[value], optionsIntl)}
        </CommonField>
      );
      if (showCustom) {
        fields.push(this.getCustomFields(i, name));
      }
    });

    return fields;
  }

  getStatus() {
    const { data } = this.props;
    const { checked } = data;

    if (checked) {
      return _.reduce(
        this.inputRefs,
        (result, ref) => {
          const status = ref.current.getStatus ? ref.current.getStatus() : true;

          return result && (status === "init" ? false : status);
        },
        true
      );
    } else {
      return true;
    }
  }

  reset() {
    _.each(this.inputRefs, ref => {
      if (ref && ref.current && ref.current.state) {
        ref.current.reset();
      }
    });
  }

  render() {
    const { label, data, disabled } = this.props;
    const { checked } = data;

    return (
      <div className="drug">
        <CheckExpansionPanel
          color="primary"
          label={label}
          checked={checked}
          disabled={disabled}
          onChange={this.handleChange.bind(this, "checked")}
        >
          {this.getFields()}
        </CheckExpansionPanel>
      </div>
    );
  }
}

Drug.defaultProps = {
  name: "",
  label: "",
  data: {},
  disabled: false,
  onChange: () => {}
};

Drug.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  tooltipContent: PropTypes.array,
  intl: intlShape.isRequired
};

export default injectIntl(Drug, {
  withRef: true
});
