const languages = [
  {
    label: "English",
    value: "en"
  },
  {
    label: "日本語",
    value: "ja"
  }
];

const yesNoOptions = [
  {
    label: "persona.options.yesno.yes",
    value: "yes"
  },
  {
    label: "persona.options.yesno.no",
    value: "no"
  }
];

const yesNoNaOptions = [
  {
    label: "persona.options.yesno.yes",
    value: "yes"
  },
  {
    label: "persona.options.yesno.no",
    value: "no"
  },
  {
    label: "persona.options.yesno.na",
    value: ""
  }
];

const biologicTreatmentStatus = [
  {
    label: "Biologic naive",
    value: "Biologic naive"
  },
  {
    label: "Previous biologic treatment",
    value: "Previous biologic treatment"
  }
];

const personaSteps = [
  {
    label: "persona.main.container.demographics",
    value: "demographics"
  },
  {
    label: "persona.main.container.disease.state",
    value: "disease-state"
  },
  {
    label: "persona.sidebar.historicIntervention.treatment.history",
    value: "treatment-history"
  },{
    label: "persona.sidebar.initial.response.title",
    value: "responder-profile"
  }
];//["Demographics", "Disease State", "Treatment History", "Responder Profile"]

export {
  languages,
  yesNoOptions,
  biologicTreatmentStatus,
  personaSteps,
  yesNoNaOptions
};
