import React, { Component } from "react";
import PropTypes from "prop-types";
import PersonaContainer from "./personaContainer";
import {validateLogin} from "../../../utils";
import Loading from "../../../components/Loading";
// import {withRouter} from "react-router-dom";

import "../../../../styles/persona/main.scss";

class CustomPersona extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    validateLogin();
    const {getCustomPersonas, selectPersona} = this.props;
    // if(!personas.isLoading && personas.personas.length)  return null;
    getCustomPersonas();
    selectPersona({
      personaSelected: "",
      isSelected: false
    });
  }

  render() {
    const { selectPersona, personaSelected, personas } = this.props;
    return (
      <div className="persona-main preset-pernona">
        <Loading isShow={personas.isLoading} />
        <PersonaContainer
          selectPersona={selectPersona}
          personaSelected={personaSelected}
          personas={personas["personas"]}
        />
      </div>
    );
  }
}

CustomPersona.defaultProps = {};

CustomPersona.propTypes = {
  selectPersona: PropTypes.func,
  personaSelected: PropTypes.string,
  personas: PropTypes.object,
  getCustomPersonas: PropTypes.func
};

export default CustomPersona;
