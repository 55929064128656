import {actionTypes} from "./actionTypes";
import common from "./common";
import login from "./login";
import persona from "./persona";
import dashboard from "./dashboard";
import admin from "./admin";
import newPersona from "./newPersona";
import personas from "./personas";

export default Object.assign(
  {
    actionTypes
  },
  common,
  login,
  persona,
  dashboard,
  admin,
  newPersona,
  personas
);
