import React, { Component } from "react";
import PropTypes from "prop-types";
import { CommonAlert } from "ui-toolkit";
import { injectIntl, intlShape } from "react-intl";
class CustomAlert extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl,
      message,
      open,
      onClose,
      showAction,
      onAction
    } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="alert-container">
        <CommonAlert
          message={message ? formatMessage({id: message}) : message}
          open={open}
          onClose={onClose}
          showAction={showAction}
          onAction={onAction}
        />
      </div>
    );
  }
}

CustomAlert.defaultProps = {
  min: 40,
  max: 100
};

CustomAlert.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  autoHideDuration: PropTypes.number,
  showAction: PropTypes.bool,
  actionLabel: PropTypes.string,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(CustomAlert);
