import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import actions from "../flux/action";
class Logout extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
  }

  handleOpenMenu(e) {
    const anchorEl = e.currentTarget;
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
      anchorEl
    });
  }

  handleCloseMenu() {
    this.setState({
      isOpen: false
    });
  }

  handleLogout() {
    const { logout } = this.props;

    logout();
  }

  render() {
    const { isOpen, anchorEl } = this.state;
    const { intl, practiceMode } = this.props;
    const { formatMessage } = intl;
    //const userName = localStorage.getItem("userName");
    return (
      <div className="app-logout">
        <IconButton
          color="inherit"
          aria-label="More"
          aria-owns={anchorEl ? "info-menu" : null}
          aria-haspopup="true"
          onClick={this.handleOpenMenu.bind(this)}
        >
          <i className="material-icons">more_vert</i>
        </IconButton>
        <Menu
          id="info-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={this.handleCloseMenu.bind(this)}
          className="logout-menu"
        >
          {/* <MenuItem>{userName}</MenuItem> */}
          {practiceMode &&
            <MenuItem>
              <span className="formatModeTextOn">{formatMessage({id:"practice.mode"})} <span className="font-bold">{formatMessage({id:"header.practise.mode.on"})}</span></span>
            </MenuItem>
          }
          {!practiceMode &&
            <MenuItem>
              <span className="formatModeTextOff">{formatMessage({id:"practice.mode"})} <span className="font-bold">{formatMessage({id:"header.practise.mode.off"})}</span></span>
            </MenuItem>
          }
          <MenuItem onClick={this.handleLogout.bind(this)}>{formatMessage({id:"header.logout"})}</MenuItem>
        </Menu>
      </div>
    );
  }
}

Logout.defaultProps = {
  practiceMode: false,
  logout: () => {},
};

Logout.propTypes = {
  practiceMode: PropTypes.bool,
  logout: PropTypes.func,
  intl: intlShape.isRequired
};

const mapStateToProps = (/*state*/) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  const { logout } = actions;
  return {
    logout: () => {
      dispatch(logout());
    }
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Logout)
);
