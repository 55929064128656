import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import {
  // RadioGroup,
  // SelectMenu,
  CommonInput,
  // CheckGroup
} from "ui-toolkit";
import FiledLabel from "../../../../components/fieldLabel";
import {
  // yesNoOptions,
  // diseaseDuraction as diseaseDuractionOptions,
  // previousSurgery as previousSurgeryOptions,
  // selectValidation,
  // durationDiseaseValidation,
  getRangeValidation,
  loadIntl,
  // localOptionsIntl
} from "../../../../model";
// import InfoSection from "../../../../components/infoSection";

class DiseaseHistory extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      diseaseDuraction: React.createRef()
    };
  }

  handleChange(name, value) {
    const { data, onChange } = this.props;

    onChange(
      Object.assign({}, data, {
        [name]: value
      })
    );
  }

  getStatus() {
    const { data } = this.props;
    const { isOpen } = data;

    if (isOpen) {
      return _.reduce(
        this.inputRefs,
        (result, ref) => {
          const status = ref && ref.current && ref.current.getStatus && ref.current.getStatus();

          return result && (status === "init" ? false : status);
        },
        true
      );
    } else {
      return true;
    }
  }

  reset() {
    _.each(this.inputRefs, ref => {
      ref && ref.current && ref.current.reset && ref.current.reset();
    });
  }

  render() {
    const { data, intl } = this.props;
    // const { perianal, diseaseDuraction, previousSurgery } = data;
    const { diseaseDuraction } = data;
    const { formatMessage } = intl;
    const durationCrohnValidation = getRangeValidation([0, 20]);
    const [durationCrohnValidationIntl] = loadIntl(
      [durationCrohnValidation],
      formatMessage
    );
    // const [yesNoOptionsIntl, previousSurgeryOptionsIntl] = localOptionsIntl(
    //   [yesNoOptions, previousSurgeryOptions],
    //   formatMessage
    // );

    return (
      <div className="disease-history">
        <FiledLabel
          label={formatMessage({
            id: "persona.sidebar.diseaseState.diseaseHistory.diseaseDuraction"
          })}
        >
          <CommonInput
            ref={this.inputRefs.diseaseDuraction}
            value={diseaseDuraction}
            validation={durationCrohnValidationIntl}
            onChange={this.handleChange.bind(this, "diseaseDuraction")}
          />
        </FiledLabel>
      </div>
    );
  }
}

DiseaseHistory.defaultProps = {
  data: {},
  onChange: () => { }
};

DiseaseHistory.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(DiseaseHistory, {
  withRef: true
});
