import React, { Component } from "react";
import PropTypes from "prop-types";
import PersonaContent from "../../../../components/personaContent";
// import CustomProfile from "../../../../components/customProfile";
import { injectIntl, intlShape } from "react-intl";
import _ from "lodash";
import {
  getInitItem
} from "../../../../model";
import AppFooter from "../../../../container/appFooter";

class PersonaContainer extends Component {
  constructor(props) {
    super(props);
    this.customRef = React.createRef();
  }

  handleSelect(index) {
    const { selectPersona, personas, personaSelected } = this.props;
    const personaData = personas[index];
    if (personaSelected == personaData.name) {
      selectPersona({
        isSelected: false,
        personaSelected: "",
        ...getInitItem(personaData.data)
      });
    } else if (!personaSelected || personaSelected != personaData.name) {
      selectPersona({
        isSelected: true,
        personaSelected: personaData.name,
        ...getInitItem(personaData.data)
      });
    }
  }

  componentDidUpdate() {
    const { personaSelected } = this.props;

    if (personaSelected === "custom") {
      setTimeout(() => {
        this.customRef.current.scrollIntoView({
          behavior: "smooth"
        });
      }, 0);
    }
  }

  render() {
    const { personaSelected, personas } = this.props;
    return (
      <div className="persona-container">
        <div className="persona-content">
          {_.map(personas, (person, i) => {
            if (person.type == "preset" && person["show_on_ui"]) {
              return (
                <PersonaContent
                  key={i}
                  isSelected={person.name === personaSelected}
                  personaInformation={person.data}
                  onSelect={this.handleSelect.bind(this, i)}
                  personaType="preset"
                />
              );
            }
          })}
        </div>
        <AppFooter />
      </div>
    );
  }
}

PersonaContainer.defaultProps = {
  personaSelected: ""
};

PersonaContainer.propTypes = {
  personaSelected: PropTypes.string,
  selectPersona: PropTypes.func,
  intl: intlShape.isRequired,
  personas: PropTypes.array
};

export default injectIntl(PersonaContainer, { withRef: true });
