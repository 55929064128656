import {connect} from "react-redux";
import HistoricIntervention from "./historicIntervention";
import action from "../../../../flux/action";

const mapStateToProps = (state) => {
  const {persona: personaState} = state;
  const {historicIntervention} = personaState;
  return {
    historicIntervention
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editPersona: (options) => {
      dispatch(action.editPersona(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(HistoricIntervention);
