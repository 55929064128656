import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {CommonChart} from "ui-toolkit";
import _ from "lodash";

const chartOptions = {
  legend: {
    display: false
  }
};

class UserGroupChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {userGroup} = this.props;
    const data = {
      labels: [],
      datasets: [
        {
          data: []
        }
      ]
    };
    _.each(userGroup, (item) => {
      const {user, count} = item;

      data.labels.push(_.toLower(user));
      data.datasets[0].data.push(count);
    });

    return (
      <div className="user-group-chart">
        <div className="label">Group by user</div>
        <CommonChart
          type="bar"
          data={data}
          options={chartOptions}
        />
      </div>
    );
  }
}

UserGroupChart.defaultProps = {};

UserGroupChart.propTypes = {
  userGroup: PropTypes.array
};

const mapStateToProps = state => {
  const { admin: adminState } = state;
  const { userGroup } = adminState;

  return {
    userGroup
  };
};

const mapDispatchToProps = (/*dispatch*/) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGroupChart);
