import { createActions } from "redux-actions";
import { actionTypes } from "./actionTypes";
import common from "./common";
import { createRequest, naviTo } from "../../utils";

const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  FORGOT_PASSWORD_VERIFY,
  FORGOT_PASSWORD_VERIFY_SUCCESS,
  FORGOT_PASSWORD_VERIFY_FAILED,
  CONFIRM_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAILED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  SHOW_MESSAGE,
} = actionTypes;

const commonActions = createActions(
  {},
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  FORGOT_PASSWORD_VERIFY,
  FORGOT_PASSWORD_VERIFY_SUCCESS,
  FORGOT_PASSWORD_VERIFY_FAILED,
  CONFIRM_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAILED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  SHOW_MESSAGE,
);

const logout = (options = {}) => {
  return dispatch => {
    dispatch(commonActions.logout());
    const successCallback = res => {
      localStorage.isLogin ? localStorage.removeItem("isLogin") : null;
      dispatch(commonActions.logoutSuccess(res));
      naviTo("/login");
    };
    const failCallback = err => {
      const { message } = err;
      dispatch(commonActions.showMessage({ message: message }));
    };
    const opts = {
      pathName: "logout",
      method: "POST",
      ...options
    };
    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failCallback);
  };
};

const login = (options = {}) => {
  return dispatch => {
    dispatch(commonActions.login());
    const successCallback = res => {
      // const { isRemembered } = options;
      const { isAdmin, practiceMode } = res;
      console.log("practiceMode",practiceMode);
      localStorage.setItem("isAdmin", isAdmin);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("practiceMode", practiceMode);
      window.dataLayer = window.dataLayer || [];
      const login_time = new Date().toUTCString();
      const email_data = options.email.split("@")[0];
      window.dataLayer.push({
        "event" : "login",
        "authenticationMethod" : "email",
        "email_id" : email_data,
        "practice_mode_status" : practiceMode,
        "login_time": login_time
      });
      //localStorage.setItem("practiceMode", practiceMode);
      naviTo("/app/persona");
      dispatch(commonActions.loginSuccess(res));
    };
    const failCallback = err => {
      const { message } = err;
      dispatch(commonActions.loginFailed(message));
      // dispatch(commonActions.showMessage({ message: message }));
    };
    const opts = {
      pathName: "login",
      method: "POST",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failCallback);
  };
};

const createUser = options => {
  return dispatch => {
    dispatch(commonActions.createUser());

    const successCallback = res => {
      dispatch(commonActions.createUserSuccess(res));
    };
    const failCallback = err => {
      const { message } = err;
      dispatch(common.showMessage({ message }));
      dispatch(commonActions.createUserFailed());
    };

    const opts = {
      pathName: "createUser",
      method: "POST",
      ...options
    };

    return createRequest(opts)
      .then(successCallback)
      .catch(failCallback);
  };
};

const verifyEmail = options => {
  return dispatch => {
    dispatch(commonActions.verifyEmail());

    const successCallback = res => {
      dispatch(commonActions.verifyEmailSuccess(res));
      naviTo("/login");
    };
    const failCallback = err => {
      const { message } = err;
      dispatch(commonActions.showMessage({ message }));
      dispatch(commonActions.verifyEmailFailed());
    };

    const opts = {
      pathName: "verifyEmail",
      method: "POST",
      ...options
    };

    return createRequest(opts)
      .then(successCallback)
      .catch(failCallback);
  };
};

const verifyPasswordReset = options => {
  return dispatch => {
    dispatch(commonActions.forgotPasswordVerify());

    const successCallback = res => {
      dispatch(commonActions.forgotPasswordVerifySuccess(res));
    };
    const failCallback = err => {
      const { message } = err;
      dispatch(commonActions.showMessage({ message }));
      dispatch(commonActions.forgotPasswordVerifyFailed());
    };

    const opts = {
      pathName: "verifyPasswordReset",
      method: "POST",
      ...options
    };

    return createRequest(opts)
      .then(successCallback)
      .catch(failCallback);
  };
};

const confirmPasswordReset = options => {
  return dispatch => {
    dispatch(commonActions.confirmResetPassword());

    const successCallback = res => {
      dispatch(commonActions.confirmResetPasswordSuccess(res));
    };
    const failCallback = err => {
      const { message } = err;
      dispatch(commonActions.showMessage({ message }));
      dispatch(commonActions.confirmResetPasswordFailed());
    };

    const opts = {
      pathName: "confirmPasswordReset",
      method: "POST",
      ...options
    };

    return createRequest(opts)
      .then(successCallback)
      .catch(failCallback);
  };
};

const forgotPassword = options => {
  return dispatch => {
    dispatch(commonActions.forgotPassword());

    const successCallback = res => {
      dispatch(commonActions.forgotPasswordSuccess(res));
    };
    const failCallback = err => {
      const { message } = err;
      dispatch(commonActions.showMessage({ message }));
      dispatch(commonActions.forgotPasswordFailed());
    };

    const opts = {
      pathName: "forgetPassword",
      method: "POST",
      ...options
    };

    return createRequest(opts)
      .then(successCallback)
      .catch(failCallback);
  };
};

export default Object.assign({}, commonActions, {
  login,
  logout,
  createUser,
  verifyEmail,
  verifyPasswordReset,
  confirmPasswordReset,
  forgotPassword,
});
