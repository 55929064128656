import React, {Component} from "react";
import {AppBar, Toolbar, /*Typography,*/ Tabs, Tab} from "@material-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import Logout from "./logout";
import logo from "../../assets/takeda_logo.svg";
import {languages} from "../model";
// import {IconSelect} from "ui-toolkit";
import LanguageSelect from "../components/languageSelect";
import actions from "../flux/action";
//import {injectIntl, intlShape} from "react-intl";
//import {changeLanguageAction} from "../flux/action";

class AppHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  static getDerivedStateFromProps(props){
    const {label} = props;

    return {
      selectedTab: label
    };
  }

  handleTabChange(e, value) {
    const {basePath, history, resetNewPersona} = this.props;
    resetNewPersona();
    this.setState({
      selectedTab: value
    });
    if(value == "persona"){
      history.push(`${basePath}/${value}/presetPersona`);
    } else {
      history.push(`${basePath}/${value}`);
    }
  }

  handleLanguageChange(language){
    const {changeLanguage} = this.props;

    changeLanguage(language);
  }

  render() {
    const {routes, language, intl, practiceMode} = this.props;
    const {selectedTab} = this.state;
    const { formatMessage } = intl;

    return (
      <AppBar
        color="secondary"
        classes={{
          root: "app-header"
        }}
      >
        <Toolbar
          classes={{
            root: "toolbar-root"
          }}
        >
          <img className="logo" src={logo}/>
          <Tabs
            classes={{
              root: "app-tabs"
            }}
            indicatorColor="primary"
            value={selectedTab}
            onChange={this.handleTabChange.bind(this)}
          >
            {_.map(routes, (route, i) => {
              const {path, label, disabled} = route;

              return (
                <Tab
                  classes={{
                    selected: "app-tab-selected"
                  }}
                  key={i}
                  label={formatMessage({id: label})}
                  value={path}
                  disabled={disabled}
                />
              );
            })}
          </Tabs>
          {practiceMode &&
            <span className="formatModeTextOn">{formatMessage({id:"practice.mode"})} <span className="font-bold">{formatMessage({id:"header.practise.mode.on"})}</span></span>
          }
          <LanguageSelect
            config={languages}
            value={language}
            onChange={this.handleLanguageChange.bind(this)}
          />
          <Logout practiceMode={practiceMode} />
        </Toolbar>
      </AppBar>
    );
  }
}

AppHeader.defaultProps = {
  routes: [],
  basePath: "",
  history: {},
  label: ""
};

AppHeader.propTypes = {
  routes: PropTypes.array.isRequired,
  classes: PropTypes.object,
  practiceMode: PropTypes.bool,
  history: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  resetNewPersona: PropTypes.func
};

const mapStateToProps = (state) => {
  const {common: commonState} = state;
  const {language} = commonState;

  return {language};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    resetNewPersona: options => {
      dispatch(actions.resetNewPersona(options));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader));
