import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import InfoSection from "../../../../components/infoSection";
import FiledLabel from "../../../../components/fieldLabel";
import { RadioGroup } from "ui-toolkit";
import { injectIntl, intlShape } from "react-intl";
import { responderResponseOptions, localOptionsIntl } from "../../../../model";
import classnames from "classnames";

class ResponderProfile extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      "steroid": React.createRef(),
      "immunomodulator": React.createRef(),
      "anti-tnf": React.createRef(),
      "anti-il": React.createRef(),
      "anti-integrin": React.createRef(),
    };
  }

  handleChange(name, value) {
    const { responderProfile, editPersona } = this.props;

    editPersona({
      responderProfile: Object.assign({}, responderProfile, {
        [name]: value
      })
    });
  }

  componentDidUpdate() {
    this.getStatus();
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = (ref && ref.current && ref.current.getStatus && ref.current.getStatus()) || true;

        return result && (status === "init" ? false : status);
      },
      true
    );
  }

  reset() {
    // _.each(this.inputRefs, ref => {
    //   ref.current.reset();
    // });
  }

  checkIsActiveStep(activeStep){
    return (activeStep == 3) ? true : false;
  }

  render() {
    const { intl, responderProfile } = this.props;
    // const {selectedImmunoInitialResponse, selectedAntiTnfInitialResponse, selectedAntiIntegrinInitialResponse, selectedAntIlInitialResponse} = this.state;
    // const { age, weight, gender, bmi, tobaccoUse } = responderProfile;
    const { formatMessage } = intl;
    // const ageValidation = getRangeValidation([20, 75]);
    // const weightValidation = getRangeValidation([35, 150], "number");
    // const bmiValidation = getRangeValidation([15, 35], "number");
    // const [
    //   // numberInputValidationIntl,
    //   // integerInputValidationIntl,
    //   selectValidationIntl,
    //   ageValidationIntl,
    //   weightValidationIntl,
    //   bmiValidationIntl
    // ] = loadIntl(
    //   [
    //     // numberInputValidation,
    //     // integerInputValidation,
    //     selectValidation,
    //     ageValidation,
    //     weightValidation,
    //     bmiValidation
    //   ],
    //   formatMessage
    // );
    const [responderResponseOptionsIntl] = localOptionsIntl(
      [responderResponseOptions],
      formatMessage
    );

    return (
      <div className={classnames({
        "responder-profile": true
      })}>
        <InfoSection
          title={formatMessage({ id: "persona.sidebar.initial.response.title" })}
        >
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.steroids"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <RadioGroup className="flex-row"
              ref={this.inputRefs.steroid}
              config={responderResponseOptionsIntl}
              value={responderProfile["steroid"]}
              onChange={this.handleChange.bind(this, "steroid")}
            />
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.immunomodulator"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <RadioGroup className="flex-row"
              ref={this.inputRefs.immunomodulator}
              config={responderResponseOptionsIntl}
              value={responderProfile["immunomodulator"]}
              onChange={this.handleChange.bind(this, "immunomodulator")}
            />
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.anti-tnf"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <RadioGroup className="flex-row"
              config={responderResponseOptionsIntl}
              ref={this.inputRefs["anti-tnf"]}
              value={responderProfile["anti-tnf"]}
              onChange={this.handleChange.bind(this, "anti-tnf")}
            />
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.anti-integrin"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <RadioGroup className="flex-row"
              config={responderResponseOptionsIntl}
              ref={this.inputRefs["anti-integrin"]}
              value={responderProfile["anti-integrin"]}
              onChange={this.handleChange.bind(this, "anti-integrin")}
            />
          </FiledLabel>
          <FiledLabel label={formatMessage({id: "persona.sidebar.treatment.anti-il"})} className="width-80" labelClass="text-capitalize font-weight-600 font-size-14 radio-option-captitalize">
            <RadioGroup className="flex-row"
              config={responderResponseOptionsIntl}
              ref={this.inputRefs["anti-il"]}
              value={responderProfile["anti-il"]}
              onChange={this.handleChange.bind(this, "anti-il")}
            />
          </FiledLabel>
        </InfoSection>
      </div>
    );
  }
}

ResponderProfile.defaultProps = {};

ResponderProfile.propTypes = {
  editPersona: PropTypes.func,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  responderProfile: PropTypes.object
};

export default injectIntl(ResponderProfile, {
  withRef: true
});
