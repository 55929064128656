const en = {
  //login
  "login.header.title": "Better Health, Brighter Future",
  "login.title.welcome": "Welcome",
  "login.title.sign.in": "Sign in",
  "login.email.title": "Email",
  "login.email.input": "Enter your email",
  "login.password.title": "Password",
  "login.forget.password": "Forgot password?",
  "login.password.input": "Enter your password",
  "login.remember.me": "Remember me",
  "login.button.sign.in": "SIGN IN",
  "login.do.not.have.account": "Don't have an account?",
  "login.get.start": "Get started",
  "login.parameters.missing":"Please enter your email and password",
  "login.failed":"Invalid email and password combination",
  "login.verify.email":"Please verify your email",
  "login.invalid":"Invalid email",
  //createAccount
  "create.account.title.welcome": "Get started ",
  "create.account.title.sign.up": "Create account",
  "create.account.email.adress": "Enter your name ",
  "create.account.name.title": "Name",
  "create.account.password.input": "Enter your email ",
  "create.account.repassword.title": "Password",
  "create.account.repassword.input": "Enter your password",
  "create.account.button.create.account": "SIGN UP",
  "create.account.have.account": "Already have an account?",
  "create.account.sign.in": "Sign in",
  "create.account.title.Account.created":"Account created",
  "create.account.info.text2":"An email will be sent to that address containing a link to verify your account.",
  "create.account.parameters.missing":"create user parameters missing",
  "create.user.failed":"This email has been registered.",
  "create.user.not.approve":"This email account has not been approved for registration. Please contact US_bodylogical_info@pwc.com for assistance.",
  //forgetPassword
  "forget.password.title.forget": "Forgot password?",
  "forget.password.info.text":
    "Enter your email address below and you will receive a link to rese your password.",
  "forget.password.botton.reset.password": "CONTINUE",
  "forget.password.suddenly.remember": "Suddenly remembered?",
  "forget.password.sign.in.now": "Sign in now!",
  "forget.password.info.text1":
    "An email has been sent with a link to reset your password.",
  "forget.password.parameters.missing":"forget password parameters missing",
  "forget.password.reset.user.not.found":"user not found",
  //resetPassword
  "reset.password.info.text": "Reset password",
  "reset.password.new.password": "New password",
  "reset.passsword.password.input": "Enter your password",
  "reset.password.confirm.input": "Confirm password",
  "reset.password.button.reset": "SUBMIT",
  "reset.password.reset.successful":
    "Your password has been reset successfully. Please use your new password to sign in.",
  "reset.password.button.sign.in": "RETURN TO SIGN IN",
  "password.verify.parameters.missing":"verify password parameters missing",
  "reset.password.failed":"reset password failed",
  "confirm.password.parameters.missing":"confirm password parameters missing",
  "failed.to.updated.password":"failed to updated password",
  //emailVerify
  "email.verify.account.verify": "Email verified",
  "email.verify.info.text":
    "Congratulations, your account has been verified. Please sign in to get started.",
  "email.verify.button.sign.in": "RETURN TO SIGN IN",
  "email.verify.parameters.missing":"verify email parameters missing",
  "email.verify.failed":"email verify failed",
  //logout
  "logout.failed":"The internet connection appears to be offline",
  //practice Mode
  "practice.mode":"Practice Mode"
};

const ja = {
  //login
  "login.header.title": "Better Health, Brighter Future",
  "login.title.welcome": "ようこそ",
  "login.title.sign.in": "Sign in",
  "login.email.title": "メールアドレス",
  "login.email.input": "メールアドレス を入力",
  "login.password.title": "パスワード",
  "login.forget.password": "パスワードを忘れた場合？",
  "login.password.input": "パスワードを入力",
  "login.remember.me": "ログインしたままにする",
  "login.button.sign.in": "ログイン",
  "login.do.not.have.account": "初めての方？",
  "login.get.start": "アカウントを作成",
  "login.parameters.missing":"メールアドレスとパスワードを入力",
  "login.failed":"メールアドレスまたはパスワードが間違っています",
  "login.verify.email":"メールアドレスを確認",
  "login.invalid":"無効なメール",
  //createAccount
  "create.account.title.welcome": "アカウントを作成",
  "create.account.title.sign.up": "新規登録",
  "create.account.email.adress": "氏名を入力",
  "create.account.name.title": "氏名",
  "create.account.password.input": "メールアドレス を入力",
  "create.account.repassword.title": "パスワード",
  "create.account.repassword.input": "パスワードを入力",
  "create.account.button.create.account": "登録",
  "create.account.have.account": "既にアカウントをお持ちの方?",
  "create.account.sign.in": "ログイン",
  "create.account.title.Account.created":"アカウントが作成されました",
  "create.account.info.text2":"アカウントを確認するためのリンクを登録したメールアドレス宛に送信しました。",
  "create.account.parameters.missing":"create user parameters missingJA",
  "create.user.failed":"このメールは登録されました",
  "create.user.not.approve":"このメールアドレスは登録が許可されていません。US bodylogical_info@pwc.comまでご連絡ください。",
  //forgetPassword
  "forget.password.title.forget": "パスワードを忘れた場合?",
  "forget.password.info.text":
    "登録されているメールアドレスを入力してください。パスワード再設定の案内をメールで送信します。",
  "forget.password.botton.reset.password": "次へ",
  "forget.password.suddenly.remember": "Suddenly remembered?",
  "forget.password.sign.in.now": "Sign in now!",
  "forget.password.info.text1":
    "パスワード再設定用のURLをを送信します。",
  "forget.password.parameters.missing":"forget password parameters missingJA",
  "forget.password.reset.user.not.found":"ユーザーが見つかりません",
  //resetPassword
  "reset.password.info.text": "パスワードの再設定",
  "reset.password.new.password": "新しいパスワード",
  "reset.passsword.password.input": "パスワードを入力",
  "reset.password.confirm.input": "新しいパスワードの確認",
  "reset.password.button.reset": "送信",
  "reset.password.reset.successful":
    "パスワードの再設定手続きが完了しました。新しいパスワードでログインしてください。",
  "reset.password.button.sign.in": "ログイン画面に戻る",
  "password.verify.parameters.missing":"verify password parameters missingJA",
  "reset.password.failed":"reset password failedJA",
  "confirm.password.parameters.missing":"confirm password parameters missingJA",
  "failed.to.updated.password":"failed to updated passwordJA",
  //emailVerify
  "email.verify.account.verify": "メールアドレスが確認できました",
  "email.verify.info.text":
    "メールアドレスを確認できました。 サインインして開始してください。",
  "email.verify.button.sign.in": "ログイン画面に戻る",
  "email.verify.parameters.missing":"verify email parameters missingJA",
  "email.verify.failed":"email verify failed JA",
  //logout
  "logout.failed":"インターネット接続はオフラインのようです",
  //practice Mode
  "practice.mode":"練習モード"
};

export default {
  en,
  ja
};
