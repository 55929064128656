import {connect} from "react-redux";
import ModelMain from "./modelMain";

const mapStateToProps = (/*state*/) => {
  return {};
};

const mapDispatchToProps = (/*dispatch*/) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelMain);
