import React, { Component } from "react";
import PropTypes from "prop-types";


class InfoSection extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    const {title, children} = this.props;

    return (
      <div className="info-section">
        <div className="title">{title}</div>
        <div className="content">
          {children}
        </div>
      </div>
    );
  }
}

InfoSection.defaultProps = {};

InfoSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default InfoSection;
