import React, { Component } from "react";
import PropTypes from "prop-types";
import { CommonTable } from "ui-toolkit";
import { connect } from "react-redux";
import action from "../../../flux/action";

const headersConfig = [
  {
    label: "ID",
    name: "id",
    isKey: true
  },
  {
    label: "User",
    name: "user"
  },
  {
    label: "Persona",
    name: "persona"
  },
  {
    label: "Time",
    name: "createAt"
  }
];

class DataList extends Component {
  constructor(props) {
    super(props);
  }

  handleSelect(value) {
    const { loadSimulateDetail } = this.props;
    loadSimulateDetail({id: value});
  }

  render() {
    const { totalSimulations } = this.props;

    return (
      <div className="data-list">
        <CommonTable
          headersConfig={headersConfig}
          data={totalSimulations}
          pagination={true}
          isRowSelectable={true}
          isMultiple={false}
          onRowSelected={this.handleSelect.bind(this)}
        />
      </div>
    );
  }
}

DataList.defaultProps = {};

DataList.propTypes = {
  totalSimulations: PropTypes.array,
  loadSimulateDetail: PropTypes.func,
};

const mapStateToProps = state => {
  const { admin: adminState } = state;
  const { totalSimulations } = adminState;

  return {
    totalSimulations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadSimulateDetail: options => {
      dispatch(action.loadSimulateDetail(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataList);
