import { connect } from "react-redux";
import PersonaSidebar from "./personaSidebar";
import action from "../../../flux/action";

const mapStateToProps = state => {
  const { persona: personaState } = state;
  const {
    isSelected,
    personaSelected
  } = personaState;

  return {
    isSelected,
    personaSelected,
    personaState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editPersona: options => {
      dispatch(action.editPersona(options));
    },
    resetDashboard: options => {
      dispatch(action.resetDashboard(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonaSidebar);
