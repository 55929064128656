const en = {
  "model.container.header": "CROHN'S MODEL",
  "model.content.compartments.title": "Compartments",
  "model.content.biomarkers.title": "Biomarkers/clinical outcomes",
  "model.content.external.title": "External controllable variables",
  "model.content.components.title": "Components",
  "model.content.biological.title": "Biological processes and pharmacology",
  "model.reference.title": "Reference",
  // "model.reference.content2": "2. Daperno M, D'Haens G, Van Assche G, Baert F, Bulois P, Maunoury V, Sostegni R, Rocca R, Pera A, Gevers A, Mary JY, Colombel JF, Rutgeerts P. Development and validation of a new, simplified endoscopic activity score for Crohn's disease: the SES-CD. ",
  // "model.reference.content4": "4. Gastrointest Endosc 2004; 60 (4): 505-512.Geremia A and Arancibia-Cárcamo CV (2017) Innate Lymphoid Cells in Intestinal Inflammation. Front. Immunol. 8:1296. doi: 10.3389/fimmu.2017.01296.",
  // "model.reference.content8": "8. Rutgeerts, P, K Geboes, G Vantrappen, R Kerremans, J L Coenegrachts, and G Coremans. “Natural History of Recurrent Crohn’s Disease at the Ileocolonic Anastomosis after Curative Surgery.” Gut 25, no. 6 (June 1, 1984): 665–72.",
  // "model.reference.content3": "3. Example reference for cytokine half-lives: Oliver et. al., Lymphokine Cytokine Res. 1993 Apr;12(2):115-20.",
  // "model.reference.content5": "5. Keytar McSweeney's Williamsburg, readymade leggings try-hard 90's street art letterpress hoodie occupy Wes Anderson Banksy. Asymmetrical viral letterpress, McSweeney's seitan 3.",
  // "model.reference.content1": "1. A Schippers, M Muschaweck, T Clahsen, S Tautorat, L Grieb, K Tenbrock, N Gaßler & N Wagner. β7-Integrin exacerbates experimental DSS-induced colitis in mice by directing inflammatory monocytes into the colon. Mucosal Immunology volume 9, pages 527–538 (2016)",
  // "model.reference.content6": "6. Rosario M1, Dirks NL2, Gastonguay MR2, Fasanmade AA1, Wyant T1, Parikh A3, Sandborn WJ4, Feagan BG5, Reinisch W6,7, Fox I1. Population pharmacokinetics-pharmacodynamics of vedolizumab in patients with ulcerative colitis and Crohn's disease. Aliment Pharmacol Ther. 2015 Jul;42(2):188-202. ",
  // "model.reference.content7": "7. Rosario M1, Dirks NL2, Milch C3, Parikh A3, Bargfrede M3, Wyant T4, Fedyk E5, Fox I3. A Review of the Clinical Pharmacokinetics, Pharmacodynamics, and Immunogenicity of Vedolizumab. Clin Pharmacokinet. 2017 Nov;56(11):1287-1301.",
  // "model.reference.content9": "9. Blake, M.R., Raker, J.M. and Whelan, K. (2016), Validity and reliability of the Bristol Stool Form Scale in healthy adults and patients with diarrhoea‐predominant irritable bowel syndrome. Aliment Pharmacol Ther, 44: 693-703. https://doi.org/10.1111/apt.13746",
  "model.reference.content2": "2. Okumura R, Takeda K. Roles of intestinal epithelial cells in the maintenance of gut homeostasis. Exp Mol Med 2017; 49: e338. DOI:10.1038/emm.2017.20 ",
  "model.reference.content4": "4. Hibi T, Ogata H. Novel pathophysiological concepts of inflammatory bowel disease. J Gastroenterol 2006; 41: 10-6. DOI:10.1007/s00535-005-1744-3",
  "model.reference.content8": "8. Danese S, Sandborn W J, Colombel J-F et al. Endoscopic, radiologic, and histologic healing with vedolizumab in patients with active Crohn’s disease. Gastroenterology 2019; 157: 1007-18.e7. DOI:10.1053/j.gastro.2019.06.038",
  "model.reference.content3": "3. Baumgart D C, Sandborn W J. Crohn’s disease. Lancet 2012; 380: 1590-605. DOI:10.1016/S0140-6736(12)60026-9",
  "model.reference.content5": "5. Neurath M F. Cytokines in inflammatory bowel disease. Nat Rev Immunol 2014; 14: 329-42. DOI:10.1038/nri3661",
  "model.reference.content1": "1. Abraham C, Cho J H. Inflammatory bowel disease. N Engl J Med 2009; 361: 2066-78. DOI:10.1056/NEJMra0804647",
  "model.reference.content6": "6. Eckmann L, Kagnoff M F, Fierer J. Epithelial cells secrete the chemokine interleukin-8 in response to bacterial entry. Infect Immun 1993; 61: 4569-74. DOI:10.1128/IAI.61.11.4569-4574.1993 ",
  "model.reference.content7": "7. Oliver J C, Bland L A, Oettinger C W et al. Cytokine kinetics in an in vitro whole blood model following an endotoxin challenge. Lymphokine Cytokine Res 1993; 12: 115-20",
  "model.reference.content9": "9. Rosario M, Dirks N L, Milch C et al. A review of the clinical pharmacokinetics, pharmacodynamics, and immunogenicity of vedolizumab. Clin Pharmacokinet 2017; 56: 1287-301. DOI:10.1007/s40262-017-0546-0",
  "model.reference.content10": "10. Daperno M, D’Haens G, Van Assche G et al. Development and validation of a new, simplified endoscopic activity score for Crohn’s disease: the SES-CD. Gastrointest Endosc 2004; 60: 505-12. DOI:10.1016/S0016-5107(04)01878-4",
  "model.reference.content11": "11. Blake, M.R., Raker, J.M. and Whelan, K. (2016), Validity and reliability of the Bristol Stool Form Scale in healthy adults and patients with diarrhoea‐predominant irritable bowel syndrome. Aliment Pharmacol Ther, 44: 693-703. DOI: 10.1111/apt.13746",
  "model.content.compartments.Jejunum":"Jejunum",
  "model.content.compartments.Ascending.colon": "Ascending colon",
  "model.content.compartments.ileum":"Ileum",
  "model.content.compartments.Transverse.colon":"Transverse colon",
  "model.content.compartments.Descendening.colon":"Descending colon",
  "model.content.compartments.Sigmoid.Rectum":"Sigmoid & Rectum",
  "model.content.compartments.Colon":"Colon",
  "model.content.compartments.Ascendent":"Ascending",
  "model.content.compartments.Transverse":"Transverse",
  "model.content.compartments.Descendent":"Descending",
  "model.content.compartments.Sigmoid":"Sigmoid & Rectum",
  "model.content.compartments.Circulation":"Blood circulation around bowels",
  "model.content.biomarker.CRP":"CRP (C-reactive protein)",
  "model.content.biomarker.Fecal":"Fecal calprotectin",
  "model.content.biomarker.Stool":"Stool quality",
  "model.content.biomarker.Endoscopic":"Endoscopic state (SES-CD)",
  "model.content.external.Treatment":"Treatment sequence",
  "model.content.external.drug":"Responsiveness to drug",
  "model.content.external.Development":"Development of immunogenicity",
  "model.content.external.distribution":"Initial disease distribution",
  "model.content.components.cells":"Cells",
  "model.content.components.Intestinal":"Intestinal Epithelial cells",
  "model.content.components.Dendritic":"Dendritic cells",
  "model.content.components.Naive":"Naive T cells",
  "model.content.components.Regulatory":"Regulatory T cells (Treg)",
  "model.content.components.THelper1":"THelper 1 cells",
  "model.content.components.THelper17":"THelper 17 cells",
  "model.content.components.Monocytes":"Monocytes",
  "model.content.components.Macrophages":"Macrophages",
  "model.content.components.Neutrophils":"Neutrophils",
  "model.content.components.Cytokines":"Cytokines",
  "model.content.components.InterferonƔ":"InterferonƔ",
  "model.content.components.Interleukin6":"Interleukin 6",
  "model.content.components.Interleukin8":"Interleukin 8",
  "model.content.components.Interferon10":"Interleukin 10",
  "model.content.components.Interferon12":"Interleukin 12",
  "model.content.components.Interferon22":"Interleukin 22",
  "model.content.components.Interferon23":"Interleukin 23",
  "model.content.components.TGFβ":"TGFβ",
  "model.content.components.TNFɑ":"TNFɑ",
  "model.content.components.Bacterial":"Bacterial antigen",
  "model.content.components.Mucus":"Mucus",
  "model.content.components.MAdCAM":"MAdCAM-1",
  "model.content.components.Other":"Other",
  "model.content.biological.content.title1":"Intestinal homeostasis",
  "model.content.biological.content1":"Healthy mucus layer, tolerogenic response to commensal bacterial antigens in the lumen",
  "model.content.biological.content.title2":"Inflammatory response",
  "model.content.biological.content2":"Infection or injury to the mucus layer drives epithelial cell exposure to antigen",
  "model.content.biological.content3":"Entry of antigen into the lamina propria",
  "model.content.biological.content4":"Initiation of inflammation by activated epithelial cells and dendritic cells",
  "model.content.biological.content5":"Inflammation sustained by adaptive immune response and neutrophil trafficking to the lamina propria",
  "model.content.biological.content.title3":"Mucosal healing upon suppression of chronic inflammation",
  "model.content.biological.content6":"Epithelial cell regeneration",
  "model.content.biological.content7":"Bacterial antigen clearance",
  "model.content.biological.content.title4":"Progressive tissue damage driven by persistent inflammation",
  "model.content.biological.content.title5":"Suppressive effects of vedolizumab, adalimumab, and ustekinumab on inflammation",
  "model.content.biological.content.title6":"Effects of immunomodulators (Azathioprine/6-MP) on inflammation",
  "model.content.biological.content.title7":"Misregulation of liquid exchange in damaged tissue",
  "model.content.frequently.asked.content.title":"Frequently asked questions",
  "model.content.frequently.asked.content.title1":"1. What does “surgery” refer to in a persona? What is the impact of surgery on the input?",
  "model.content.frequently.asked.content.content1":"If “resection surgery” is indicated, the length of the corresponding GI segment is reduced by 25% and any tissue damage is removed. Residual inflammatory activity or disease in other GI segments may cause new lesions to develop (Note: this is an experimental feature)",
  "model.content.frequently.asked.content.title2":"2. Is there differentiation in young-age onset and elder-age onset?",
  "model.content.frequently.asked.content.content2": "There is no explicit difference in disease profile due to age of onset. However, the following is true: patients with relatively recent onset (indicated by a short 'duration of disease') and with significant tissue damage or biomarkers will correspond to more aggressive disease. Because disease duration is constrained by age, for the same level of damage, young patients (short duration of disease) will tend to map to more aggressive disease phenotypes compared to older patients with longer disease duration.",
  "model.content.frequently.asked.content.title3": "3. What dietary factors are included in the model?",
  "model.content.frequently.asked.content.content3": "In the current version of the model, diet does not play a role in determining model outcomes.",
  "model.content.frequently.asked.content.title4":"4. What ethnic differences are included in the model?",
  "model.content.frequently.asked.content.content4":"The model is trained with data from patients with diverse ethnicities.",
  "model.content.frequently.asked.content.title5":"5. What does immunogenicity reference?",
  "model.content.frequently.asked.content.content5":"Immunogenicity refers to the development of HAHA (Human Anti-Human Antibody) to a biologic drug.",
  "model.content.frequently.asked.content.title6": "6. What is the available time scale? What intervals are available?",
  "model.content.frequently.asked.content.content6": "At present the model supports a 2-year time scale (104 weeks), available in 1 week intervals."
};

const ja = {
  "model.container.header": "本モデルの要素及び引用論文",
  "model.content.compartments.title": "部位",
  "model.content.biomarkers.title": "バイオマーカー  /  アウトカム",
  "model.content.external.title": "外部変数",
  "model.content.components.title": "構成要素",
  "model.content.biological.title": "生理学的プロセスと薬効薬理",
  "model.reference.title": "参照",
  // "model.reference.content2": "2. Daperno M, D'Haens G, Van Assche G, Baert F, Bulois P, Maunoury V, Sostegni R, Rocca R, Pera A, Gevers A, Mary JY, Colombel JF, Rutgeerts P. Development and validation of a new, simplified endoscopic activity score for Crohn's disease: the SES-CD. ",
  // "model.reference.content4": "4. Gastrointest Endosc 2004; 60 (4): 505-512.Geremia A and Arancibia-Cárcamo CV (2017) Innate Lymphoid Cells in Intestinal Inflammation. Front. Immunol. 8:1296. doi: 10.3389/fimmu.2017.01296.",
  // "model.reference.content8": "8. Rutgeerts, P, K Geboes, G Vantrappen, R Kerremans, J L Coenegrachts, and G Coremans. “Natural History of Recurrent Crohn’s Disease at the Ileocolonic Anastomosis after Curative Surgery.” Gut 25, no. 6 (June 1, 1984): 665–72.",
  // "model.reference.content3": "3. Example reference for cytokine half-lives: Oliver et. al., Lymphokine Cytokine Res. 1993 Apr;12(2):115-20.",
  // "model.reference.content5": "5. Keytar McSweeney's Williamsburg, readymade leggings try-hard 90's street art letterpress hoodie occupy Wes Anderson Banksy. Asymmetrical viral letterpress, McSweeney's seitan 3.",
  // "model.reference.content1": "1. A Schippers, M Muschaweck, T Clahsen, S Tautorat, L Grieb, K Tenbrock, N Gaßler & N Wagner. β7-Integrin exacerbates experimental DSS-induced colitis in mice by directing inflammatory monocytes into the colon. Mucosal Immunology volume 9, pages 527–538 (2016)",
  // "model.reference.content6": "6. Rosario M1, Dirks NL2, Gastonguay MR2, Fasanmade AA1, Wyant T1, Parikh A3, Sandborn WJ4, Feagan BG5, Reinisch W6,7, Fox I1. Population pharmacokinetics-pharmacodynamics of vedolizumab in patients with ulcerative colitis and Crohn's disease. Aliment Pharmacol Ther. 2015 Jul;42(2):188-202. ",
  // "model.reference.content7": "7. Rosario M1, Dirks NL2, Milch C3, Parikh A3, Bargfrede M3, Wyant T4, Fedyk E5, Fox I3. A Review of the Clinical Pharmacokinetics, Pharmacodynamics, and Immunogenicity of Vedolizumab. Clin Pharmacokinet. 2017 Nov;56(11):1287-1301.",
  // "model.reference.content9": "9. Blake, M.R., Raker, J.M. and Whelan, K. (2016), Validity and reliability of the Bristol Stool Form Scale in healthy adults and patients with diarrhoea‐predominant irritable bowel syndrome. Aliment Pharmacol Ther, 44: 693-703. https://doi.org/10.1111/apt.13746",
  "model.reference.content2": "2. Okumura R, Takeda K. Roles of intestinal epithelial cells in the maintenance of gut homeostasis. Exp Mol Med 2017; 49: e338. DOI:10.1038/emm.2017.20 ",
  "model.reference.content4": "4. Hibi T, Ogata H. Novel pathophysiological concepts of inflammatory bowel disease. J Gastroenterol 2006; 41: 10-6. DOI:10.1007/s00535-005-1744-3",
  "model.reference.content8": "8. Danese S, Sandborn W J, Colombel J-F et al. Endoscopic, radiologic, and histologic healing with vedolizumab in patients with active Crohn’s disease. Gastroenterology 2019; 157: 1007-18.e7. DOI:10.1053/j.gastro.2019.06.038",
  "model.reference.content3": "3. Baumgart D C, Sandborn W J. Crohn’s disease. Lancet 2012; 380: 1590-605. DOI:10.1016/S0140-6736(12)60026-9",
  "model.reference.content5": "5. Neurath M F. Cytokines in inflammatory bowel disease. Nat Rev Immunol 2014; 14: 329-42. DOI:10.1038/nri3661",
  "model.reference.content1": "1. Abraham C, Cho J H. Inflammatory bowel disease. N Engl J Med 2009; 361: 2066-78. DOI:10.1056/NEJMra0804647",
  "model.reference.content6": "6. Eckmann L, Kagnoff M F, Fierer J. Epithelial cells secrete the chemokine interleukin-8 in response to bacterial entry. Infect Immun 1993; 61: 4569-74. DOI:10.1128/IAI.61.11.4569-4574.1993 ",
  "model.reference.content7": "7. Oliver J C, Bland L A, Oettinger C W et al. Cytokine kinetics in an in vitro whole blood model following an endotoxin challenge. Lymphokine Cytokine Res 1993; 12: 115-20",
  "model.reference.content9": "9. Rosario M, Dirks N L, Milch C et al. A review of the clinical pharmacokinetics, pharmacodynamics, and immunogenicity of vedolizumab. Clin Pharmacokinet 2017; 56: 1287-301. DOI:10.1007/s40262-017-0546-0",
  "model.reference.content10": "10. Daperno M, D’Haens G, Van Assche G et al. Development and validation of a new, simplified endoscopic activity score for Crohn’s disease: the SES-CD. Gastrointest Endosc 2004; 60: 505-12. DOI:10.1016/S0016-5107(04)01878-4",
  "model.reference.content11": "11. Blake, M.R., Raker, J.M. and Whelan, K. (2016), Validity and reliability of the Bristol Stool Form Scale in healthy adults and patients with diarrhoea‐predominant irritable bowel syndrome. Aliment Pharmacol Ther, 44: 693-703. DOI: 10.1111/apt.13746",
  "model.content.compartments.Jejunum":"空腸",
  "model.content.compartments.Ascending.colon": "上行結腸",
  "model.content.compartments.Transverse.colon":"横行結腸",
  "model.content.compartments.Descendening.colon":"下行結腸",
  "model.content.compartments.Sigmoid.Rectum":"S状結腸・直腸",
  "model.content.compartments.ileum":"回腸",
  "model.content.compartments.Colon":"結腸",
  "model.content.compartments.Ascendent":"上行",
  "model.content.compartments.Transverse":"横行",
  "model.content.compartments.Descendent":"下行",
  "model.content.compartments.Sigmoid":"S状結腸と直腸",
  "model.content.compartments.Circulation":"対象臓器周辺の血液循環",
  "model.content.biomarker.CRP":"CRP（C反応性タンパク質）",
  "model.content.biomarker.Fecal":"便中カルプロテクチン",
  "model.content.biomarker.Stool":"便質",
  "model.content.biomarker.Endoscopic":"内視鏡状態 (SES-CD)",
  "model.content.external.Treatment":"治療シーケンス",
  "model.content.external.drug":"対薬物反応",
  "model.content.external.Development":"免疫原性の増大",
  "model.content.external.distribution":"初期の炎症箇所",
  "model.content.components.cells":"細胞",
  "model.content.components.Intestinal":"腸上皮細胞",
  "model.content.components.Dendritic":"樹状細胞",
  "model.content.components.Naive":"ナイーブT細胞",
  "model.content.components.Regulatory":"制御性T細胞（Treg）",
  "model.content.components.THelper1":"Th1細胞",
  "model.content.components.THelper17":"Th17細胞",
  "model.content.components.Monocytes":"単球",
  "model.content.components.Macrophages":"マクロファージ",
  "model.content.components.Neutrophils":"好中球",
  "model.content.components.Cytokines":"サイトカイン",
  "model.content.components.InterferonƔ":"インターフェロン Ɣ",
  "model.content.components.Interleukin6":"インターロイキン 6",
  "model.content.components.Interleukin8":"インターロイキン 8",
  "model.content.components.Interferon10":"インターロイキン 10",
  "model.content.components.Interferon12":"インターロイキン 12",
  "model.content.components.Interferon22":"インターロイキン 22",
  "model.content.components.Interferon23":"インターロイキン 23",
  "model.content.components.TGFβ":"TGFβ",
  "model.content.components.TNFɑ":"TNFɑ",
  "model.content.components.Bacterial":"バクテリア抗原",
  "model.content.components.Mucus":"粘液",
  "model.content.components.MAdCAM":"MAdCAM-1",
  "model.content.components.Other":"その他",
  "model.content.biological.content.title1":"腸管の恒常性",
  "model.content.biological.content1":"正常な粘液層、管腔内の共生細菌抗原に対する免疫寛容原性反応",
  "model.content.biological.content.title2":"炎症反応",
  "model.content.biological.content2":"Infection or injury to the mucus layer drives epithelial cell exposure to antigen",
  "model.content.biological.content3":"抗原が粘膜固有層に侵入",
  "model.content.biological.content4":"活性化上皮細胞および樹状細胞により炎症が発現",
  "model.content.biological.content5":"適応免疫反応および粘膜固有層への好中球運搬により炎症が持続",
  "model.content.biological.content.title3":"炎症抑制による粘膜治癒",
  "model.content.biological.content6":"上皮細胞の再生",
  "model.content.biological.content7":"細菌抗原の除去",
  "model.content.biological.content.title4":"持続的な炎症によって引き起こされる進行性の組織傷害",
  "model.content.biological.content.title5":"ベドリズマブ、アダリムマブ、およびウステキヌマブの炎症抑制効果",
  "model.content.biological.content.title6":"免疫調節薬（アザチオプリン/ 6-MP）の炎症に対する効果",
  "model.content.biological.content.title7":"損傷組織における液体移動の調節障害",

  "model.content.frequently.asked.content.title": "よくある質問",
  "model.content.frequently.asked.content.title1": "1. ペルソナの「手術経験」はどのように解釈されるのか？入力項目にどのような影響を与えるか？",
  "model.content.frequently.asked.content.content1": "「手術経験」が選択された場合、該当するGIの長さが25%縮小し、組織損傷がなくなります。残った炎症や他のGIにある疾患が新しい病変を生む可能性があります。（※これは試験的機能です）",
  "model.content.frequently.asked.content.title2": "2. 発症時期（若年と高齢）の違いはあるのか？",
  "model.content.frequently.asked.content.content2": "発症年齢による疾患の発現や遺伝型の明確な違いはありません。ただし、比較的最近発症し、組織損傷あるいはバイオマーカーが重度の場合は病状の進行が速いというのは事実です。罹患期間は年齢に左右されるため、同等の損傷でも若い（＝罹患期間の短い）患者の方がより高齢で罹患期間の長い患者より進行の速い表現型になります。",
  "model.content.frequently.asked.content.title3": "3. 食事の摂取はどのようにモデルに組まれているのか？",
  "model.content.frequently.asked.content.content3": "現在のモデルのバージョンでは食事はシミュレーション結果に反映されません。",
  "model.content.frequently.asked.content.title4": "4. 人種・民族の違いはモデルに組まれているのか？",
  "model.content.frequently.asked.content.content4": "モデルは様々な人種・民族のデータを用いて開発・調整されています。",
  "model.content.frequently.asked.content.title5": "5. 「免疫原性発生タイプ」はどういう意味か？",
  "model.content.frequently.asked.content.content5": "免疫原性発生は生物製剤に対するHAHA (Human Anti-Human Antibody　ヒト抗ヒト抗体) の発現を示しています。",
  "model.content.frequently.asked.content.title6": "6. どの単位で、どのくらいの期間までシミュレーションできるか？",
  "model.content.frequently.asked.content.content6": "現在のモデルは週単位で、２年間（１０４週）までシミュレーションできます。"
};

export default {
  en,
  ja
};
