import React, {Component} from "react";
import PropTypes from "prop-types";
import ForgotPasswordVerify from "./forgotPasswordVerify";
import EmailVerify from "./emailVerify";
import queryString from "query-string";

class Verify extends Component {
  constructor(props) {
    super(props);

    const {location} = this.props;
    const {mode, oobCode} = queryString.parse(location.search);

    this.state = {
      mode,
      oobCode
    };
  }

  render() {
    const {mode, oobCode} = this.state;
    if (mode === "resetPassword") {
      return <ForgotPasswordVerify oobCode={oobCode} />;
    } else {
      return <EmailVerify oobCode={oobCode} />;
    }
  }
}

Verify.defaultProps = {};

Verify.propTypes = {
  location: PropTypes.object
};

export default Verify;
