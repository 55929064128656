import {
  // commonRegimeOptions,
  antiTnfAdalimumabOptions,
  antiTnfInfliximabOptions,
  antiIntegrinVedolizumabOptions,
  antiIlUstekinumabOptions,
  developmemtImmunogenicityOptions
} from "./detailOptions";

import {
  // numberInputValidation,
  // integerInputValidation,
  selectValidation
} from "./validation";

const steroidsPrednisoneConfig = [
  // {
  //   type: "select",
  //   label: "dashboard.sidebar.proposedTreatment.regime",
  //   value: "regime",
  //   options: commonRegimeOptions,
  //   validation: selectValidation,
  //   showDesc: true
  // },
];

const steroidsBudesonideConfig = steroidsPrednisoneConfig;

const steroidsGenericConfig = steroidsPrednisoneConfig;

const immunomodulatorGenericConfig = steroidsPrednisoneConfig;

const antiTnfAdalimumabConfig = [{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.regime",
  value: "regime",
  options: antiTnfAdalimumabOptions,
  validation: selectValidation,
  showDesc: true,
  showCustom: true
},
{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.development.immunogenicity",
  value: "developmentImmunogenicity",
  options: developmemtImmunogenicityOptions,
  validation: selectValidation,
}];

const antiTnfInfliximabConfig = [{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.regime",
  value: "regime",
  options: antiTnfInfliximabOptions,
  validation: selectValidation,
  showDesc: true,
  showCustom: true
},
{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.development.immunogenicity",
  value: "developmentImmunogenicity",
  options: developmemtImmunogenicityOptions,
  validation: selectValidation,
}];

const antiIntegrinVedolizumabConfig = [{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.regime",
  value: "regime",
  options: antiIntegrinVedolizumabOptions,
  validation: selectValidation,
  showDesc: true,
  showCustom: true
},
{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.development.immunogenicity",
  value: "developmentImmunogenicity",
  options: developmemtImmunogenicityOptions,
  validation: selectValidation,
}];

const antiIlUstekinumabConfig = [{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.regime",
  value: "regime",
  options: antiIlUstekinumabOptions,
  validation: selectValidation,
  showDesc: true,
  showCustom: true
},
{
  type: "select",
  label: "dashboard.sidebar.proposedTreatment.development.immunogenicity",
  value: "developmentImmunogenicity",
  options: developmemtImmunogenicityOptions,
  validation: selectValidation,
}];

const drugConfig = {
  "steroids-prednisone": steroidsPrednisoneConfig,
  "steroids-budesonide": steroidsBudesonideConfig,
  "steroids-generic": steroidsGenericConfig,
  "immunomodulator-generic": immunomodulatorGenericConfig,
  "anti-tnf-adalimumab": antiTnfAdalimumabConfig,
  "anti-tnf-infliximab": antiTnfInfliximabConfig,
  "anti-integrin-vedolizumab": antiIntegrinVedolizumabConfig,
  "anti-il-ustekinumab": antiIlUstekinumabConfig
};

export {
  drugConfig
};
