import React from "react";

function biomarkerContent(formatMessage) {
  return (
    <div className="left-title">
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.biomarker.CRP"})}</li>
      </ul>
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.biomarker.Fecal"})}</li>
      </ul>
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.biomarker.Stool"})}</li>
      </ul>
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.biomarker.Endoscopic"})}</li>
      </ul>
    </div>
  );
}

export { biomarkerContent };
