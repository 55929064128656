const historicInterventionOptions = [
  {
    label: "persona.sidebar.treatment.steroids",
    value: "steroids"
  },
  {
    label: "persona.sidebar.treatment.immunomodulator",
    value: "immunomodulator"
  },
  {
    label: "persona.sidebar.treatment.anti-tnf",
    value: "anti-tnf"
  },
  {
    label: "persona.sidebar.treatment.anti-integrin",
    value: "anti-integrin"
  },
  {
    label: "persona.sidebar.treatment.anti-il",
    value: "anti-il"
  }
];

const biologicTreatmentStatusOptions = [
  {
    label: "persona.sidebar.historicIntervention.biologic.naive",
    value: "biologic-naive"
  },
  {
    label: "persona.sidebar.historicIntervention.previous.biologic.treatment",
    value: "previous-biologic-treatment"
  }
];

const steroidsDuractionTreatmentOptions = [
  {
    label: "persona.sidebar.duractionTreatment.1w",
    value: "1w"
  },
  {
    label: "persona.sidebar.duractionTreatment.2w",
    value: "2w"
  },
  {
    label: "persona.sidebar.duractionTreatment.>2w",
    value: ">2w"
  }
];

const steroidsTimeLastDoseOptions = [
  {
    label: "persona.sidebar.time.last.dose.<1w",
    value: "<1"
  },
  {
    label: "persona.sidebar.time.last.dose.1w~2w",
    value: "1w~2w"
  },
  {
    label: "persona.sidebar.time.last.dose.>2w",
    value: ">2w"
  }
];

const steroidsTreatmentOptions = [
  {
    label: "persona.sidebar.treatment.budensonide",
    value: "budensonide"
  },
  {
    label: "persona.sidebar.treatment.prednisone",
    value: "prednisone"
  },
  {
    label: "persona.sidebar.treatment.other",
    value: "other"
  }
];

const immunomodulatorDurationTreatmentOptions = [
  {
    label: "persona.sidebar.duractionTreatment.1w",
    value: "1w"
  },
  {
    label: "persona.sidebar.duractionTreatment.2w",
    value: "2w"
  },
  {
    label: "persona.sidebar.duractionTreatment.4w~8w",
    value: "4w~8w"
  },
  {
    label: "persona.sidebar.duractionTreatment.8w~24w",
    value: "8w~24w"
  },
  {
    label: "persona.sidebar.duractionTreatment.>24w",
    value: ">24w"
  }
];

const immunomodulatorTimeLastDoseOptions = [
  {
    label: "persona.sidebar.time.last.dose.<4w",
    value: "<4w"
  },
  {
    label: "persona.sidebar.time.last.dose.4w~24w",
    value: "4w~24w"
  },
  {
    label: "persona.sidebar.time.last.dose.>24w",
    value: ">24w"
  }
];

const immunomodulatorTreatmentOptions = [
  {
    label: "persona.sidebar.treatment.azathioprine",
    value: "azathioprine"
  },
  {
    label: "persona.sidebar.treatment.mercaptopurine",
    value: "mercaptopurine"
  },
  {
    label: "persona.sidebar.treatment.methotrexate",
    value: "methotrexate"
  },
  {
    label: "persona.sidebar.treatment.other",
    value: "other"
  }
];

const anitTnfDurationTreatmentOptions = immunomodulatorDurationTreatmentOptions;

const anitTnfInitialResponseOptions = [
  {
    label: "persona.sidebar.initial.response.response",
    value: "responde"
  },
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  }
];

const anitTnfTreatmentOptions = [
  {
    label: "persona.sidebar.treatment.adalimumab",
    value: "adalimumab"
  },
  {
    label: "persona.sidebar.treatment.adalimumab+maintnance",
    value: "adalimumab+maintnance"
  },
  {
    label: "persona.sidebar.treatment.infliximab",
    value: "infliximab"
  },
  {
    label: "persona.sidebar.treatment.infliximab+maintenance",
    value: "infliximab+maintenance"
  }
];

const anitTnfTimeTreatmentEndOptions = [
  {
    label: "persona.sidebar.time.treatment.end.<5w",
    value: "<5w"
  },
  {
    label: "persona.sidebar.time.treatment.end.4w~8w",
    value: "4w~8w"
  },
  {
    label: "persona.sidebar.time.treatment.end.8w~1y",
    value: "8w~1y"
  },
  {
    label: "persona.sidebar.time.treatment.end.>1y",
    value: ">1y"
  }
];

const anitTnfClinicalOutcomeOptions = [
  // {
  //   label: "persona.sidebar.clinical.outcome.none",
  //   value: "none"
  // },
  {
    label: "persona.sidebar.clinical.outcome.response",
    value: "response"
  },
  {
    label: "persona.sidebar.clinical.outcome.no-response",
    value: "no-response"
  },
  {
    label: "persona.sidebar.clinical.outcome.loss-of-response",
    value: "loss-of-response"
  }
];

const anitTnfObjectiveOutcomeOptions = [
  {
    label: "persona.sidebar.objective.outcome.none",
    value: "no-response"
  },
  {
    label: "persona.sidebar.objective.outcome.endoscopic-response",
    value: "endoscopic-response"
  },
  {
    label: "persona.sidebar.objective.outcome.endoscopic-remission",
    value: "endoscopic-remission"
  },
  {
    label: "persona.sidebar.objective.outcome.loss-of-response",
    value: "loss-of-response"
  }
];

const antiIntegrinDuractionTreatmentOptions = anitTnfDurationTreatmentOptions;

const antiIntegrinInitialResponseOptions = [
  {
    label: "persona.sidebar.initial.response.response",
    value: "response"
  },
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  },
  {
    label: "persona.sidebar.initial.response.loss-of-response",
    value: "loss-of-response"
  }
];

const antiIntegrinTreatmentOptions = [
  {
    label: "persona.sidebar.treatment.vedolizumab",
    value: "vedolizumab"
  },
  {
    label: "persona.sidebar.treatment.vedolizumab+maintnance",
    value: "vedolizumab+maintnance"
  }
];

const antiIntegrinTimeTreatmentEndOptions = anitTnfTimeTreatmentEndOptions;

const antiIntegrinClinicalOutcomeOptions = anitTnfClinicalOutcomeOptions;

const antiIntegrinObjectiveOutcomeOptions = anitTnfObjectiveOutcomeOptions;

const antiIlDuractionTreatmentOptions = anitTnfDurationTreatmentOptions;

const antiIlInitialResponseOptions = antiIntegrinInitialResponseOptions;

const antiIlTreatmentOptions = [
  {
    label: "persona.sidebar.treatment.ustekinumab",
    value: "ustekinumab"
  },
  {
    label: "persona.sidebar.treatment.ustekinumab+maintnance",
    value: "ustekinumab+maintnance"
  }
];

const antiIlTimeTreatmentEndOptions = anitTnfTimeTreatmentEndOptions;

const antiIlClinicalOutcomeOptions = anitTnfClinicalOutcomeOptions;

const antiIlObjectiveOutcomeOptions = anitTnfObjectiveOutcomeOptions;

const responderResponseOptions = [
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  },
  {
    label: "persona.sidebar.initial.response.responder",
    value: "responder"
  },{
    label: "persona.sidebar.initial.response.fast-responder",
    value: "fast-responder"
  },
];

const steroidResponderOptions = [
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  },
  {
    label: "persona.sidebar.initial.response.responder",
    value: "responder"
  },{
    label: "persona.sidebar.initial.response.fast-responder",
    value: "fast-responder"
  },
];
const immunoResponderOptions = [
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  },
  {
    label: "persona.sidebar.initial.response.responder",
    value: "responder"
  },{
    label: "persona.sidebar.initial.response.fast-responder",
    value: "fast-responder"
  },
];
const antiilResponderOptions = [
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  },
  {
    label: "persona.sidebar.initial.response.responder",
    value: "responder"
  },{
    label: "persona.sidebar.initial.response.fast-responder",
    value: "fast-responder"
  },
];
const antiIntegrinResponderOptions = [
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  },
  {
    label: "persona.sidebar.initial.response.responder",
    value: "responder"
  },{
    label: "persona.sidebar.initial.response.fast-responder",
    value: "fast-responder"
  },
];
const antiTnfResponderOptions = [
  {
    label: "persona.sidebar.initial.response.non-responder",
    value: "non-responder"
  },
  {
    label: "persona.sidebar.initial.response.responder",
    value: "responder"
  },{
    label: "persona.sidebar.initial.response.fast-responder",
    value: "fast-responder"
  },
];

export {
  historicInterventionOptions,
  steroidsDuractionTreatmentOptions,
  steroidsTimeLastDoseOptions,
  steroidsTreatmentOptions,
  immunomodulatorDurationTreatmentOptions,
  immunomodulatorTimeLastDoseOptions,
  immunomodulatorTreatmentOptions,
  anitTnfDurationTreatmentOptions,
  anitTnfInitialResponseOptions,
  anitTnfTreatmentOptions,
  anitTnfTimeTreatmentEndOptions,
  anitTnfClinicalOutcomeOptions,
  anitTnfObjectiveOutcomeOptions,
  antiIntegrinDuractionTreatmentOptions,
  antiIntegrinInitialResponseOptions,
  antiIntegrinTreatmentOptions,
  antiIntegrinTimeTreatmentEndOptions,
  antiIntegrinClinicalOutcomeOptions,
  antiIntegrinObjectiveOutcomeOptions,
  antiIlDuractionTreatmentOptions,
  antiIlInitialResponseOptions,
  antiIlTreatmentOptions,
  antiIlTimeTreatmentEndOptions,
  antiIlClinicalOutcomeOptions,
  antiIlObjectiveOutcomeOptions,
  steroidResponderOptions,
  immunoResponderOptions,
  antiTnfResponderOptions,
  antiilResponderOptions,
  antiIntegrinResponderOptions,
  responderResponseOptions,
  biologicTreatmentStatusOptions
};
