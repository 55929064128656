import React, { Component } from "react";
import PropTypes from "prop-types";
import prettier from "prettier/standalone";
import parser from "prettier/parser-babylon";
import hljs from "highlight.js/lib/highlight";
import json from "highlight.js/lib/languages/json";
hljs.registerLanguage("json", json);

import "highlight.js/styles/atom-one-dark-reasonable.css";

class CodeBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.code = React.createRef();
  }

  static getDerivedStateFromProps(props) {
    const { code } = props;
    return {
      codes: prettier.format(code, {
        parser: "json",
        plugins: [parser]
      })
    };
  }

  componentDidMount() {
    hljs.highlightBlock(this.code.current);
  }

  componentDidUpdate() {
    hljs.highlightBlock(this.code.current);
  }

  render() {
    const { codes } = this.state;
    return (
      <div className="code-box">
        <pre>
          <code ref={this.code}>{codes}</code>
        </pre>
      </div>
    );
  }
}

CodeBox.defaultProps = {
  code: ""
};

CodeBox.propTypes = {
  code: PropTypes.string
};

export default CodeBox;
