const en = {
  "dashboard.sidebar.control.panel.title": "Patient’s Condition",
  "dashboard.sidebar.action.label": "SIMULATE",
  "dashboard.sidebar.reset.label": "RESET",
  "dashboard.sidebar.proposedTreatment.title": "Proposed program",
  "dashboard.sidebar.proposedTreatment.chooseTreatment": "Choose treatment",
  "dashboard.sidebar.proposedTreatment.pharmacology.duractionTreatment": "TREATMENT DURATION (weeks)",
  "dashboard.sidebar.proposedTreatment.pharmacology.duractionTreatment.lower": "Treatment Duration",
  "dashboard.sidebar.proposedTreatment.pharmacology.doseFrequency": "DOSE AND FREQUENCY",
  // "dashboard.sidebar.proposedTreatment.pharmacology.frequency": "FREQUENCY",
  "dashboard.sidebar.proposedTreatment.pharmacology.immunogenicity": "ASSUMED RATE OF IMMUNOGENICITY DEVELOPMENT TYPE",
  "dashboard.sidebar.proposedTreatment.pharmacology.increase": "RATE OF INCREASE",
  "dashboard.sidebar.proposedTreatment.pharmacology.assume": "ASSUME RESPONSIVE",
  "dashboard.sidebar.proposedTreatment.addTreatment": "+   ADD TREATMENT",
  "dashboard.sidebar.proposedTreatment.deleteTreatment": "REMOVE",
  "Treatment 1": "Treatment 1",
  "Treatment 2": "Treatment 2",
  "Treatment 3": "Treatment 3",
  "Treatment 4": "Treatment 4",
  "Treatment 5": "Treatment 5",
  "dashboard.sidebar.proposedTreatment.regime": "REGIME",
  "dashboard.sidebar.proposedTreatment.responsive": "RESPONSIVE",
  "dashboard.sidebar.proposedTreatment.development.immunogenicity": "DEVELOPMENT OF IMMUNOGENICITY",
  "dashboard.sidebar.proposedTreatment.frequency": "FREQUENCY (weeks)",
  "dashboard.sidebar.proposedTreatment.dose": "DOSE ({unit})",
  "persona.sidebar.proposedTreatment.regime": "REGIME",
  "persona.sidebar.proposedTreatment.regime.lower": "Regime",
  "persona.sidebar.treatment.steroids.prednisone": "Steroids - prednisone",
  "persona.sidebar.treatment.steroids.budesonide": "Steroids - budesonide",
  "persona.sidebar.treatment.steroids.generic": "Steroids",
  "persona.sidebar.treatment.immunomodulator.generic": "Immunomodulator",
  "persona.sidebar.treatment.anti-tnf.adalimumab": "Anti-TNF - Adalimumab",
  "persona.sidebar.treatment.anti-tnf.infliximab": "Anti-TNF - Infliximab",
  "persona.sidebar.treatment.anti-integrin.vedolizumab": "Anti-Integrin - Vedolizumab",
  "persona.sidebar.treatment.biologic.vedolizumab": "Biologic - Vedolizumab",
  "persona.sidebar.treatment.biologic.other": "Biologic - Other",
  "persona.sidebar.treatment.anti-il.ustekinumab": "Anti-IL12/23 - Ustekinumab",
  "dashboard.options.anti-tnf.adalimumab.induction": "Induction: 160 + 80 mg wk 0 and wk 2",
  "dashboard.options.anti-tnf.adalimumab.maintenance": "Maintenance: 40 mg every 2 wks",
  "dashboard.options.anti-tnf.adalimumab.induction.maintenance": "Induction: 160 + 80 mg wk 0 and wk 2 and Maintenance: 40 mg every 2 wks",
  "dashboard.options.anti-tnf.infliximab.induction": "Induction: 5 mg/kg wk 0, 2, 6",
  "dashboard.options.anti-tnf.infliximab.maintenance": "Maintenance: 5 mg/kg every 8 wks",
  "dashboard.options.anti-tnf.infliximab.induction.maintenance": "Induction: 5 mg/kg wk 0, 2, 6 and Maintenance: 5 mg/kg every 8 wks",
  "dashboard.options.anti-integrin.vedolizumab.induction": "Induction: 300 mg wk 0, 2, 6",
  "dashboard.options.anti-integrin.vedolizumab.maintenance": "Maintenance: 300 mg every 8 wks",
  "dashboard.options.anti-integrin.vedolizumab.induction.maintenance": "Induction: 300 mg wk 0, 2, 6 and Maintenance: 300 mg every 8 wks",
  "dashboard.options.anti-il.ustekinumab.induction": "Induction: as indicated for weight wk 0",
  "dashboard.options.anti-il.ustekinumab.maintenance": "Maintenance: 90 mg every 8 wks",
  "dashboard.options.anti-il.ustekinumab.induction.maintenance": "Induction: as indicated for weight wk 0 and Maintenance: 90 mg every 8 wks",
  "dashboard.sidebar.select.at.least.drug": "please select at least one drug",
  "dashboard.sidebar.content.tooltip.detail1": "When ‘yes’ is selected, the drug MoA is simulated.",
  "dashboard.sidebar.content.tooltip.detail2": "When ‘no’ is selected, the drug MoA is deactivated in the simulation as to produce no effect.",
  "dashboard.sidebar.content.tooltip.detail3": "For ‘non-responsive’ selections the anti-drug antibodies will still develop over time according to the immunogenicity profile indicated by the user.",
  "dashboard.sidebar.content.tooltip.detail4": "This could potentially lead to non-biologically-naive patients failing to respond to second-line therapies because of prior unsuccessful treatment with another biological."
};

const ja = {
  "dashboard.sidebar.control.panel.title": "治療シミュレーション",
  "dashboard.sidebar.action.label": "開始",
  "dashboard.sidebar.reset.label": "リセット",
  "dashboard.sidebar.proposedTreatment.title": "治療プログラムの提案",
  "dashboard.sidebar.proposedTreatment.chooseTreatment": "治療法を選択してください",
  "dashboard.sidebar.proposedTreatment.pharmacology.duractionTreatment": "治療期間(週)",
  "dashboard.sidebar.proposedTreatment.pharmacology.doseFrequency": "線量と頻度",
  // "dashboard.sidebar.proposedTreatment.pharmacology.frequency": "FREQUENCY",
  "dashboard.sidebar.proposedTreatment.pharmacology.immunogenicity": "免疫原性発生タイプの推定率",
  "dashboard.sidebar.proposedTreatment.pharmacology.increase": "RATE OF INCREASE",
  "dashboard.sidebar.proposedTreatment.pharmacology.assume": "ASSUME RESPONSIVE",
  "dashboard.sidebar.proposedTreatment.addTreatment": "+   治療を追加",
  "dashboard.sidebar.proposedTreatment.deleteTreatment": "削除",
  "Treatment 1": "治療 1",
  "Treatment 2": "治療 2",
  "Treatment 3": "治療 3",
  "Treatment 4": "治療 4",
  "Treatment 5": "治療 5",
  "dashboard.sidebar.proposedTreatment.regime": "投薬計画",
  "dashboard.sidebar.proposedTreatment.responsive": "反応",
  "dashboard.sidebar.proposedTreatment.development.immunogenicity": "免疫原性発生タイプの推定率",
  "dashboard.sidebar.proposedTreatment.frequency": "頻度(週)",
  "dashboard.sidebar.proposedTreatment.dose": "用量 ({unit})",
  "persona.sidebar.proposedTreatment.regime": "投薬計画",
  "persona.sidebar.proposedTreatment.regime.lower": "投薬計画",
  "persona.sidebar.treatment.steroids.prednisone": "ステロイドープレドニゾン",
  "persona.sidebar.treatment.steroids.budesonide": "ステロイドーブデソニド",
  "persona.sidebar.treatment.steroids.generic": "ステロイドージェネリック",
  "persona.sidebar.treatment.immunomodulator.generic": "免疫調節薬",
  "persona.sidebar.treatment.anti-tnf.adalimumab": "抗TNF-α製剤 アダリムマブ",
  "persona.sidebar.treatment.anti-tnf.infliximab": "抗TNF-α製剤 インフリキシマブ",
  "persona.sidebar.treatment.anti-integrin.vedolizumab": "抗インテグリン製剤ーベドリズマブ",
  "persona.sidebar.treatment.biologic.vedolizumab": "生物学的製剤 ベドリズマブ",
  "persona.sidebar.treatment.biologic.other": "生物学的製剤-その他",
  "persona.sidebar.treatment.anti-il.ustekinumab": "抗IL 12/23製剤 ウステキヌマブ",
  "dashboard.options.anti-tnf.adalimumab.induction": "導入療法：160mg + 0週と2週に80mg",
  "dashboard.options.anti-tnf.adalimumab.maintenance": "維持療法：隔週40mg",
  "dashboard.options.anti-tnf.adalimumab.induction.maintenance": "導入療法：160mg + 0週と2週に80mg 維持療法：隔週40mg",
  "dashboard.options.anti-tnf.infliximab.induction": "導入療法：0週、2週、6週に5mg/kg",
  "dashboard.options.anti-tnf.infliximab.maintenance": "維持療法：8週間毎に5mg/kg",
  "dashboard.options.anti-tnf.infliximab.induction.maintenance": "導入療法：0週、2週、6週に5mg/kg 維持療法：8週間毎に5mg/kg",
  "dashboard.options.anti-integrin.vedolizumab.induction": "導入療法：0週、2週、6週に300mg",
  "dashboard.options.anti-integrin.vedolizumab.maintenance": "維持療法：8週間毎に300mg",
  "dashboard.options.anti-integrin.vedolizumab.induction.maintenance": "導入療法：0週、2週、6週に300mg 維持療法：8週間毎に300mg",
  "dashboard.options.anti-il.ustekinumab.induction": "導入療法：0週に体重により規定された量",
  "dashboard.options.anti-il.ustekinumab.maintenance": "維持療法：8週間毎に90mg",
  "dashboard.options.anti-il.ustekinumab.induction.maintenance": "導入療法：0週に体重により規定された量 維持療法：8週間毎に90mg",
  "dashboard.sidebar.select.at.least.drug": "少なくとも1つの薬を選択してください",

  "dashboard.sidebar.content.tooltip.detail1": "「はい」を選択すると治療のMoAをシミュレーションします。",
  "dashboard.sidebar.content.tooltip.detail2": "「いいえ」を選択すると治療のMoAが無効となり、シミュレーションに反映されません。",
  "dashboard.sidebar.content.tooltip.detail3": "「治療反応性なし」を選択しても、ユーザーが指定した免疫原性発生タイプに従い、時間の経過と共に抗薬物抗体は生成されます。",
  "dashboard.sidebar.content.tooltip.detail4": "これは抗TNFなどの生物学的製剤を使用したことのある患者が、抗薬物抗体の交差性により第二次療法で効果が得られない可能性を生みます。"
};

export default {
  en,
  ja
};
