import { connect } from "react-redux";
import AdminMain from "./adminMain";
import action from "../../../flux/action";

const mapStateToProps = state => {
  const { admin: adminState } = state;
  const {
    selectedUser,
    dateRange,
    users,
    totalSimulations,
    simulateResult,
    simulateDetail
  } = adminState;
  return {
    selectedUser,
    dateRange,
    users,
    totalSimulations,
    simulateResult,
    simulateDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editAdmin: (options) => {
      dispatch(action.editAdmin(options));
    },
    loadUser: () => {
      dispatch(action.loadUser());
    },
    loadSimulateResult: (options) => {
      dispatch(action.loadSimulateResult(options));
    },
    loadSimulateDetail: (options) => {
      dispatch(action.loadSimulateDetail(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMain);
