import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import InfoSection from "../../../../components/infoSection";
import Biomarkers from "./biomarkers";
import Location from "./location";
import DiseaseHistory from "./diseaseHistory";
import {getWrappedInstance} from "../../../../utils";
import classnames from "classnames";
import {getDefaultIntestine} from "../../../../model";

class DiseaseState extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      biomarkers: React.createRef(),
      location: React.createRef(),
      diseaseHistory: React.createRef()
    };
    this.diseaseState = _.cloneDeep(props.diseaseState);
  }

  componentDidUpdate() {
    this.checkIfCompleted();
  }

  checkIfCompleted(){
    const {diseaseState, createPersona} = this.props;
    const {location} = diseaseState;
    const {ileum, colon} = location;
    const {diseaseHistory} = diseaseState;
    this.diseaseState = _.cloneDeep(diseaseState);
    this.diseaseState.completed = diseaseState.completed;

    if(_.isEmpty(diseaseHistory.diseaseDuraction)){
      this.diseaseState.completed = false;
    }

    if(!_.isEmpty(diseaseHistory.diseaseDuraction) && !ileum.checked && !colon.checked){
      this.diseaseState.completed = false;
    }

    if(ileum.checked){
      if( !_.isEmpty(diseaseHistory.diseaseDuraction) && !_.isEmpty(ileum.diseaseArea) && !_.isEmpty(ileum.ulcerArea) && !_.isEmpty(ileum.ulcerType)){
        this.diseaseState.completed = true;
      } else {
        this.diseaseState.completed = false;
      }
    }

    if(colon.checked){
      if( !_.isEmpty(diseaseHistory.diseaseDuraction) && !_.isEmpty(colon.diseaseArea) && !_.isEmpty(colon.ulcerArea) && !_.isEmpty(colon.ulcerType)){
        this.diseaseState.completed = true;
      } else {
        this.diseaseState.completed = false;
      }
    }

    if(this.diseaseState.completed == diseaseState.completed){
      return;
    }
    createPersona({
      diseaseState: Object.assign({}, diseaseState, this.diseaseState)
    });
  }

  handleChange(name, value) {
    const { createPersona, diseaseState } = this.props;
    this.diseaseState = _.cloneDeep(diseaseState);
    this.diseaseState[name] = value;
    if(name == "location"){
      if(!this.diseaseState[name]["ileum"].checked){
        this.diseaseState[name]["ileum"] = getDefaultIntestine();
      }
      if(!this.diseaseState[name]["colon"].checked){
        this.diseaseState[name]["colon"] = getDefaultIntestine();
      }
    }
    createPersona({
      diseaseState: Object.assign({}, diseaseState, this.diseaseState)
    });
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();

        return result && status;
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, (ref) => {
      getWrappedInstance(ref.current).reset();
    });
  }

  checkIsActiveStep(activeStep){
    return (activeStep == 1) ? true : false;
  }

  render() {
    const { diseaseState, intl } = this.props;
    const { formatMessage } = intl;
    const { biomarkers, location, diseaseHistory } = diseaseState;

    return (
      <div className={classnames({
        "disease-state": true
      })}>
        <InfoSection title={formatMessage({id: "persona.sidebar.diseaseState.title"})}>
          <div className="desc">{formatMessage({id: "persona.sidebar.diseaseState.desc"})}</div>
          <Biomarkers
            ref={this.inputRefs.biomarkers}
            data={biomarkers}
            onChange={this.handleChange.bind(this, "biomarkers")}
          />
          <div className="desc">{formatMessage({id: "persona.sidebar.diseaseState.diseaseHistory"})}</div>
          <DiseaseHistory
            ref={this.inputRefs.diseaseHistory}
            data={diseaseHistory}
            onChange={this.handleChange.bind(this, "diseaseHistory")}
          />
          <Location
            ref={this.inputRefs.location}
            data={location}
            onChange={this.handleChange.bind(this, "location")}
          />
        </InfoSection>
      </div>
    );
  }
}

DiseaseState.defaultProps = {};

DiseaseState.propTypes = {
  diseaseState: PropTypes.object,
  createPersona: PropTypes.func,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number
};

export default injectIntl(DiseaseState, {
  withRef: true
});
