import React from "react";
import picCompartments from "../../../../../assets/picture-compartments1.png";

function compartmentsContent(formatMessage) {
  return (
    <div className="content-container">
      <div className="left-title">
        {/* <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.compartments.Jejunum" })}
          </li>
        </ul> */}
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.compartments.ileum" })}
          </li>
        </ul>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.compartments.Colon" })}
            <ul>
              <li className="second-level">
                {formatMessage({ id: "model.content.compartments.Ascendent" })}
              </li>
              <li className="second-level">
                {formatMessage({ id: "model.content.compartments.Transverse" })}
              </li>
              <li className="second-level">
                {formatMessage({ id: "model.content.compartments.Descendent" })}
              </li>
              <li className="second-level">
                {formatMessage({ id: "model.content.compartments.Sigmoid" })}
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li className="first-level">
            {formatMessage({ id: "model.content.compartments.Circulation" })}
          </li>
        </ul>
      </div>
      <div className="right-image">
        <div className="left-dom">
          <div className="one">
            <span>
              {formatMessage({ id: "model.content.compartments.Jejunum" })}
            </span>
          </div>
          <div className="two">
            <span>
              {formatMessage({
                id: "model.content.compartments.Ascending.colon"
              })}
            </span>
          </div>
          <div className="three">
            <span>
              {formatMessage({ id: "model.content.compartments.ileum" })}
            </span>
          </div>
        </div>
        <img className="pic" src={picCompartments} />
        <div className="right-dom">
          <div className="four">
            <span>
              {formatMessage({
                id: "model.content.compartments.Transverse.colon"
              })}
            </span>
          </div>
          <div className="five">
            <span>
              {formatMessage({
                id: "model.content.compartments.Descendening.colon"
              })}
            </span>
          </div>
          <div className="six">
            <span>
              {formatMessage({
                id: "model.content.compartments.Sigmoid.Rectum"
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export { compartmentsContent };
