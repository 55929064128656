import React, { Component } from "react";
import { CommonInput } from "ui-toolkit";
import LanguageSelect from "../../components/languageSelect";
import { Button /*, TextField*/, Checkbox } from "@material-ui/core";
import { naviTo, checkLogin } from "../../utils";
import PropTypes from "prop-types";
import { languages } from "../../model";
import Takeda from "../../../assets/takeda_logo.svg";
import { injectIntl, intlShape } from "react-intl";
import "../../../styles/login/login.scss";
import login from "../../../assets/loginImage.png";
import { validateEmail } from "../../utils";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const CustomSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#ee1100",
    },
    "&$checked + $track": {
      backgroundColor: "#ee1100",
    },
  },
  checked: {},
  track: {},
})(Switch);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isRemembered: true,
      emailError: "",
      practiceMode: false
    };
  }
  componentDidMount() {
    checkLogin();
  }

  handlePracticeModeChange() {
    this.setState({
      practiceMode: !this.state.practiceMode
    });
  }

  handleLogin() {
    const { login } = this.props;
    const { email, password, isRemembered, practiceMode } = this.state;
    if(!validateEmail(email)){
      // showEmailValidation("Invalid email address");
      this.setState({
        emailError: "login.invalid"
      });
      return;
    } else {
      this.setState({
        emailError: ""
      });
    }
    login({
      email: email,
      password: password,
      isRemembered: isRemembered,
      practiceMode: practiceMode
    });
  }

  handleNavi(pathname, e) {
    e.preventDefault();
    naviTo(pathname);
  }

  handleAccountChange(name, value, e) {
    const {loginState} = this.props;
    Object.assign( loginState, {
      errMessage: ""
    });
    this.setState({
      [name]: e.target.value,
      emailError: ""
    });
  }

  handlePasswordChange(name, value, e) {
    const {loginState} = this.props;
    Object.assign( loginState, {
      errMessage: ""
    });
    this.setState({
      [name]: e.target.value,
      emailError: ""
    });
  }

  creatUser(e) {
    this.handleNavi("createAccount", e);
  }

  forgetPassword(e) {
    this.handleNavi("forgotPassword", e);
  }
  handleRemember() {
    const { isRemembered } = this.state;
    this.setState({ isRemembered: !isRemembered });
  }

  verify() {
    return (
      <div className="verify">
        <span onClick={this.creatUser.bind(this)}>create account</span> ||{" "}
        <span onClick={this.forgetPassword.bind(this)}>forgetpassword</span>
      </div>
    );
  }

  handleLanguageChange(language) {
    const { changeLanguage } = this.props;

    changeLanguage(language);
  }

  getErrorMessage(){
    const {intl, loginState} = this.props;
    const {emailError} = this.state;
    const { formatMessage } = intl;
    if(loginState.errMessage || emailError){
      return (
        <div className="login-error">
          {formatMessage({id: loginState.errMessage || emailError})}
        </div>
      );
    }
  }

  render() {
    const { intl, language } = this.props;
    const { formatMessage } = intl;
    const { email, password, isRemembered, practiceMode } = this.state;
    return (
      <div className="login-container">
        <div className="left-card">
          <div className="header-title">
            <img src={Takeda} />
            {/* <span>{formatMessage({ id: "login.header.title" })}</span> */}
            <LanguageSelect
              config={languages}
              value={language}
              onChange={this.handleLanguageChange.bind(this)}
            />
          </div>
          <div className="login">
            <div className="title">
              <div className="line1">
                {formatMessage({ id: "login.title.welcome" })}
              </div>
              {/* <div className="line2">
                {formatMessage({ id: "login.title.sign.in" })}
              </div> */}
            </div>
            <div className="email-content">
              <div className="email">
                {formatMessage({ id: "login.email.title" })}
              </div>
              {/* <TextField
                classes={{ root: "email-root-input" }}
                value={email}
                placeholder="Enter your Email"
                variant="outlined"
                onChange={this.handleAccountChange.bind(this, "email")}
              /> */}
              <CommonInput
                classes={{ root: "email-root-input" }}
                placeholder={formatMessage({ id: "login.email.input" })}
                type="Email"
                //isDisplay={false}
                value={email}
                onChange={this.handleAccountChange.bind(this, "email")}
              />
            </div>
            <div className="email-content">
              <div className="password">
                <span className="password-text">
                  {formatMessage({ id: "login.password.title" })}
                </span>
                <span
                  className="reset-password"
                  onClick={this.forgetPassword.bind(this)}
                >
                  {formatMessage({ id: "login.forget.password" })}
                </span>
              </div>
              <CommonInput
                classes={{ root: "email-root-input" }}
                type="password"
                placeholder={formatMessage({ id: "login.password.input" })}
                //isDisplay={false}
                value={password}
                onChange={this.handlePasswordChange.bind(this, "password")}
              />
              {/* <TextField
                classes={{ root: "email-root-input" }}
                value={password}
                placeholder="Enter your Password"
                variant="outlined"
                onChange={this.handlePasswordChange.bind(this, "password")}
              /> */}
            </div>
            {this.getErrorMessage()}
            {/* {this.verify()} */}
            <div className="login-action clearFix">
              <div className="remember-me">
                <Checkbox
                  color="primary"
                  checked={isRemembered}
                  onChange={this.handleRemember.bind(this, isRemembered)}
                />{" "}
                {formatMessage({ id: "login.remember.me" })}
              </div>
              <div className="login-btn">
                <Button
                  classes={{
                    root: "login-button"
                  }}
                  variant="contained"
                  color="primary"
                  onClick={this.handleLogin.bind(this)}
                >
                  {formatMessage({ id: "login.button.sign.in" })}
                </Button>
              </div>
            </div>
            <div className="creat-account">
              <div className="practice_mode_switch">
                <CustomSwitch
                  checked={practiceMode}
                  onChange={this.handlePracticeModeChange.bind(this)}
                  name="checkedA"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  label={formatMessage({ id: "practice.mode" })}
                />
                <span className="practice_mode_text">{formatMessage({ id: "practice.mode" })}</span>
              </div>
              <div>
                {formatMessage({ id: "login.do.not.have.account" })}
                <span onClick={this.creatUser.bind(this)} className="get-started">
                  {formatMessage({ id: "login.get.start" })}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="right-card">
          <img className="login" src={login} />
        </div>
      </div>
    );
  }
}

Login.defaultProps = {};

Login.propTypes = {
  login: PropTypes.func,
  intl: intlShape.isRequired,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  showEmailValidation: PropTypes.func,
  loginState: PropTypes.object
};

export default injectIntl(Login, {withRef: true});
