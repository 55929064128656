import _ from "lodash";

class BaseModel {
  constructor(options) {
    Object.defineProperty(this, "_baseline", {
      value: Object.assign({}, options),
      writable: false,
      configurable: false
    });
    Object.assign(this, { _isBaseline: true }, options);
  }

  reset() {
    Object.assign(this, this._baseline, {
      _isBaseline: true
    });
  }

  update(options) {
    const optionForUpdate = {};
    const descriptors = Object.getOwnPropertyDescriptors(this);

    _.each(options, (value, name) => {
      if (!descriptors[name] || descriptors[name].writable) {
        optionForUpdate[name] = value;
      }
    });

    Object.assign(this, optionForUpdate, {
      _isBaseline: false
    });
  }

  isBaseline() {
    return this._isBaseline;
  }
}

const getModelItem = (defaultOptions, options = {}, customizer = () => {}) => {
  const baseline = _.mergeWith({}, defaultOptions, options, customizer);

  Object.assign({}, defaultOptions, options);

  return new BaseModel(baseline);
};

const getInitItem = (defaultOptions, options = {}) => {
  if (_.isArray(defaultOptions)) {
    return _.defaultsDeep([], options, defaultOptions);
  } else {
    return _.defaultsDeep({}, options, defaultOptions);
  }
};

export { BaseModel, getModelItem, getInitItem };
