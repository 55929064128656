import React from "react";
import cell from "../../../../../assets/cell.png";
import other from "../../../../../assets/other.png";
import cytokines from "../../../../../assets/cytokines.png";

function componentsContent(formatMessage) {
  return (
    <div className="components-container">
      <div className="component-container">
        <img className="left-image" src={cell} />
        <div className="right-container">
          <div className="component-title">{formatMessage({id:"model.content.components.cells"})}</div>
          <div className="component-content">
            <ul className="components-ul">
              <li>{formatMessage({id:"model.content.components.Intestinal"})}</li>
              <li>{formatMessage({id:"model.content.components.Dendritic"})}</li>
              <li>{formatMessage({id:"model.content.components.Naive"})}</li>
            </ul>
            <ul className="components-ul">
              <li>{formatMessage({id:"model.content.components.Regulatory"})}</li>
              <li>{formatMessage({id:"model.content.components.THelper1"})}</li>
              <li>{formatMessage({id:"model.content.components.THelper17"})}</li>
            </ul>
            <ul className="components-ul">
              <li>{formatMessage({id:"model.content.components.Monocytes"})}</li>
              <li>{formatMessage({id:"model.content.components.Macrophages"})}</li>
              <li>{formatMessage({id:"model.content.components.Neutrophils"})}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dashed-divider" />
      <div className="component-container">
        <img className="left-image" src={cytokines} />
        <div className="right-container">
          <div className="component-title">{formatMessage({id:"model.content.components.Cytokines"})}</div>
          <div className="component-content">
            <ul className="components-ul">
              <li>{formatMessage({id:"model.content.components.InterferonƔ"})}</li>
              <li>{formatMessage({id:"model.content.components.Interleukin6"})}</li>
              <li>{formatMessage({id:"model.content.components.Interleukin8"})}</li>
            </ul>
            <ul className="components-ul">
              <li>{formatMessage({id:"model.content.components.Interferon10"})}</li>
              <li>{formatMessage({id:"model.content.components.Interferon12"})}</li>
              <li>{formatMessage({id:"model.content.components.Interferon22"})}</li>
            </ul>
            <ul className="components-ul">
              <li>{formatMessage({id:"model.content.components.Interferon23"})}</li>
              <li>{formatMessage({id:"model.content.components.TGFβ"})}</li>
              <li>{formatMessage({id:"model.content.components.TNFɑ"})}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dashed-divider" />
      <div className="component-container">
        <img className="left-image" src={other} />
        <div className="right-container">
          <div className="component-title">{formatMessage({id:"model.content.components.Other"})}</div>
          <div className="component-content">
            <ul>
              <li>{formatMessage({id:"model.content.components.Bacterial"})}</li>
              <li>{formatMessage({id:"model.content.components.Mucus"})}</li>
              <li>{formatMessage({id:"model.content.components.MAdCAM"})}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export { componentsContent };
