import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
// import { SwitchExpansionPanel } from "ui-toolkit";
import Intestine from "./intestine";
import { intestineOptions /*defaultLocation*/ } from "../../../../../model";
import { getWrappedInstance } from "../../../../../utils";

class Location extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }

  handleChange(name, value) {
    const { data, onChange } = this.props;
    onChange(
      Object.assign({}, data, {
        [name]: value
      }), name
    );
  }

  getStatus() {
    const { data } = this.props;
    const { isOpen } = data;

    if (isOpen) {
      return _.reduce(
        this.inputRefs,
        (result, ref) => {
          const status = getWrappedInstance(ref.current).getStatus();

          return result && status;
        },
        true
      );
    } else {
      return true;
    }
  }

  reset() {
    _.each(this.inputRefs, ref => {
      getWrappedInstance(ref.current).reset();
    });
  }

  render() {
    const { data, intl } = this.props;
    const { formatMessage } = intl;
    // const { isOpen } = data;

    return (
      <div className="location">
        {/* <SwitchExpansionPanel
          color="primary"
          label={formatMessage({ id: "persona.sidebar.diseaseState.location" })}
          checked={isOpen}
          onToggle={this.handleChange.bind(this, "isOpen")}
        > */}
        <div className="desc">{formatMessage({id: "persona.sidebar.diseaseState.location"})}</div>
        {_.map(intestineOptions, (item, index) => {
          const { label, value } = item;
          const intestine = data[value];
          this.inputRefs[value] = React.createRef();

          return (
            <Intestine
              ref={this.inputRefs[value]}
              key={`intestine-${index}`}
              label={formatMessage({ id: label })}
              data={intestine}
              onChange={this.handleChange.bind(this, value)}
            />
          );
        })}
        {/* </SwitchExpansionPanel> */}
      </div>
    );
  }
}

Location.defaultProps = {
  data: {},
  onChange: () => {}
};

Location.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(Location, {
  withRef: true
});
