import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
//import "../../../styles/components/persona-content.scss";
// import user1 from "../../../assets/user1.png";
import { injectIntl, intlShape } from "react-intl";
import {Grid, List, ListSubheader, ListItem, ListItemText} from "@material-ui/core";
import user1Avatar from "../../../assets/avatars/user1.png";
import user2Avatar from "../../../assets/avatars/user2.png";
import user3Avatar from "../../../assets/avatars/user3.png";
import user4Avatar from "../../../assets/avatars/user4.png";
import customUserAvatar from "../../../assets/avatars/custom_user.png";
import diseaseDurationIcon from "../../../assets/icons/ico_disease_state.png";
//import symptomsIcon from "../../../assets/icons/ico_symptoms.png";
import treatmentHistoryIcon from "../../../assets/icons/ico_treatment_history.png";
import responderProfileIcon from "../../../assets/icons/ico_responder_profile.png";
import {
  responderResponseOptions,
  // ageRanges,
  // ageRangesPreset,
  // weights,
  // diseaseDuration,
  // diseaseDurationPreset,
  genderOptions,
  surfaceAreaWithUlcers,
  surfaceAreaWithUlcersPreset,
  surfaceInvolved,
  ulcerTypeOptions,
  yesNoOptions,
  yesNoNaOptions,
  antiTnfAdalimumabOptions
} from "../../model";
import _ from "lodash";
// import colonIcon from "../../../assets/icons/ico_colon.png";
class PersonaContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      persona: "",
      className: "container-persona",
      className1: "non-selected"
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { isSelected } = nextProps;
    if (isSelected) {
      return {
        className: "container-persona active",
        className1: "left-selected"
      };
    } else {
      return {
        className: "container-persona",
        className1: "non-selected"
      };
    }
  }

  selectProfile() {
    const { onSelect } = this.props;

    onSelect();
  }

  getAvatarIcon(){
    const {personaType, personaInformation} = this.props;
    const {archetypeId} = personaInformation;
    if(personaType == "preset"){
      if(archetypeId == 1){
        return(
          <img src={user1Avatar} className="image"/>
        );
      } else if(archetypeId == 2){
        return(
          <img src={user2Avatar} className="image"/>
        );
      } else if(archetypeId == 3){
        return(
          <img src={user3Avatar} className="image"/>
        );
      } else if(archetypeId == 4){
        return(
          <img src={user4Avatar} className="image"/>
        );
      }
    } else {
      return(
        <img src={customUserAvatar} className="image"/>
      );
    }
  }

  getHostoricInterventionContent(){
    const { personaInformation, intl } = this.props;
    const {formatMessage} = intl;
    const {historicIntervention} = personaInformation;
    const biologyTreatmentStatus = historicIntervention["biologyTreatmentStatus"] ? historicIntervention["biologyTreatmentStatus"] : "biologic-naive";
    if(biologyTreatmentStatus === "biologic-naive"){
      return(
        <Fragment>
          {!historicIntervention["immunomodulator"]["checked"] ? null : (<ListItem className="list-item">
            <ListItemText className="label subheader">{formatMessage({id: "persona.sidebar.treatment.immunomodulator.noex"})}</ListItemText>
          </ListItem>)}
          {this.getContentBySection("immunomodulator")}
          {!historicIntervention["steroids"]["checked"] ? null : (<ListItem className="list-item">
            <ListItemText className="label subheader">{formatMessage({id: "persona.sidebar.treatment.steroids"})}</ListItemText>
          </ListItem>)}
          {this.getContentBySection("steroids")}
        </Fragment>
      );
    } else if(biologyTreatmentStatus == "previous-biologic-treatment"){
      return(
        <Fragment>
          {!historicIntervention["anti-tnf"]["checked"] ? null : (<ListItem className="list-item">
            <ListItemText className="label subheader">{formatMessage({id: "persona.sidebar.treatment.anti-tnf.noex"})}</ListItemText>
          </ListItem>)}
          {this.getContentBySection("anti-tnf", true)}
          {!historicIntervention["anti-integrin"]["checked"] ? null : (<ListItem className="list-item">
            <ListItemText className="label subheader">{formatMessage({id: "persona.sidebar.treatment.anti-integrin.noex"})}</ListItemText>
          </ListItem>)}
          {this.getContentBySection("anti-integrin", true)}
          {!historicIntervention["anti-il"]["checked"] ? null : (<ListItem className="list-item">
            <ListItemText className="label subheader">{formatMessage({id: "persona.sidebar.treatment.anti-il.noex"})}</ListItemText>
          </ListItem>)}
          {this.getContentBySection("anti-il", true)}
        </Fragment>
      );
    }
  }

  getContentBySection(type, showRegime){
    const { personaInformation, intl } = this.props;
    const {formatMessage} = intl;
    const {historicIntervention} = personaInformation;
    if(!historicIntervention[type]["checked"]) return;
    return(
      <Fragment>
        <ListItem className="list-item">
          <ListItemText className="label">{formatMessage({id: "persona.sidebar.historicIntervention.initialResponse.lower"})}</ListItemText>
          <ListItemText className="value">{historicIntervention[type]["initialResponse"] ? this.getLabelByValueFromOptions(yesNoOptions, historicIntervention[type]["initialResponse"])  : ""}</ListItemText>
        </ListItem>
        <ListItem className="list-item">
          <ListItemText className="label">{formatMessage({id: "persona.sidebar.historicIntervention.lossOfResponse.lower"})}</ListItemText>
          <ListItemText className="value">{(historicIntervention[type]["lossOfResponse"] || historicIntervention[type]["initialResponse"] == "no") ? this.getLabelByValueFromOptions(yesNoNaOptions, historicIntervention[type]["lossOfResponse"]) : ""}</ListItemText>
        </ListItem>
        <ListItem className="list-item">
          <ListItemText className="label">{formatMessage({id: "persona.sidebar.historicIntervention.duractionTreatment.lower"})}</ListItemText>
          <ListItemText className="value">{historicIntervention[type]["duractionTreatment"] ? historicIntervention[type]["duractionTreatment"] : 0}</ListItemText>
        </ListItem>
        {!showRegime ? "" : (
          <ListItem className="list-item">
            <ListItemText className="label">{formatMessage({id: "persona.sidebar.proposedTreatment.regime.lower"})}</ListItemText>
            <ListItemText className="value">{this.getLabelByValueFromOptions(antiTnfAdalimumabOptions, historicIntervention[type]["regime"])}</ListItemText>
          </ListItem>
        )}
      </Fragment>
    );
  }

  getDiseaseStateLocationContent(location, locationType, formatMessage){
    const { personaType } = this.props;
    if(!location[locationType]["checked"]) return;
    return (
      <Fragment>
        <ListItem className="list-item">
          <ListItemText className="label subheader">{formatMessage({id: locationType == "ileum" ? "persona.sidebar.intestine.ileum": "persona.sidebar.intestine.colon"})}</ListItemText>
        </ListItem>
        <ListItem className="list-item">
          <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerArea.lower"})}</ListItemText>
          <ListItemText className="value">{this.getLabelByValueFromOptions(personaType == "preset" ? surfaceAreaWithUlcersPreset : surfaceAreaWithUlcers, location[locationType]["ulcerArea"])}</ListItemText>
        </ListItem>
        <ListItem className="list-item">
          <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.diseaseArea.lower"})}</ListItemText>
          <ListItemText className="value">{this.getLabelByValueFromOptions(surfaceInvolved, location[locationType]["diseaseArea"])}</ListItemText>
        </ListItem>
        <ListItem className="list-item">
          <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerType.lower"})}</ListItemText>
          <ListItemText className="value">{this.getLabelByValueFromOptions(ulcerTypeOptions, location[locationType]["ulcerType"])}</ListItemText>
        </ListItem>
      </Fragment>
    );
  }

  getLabelByValueFromOptions(options, value){
    const { intl } = this.props;
    const {formatMessage} = intl;
    let obj = _.find(options, item=>{
      return item.value === value;
    });
    return obj ? formatMessage({ id: obj.label}) : "";
  }

  render() {
    const { className } = this.state;
    const { personaInformation, intl, personaType } = this.props;
    const {formatMessage, locale} = intl;
    const {name, desc, patientInfo, diseaseState, responderProfile, transText} = personaInformation; //diseaseState, historicIntervention
    const {diseaseHistory, location} = diseaseState;
    // const {colon, ileum} = location;
    return (
      <div onClick={this.selectProfile.bind(this)} className={className}>
        <Grid container wrap="nowrap" className="persona-card">
          <Grid item xs={3} className="personal-information">
            <div className="person-image">
              {this.getAvatarIcon()}
            </div>
            <ul className="info">
              <div className="userName">{locale == "ja" && personaType == "preset" ? transText["name"] : name}</div>
              <li>
                <div className="list-label">{formatMessage({id: "persona.sidebar.patientInfo.gender"})}: </div>
                <div className="list-item text-capitalize">{this.getLabelByValueFromOptions(genderOptions, patientInfo.gender)}</div>
              </li>
              <li>
                <div className="list-label">{formatMessage({id: "persona.sidebar.patientInfo.age"})}: </div>
                <div className="list-item">
                  {patientInfo.age} {formatMessage({id: "persona.options.years.old"})}
                </div>
              </li>
              <li><div className="list">{locale == "ja" && personaType == "preset" ? transText["desc"] : desc}</div></li>
            </ul>
          </Grid>
          <Grid item container zeroMinWidth xs={9} wrap="nowrap" className="treatment-info">
            <Grid item xs={6} className="ptb-10">
              <List subheader={<li />} className="left-list ptb-10">
                <ListSubheader className="list-header" disableSticky={true}>
                  <img className="icon" src={diseaseDurationIcon} />
                  <div className="label">{formatMessage({id: "persona.sidebar.diseaseState.title"})}</div>
                </ListSubheader>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.main.container.disease.state.diseaseDuration"})}</ListItemText>
                  <ListItemText className="value">{diseaseHistory["diseaseDuraction"]}</ListItemText>
                </ListItem>
                {this.getDiseaseStateLocationContent(location, "ileum", formatMessage)}
                {this.getDiseaseStateLocationContent(location, "colon", formatMessage)}
                {/* <ListItem className="list-item">
                  <ListItemText className="label subheader">{formatMessage({id: "persona.sidebar.intestine.ileum"})}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerArea.lower"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(surfaceAreaWithUlcers, ileum["ulcerArea"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.diseaseArea.lower"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(surfaceInvolved, ileum["diseaseArea"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerType.lower"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(ulcerTypeOptions, ileum["ulcerType"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label subheader">{formatMessage({id: "persona.sidebar.intestine.colon"})}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerArea.lower"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(surfaceAreaWithUlcers, colon["ulcerArea"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.diseaseArea.lower"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(surfaceInvolved, colon["diseaseArea"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerType.lower"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(ulcerTypeOptions, colon["ulcerType"])}</ListItemText>
                </ListItem> */}
                {/* DiarrheaSymptoms is removed based on ryoko change request */}
                {/* <ListSubheader className="list-header pt-10" disableSticky={true}>
                  <img className="icon" src={symptomsIcon} />
                  <div className="label">{formatMessage({id: "dashboard.content.symptoms.title"})}</div>
                </ListSubheader>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "Diarrhea"})}</ListItemText>
                  <ListItemText className="value">{formatMessage({id: "persona.options.yesno.no"})}</ListItemText>
                </ListItem> */}
              </List>
            </Grid>
            <Grid item xs={6} className="ptb-10">
              <List subheader={<li />} className="left-list ptb-10">
                <ListSubheader className="list-header" disableSticky={true}>
                  <img className="icon" src={treatmentHistoryIcon} />
                  <div className="label">{formatMessage({id: "persona.sidebar.historicIntervention.treatment.history"})}</div>
                </ListSubheader>
                {this.getHostoricInterventionContent()}
                <ListSubheader className="list-header pt-10" disableSticky={true}>
                  <img className="icon" src={responderProfileIcon} />
                  <div className="label">{formatMessage({id: "persona.sidebar.initial.response.title"})}</div>
                </ListSubheader>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.treatment.steroids"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(responderResponseOptions, responderProfile["steroid"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.treatment.immunomodulator.noex"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(responderResponseOptions, responderProfile["immunomodulator"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.treatment.anti-tnf.noex"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(responderResponseOptions, responderProfile["anti-tnf"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.treatment.anti-integrin.noex"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(responderResponseOptions, responderProfile["anti-integrin"])}</ListItemText>
                </ListItem>
                <ListItem className="list-item">
                  <ListItemText className="label">{formatMessage({id: "persona.sidebar.treatment.anti-il.noex"})}</ListItemText>
                  <ListItemText className="value">{this.getLabelByValueFromOptions(responderResponseOptions, responderProfile["anti-il"])}</ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        {/* <div className={className1} />
        <div className="personal-information">
          <img className="image" src={personaInformation.personUrl} />
          <div className="information">
            <div className="userName">{personaInformation.userName}</div>
            <div className="detail">{personaInformation.detail}</div>
          </div>
        </div>
        <div className="content">
          <div className="patient-information">
            <div className="titles">{personaInformation.titles.title1}</div>
            <div className="details">
              <div>{personaInformation.details.details1[0]}</div>
            </div>
          </div>
          <div className="disease-state">
            <div className="titles">{personaInformation.titles.title2}</div>
            <div className="details">
              <div>{personaInformation.details.details2[0]}</div>
            </div>
          </div>
          <div className="intervention">
            <div className="titles">{personaInformation.titles.title3}</div>
            <div className="details">
              <div>{personaInformation.details.details3[0]}</div>
            </div>
          </div>
          <div className="proposed-program">
            <div className="titles">{personaInformation.titles.title4}</div>
            <div className="details">
              <div>{personaInformation.details.details4[0]}</div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

PersonaContent.defaultProps = {
  userName: "Christina Little",
};

PersonaContent.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  personaInformation: PropTypes.object,
  intl: intlShape.isRequired,
  personaType: PropTypes.string
};

export default injectIntl(PersonaContent, { withRef: true });
