import React, { Component } from "react";
// import PropTypes from "prop-types";
import {injectIntl, intlShape} from "react-intl";
import {connect} from "react-redux";
import actions from "../flux/action";

class AppFooter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl} = this.props;
    const {formatMessage} = intl;
    return (
      <div className="app-footer">
        <span style={{fontWeight: "bold"}}>{formatMessage({id: "footer.version"})} 2.1</span>
        <span style={{paddingLeft: "10px", paddingRight: "10px"}}>|</span>
        <span> August 2021</span>
      </div>
    );
  }
}

AppFooter.defaultProps = {};

AppFooter.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    persona: personaState} = state;
  return {
    personaState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => {
      dispatch(actions.clearAction());
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(AppFooter));

