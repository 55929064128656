import React, { Component } from "react";
import { CommonChart } from "ui-toolkit";
import PropTypes from "prop-types";
//import moment from "moment";
import _ from "lodash";
import { getChartOptions, getChartConfig, getDetailOptions } from "./config";
import { injectIntl, intlShape } from "react-intl";
// import { formatChartConfig } from "../../../../model/dashboard";
class ChartContainer extends Component {
  constructor(props) {
    super(props);
  }

  getContent() {
    const { data, intl, min, max, treatmentMonth, name, labels } = this.props;
    const { formatMessage } = intl;
    const gridLinesColor = [];
    if (_.isEmpty(data)) {
      return (
        <span className="no-data">
          {formatMessage({ id: "dashboard.content.nodata" })}
        </span>
      );
    } else {
      const { no_treatment } = data;
      const configs = Object.assign(data, { labels });
      const config = getChartConfig(configs);
      _.each(no_treatment, (item, i) => {
        config.labels.push(
          // moment()
          //   .add(i, "M")
          //   .format("MMM")
          i
        );
        // if (treatmentMonth == i) {
        //   gridLinesColor.push("#EE1100");
        // } else {
        //   gridLinesColor.push("transparent");
        // }
      });
      const chartDetailOptions = getDetailOptions(formatMessage);
      const options = Object.assign(chartDetailOptions[name], {
        gridLinesColor,
        min,
        max,
        treatmentMonth,
      });
      const chartOptions = getChartOptions(options);
      return (
        <div>
          <CommonChart type="line" data={config} options={chartOptions} />
        </div>
      );
    }

    // return _.isEmpty(data)? (
    //   <span className="no-data">No data to display</span>
    // ) : (
    //   <CommonChart type="line" data={biomarkerConfig} options={chartOptions} />
    // );
  }

  render() {
    const { title, intl } = this.props;
    const { formatMessage } = intl;

    if (title) {
      return (
        <div className="chart-container">
          <div className="header">{formatMessage({ id: title })}</div>
          <div className="chart-content">{this.getContent()}</div>
        </div>
      );
    } else {
      return (
        <div className="chart-container">
          <div className="chart-content">{this.getContent()}</div>
        </div>
      );
    }
  }
}

ChartContainer.defaultProps = {
  min: 40,
  max: 100
};

ChartContainer.propTypes = {
  labels: PropTypes.array,
  name: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  treatmentMonth: PropTypes.number,
  intl: intlShape.isRequired
};

export default injectIntl(ChartContainer, { withRef: true });
