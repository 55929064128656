const en = {
  "dashboard.content.clinical.assessment.title": "Clinical",
  "dashboard.content.nodata": "No data to display",
  "dashboard.content.clinical.assessment.tooltip1": "SES-CD score",
  "dashboard.content.mucosal.healing.tooltip2": "Endoscopic Activity",
  "dashboard.content.tooltip.title": "What is a SES-CD score?",
  "dashboard.content.tooltip2.title": "What is stool quality?",
  "dashboard.content.tooltip.detail1": "The Simple Endoscopic Score for Crohn's Disease (SES-CD) assesses the size of ulcers, the ulcerated surface, ",
  "dashboard.content.tooltip.detail2": "affected surface and the presence of narrowing.",
  "dashboard.content.tooltip.quality1": "Score values: <=4 indicate Remission",
  "dashboard.content.tooltip.quality2": "5 - 8 indicate Mild endoscopic activity",
  "dashboard.content.tooltip.quality3": "9 - 14 indicate Moderate endoscopic activity",
  "dashboard.content.tooltip.quality4": ">15 indicate Severe endoscopic activity",
  "dashboard.content.tooltip.bottom": "A SES-CD score decrease of 50% has been proposed as prognostically significant.",
  "dashboard.content.tooltip2.quality1": "Type 1–2 indicate constipation",
  "dashboard.content.tooltip2.quality2": "Type 3–4 are ideal stools as they are easier to pass",
  "dashboard.content.tooltip2.quality6": "Type 5–7 may indicate diarrhoea and urgency",
  "dashboard.content.tooltip2.bottom": "A decrease of 50% of the SES-CD score has recently been proposed as prognostically significant.",
  "dashboard.content.tooltip2.detail1": "The Bristol Stool Chart defines seven types of stool (feces):",
  "dashboard.content.tooltip2.detail2": "The Bristol Stool Chart or Bristol Stool Scale is a medical aid designed to classify faeces into seven groups.",
  "dashboard.content.tooltip2.detail3": "The Bristol Stool Chart shows seven categories of stool.",
  "dashboard.content.tooltip2.detail4": "Every person will have different bowel habits, but the important thing is that your stools are soft and easy to pass – like types 3 and 4 below.",
  "dashboard.content.tooltip3.title": "What is stool weight?",
  "dashboard.content.tooltip3.detail1": "Stool weight denotes the weight of all, combined bowel movements.",
  "dashboard.content.tooltip3.detail2": "This model assumes a “normal” weight of 100g per day.",
  "dashboard.content.clinical.assessment.tooltip2": "Stool quality",
  "dashboard.content.clinical.assessment.tooltip3": "Stool weight",
  "dashboard.content.clinical.assessment.tooltip3.unit": "(gram per day)",
  "dashboard.content.clinical.assessment.tooltip4": "Stool frequency",
  "dashboard.content.clinical.assessment.tooltip4.unit": "(per day)",
  "dashboard.content.plasma.concentration.title": "Plasma drug concentration (Serum)",
  "dashboard.content.plasma.concentration.label.baseline": "No immunogenic response",
  "dashboard.content.plasma.concentration.label.treatment": "With immunogenic response",
  "dashboard.content.biomarker.title": "BIOMARKERS",
  "dashboard.content.biomarker.calprotectin.chart.header": "Fecal Calprotectin (µg/g)",
  "dashboard.content.biomarker.crp.chart.header": "CRP (mg/dL)",
  "dashboard.content.chart.label.baseline": "No Treatment",
  "dashboard.content.chart.label.treatment": "Treatment",
  "dashboard.content.physiology.title": "PHYSIOLOGY",
  "dashboard.content.physiology.serumTNF.chart.header": "% change in TNF (Serum)",
  "dashboard.content.physiology.Th1.chart.header": "% change in Th1 (Tissue)",
  "dashboard.content.physiology.serumIL12.chart.header": "% change in IL12/23 (Serum)",
  "dashboard.content.physiology.Th17.chart.header": "% change in Th17 (Tissue)",
  "dashboard.content.physiology.serumIL22.chart.header": "% change in IL22 (Serum)",
  "dashboard.content.physiology.Macrophages.chart.header": "% change in Macrophages",
  "dashboard.content.symptoms.title": "SYMPTOMS",
  "dashboard.content.symptoms.stool.label": "Stool symptoms",
  "dashboard.content.mucosal.healing.title": "Mucosal healing",
  "dashboard.content.mucosal.healing.Jejunum.title": "Jejunum",
  "dashboard.content.mucosal.healing.Ileum.title": "Ileum",
  "dashboard.content.mucosal.healing.Colon.title": "Colon",
  "dashboard.content.mucosal.healing.overall.title": "Overall",
  "dashboard.content.mucosal.healing.present": "Present",
  "dashboard.content.mucosal.healing.future": "weeks",
  "dashboard.content.mucosal.healing.condition.title": "Mucosal layer condition",
  "dashboard.content.mucosal.healing.leisions.title": "Surface involved by disease",
  "dashboard.content.mucosal.healing.size.title": "Average size",
  "dashboard.content.mucosal.healing.surface.title": "Surface involved by ulceration",
  "dashboard.content.mucosal.healing.type.title": "Ulcers",
  "dashboard.chart.x.label.weeks": "Weeks",
  "dashboard.chart.y.label.changes": "% change",
  "INTACT": "INTACT",
  "DAMAGED": "DAMAGED",
  "Severe": "Severe",
  "Remission": "Remission",
  "Mild": "Mild",
  "Moderate": "Moderate",
  "COMPROMISED": "COMPROMISED",
  "Diarrhea": "Diarrhea",
  "Normal": "Normal",
  "Constipation": "Constipation",
  "SEVERELY COMPROMISED": "SEVERELY COMPROMISED",
  "None": "None",
  "Aphthous": "Aphthous",
  "Large": "Large",
  "Very large": "Very large",
  "dashboard.content.popover.quality1": "Type 1–2 indicate constipation",
  "dashboard.content.popover.quality2": "Type 3–4 are ideal stools as they are easier to pass",
  "dashboard.content.popover.quality3": "Type 5–7 may indicate diarrhoea and urgency",
  "dashboard.content.popover.quality4": "Type 1–2 indicate constipation",
  "dashboard.content.popover.quality5": "Type 3–4 are ideal stools as they are easier to pass",
  "dashboard.content.popover.quality6": "Type 5–7 may indicate diarrhoea and urgency",
  "dashboard.content.popover.quality7": "Type 5–7 may indicate diarrhoea and urgency",
  "dashboard.contents.mucosal.healing.note1": "A note on imaging",
  "dashboard.contents.mucosal.healing.note2": "Images supervised by Dr. Iwakiri.",
  "dashboard.contents.mucosal.healing.note3": "Computer graphic images for illustration purposes."
};

const ja = {
  "dashboard.content.clinical.assessment.title": "疾患活動性",
  "dashboard.content.nodata": "表示するデータがありません",
  "dashboard.content.clinical.assessment.tooltip1": "SES-CDスコア",
  "dashboard.content.mucosal.healing.tooltip2": "内視鏡所見",
  "dashboard.content.tooltip2.title": "便質とは？",
  "dashboard.content.tooltip.title": "SES-CDスコアとは?",
  "dashboard.content.tooltip.detail1": "Simple Endoscopic Score for Crohn's Disease (SES-CD)とは潰瘍の大きさ、潰瘍面積、",
  "dashboard.content.tooltip.detail2": "病変面積、そして狭窄の有無を評価項目とする内視鏡スコアを指し、以下の通り評価される:",
  "dashboard.content.tooltip.quality1": "<=4 は寛解を示します",
  "dashboard.content.tooltip.quality2": "5 - 8 は軽度の活動性を示します",
  "dashboard.content.tooltip.quality3": "9 - 14 は、中程度の活動性を示します",
  "dashboard.content.tooltip.quality4": ">15 は、重度の活動性を示します",
  "dashboard.content.tooltip.bottom": "最近ではSES-CDスコアの50％減は有意であるとされています。",
  "dashboard.content.tooltip2.quality1": "タイプ1〜2＝硬便",
  "dashboard.content.tooltip2.quality2": "タイプ3〜4＝普通便",
  "dashboard.content.tooltip2.quality6": "タイプ5〜7＝水様便",
  "dashboard.content.tooltip2.bottom": "A decrease of 50% of the SES-CD score has recently been proposed as prognostically significant.",
  "dashboard.content.tooltip2.detail1": "ブリストルスツールチャートでは、便の質を７段階に定義します。",
  "dashboard.content.tooltip2.detail2": "ブリストルスツールチャートまたはブリストルスツールスケールは、便を7つのグループに分類するために設計された医療補助具です。",
  "dashboard.content.tooltip2.detail3": "ブリストルスツールチャートには、7つのカテゴリのスツールが表示されます。",
  "dashboard.content.tooltip2.detail4": "人によって腸の習慣は異なりますが、重要なことは、下のタイプ3および4のように、便が柔らかくて通過しやすいことです。",
  "dashboard.content.tooltip3.title": "排便量 とは？",
  "dashboard.content.tooltip3.detail1": "便量は腸全体の活動を表します。",
  "dashboard.content.tooltip3.detail2": "このモデルでは一日当たり100グラムを「普通」とみなします。",
  "dashboard.content.clinical.assessment.tooltip2": "便の質",
  "dashboard.content.clinical.assessment.tooltip3": "排便量",
  "dashboard.content.clinical.assessment.tooltip3.unit": "(g / 1日あたり)",
  "dashboard.content.clinical.assessment.tooltip4": "排便回数",
  "dashboard.content.clinical.assessment.tooltip4.unit": "（1日あたり)",
  "dashboard.content.plasma.concentration.title": "血中薬物濃度 (血清)",
  "dashboard.content.plasma.concentration.label.baseline": "抗薬剤抗体  陰性",
  "dashboard.content.plasma.concentration.label.treatment": "抗薬剤抗体  陽性",
  "dashboard.content.biomarker.title": "バイオマーカー",
  "dashboard.content.biomarker.calprotectin.chart.header": "便中カルプロテクチン (µg/g)",
  "dashboard.content.biomarker.crp.chart.header": "CRP (μg/l)",
  // "dashboard.content.chart.label.baseline": "ベースライン",
  // "dashboard.content.chart.label.treatment": "治療",
  "dashboard.content.chart.label.baseline": "No Treatment",
  "dashboard.content.chart.label.treatment": "Treatment",
  "dashboard.content.physiology.title": "PHYSIOLOGY",
  "dashboard.content.physiology.serumTNF.chart.header": "TNFの変化率 (血清)",
  "dashboard.content.physiology.Th1.chart.header": "Th1の変化率 (組織)",
  "dashboard.content.physiology.serumIL12.chart.header": "IL12 / 23の変化率 (血清)",
  "dashboard.content.physiology.Th17.chart.header": "Th17の変化率 (組織)",
  "dashboard.content.physiology.serumIL22.chart.header": "IL22の変化率 (血清)",
  "dashboard.content.physiology.Macrophages.chart.header": "マクロファージの変化率",
  "dashboard.content.symptoms.title": "症状",
  "dashboard.content.symptoms.stool.label": "便の状態",
  "dashboard.content.mucosal.healing.title": "粘膜治癒",
  "dashboard.content.mucosal.healing.Jejunum.title": "空腸",
  "dashboard.content.mucosal.healing.Ileum.title": "回腸",
  "dashboard.content.mucosal.healing.Colon.title": "結腸",
  "dashboard.content.mucosal.healing.overall.title": "概観",
  "dashboard.content.mucosal.healing.present": "現在",
  "dashboard.content.mucosal.healing.future": "週後",
  "dashboard.content.mucosal.healing.condition.title": "粘膜層の状態",
  "dashboard.content.mucosal.healing.leisions.title": "病変面積",
  "dashboard.content.mucosal.healing.size.title": "平均サイズ（mm）",
  "dashboard.content.mucosal.healing.surface.title": "潰瘍面積",
  "dashboard.content.mucosal.healing.type.title": "潰瘍",
  "dashboard.chart.x.label.weeks": "週",
  "dashboard.chart.y.label.changes": "% 変化",
  "dashboard.contents.mucosal.healing.note1": "画像注釈",
  "dashboard.contents.mucosal.healing.note2": "岩切龍一先生監修",
  "dashboard.contents.mucosal.healing.note3": "シミュレーション結果から選出したイメージ図です",
  // "INTACT":"無傷",
  // "DAMAGED":"損傷あり",
  // "COMPROMISED":"妥協",
  "INTACT": "損傷なし",
  "DAMAGED": "大部分損傷",
  "COMPROMISED": "一部損傷",
  "Severe": "重度",
  "Remission": "寛解",
  "Mild": "軽度",
  "Moderate": "中程度",
  "SEVERELY COMPROMISED": "重度の損傷",
  "Diarrhea": "下痢",
  "Normal": "通常便",
  "Constipation": "便秘",
  "None": "なし",
  "Aphthous": "アフタ性潰瘍",
  "Large": "潰瘍",
  "Very large": "大潰瘍"
};

export default {
  en,
  ja
};
