import { connect } from "react-redux";
import DiarrheaSymptoms from "./diarrheaSymptoms";
import action from "../../../../flux/action";

const mapStateToProps = state => {
  const { dashboard: dashboardState } = state;
  const { treatmentMonth, forecasted } = dashboardState;
  return {
    treatmentMonth,
    forecasted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editDashboard: options => {
      dispatch(action.editDashboard(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(DiarrheaSymptoms);
