import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

class ControlPanel extends Component {
  constructor(props) {
    super(props);
  }

  handleAction() {
    const { onAction } = this.props;

    onAction();
  }

  handleReset() {
    const { onReset } = this.props;

    onReset();
  }

  handleClose() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { title, actionLabel, resetLabel, actionDisabled, closable } = this.props;
    return (
      <div className="control-panel">
        <div className="header">
          <div className="header-title">{title}</div>
          {
            closable === true && <div className="header-close" onClick={this.handleClose.bind(this)}>
              <Close style={{ color: "black", fontSize: "2rem" }} />
            </div>
          }
        </div>
        <div className="actions">
          <Button className="actions-button"
            disabled={actionDisabled ? actionDisabled : false}
            variant="contained" color="primary"
            onClick={this.handleAction.bind(this)}
          >
            {actionLabel}
          </Button>
          <Button className="actions-button-outline"
            variant="outlined" color="primary"
            onClick={this.handleReset.bind(this)}
          >
            {resetLabel}
          </Button>
        </div>
      </div>
    );
  }
}

ControlPanel.defaultProps = {
  title: "Patient's Condition",
  actionLabel: "Simulate",
  resetLabel: "Reset",
  onAction: () => { },
  onReset: () => { }
};

ControlPanel.propTypes = {
  title: PropTypes.string,
  actionLabel: PropTypes.string,
  resetLabel: PropTypes.string,
  onAction: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
  actionDisabled: PropTypes.bool,
  closable: PropTypes.bool
};

export default ControlPanel;
