import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
// import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import actions from "../../../flux/action";
import {Button} from "@material-ui/core";

class StepperButtons extends Component {
  constructor(props) {
    super(props);
    // const {patientInfo}= props;
    // this.patientInfo = _.cloneDeep(patientInfo);
  }

  nextStepperProgress(e) {
    const {personaState, createPersona, onNextStepChange} = this.props;
    const {activeStep} = personaState;
    const nextActiveStep = (activeStep == 3) ? 3 : activeStep + 1;
    createPersona(
      Object.assign({}, personaState, {
        activeStep: nextActiveStep,
        stepAction: activeStep == 3 ? "save" : "next"
      })
    );
    onNextStepChange(e);
  }

  prevStepperProgress() {
    const {personaState, createPersona} = this.props;
    const {activeStep} = personaState;
    const prevActiveStep = activeStep ? activeStep - 1 : 0;
    createPersona(
      Object.assign({}, personaState, {
        activeStep: prevActiveStep,
        stepAction: "back"
      })
    );
    // onChange(e);
  }

  checkForStateDisabled(){
    const {personaState} = this.props;
    const {activeStep, patientInfo, diseaseState, historicIntervention, responderProfile} = personaState;
    if(!activeStep){
      return patientInfo.completed;
    } else if(activeStep == 1){
      return diseaseState.completed;
    } else if(activeStep == 2){
      return historicIntervention.completed;
    } else if(activeStep == 3){
      return responderProfile.completed;
    } else {
      return false;
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const {personaState} = this.props;
    const {activeStep} = personaState;
    return (
      <div className="stepper-buttons">
        <Button
          classes={{
            root: "stepper-back-button"
          }}
          color="primary"
          variant="contained"
          disabled={activeStep === 0}
          onClick={this.prevStepperProgress.bind(this)}
        >{formatMessage({id: "persona.sidebar.action.back"})}</Button>
        <Button
          classes={{
            root: "stepper-next-button"
          }}
          color="primary"
          variant="contained"
          disabled={!(this.checkForStateDisabled())}
          onClick={this.nextStepperProgress.bind(this)}
        >{activeStep == 3 ? formatMessage({id: "persona.sidebar.action.save"}) : formatMessage({id: "persona.sidebar.action.next"})}</Button>
      </div>
    );
  }
}

StepperButtons.defaultProps = {
  subroutes: [],
  basePath: "",
  history: {},
  label: ""
};

StepperButtons.propTypes = {
  onNextStepChange: PropTypes.func,
  changeLanguage: PropTypes.func,
  createPersona: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  personaState: PropTypes.object
};

const mapStateToProps = (state) => {
  const {newPersona: personaState} = state;

  return {
    personaState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    createPersona: (persona) => {
      dispatch(actions.createPersona(persona));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(StepperButtons));
