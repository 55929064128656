import React, { Component } from "react";
import PropTypes from "prop-types";
import ChartContainer from "../../components/chartContainer";
import { injectIntl, intlShape } from "react-intl";
import { SwitchExpansionPanel } from "ui-toolkit";

class Physiology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  getRange(range = []) {
    const [min, max] = range;

    return {
      min,
      max
    };
  }

  handleChange() {
    this.setState({
      isOpen: !this.state.isOpen
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "event" : "physiology_toggle_event",
      "physiology_toggle_status" : "TRUE",
    });
  }

  render() {
    const { data, plotConfig, treatmentMonth, intl } = this.props;
    const { isOpen } = this.state;

    const { formatMessage } = intl;
    const { serumTNF, Th1, Th17, serumIL22,plasma_drug_concentration } = data;
    const labels = [
      formatMessage({ id: "dashboard.content.chart.label.baseline" }),
      formatMessage({ id: "dashboard.content.chart.label.treatment" })
    ];
    return (
      <div className="physiology">
        <SwitchExpansionPanel
          color="primary"
          label={formatMessage({ id: "dashboard.content.physiology.title" })}
          checked={isOpen}
          onToggle={this.handleChange.bind(this)}
        >
          {/* <div className="header">
          <div className="title">
            {formatMessage({ id: "dashboard.content.physiology.title" })}
          </div>
        </div> */}
          <div className="content">
            <ChartContainer
              labels={labels}
              name={"serumTNF"}
              treatmentMonth={treatmentMonth}
              data={serumTNF}
              {...this.getRange(plotConfig["serumTNF"])}
              title={"dashboard.content.physiology.serumTNF.chart.header"}
            />
            <ChartContainer
              labels={labels}
              name={"th1"}
              treatmentMonth={treatmentMonth}
              data={Th1}
              {...this.getRange(plotConfig["Th1"])}
              title={"dashboard.content.physiology.Th1.chart.header"}
            />
            <ChartContainer
              labels={labels}
              name={"serumIL12"}
              treatmentMonth={treatmentMonth}
              data={data["serumIL12/IL23"]}
              {...this.getRange(plotConfig["serumIL12/IL23"])}
              title={"dashboard.content.physiology.serumIL12.chart.header"}
            />
            <ChartContainer
              labels={labels}
              name={"th17"}
              treatmentMonth={treatmentMonth}
              data={Th17}
              {...this.getRange(plotConfig["Th17"])}
              title={"dashboard.content.physiology.Th17.chart.header"}
            />
            <ChartContainer
              labels={labels}
              name={"serumil22"}
              treatmentMonth={treatmentMonth}
              data={serumIL22}
              {...this.getRange(plotConfig["serumIL22"])}
              title={"dashboard.content.physiology.serumIL22.chart.header"}
            />
            <ChartContainer
              {...this.getRange(plotConfig["plasma_drug_concentration"])}
              labels={labels}
              name="plasmaDrugConcentration"
              data={plasma_drug_concentration}
              treatmentMonth={treatmentMonth}
              title={"dashboard.content.plasma.concentration.title"}
            />
          </div>
        </SwitchExpansionPanel>
      </div>
    );
  }
}

Physiology.defaultProps = {};

Physiology.propTypes = {
  data: PropTypes.object,
  plotConfig: PropTypes.object,
  range:PropTypes.object,
  treatmentMonth: PropTypes.number,
  intl: intlShape.isRequired
};

export default injectIntl(Physiology);
