import React, { Component } from "react";
import PropTypes from "prop-types";
import PersonaContainer from "./personaContainer";
import {validateLogin} from "../../../utils";
import Loading from "../../../components/Loading";
import { injectIntl, intlShape } from "react-intl";
import "../../../../styles/persona/main.scss";

class PersonaMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    validateLogin();
    const {getPresetPersonas, selectPersona} = this.props;
    // if(!personas.isLoading && personas.personas.length)  return null;
    getPresetPersonas();
    selectPersona({
      personaSelected: "",
      isSelected: false
    });
  }

  render() {
    const { selectPersona, personaSelected, personas } = this.props;
    return (
      <div className="persona-main preset-pernona">
        <Loading isShow={personas.isLoading} />
        <PersonaContainer
          selectPersona={selectPersona}
          personaSelected={personaSelected}
          personas={personas["personas"]}
        />
      </div>
    );
  }
}

PersonaMain.defaultProps = {};

PersonaMain.propTypes = {
  selectPersona: PropTypes.func,
  personaSelected: PropTypes.string,
  personas: PropTypes.object,
  getPresetPersonas: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(PersonaMain);
