import { connect } from "react-redux";
import Login from "./login";
import action from "../../flux/action";

const mapStateToProps = (state) => {
  console.log(state);
  const {common: commonState} = state;
  const {login: loginState} = state;
  const {language} = commonState;
  return {language, loginState};
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (options) => {
      dispatch(action.login(options));
    },
    changeLanguage: (language) => {
      dispatch(action.changeLanguage(language));
    },
    showEmailValidation: (message) => {
      dispatch(action.showMessage({ message: message }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
