import React, { Component } from "react";
import PropTypes from "prop-types";
import { SelectMenu, CommonInput, RadioGroup } from "ui-toolkit";
import FiledLabel from "../fieldLabel";

class CommonField extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(value) {
    const { onChange } = this.props;

    onChange(value);
  }

  getComponent() {
    const { type, value, options, validation, forwardedRef } = this.props;

    switch (type) {
    case "select":
      return (
        <SelectMenu
          ref={forwardedRef}
          config={options}
          value={value}
          validation={validation}
          onSelect={this.handleChange.bind(this)}
        />
      );
    case "radio":
      return (
        <RadioGroup
          ref={forwardedRef}
          config={options}
          value={value}
          onChange={this.handleChange.bind(this)}
        />
      );
    default:
      return (
        <CommonInput
          ref={forwardedRef}
          value={value}
          validation={validation}
          onChange={this.handleChange.bind(this)}
        />
      );
    }
  }


  render() {
    const { label, children, tooltipContent } = this.props;

    return (
      <FiledLabel label={label} tooltipContent={tooltipContent}>
        {this.getComponent()}
        {children}
      </FiledLabel>
    );
  }
}

CommonField.defaultProps = {
  type: "input",
  label: "",
  value: "",
  options: [],
  validation: {},
  onChange: () => {}
};

CommonField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  forwardedRef: PropTypes.any,
  children: PropTypes.any,
  tooltipContent: PropTypes.array,
};

const WrappedComponent = React.forwardRef((props, ref) => {
  return <CommonField {...props} forwardedRef={ref} />;
});

WrappedComponent.displayName = "CommonField";

export default WrappedComponent;
