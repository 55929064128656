import {connect} from "react-redux";
import HistoricIntervention from "./historicIntervention";
import action from "../../../../flux/action";

const mapStateToProps = (state) => {
  const {newPersona: personaState} = state;
  const {historicIntervention, responderProfile} = personaState;
  return {
    historicIntervention,
    responderProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPersona: (options) => {
      dispatch(action.createPersona(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(HistoricIntervention);
