import React/*{ Component } */ from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import { injectIntl, intlShape } from "react-intl";
import _ from "lodash";


const FieldLabel = (props) => {
  const { label, className, children, intl, tooltipContent, labelClass } = props;
  const { formatMessage } = intl;
  const classes = ["field-label"];

  if (className) {
    classes.push(className);
  }
  const random = Math.floor(Math.random() * 100);
  const labelClasses = "label " + labelClass;
  return (
    <div className={classnames(classes)}>
      <div className={labelClasses}><div className="label-title">{label}</div>
        {(label === "RESPONSIVE" || label === "反応") &&
          <div className="responsive">
            <ReactTooltip id={`responsive-${random}`} type="dark" aria-haspopup="true" place="left" scrollHide={true} eventOff="scroll">
              <span>
                <div className="tooltip-content">
                  {tooltipContent && _.map(tooltipContent, (content, index) => {
                    return (<div key={index}>
                      {formatMessage({
                        id: content
                      })}
                    </div>);
                  })
                  }
                </div>
              </span>
            </ReactTooltip>
          </div>}
        {children}
      </div>
    </div>
  );
};

FieldLabel.defaultProps = {
  label: "",
  className: ""
};

FieldLabel.propTypes = {
  label: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any,
  intl: intlShape.isRequired,
  tooltipContent: PropTypes.array,
  labelClass: PropTypes.string,
};

export default injectIntl(FieldLabel);