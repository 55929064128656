import { createActions } from "redux-actions";
import { actionTypes } from "./actionTypes";
import { createRequest, naviTo } from "../../utils";
import {getInitItem} from "../../model";

const { CREATE_PERSONA, ADD_NEW_PERSONA, ADD_NEW_PERSONA_SUCCESS, ADD_NEW_PERSONA_FAILED, EDIT_PERSONA, RESET_NEW_PERSONA, RESET_DASHBOARD } = actionTypes;

const commonActions = createActions({}, CREATE_PERSONA, ADD_NEW_PERSONA, ADD_NEW_PERSONA_SUCCESS, ADD_NEW_PERSONA_FAILED, EDIT_PERSONA, RESET_NEW_PERSONA, RESET_DASHBOARD);

const addNewPersona = options => {
  return dispatch => {
    dispatch(commonActions.addNewPersona(options));

    const successCallback = res => {
      dispatch(commonActions.addNewPersonaSuccess(res));
      if(res.success){
        dispatch(commonActions.editPersona({
          isSelected: true,
          personaSelected: options.name,
          showSaveDialog: false,
          ...getInitItem(options.data)
        }));
        dispatch(commonActions.resetNewPersona(res));
        dispatch(commonActions.resetDashboard());
        naviTo("/app/dashboard");
      }
    };

    const failedCallback = (res) => {
      dispatch(commonActions.addNewPersonaFailed());
      if(res.message.includes("Error")){
        dispatch(commonActions.createPersona({
          error: true,
          errorMessage: res.message,
          personaSelected: options.name,
          showSaveDialog: true,
          ...getInitItem(options.data)
        }));
      }
    };

    const opts = {
      pathName: "addNewPersona",
      method: "POST",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};

export default Object.assign({}, commonActions, {addNewPersona});
