import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { CommonInput } from "ui-toolkit";
import _ from "lodash";
import LanguageSelect from "../../components/languageSelect";
import action from "../../flux/action";
import { languages } from "../../model";
import { injectIntl, intlShape } from "react-intl";
import Takeda from "../../../assets/takeda_logo.svg";
import { naviTo } from "../../utils";
import { rePasswordValidation, passValidation, loadIntl } from "../../model";
import "../../../styles/login/forgot-password.scss";
import reset from "../../../assets/new_reset.jpg";
class ForgotPasswordVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: ""
    };
    this.inputRefs = {
      passwordRef: createRef(),
      passwordRef2: createRef()
    };
  }

  componentDidMount() {
    const { verifyPasswordReset, oobCode } = this.props;

    verifyPasswordReset({
      oobCode: oobCode
    });
  }

  handleResetPasswordValidate(value) {
    rePasswordValidation.equal.value = value;
  }

  handleFieldChange(field, value) {
    field == "password" ? this.handleResetPasswordValidate(value) : null;
    this.setState(
      {
        [field]: value
      },
      () => {
        if (this.state.password2) {
          this.inputRefs.passwordRef2.current.validate();
        }
      }
    );
  }

  handleResetPassword(e) {
    e.preventDefault();
    const { confirmPasswordReset, oobCode } = this.props;
    const { password } = this.state;
    this.getStatus()
      ? confirmPasswordReset({
        oobCode,
        password
      })
      : null;
  }

  goToLogin(e) {
    e.preventDefault();
    naviTo("/login");
  }

  // isRePasswordValid() {
  //   const { password2 } = this.state;
  //   const comfirmPassword = this.inputRefs.passwordRef2.current;
  //   return comfirmPassword ? !comfirmPassword.validate(password2) : false;
  // }

  handleLanguageChange(language) {
    const { changeLanguage } = this.props;

    changeLanguage(language);
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = ref.current.getStatus();
        return result && status === "init" ? false : status;
      },
      true
    );
  }

  getContent() {
    const {
      verifyErrorMessage,
      forgotPasswordCodeVerified,
      confirmResetPassSuccess,
      intl
    } = this.props;
    const { formatMessage } = intl;
    const { password, password2 } = this.state;
    const [repasswordInputValidation] = loadIntl(
      [rePasswordValidation],
      formatMessage
    );
    const [passInputValidation] = loadIntl([passValidation], formatMessage);
    if (forgotPasswordCodeVerified) {
      return (
        <div className="content">
          <p className="info-text">
            {formatMessage({ id: "reset.password.info.text" })}
          </p>
          <div className="new-password">
            {formatMessage({ id: "reset.password.new.password" })}
          </div>
          <CommonInput
            type="password"
            ref={this.inputRefs.passwordRef}
            placeholder={formatMessage({
              id: "reset.passsword.password.input"
            })}
            className="common-input"
            value={password}
            validation={passInputValidation}
            onChange={this.handleFieldChange.bind(this, "password")}
          />
          <div className="confirm-password">
            {" "}
            {formatMessage({ id: "reset.password.confirm.input" })}
          </div>
          <CommonInput
            type="password"
            ref={this.inputRefs.passwordRef2}
            placeholder={formatMessage({
              id: "reset.password.confirm.input"
            })}
            className="common-input"
            value={password2}
            validation={repasswordInputValidation}
            onChange={this.handleFieldChange.bind(this, "password2")}
          />
          <div className="buttons">
            <Button
              classes={{
                root: "reset-password-button"
              }}
              type="submit"
              variant="contained"
              color="primary"
              // disabled={!this.valid}
            >
              {formatMessage({ id: "reset.password.button.reset" })}
            </Button>
          </div>
        </div>
      );
    } else if (confirmResetPassSuccess) {
      return (
        <div className="content">
          <Fragment>
            <div className="reset-password-title">
              {formatMessage({ id: "reset.password.info.text" })}
            </div>
            <p className="reset-info-text">
              {formatMessage({ id: "reset.password.reset.successful" })}
            </p>
            <div className="buttons">
              <Button
                classes={{
                  root: "sign-in-button"
                }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={this.goToLogin}
              >
                {formatMessage({ id: "reset.password.button.sign.in" })}
              </Button>
            </div>
          </Fragment>
        </div>
      );
    } else {
      return <p className="info-text">{verifyErrorMessage}</p>;
    }
  }

  render() {
    const { language } = this.props;
    return (
      <div className="login-container">
        <div className="left-card">
          <form onSubmit={this.handleResetPassword.bind(this)}>
            <div className="forgot-password-verify-content">
              <div className="header-title">
                <img src={Takeda} />
                {/* <span>{formatMessage({ id: "login.header.title" })}</span> */}
                <LanguageSelect
                  config={languages}
                  value={language}
                  onChange={this.handleLanguageChange.bind(this)}
                />
              </div>
              {this.getContent()}
            </div>
          </form>
        </div>
        <div className="right-card">
          <img src={reset} />
        </div>
      </div>
    );
  }
}

ForgotPasswordVerify.defaultProps = {
  oobCode: ""
};

ForgotPasswordVerify.propTypes = {
  oobCode: PropTypes.string,
  verifyErrorMessage: PropTypes.string,
  forgotPasswordCodeVerified: PropTypes.bool,
  confirmResetPassSuccess: PropTypes.bool,
  verifyPasswordReset: PropTypes.func,
  confirmPasswordReset: PropTypes.func,
  intl: intlShape.isRequired,
  language: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => {
  const { login, common } = state;
  const { language } = common;
  const {
    verifyErrorMessage,
    forgotPasswordCodeVerified,
    confirmResetPassSuccess
  } = login;
  return {
    language,
    verifyErrorMessage,
    forgotPasswordCodeVerified,
    confirmResetPassSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyPasswordReset: options => {
      dispatch(action.verifyPasswordReset(options));
    },
    confirmPasswordReset: options => {
      dispatch(action.confirmPasswordReset(options));
    },
    changeLanguage: language => {
      dispatch(action.changeLanguage(language));
    }
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      forwardRef: true
    }
  )(ForgotPasswordVerify),
  { withRef: true }
);
