import React, { Component } from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import _ from "lodash";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { addLocaleData, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { initRouter } from "../utils";
import action from "../flux/action";
import theme from "./baseTheme";
import AppContainer from "./appContainer";
import Login from "../modules/login";
import Verify from "../modules/login/verify";
import CreateAccount from "../modules/login/createAccount";
import ForgotPassword from "../modules/login/forgotPassword";
import Loading from "../components/Loading";
import Error from "../components/error";
import { locales } from "../locales";
import "../../styles/common.scss";
import CustomAlert from "./customAlert";
class Entry extends Component {
  constructor(props) {
    super(props);
    const { match, location, history } = props;

    initRouter({
      match,
      location,
      history
    });
    addLocaleData(
      _.reduce(
        locales,
        (result, locale) => {
          const { data } = locale;

          return _.concat(result, data);
        },
        []
      )
    );
  }

  handleCloseMessage() {
    const { hideMessage } = this.props;

    hideMessage();
  }

  render() {
    const {
      isShowMessage,
      messageText,
      hideMessage,
      isShowAction,
      language,
      isLoading
    } = this.props;
    const { messages } = locales[language];
    return (
      <IntlProvider locale={language} messages={messages}>
        <MuiThemeProvider theme={theme}>
          <div className="app">
            <CustomAlert
              message={messageText}
              open={isShowMessage}
              onClose={hideMessage}
              showAction={isShowAction}
              onAction={hideMessage}
            />
            <Loading isShow={isLoading} type="simulation"/>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/verify" component={Verify} />
              <Route path="/createAccount" component={CreateAccount} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/app" component={AppContainer} />
              <Route path="/error" component={Error} />
              <Redirect path="*" to="/login" />
            </Switch>
          </div>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

Entry.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  isShowMessage: PropTypes.bool,
  isShowAction: PropTypes.bool,
  messageText: PropTypes.string,
  hideMessage: PropTypes.func,
  language: PropTypes.string,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    common: commonState,
    persona: personaState,
    dashboard: dashboardState
  } = state;

  const { showMessage, message, language } = commonState;
  return {
    isLoading: personaState.isLoading || dashboardState.isLoading,
    isShowMessage: showMessage,
    messageText: message,
    language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMessage: () => {
      dispatch(action.hideMessage());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Entry);
