import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
// import { naviTo } from "../../../utils";
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import savePersonaImage from "../../../assets/Save-persona.png";
import FiledLabel from "../fieldLabel";
import { CommonInput } from "ui-toolkit";
import Loading from "../Loading";
import actions from "../../flux/action";
// import "../../../../styles/persona/sidebar.scss";

class SavePersona extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      personaName: React.createRef(),
      personaDesc: React.createRef(),
    };
    this.state = {
      personaName: "",
      personaDesc: "",
      isNameErrored: false,
      isDescErrored: false
    };
    this.personaState = props.personaState;
    this.personaState.completed = false;
    this.specialCharArray = ["!", "#", "$", "^", "*", "~", "`", "<", ">", "{", "}", "\\", "/", ":", ";"];
  }

  savePersona() {
    let data = {};
    const { addNewPersona, personaState } = this.props;
    const { name, desc, patientInfo, diseaseState, historicIntervention, responderProfile } = personaState;
    data.name = name;
    data.desc = desc;
    data.archetypeId = name;
    data.patientInfo = patientInfo;
    data.diseaseState = diseaseState;
    data.historicIntervention = historicIntervention;
    data.responderProfile = responderProfile;
    addNewPersona({ name, desc, data });
    // this.handleReset();
    // resetNewPersona();
  }

  cancelDialog() {
    const { personaState, createPersona } = this.props;
    this.setErrorState("desc", false);
    this.setErrorState("name", false);
    createPersona(
      Object.assign({}, personaState, {
        showSaveDialog: false,
        name: "",
        desc: "",
        error: false,
        errorMessage: ""
      })
    );
  }

  checkSpecialCharactersExist(input) {
    return this.specialCharArray.some(specialChar => input.includes(specialChar));
  }

  setErrorState(type, isErrored) {
    if (type === "desc") {
      this.setState({
        isDescErrored: isErrored
      });
    }
    else {
      this.setState({
        isNameErrored: isErrored
      });
    }
  }

  handleChange(type, event) {
    const { personaState, createPersona } = this.props;
    personaState[type] = event;
    if (this.checkSpecialCharactersExist(personaState[type])) {
      if (type === "name") {
        this.setErrorState(type, true);
      }
    }
    else {
      if (type === "name") {
        this.setErrorState(type, false);
      }
    }
    createPersona({
      personaState: Object.assign({}, personaState)
    });
  }

  getErrorBlock() {
    const { personaState } = this.props;
    if (personaState["error"] && personaState["errorMessage"]) {
      return (
        <div style={{ color: "red" }}>{personaState["errorMessage"]}</div>
      );
    }
  }

  getSaveActionDialog() {
    const { intl, personaState } = this.props;
    const { showSaveDialog } = personaState;
    const { formatMessage } = intl;
    return (
      <Dialog
        open={!!showSaveDialog}
        aria-labelledby="new-patient-save-dialog-title"
        aria-describedby="new-patient-save-dialog-description"
      >
        <DialogContent className="sidebar-save-dialog" id="new-patient-save-dialog-title">
          <div className="sidebar-save-dialog">
            <img src={savePersonaImage} />
            <div className="sidebar-save-dialog-title">{formatMessage({ id: "persona.sidebar.save.persona" })}</div>
            <div className="input-section">
              <FiledLabel
                label={formatMessage({ id: "persona.sidebar.save.persona.name" })}
                labelClass="text-uppercase font-size-14"
              >
                <CommonInput
                  ref={this.inputRefs.personaName}
                  value={personaState["name"]}
                  onChange={this.handleChange.bind(this, "name")}
                  inputProps={{ maxLength: 30 }}
                  key="personaName"
                />
              </FiledLabel>
              {
                this.state.isNameErrored && <div><label style={{ color: "red", fontSize: "smaller" }}>{formatMessage({ id: "persona.sidebar.save.persona.error" })}</label></div>
              }
              <FiledLabel
                label={formatMessage({ id: "persona.sidebar.save.persona.desc" })}
                labelClass="text-uppercase font-size-14"
              >
                <CommonInput
                  ref={this.inputRefs.personaDesc}
                  value={personaState["desc"]}
                  onChange={this.handleChange.bind(this, "desc")}
                  inputProps={{ maxLength: 200 }}
                  key="personaDesc"
                />
              </FiledLabel>
              {this.getErrorBlock()}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="expired-dialog">
          <div className="stepper-buttons pb-20 pr-25">
            <Button
              classes={{
                root: "stepper-next-button save-button"
              }}
              color="primary"
              variant="contained"
              disabled={_.isEmpty(personaState["name"]) || _.isEmpty(personaState["desc"]) || this.state.isNameErrored || this.state.isDescErrored}
              onClick={this.savePersona.bind(this)}
            >
              {formatMessage({ id: "persona.sidebar.action.save" })}
            </Button>
            <Button
              classes={{
                root: "stepper-back-button"
              }}
              className="ml-24"
              color="primary"
              variant="contained"
              onClick={this.cancelDialog.bind(this)}
            >
              {formatMessage({ id: "persona.sidebar.action.cancel" })}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { personaState } = this.props;
    return (
      <Fragment>
        <Loading isShow={personaState.isLoading} />
        {this.getSaveActionDialog()}
      </Fragment>
    );
  }
}

SavePersona.defaultProps = {};

SavePersona.propTypes = {
  createPersona: PropTypes.func,
  intl: intlShape.isRequired,
  addNewPersona: PropTypes.func,
  personaState: PropTypes.object,
  progressAction: PropTypes.string
};

const mapStateToProps = (state) => {
  const { newPersona: personaState } = state;
  return {
    personaState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    addNewPersona: options => {
      dispatch(actions.addNewPersona(options));
    },
    createPersona: (persona) => {
      dispatch(actions.createPersona(persona));
    }
  };
};
// export default injectIntl(SavePersona, { withRef: true });

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(SavePersona, { withRef: true }));
