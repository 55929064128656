import {connect} from "react-redux";
import DiseaseState from "./diseaseState";
import action from "../../../../flux/action";

const mapStateToProps = (state) => {
  const {newPersona: personaState} = state;
  const {diseaseState} = personaState;

  return {
    diseaseState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPersona: (options) => {
      dispatch(action.createPersona(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(DiseaseState);
