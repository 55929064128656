import { connect } from "react-redux";
import Intestine from "./intestine";

const mapStateToProps = state => {
  const { persona: personaState } = state;
  return {
    personaState
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  {
    forwardRef: true
  }
)(Intestine);
