import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconSelect } from "ui-toolkit";
import _ from "lodash";

class LanguageSelect extends Component {
  constructor(props, context) {
    super(props, context);
  }

  handleChange(value) {
    const { onChange } = this.props;

    onChange(value);
  }

  render() {
    const { config, value } = this.props;
    const selected = _.find(config, { value });

    return (
      <div className="language-select">
        <IconSelect
          config={config}
          value={value}
          onChange={this.handleChange.bind(this)}
        />
        <div className="selected">{selected ? selected.label: ""}</div>
      </div>
    );
  }
}

LanguageSelect.defaultProps = {
  config: [],
  value: "",
  onChange: () => {}
};

LanguageSelect.propTypes = {
  config: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default LanguageSelect;
