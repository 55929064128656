import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
//import placeholderImage from "../../../../../assets/SES-CD-score.png";
import _ from "lodash";
import defaultDummyImageColon from "../../../../../assets/Dummy_Images_Illeum_Colon/endoscopic_images_final_hd_images_endo_img_30000000.jpg";
import defaultDummyImageIleum from "../../../../../assets/Dummy_Images_Illeum_Colon/endoscopic_images_final_hd_images_endo_img_20000000.jpg";
import loadingColon from "../../../../../assets/Dummy_Images_Illeum_Colon/endoscopic_images_final_hd_images_endo_img_colon_loading.jpg";
import loadingIleum from "../../../../../assets/Dummy_Images_Illeum_Colon/endoscopic_images_final_hd_images_endo_img_ileum_loading.jpg";

const typeMapping = {
  "none": "0cm",
  "aphthous": "0.1 - 0.5cm",
  "large": "0.5 - 2cm",
  "very large": "> 2cm"
};

class MucosalHealingContent extends Component {
  constructor(props) {
    super(props);
  }

  addDefaultSrc(ev,data){
    if(data === "Ileum"){
      ev.target.src = defaultDummyImageIleum;
    }
    if(data === "Colon"){
      ev.target.src = defaultDummyImageColon;
    }
  }

  render() {
    const { data, intl, imagevalue, image, content, loadingImage } = this.props;
    const { formatMessage } = intl;
    const {
      condition,
      disease_area: leisions /*, size*/,
      ulcer_area: surface_covered,
      ulcer_type: type
    } = data;

    let images = loadingImage;
    if(_.isEmpty(image) !== true){
      if(content === "Ileum"){
        images = image[0][imagevalue] === [] || image[0][imagevalue] === undefined ?  loadingIleum : image[0][imagevalue];
      }
      if(content === "Colon"){
        images = image[0][imagevalue] === [] || image[0][imagevalue] === undefined ?  loadingColon : image[0][imagevalue];
      }
    }

    return (
      <div className="mucosal-healing-content mucosal-style">
        {/* <div className="title">{title}</div> */}
        <div className="detail-content">
          <div className="condition">
            <div className="image pr-10">
              <img style={{height:"160px",width:"286px"}} src={images} onError={(e) => {this.addDefaultSrc(e, content);}}/>
            </div>
          </div>
          <div className="detail detail1" >
            <div className="condition-data">
              {formatMessage({ id: condition })}
            </div>
            <div className="titles">
              {formatMessage({id: "dashboard.content.mucosal.healing.condition.title"})}
            </div>
          </div>
        </div>
        <div className="content-row">
          <div className="detail detail2" >
            <div className="condition-data">
              {formatMessage({ id: condition })}
            </div>
            <div className="titles">
              {formatMessage({id: "dashboard.content.mucosal.healing.condition.title"})}
            </div>
          </div>
          <div className="type">
            <div className="type-data">{formatMessage({ id: type })}</div>
            <div className="type-unit">
              <div className="titles">{typeMapping[type.toLowerCase()]} {formatMessage({id: "dashboard.content.mucosal.healing.type.title"})}</div>
            </div>
          </div>
          {/*<div className="detail-surface-leision">*/}
          <div className="leisions">
            <div className="leisions-data">
              {leisions}
              <span className="percent">%</span>
            </div>
            <div className="titles">
              {formatMessage({
                id: "dashboard.content.mucosal.healing.leisions.title"
              })}
            </div>
          </div>
          {/* <div className="size">
            <div className="size-data">{size}</div>
            <div className="titles">
              {formatMessage({
                id: "dashboard.content.mucosal.healing.size.title"
              })}
            </div>
          </div> */}
          <div className="surface_covered">
            <div className="surface">
              {surface_covered}
              <span className="percent">%</span>
            </div>
            <div className="titles">
              {formatMessage({id: "dashboard.content.mucosal.healing.surface.title"})}
            </div>
          </div>
          {/*</div>*/}
        </div>{/*end of row*/}
      </div>
    );
  }
}

MucosalHealingContent.defaultProps = {};

MucosalHealingContent.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  intl: intlShape.isRequired,
  image: PropTypes.string,
  imagevalue: PropTypes.string,
  screenOrientation: PropTypes.string,
  default: PropTypes.string,
  content: PropTypes.string,
  loadingImage: PropTypes.string
};

export default injectIntl(MucosalHealingContent);
