import { handleActions } from "redux-actions";
import _ from "lodash";
import action from "../action";
import dState from "./defaultState";
import { getDefaultProposedTreatment } from "../../model";

const { actionTypes } = action;
const {
  EDIT_DASHBOARD,
  RESET_DASHBOARD,
  SIMULATE,
  SIMULATE_SUCCESS,
  SIMULATE_FAILED,
  SESSION_EXPRIRED,
  CLEAR_MESSAGE,
  SIGNEDURL_SUCCESS,
  SIGNEDURL_FAILED,
  GET_IMAGE_SIGNED_URL
  // CHECK_SESSION
} = actionTypes;

const defaultState = Object.assign(dState, {
  treatment: {
    treatment1: true
  },
  proposedTreatment: getDefaultProposedTreatment({
    duractionTreatment: "52"
  }),
  // biomarker: "line",
  treatmentMonth: 0,
  forecasted: {},
  plotConfig:{},
  imageSignedUrls:[]
});

export default handleActions(
  {
    [EDIT_DASHBOARD]: (state, action) => {
      const { payload } = action;
      return Object.assign({}, state, payload);
    },
    [RESET_DASHBOARD]: () => {
      return _.defaultsDeep({}, defaultState);
    },
    [GET_IMAGE_SIGNED_URL]: state => {
      return Object.assign({}, state, {
        isLoading: true,
        //forecasted: data,
      });
    },
    [SIGNEDURL_SUCCESS]: (state, action) => {
      const { payload } = action;
      const { data } = payload;
      const { image_data } = data;

      return Object.assign({}, state, {
        imageSignedUrls: image_data,
        isLoading: false
      });
    },
    [SIGNEDURL_FAILED]: state => {
      return Object.assign({}, state, {
        isLoading: false
      });
    },
    [SIMULATE]: state => {
      return Object.assign({}, state, {
        isLoading: true,
        //forecasted: data,
      });
    },
    [SIMULATE_SUCCESS]: (state, action) => {
      const { payload } = action;
      const { data } = payload;
      const {plot_config, sim_output} = data;

      return Object.assign({}, state, {
        treatmentMonth: 0,
        forecasted: sim_output,
        plotConfig: plot_config,
        isLoading: false
      });
    },
    [SIMULATE_FAILED]: state => {
      return Object.assign({}, state, {
        isLoading: false
      });
    },
    [SESSION_EXPRIRED]: state => {
      return Object.assign({}, { isSessionExpired: true }, state);
    },
    [CLEAR_MESSAGE]: state => {
      return Object.assign({}, state,{ isSessionExpired: false });
    },
    // [CHECK_SESSION] : (state, action) => {
    //   const { payload } = action;
    //   return Object.assign({}, state, {
    //     isSessionExpired: payload,
    //     isLoading: true
    //   });
    // },
  },
  defaultState
);
