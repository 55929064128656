import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import loaderImage from "../../../assets/loader.gif";
import buildTwinPic from "../../../assets/Loading_1.gif";
import administeringPic from "../../../assets/Loading_2.gif";
import simulateResonsePic from "../../../assets/Loading_3.gif";
import compilingResultPic from "../../../assets/Loading_4.gif";
import Disclaimer from "../disclaimer";

const loadingStep = [
  // {
  //   loadingPic: loadingPic1,
  //   loadingText: "common.loading.desc"
  // },
  {
    loadingPic: buildTwinPic,
    loadingText: "common.loading.desc1"
  },
  {
    loadingPic: administeringPic,
    loadingText: "common.loading.desc2"
  },
  {
    loadingPic: simulateResonsePic,
    loadingText: "common.loading.desc3"
  },
  {
    loadingPic: compilingResultPic,
    loadingText: "common.loading.desc4"
  }
];
class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simulateStep: 0
    };
  }

  componentDidUpdate(prevProps) {
    const { isShow } = prevProps;
    if(!this.imgChanged && this.props.isShow && !isShow) {
      this.imgChanged = setInterval(() => this.setStep(), 6000);
    } else if (!this.props.isShow && isShow) {
      clearInterval(this.imgChanged);
      this.imgChanged = null;
      this.setState({ simulateStep: 0 });
    }
  }

  componentWillUnmount() {
    if (this.imgChanged) {
      clearInterval(this.imgChanged);
      this.imgChanged = null;
      this.setState({ simulateStep: 0 });
    }
  }

  setStep() {
    const { simulateStep } = this.state;
    if (simulateStep >= 3) {
      clearInterval(this.imgChanged);
      this.imgChanged = null;
    } else {
      this.setState({
        simulateStep: simulateStep + 1
      });
    }
  }

  stepChanged() {
    const { simulateStep } = this.state;
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <Fragment>
        <img
          className="loading-img simulation"
          src={loadingStep[simulateStep].loadingPic}
        />
        <div className="loading-desc">
          {formatMessage({ id: loadingStep[simulateStep].loadingText })}
        </div>
      </Fragment>
    );
  }

  render() {
    const { isShow, type } = this.props;
    if (isShow) {
      if(type === "simulation"){
        return (
          <div className="loading">
            <div className="loading-container">{this.stepChanged()}</div>
            <div className="loading-disclaimer"><Disclaimer /></div>
          </div>
        );
      } else {
        return (
          <div className="loading">
            <div className="loading-container">
              <img className="loading-img default" src={loaderImage}/>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

Loading.defaultProps = {
  isShow: false
};

Loading.propTypes = {
  isShow: PropTypes.bool,
  intl: intlShape.isRequired,
  showDisclaimer: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string
};

export default injectIntl(Loading);
