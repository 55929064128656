import _ from "lodash";
import { drugConfig } from "./drugConfig";
/**************************************************************
const dataStructure = {
  proposedTreatment: [
    {
      name: "treatment1",
      label: "Treatment 1",
      duractionTreatment: "",
      "anti-tnf": {
        checked: true,
        doseFrequency: "",
        immunogenicity: ""
      },
      "anti-il": "as above"
      "anti-integrin": "as above"
      steroids: "as above"
      immunomodulator: "as above"
    },
    ..."as above"
  ]
}
**************************************************************/
// const defaultTreatmentPharmacology = {
//   checked: false,
//   // duractionTreatment: "",
//   doseFrequency: "",
//   immunogenicity: ""
//   // increase: "",
//   // assume: "yes"
// };

const getDefaultDrug = (name) => {
  const drug = {
    checked: false
  };
  const config = drugConfig[name] || {};
  // console.log("config",config);
  _.each(config, (field) => {
    const { type, value } = field;
    switch (type) {
    case "radio":
      Object.assign(drug, {
        [value]: "yes"
      });
      break;
    case "select":
      switch (value) {
      case "regime":
        Object.assign(drug, {
          [value]: "induction + maintenance"
        });
        break;
      case "developmentImmunogenicity":
        Object.assign(drug, {
          [value]: "none"
        });
        break;
      }
    }
  });

  return drug;
};


const getDefaultTreatment = (options) => {
  const treatment = {
    name: "",
    label: "",
    duractionTreatment: "",
  };
  _.each(drugConfig, (config, name) => {
    Object.assign(treatment, {
      [name]: getDefaultDrug(name)
    });
  });

  return _.defaultsDeep(options, treatment);
};

// const getTreatmentItem = options => {
//   return _.defaultsDeep({}, options, defaultTreatment);
// };

const getDefaultProposedTreatment = (options) => {
  return [
    getDefaultTreatment({
      name: "treatment1",
      label: "Treatment 1",
      ...options
    })
  ];
};

const getDefaultDashboard = () => {
  return {
    proposedTreatment: [
      getDefaultTreatment({
        name: "treatment1",
        label: "Treatment 1"
      })
    ]
  };
};

const formatChartConfig = (chartConfig, formatMessage) => {
  if (_.isFunction(formatMessage)) {
    return _.map(chartConfig, item => {
      let newConfig = [];
      const { datasets, labels } = item;

      _.map(datasets, item => {
        const { label, ...other } = item;

        newConfig.push({
          label: formatMessage({ id: label }),
          ...other
        });
      });
      return { labels: labels, datasets: newConfig };
    });
  } else {
    return chartConfig;
  }
};

export {
  getDefaultDrug,
  getDefaultTreatment,
  getDefaultProposedTreatment,
  getDefaultDashboard,
  // getTreatmentItem,
  formatChartConfig
};
