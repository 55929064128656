import React, { Component } from "react";
import ModelContent from "../../../../components/modelContent";
import { compartmentsContent } from "./compartments";
import { biomarkerContent } from "./biomarkers";
import { externalContent } from "./externalVariables";
import { componentsContent } from "./components";
import { biologicalContent } from "./biologicalProcesses";
import { frequentlyAskedContent } from "./frequentlyAskedQuestions";
import compartments from "../../../../../assets/compartments.png";
import biomarkers from "../../../../../assets/biomarkers.png";
import external from "../../../../../assets/external.png";
import components from "../../../../../assets/components.png";
import biological from "../../../../../assets/biological.png";
import { injectIntl, intlShape } from "react-intl";
class ModelContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="model-container">
        <div className="header">{formatMessage({ id: "model.container.header" })}</div>
        <div className="header-divder" />
        <div className="model-content">
          <ModelContent
            titleImgUrl={compartments}
            title={formatMessage({ id: "model.content.compartments.title" })}
          >
            {compartmentsContent(formatMessage)}
          </ModelContent>
          <ModelContent
            titleImgUrl={biomarkers}
            title={formatMessage({ id: "model.content.biomarkers.title" })}
          >
            {biomarkerContent(formatMessage)}
          </ModelContent>
          <ModelContent
            titleImgUrl={external}
            title={formatMessage({ id: "model.content.external.title" })}
          >
            {externalContent(formatMessage)}
          </ModelContent>
          <ModelContent
            titleImgUrl={components}
            title={formatMessage({ id: "model.content.components.title" })}
          >
            {componentsContent(formatMessage)}
          </ModelContent>
          <ModelContent
            titleImgUrl={biological}
            title={formatMessage({ id: "model.content.biological.title" })}
          >
            {biologicalContent(formatMessage)}
          </ModelContent>
          <ModelContent
            title={formatMessage({ id: "model.content.frequently.asked.content.title" })}
          >
            {frequentlyAskedContent(formatMessage)}
          </ModelContent>
          <div className="model-divder" />
          <div className="reference">
            <div className="reserence-title">{formatMessage({ id: "model.reference.title" })}</div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content1" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content2" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content3" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content4" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content5" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content6" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content7" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content8" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content9" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content10" })}
            </div>
            <div className="reference-content">
              {formatMessage({ id: "model.reference.content11" })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModelContainer.defaultProps = {};

ModelContainer.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ModelContainer);
