import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import StepConnector from "@material-ui/core/StepConnector";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 67,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#D01A1B"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#D01A1B"
    }
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#9E9E9E",
    display: "flex",
    height: 22,
    alignItems: "center",
    fontSize: 18
  },
  active: {
    color: "#D01A1B",
    fontSize: 20,
  },
  circle: {
    fontSize: 22,
    color: "#9E9E9E"
  },
  completed: {
    // color: "#784af4",
    // zIndex: 1,
    // fontSize: 18
    color: "#D0021B",
    fontSize: 20
  }
});

function getStepStatusIcon(props, classes){
  const { active, completed } = props;

  if(completed) {
    return <RadioButtonUncheckedIcon className={classes.completed} />;
  }
  else if(active) {
    return <FiberManualRecordIcon className={classes.active} />;
  }
  else {
    return <RadioButtonUncheckedIcon className={classes.circle} />;
  }
}

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {getStepStatusIcon(props, classes)}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default function StepperProgress(props){
  const classes = useStyles();
  const {activeStep, steps} = props;
  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((obj, key) => (
          <Step key={key}>
            <StepLabel classes={{root: "flex-column-reverse", labelContainer: "stepper-label-container"}}StepIconComponent={QontoStepIcon}>{obj.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

StepperProgress.defaultProps = {
  steps: [],
  activeStep: ""
};

StepperProgress.propTypes = {
  classes: PropTypes.object,
  steps: PropTypes.array,
  activeStep: PropTypes.number
};

// export default StepperProgress;
