import React, { Component } from "react";
import PropTypes from "prop-types";
import AppHeader from "./appHeader";
import AppSubHeader from "./appSubHeader";
import { Route, Switch } from "react-router-dom";
import {Dialog, DialogTitle, DialogActions, Button} from "@material-ui/core";
import { connect } from "react-redux";
import { getRoutes, getPersonaSubRoutes } from "./routes";
import { Redirect } from "react-router";
import _ from "lodash";
import classnames from "classnames";
import NewPersona from "../modules/persona/newPersona/newPersona";
import CustomPatient from "../modules/persona/custom";
import PersonaMain from "../modules/persona/main";
// import { getDefaultPersona } from "../model";
import "../../styles/common.scss";
import "../../styles/components/index.scss";
import commonActions from "../flux/action/dashboard";
import actions from "../flux/action";
import { injectIntl, intlShape } from "react-intl";

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.checkForPersonSubroute = this.checkForPersonSubroute.bind(this);
  }

  checkForPersonSubroute(){
    const pathArr = this.getCurrentRoutePathArray();
    if(pathArr[1] === "persona"){
      this.setState({
        selectedPersonaTab: pathArr[2]
      });
    }
  }

  getCurrentRoute(routes) {
    const { location } = this.props;
    const { pathname = "" } = location;
    const pathArr = pathname.split("/");
    const subPathName = pathArr.length ? pathArr[2] : "";

    const currentRoute = _.find(routes, {
      path: subPathName
    });
    return currentRoute;
  }

  getCurrentRoutePathArray() {
    const { location } = this.props;
    const { pathname = "" } = location;
    const pathArr = pathname.split("/");
    const absPathArr = pathArr.slice(1,pathArr.length);
    return absPathArr;
  }

  getSubHeaderLabel() {
    const pathArr = this.getCurrentRoutePathArray();

    if(pathArr.length > 2){
      return pathArr[2];
    } else {
      return "presetPersona";
    }
  }

  getMainRouteLabel(routes) {
    const currentRoute = this.getCurrentRoute(routes);
    const { path } = currentRoute || routes[0];
    return path;
  }

  hasSidebar(routes) {
    const currentRoute = this.getCurrentRoute(routes);
    return currentRoute && !!currentRoute.sidebar;
  }

  backToLogin(){
    const {history} = this.props;
    this.props.clear();

    history.push("/login");
  }

  getSessionExpiredDialog(){
    const {isSessionExpired} = this.props;
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <Dialog
        open={!!isSessionExpired}
        aria-labelledby="expired-dialog-title"
        aria-describedby="expired-dialog-title-description"
      >
        <DialogTitle id="expired-dialog-title" className="expired-dialog-title-style">
          {formatMessage({
            id: "common.session.expired.error"
          })}
          {/* Your session is expired, please login again */}
        </DialogTitle>
        <DialogActions className="expired-dialog stepper-buttons">
          <Button
            type="button"
            color="primary"
            classes={{
              root: "stepper-back-button"
            }}
            onClick={this.backToLogin.bind(this)}
          >
            {formatMessage({
              id: "common.back.login.button.desc"
            })}
            {/* Back To Login */}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  getRouteMainContent(routes, match){
    const pathArr = this.getCurrentRoutePathArray();
    // const {selectedPersonaTab} = this.state;
    return (
      <Switch>
        {_.map(routes, (route, i) => {
          const { path, main, disabled } = route;
          let mainRoute = main;
          if (disabled) return null;

          if(pathArr[1] === "persona" && pathArr[2] === "newPatient"){
            mainRoute = NewPersona;
          } else if(pathArr[1] === "persona" && pathArr[2] === "customPatients"){
            mainRoute = CustomPatient;
          } else if(pathArr[1] === "persona" && pathArr[2] === "presetPersona"){
            mainRoute = PersonaMain;
          }
          return (
            <Route
              key={i}
              path={`${match.path}/${path}`}
              component={mainRoute}
            />
          );
        })}
        <Redirect path="*" to={`${match.path}/persona/presetPersona`} />
      </Switch>
    );
  }

  onChangePersonaHeader(event){
    const {resetNewPersona} = this.props;
    // let defaultNewPersona = getDefaultPersona();
    this.setState({
      selectedPersonaTab: event
    });
    if((this.state.selectedPersonaTab === "newPatient") && this.state.selectedPersonaTab === event){
      return;
    } else {
      resetNewPersona();
    }
  }

  getSidebar(){
    const pathArr = this.getCurrentRoutePathArray();
    const { match, isSelected, isAdmin, selectedPersona} = this.props;
    const routes = getRoutes(isSelected, isAdmin);
    if(pathArr[1] === "persona" && !selectedPersona){
      return null;
    } else if(pathArr[1] === "persona" && (pathArr[2] === "newPatient")){
      return null;
    }
    return (
      <div
        className={classnames({
          "app-sidebar": true,
          "dashboard": (pathArr[1] === "dashboard"),
          hidden: !this.hasSidebar(routes)
        })}
      >
        {_.map(routes, (route, i) => {
          const { path, sidebar, disabled } = route;

          if (disabled) return null;

          return (
            <Route
              key={i}
              path={`${match.path}/${path}`}
              component={sidebar}
            />
          );
        })}
      </div>
    );
  }

  getPersonaSubheaderContent(){
    const pathArr = this.getCurrentRoutePathArray();
    if(pathArr[1] !== "persona"){
      return null;
    }
    const { match, history} = this.props;
    const subroutes = getPersonaSubRoutes();
    return (
      <AppSubHeader
        subroutes={subroutes}
        basePath={match.path}
        history={history}
        label={this.getSubHeaderLabel()}
        onChange={this.onChangePersonaHeader.bind(this)}
      />
    );
  }

  render() {
    const { match, history, isLoading, isSelected, isAdmin, practiceMode} = this.props;
    const routes = getRoutes(isSelected, isAdmin);
    return (
      <div
        className={classnames({
          "app-container": true,
          "app-filter": isLoading
        })}
      >
        {this.getSessionExpiredDialog()}
        <AppHeader
          practiceMode={practiceMode}
          routes={routes}
          basePath={match.path}
          history={history}
          label={this.getMainRouteLabel(routes)}
        />
        <div className="app-body">
          <div className="app-content">
            {this.getPersonaSubheaderContent()}
            {/* <Switch>
              {_.map(routes, (route, i) => {
                const { path, main, disabled } = route;

                if (disabled) return null;

                return (
                  <Route
                    key={i}
                    path={`${match.path}/${path}`}
                    component={main}
                  />
                );
              })}
              <Redirect path="*" to={`${match.path}/persona`} />
            </Switch> */}
            {this.getRouteMainContent(routes, match)}
          </div>
          {this.getSidebar()}
        </div>
        <Switch>
          <Redirect exact path={`${match.path}`} to={`${match.path}/persona`} />
        </Switch>
        {/* <AppFooter /> */}
      </div>
    );
  }
}

AppContainer.defaultProps = {};

AppContainer.propTypes = {
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSessionExpired: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  isAdmin: PropTypes.bool,
  practiceMode: PropTypes.bool,
  clear: PropTypes.func,
  selectedPersona: PropTypes.string,
  newPersonaState: PropTypes.object,
  resetNewPersona: PropTypes.func,
  intl: intlShape.isRequired
};

const mapStateToProps = state => {
  const {
    persona: personaState,
    dashboard: dashboardState,
    login: loginState,
    newPersona: newPersonaState
  } = state;
  const { isSelected } = personaState;
  const { isAdmin, practiceMode } = loginState;
  const { isSessionExpired } = dashboardState;
  return {
    isLoading: personaState.isLoading || dashboardState.isLoading,
    isSelected,
    isAdmin,
    practiceMode,
    isSessionExpired,
    selectedPersona: personaState.personaSelected || "",
    newPersonaState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => {
      dispatch(commonActions.clearAction());
    },
    resetNewPersona: options => {
      dispatch(actions.resetNewPersona(options));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer));

