import { connect } from "react-redux";
import PersonaMain from "./personaMain";
import action from "../../../flux/action";

const mapStateToProps = state => {
  const { persona, personas } = state;
  const { personaSelected } = persona;
  return {
    personaSelected, personas
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPersona: options => {
      dispatch(action.editPersona(options));
    },
    getPresetPersonas: () => {
      dispatch(action.getPresetPersonas());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonaMain);
