import { handleActions } from "redux-actions";
import action from "../action";
// import dState from "./defaultState";
import _ from "lodash";
import {
  // getInitItem,
  // getDefaultPatientInfo,
  // getDefaultDiseaseState,
  // getDefaultHistoricIntervention,
  // getDefaultResponderProfile,
  getDefaultPersona
} from "../../model";

const { actionTypes } = action;
const { CREATE_PERSONA, ADD_NEW_PERSONA, ADD_NEW_PERSONA_SUCCESS, ADD_NEW_PERSONA_FAILED, RESET_NEW_PERSONA } = actionTypes;

const defaultState = getDefaultPersona();

export default handleActions(
  {
    [CREATE_PERSONA]: (state, action) => {
      const { payload } = action;

      return Object.assign({}, state, payload);
    },
    [ADD_NEW_PERSONA]: state => {
      return Object.assign({}, state, {
        isLoading: true,
        showSaveDialog: false
        //forecasted: data,
      });
    },
    [ADD_NEW_PERSONA_SUCCESS]: (state, action) => {
      const { payload } = action;
      return Object.assign({}, state, {
        isLoading: false
      }, payload);
    },
    [ADD_NEW_PERSONA_FAILED]: state => {
      return Object.assign({}, state, {
        isLoading: false
      });
    },
    [RESET_NEW_PERSONA]: () => {
      return _.defaultsDeep({}, defaultState);
    },
  },
  defaultState
);
