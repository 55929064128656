import React, { Component } from "react";
import PropTypes from "prop-types";
// import { EnhancedSlider } from "ui-toolkit";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import { Slider, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "#EE2019",
    fontSize: 12,
    letterSpacing: "-0.29px",
    margin: "9px 0"
  },
}))(Tooltip);
const CustomSlider = withStyles({
  root: {
    color: "#ee1100",
    height: 7,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#EE2019",
    border: "2px solid #fff",
  },
  active: {},
  track: {
    height: 7,
    borderRadius: 4,
  },
  rail: {
    opacity: 1,
    backgroundColor: "#E0E0E0",
    height: 7,
    borderRadius: 4,
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  let valueNew = value ? children.props["aria-valuetext"] : "";

  return (
    <CustomTooltip open={open} enterTouchDelay={0} placement="bottom" title={valueNew}>
      {children}
    </CustomTooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
class DurationSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marks: [],
      currentValue: 0
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { marks } = state;
    const { week, treatmentMonth } = props;

    if (marks.length !== week.length) {
      marks = [];
      for (let i = 0; i <= week.length - 1; i++) {
        marks.push({
          //label: i,
          value: i
        });
      }
    }
    const currentValue = treatmentMonth;
    return {
      marks,
      currentValue
    };
  }

  handleChange(event, value) {
    console.log(event);
    console.log(value);
    this.setState({
      currentValue: value
    });
    const { editDashboard } = this.props;

    editDashboard({
      "treatmentMonth": value
    });
  }

  getValuetext(formatMessage, value){
    return `${value} ` + formatMessage({id: "persona.options.frequency.weeks"});
  }

  getDurationSlider() {
    const { week, intl } = this.props;
    const { currentValue } = this.state;
    const { formatMessage } = intl;
    if (_.isEmpty(week)) {
      return null;
    } else {
      return (
        <div className="duration-slider">
          <CustomSlider
            min={0}
            max={week.length - 1}
            value={currentValue}
            getAriaValueText={this.getValuetext.bind(this, formatMessage)}
            onChange={(e, value)=> this.handleChange(e, value)}
            valueLabelDisplay="on"
            ValueLabelComponent={ValueLabelComponent}
          />
          <div className="mark-value">
            <span className="startNum">0</span>
            <span className="startNum">{week.length - 1} {formatMessage({id: "persona.options.frequency.weeks"})}</span>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="durtion-slider-container">{this.getDurationSlider()}</div>
    );
  }
}

DurationSlider.defaultProps = {
  week: []
};

DurationSlider.propTypes = {
  week: PropTypes.array,
  treatmentMonth: PropTypes.number,
  editDashboard: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(DurationSlider, { withRef: true });
