import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import action from "../../flux/action";
import LanguageSelect from "../../components/languageSelect";
import { languages } from "../../model";
import { naviTo } from "../../utils";
import { injectIntl, intlShape } from "react-intl";
import Takeda from "../../../assets/takeda_logo.svg";
import "../../../styles/login/login.scss";
import signup from "../../../assets/new_signup.jpg";

class EmailVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      password: "",
      verifiedCode: false,
      verifyErrorMessage: "",
      confirmResetPass: false
    };
  }

  componentDidMount() {
    const { verifyEmail, oobCode } = this.props;
    verifyEmail({
      oobCode: oobCode
    });
  }

  goToLogin(e) {
    e.preventDefault();
    naviTo("/login");
  }

  getContent() {
    const { isEmailVerified, verifyErrorMessage, intl } = this.props;
    const { formatMessage } = intl;
    if (verifyErrorMessage) {
      return <p className="error-text">{verifyErrorMessage}</p>;
    } else if (isEmailVerified) {
      return (
        <div className="content">
          <Fragment>
            <div className="account-verify">
              {formatMessage({ id: "email.verify.account.verify" })}
            </div>
            <p className="info-text">
              {formatMessage({ id: "email.verify.info.text" })}
            </p>
            <div className="buttons">
              <Button
                classes={{
                  root: "sign-in-button"
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {formatMessage({ id: "email.verify.button.sign.in" })}
              </Button>
            </div>
          </Fragment>
        </div>
      );
    }
  }

  handleLanguageChange(language) {
    const { changeLanguage } = this.props;

    changeLanguage(language);
  }

  render() {
    const { language } = this.props;
    // const { formatMessage } = intl;
    return (
      <div className="login-container">
        <div className="left-card">
          <form onSubmit={this.goToLogin.bind(this)}>
            <div className="emailVerify-container">
              <div className="header-title">
                <img src={Takeda} />
                {/* <span>{formatMessage({ id: "login.header.title" })}</span> */}
                <LanguageSelect
                  config={languages}
                  value={language}
                  onChange={this.handleLanguageChange.bind(this)}
                />
              </div>
              {this.getContent()}
            </div>
          </form>
        </div>
        <div className="right-card">
          <img src={signup} />
        </div>
      </div>
    );
  }
}

EmailVerify.defaultProps = {
  oobCode: ""
};

EmailVerify.propTypes = {
  oobCode: PropTypes.string,
  verifyEmail: PropTypes.func,
  isEmailVerified: PropTypes.bool,
  verifyErrorMessage: PropTypes.string,
  intl: intlShape.isRequired,
  language: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => {
  const { login, common } = state;
  const { verifyErrorMessage, isEmailVerified } = login;
  const { language } = common;
  return { language, verifyErrorMessage, isEmailVerified };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyEmail: options => {
      dispatch(action.verifyEmail(options));
    },
    changeLanguage: language => {
      dispatch(action.changeLanguage(language));
    }
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      forwardRef: true
    }
  )(EmailVerify),
  { withRef: true }
);
