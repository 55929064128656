import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import actions from "../../../flux/action";
import Section from "../../../components/section";
import biologicTreatmentStatusIcon from "../../../../assets/icons/ico_biologic_treatment_status.png";
import steroidIcon from "../../../../assets/icons/ico_steroid.png";
import immunomodulatorIcon from "../../../../assets/icons/ico_immunomodulator.png";
import treatmentHistoryIcon from "../../../../assets/icons/ico_treatment_history.png";
// import {Button} from "@material-ui/core";
import {
  RadioGroup, SelectMenu, CommonInput
} from "ui-toolkit";
import FiledLabel from "../../../components/fieldLabel";
import {
  yesNoOptions,
  biologicTreatmentStatusOptions,
  localOptionsIntl,
  getDefaultPharmacology
} from "../../../model";
import { FormControlLabel, Radio, FormControl, RadioGroup as RadioGroupCore } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {antiTnfInfliximabOptions} from "../../../model/detailOptions";
import StepperButtons from "./stepperButtons";
// import { pharmacologyConfig } from "../../../model";
// import {
//   biologicTreatmentStatus
// } from "../../../model/selectOptions";
//import {injectIntl, intlShape} from "react-intl";
//import {changeLanguageAction} from "../flux/action";

const CustomRadio = withStyles({
  root: {
    "&$checked": {
      color: "#ee1100",
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);
class TreatmentHistory extends Component {
  constructor(props, context) {
    super(props, context);
    this.inputRefs = {
      steroids: {
        duration : React.createRef(),
        regime: React.createRef(),
      },
      immunomodulator: {
        duration : React.createRef(),
        regime: React.createRef(),
      },
      "anti-tnf": {
        duration : React.createRef(),
        regime: React.createRef(),
      },
      "anti-il": {
        duration : React.createRef(),
        regime: React.createRef(),
      },
      "anti-integrin": {
        duration : React.createRef(),
        regime: React.createRef(),
      }
    };
    this.historicIntervention = _.cloneDeep(props.historicIntervention);
    this.responderProfile = _.cloneDeep(props.responderProfile);
    this.historicIntervention["biologyTreatmentStatus"] = !_.isEmpty(this.historicIntervention["biologyTreatmentStatus"]) ? this.historicIntervention["biologyTreatmentStatus"] : "biologic-naive";
    this.state = {
      treatmentStatus: [
        {value: "Biologic naive", label: "Biologic naive"},
        {value: "Previous biologic treatment", label: "Previous biologic treatment"}
      ]
    };
  }

  componentDidUpdate() {
    this.updateResponderProfile();
  }

  updateResponderProfile(){
    const {historicIntervention, responderProfile, createPersona} = this.props;
    this.responderProfile = _.cloneDeep(responderProfile);
    let allkeys = Object.keys(responderProfile);
    let keys = allkeys.slice(0,5);
    _.forEach(keys, type=>{
      let drugType = (type == "steroid") ? "steroids" : type;
      if((type == "steroid" && this.responderProfile["isSteroidModified"]) ||
        (type == "immunomodulator" && this.responderProfile["isImmunoModified"]) ||
        (type == "anti-tnf" && this.responderProfile["isAntiTNFModified"]) ||
        (type == "anti-integrin" && this.responderProfile["isAntiIntegrinModified"]) ||
        (type == "anti-il" && this.responderProfile["isAntiILModified"])
      ){
        return;
      }
      if(historicIntervention[drugType]["checked"]){
        if((historicIntervention[drugType]["initialResponse"] == "yes" && historicIntervention[drugType]["lossOfResponse"] == "yes") ||
        (historicIntervention[drugType]["initialResponse"] == "no")){
          this.responderProfile[type] = "non-responder";
        } else if(historicIntervention[drugType]["initialResponse"] == "yes" && historicIntervention[drugType]["lossOfResponse"] == "no"){
          this.responderProfile[type] = "responder";
        } else {
          this.responderProfile[type] = "non-responder";
        }
      } else {
        this.responderProfile[type] = "responder";
      }
      this.responderProfile.completed = true;
      if(this.responderProfile[type] !== responderProfile[type]){
        createPersona({
          responderProfile: Object.assign({}, responderProfile, this.responderProfile)
        });
      }
    });
  }

  checkIfCompleted(){
    const {historicIntervention, createPersona} = this.props;
    this.historicIntervention.completed = historicIntervention.completed;

    if(historicIntervention["steroids"]["checked"]){
      if( !_.isEmpty(historicIntervention["steroids"]["initialResponse"]) &&
        !_.isEmpty(historicIntervention["steroids"]["lossOfResponse"]) &&
        historicIntervention["steroids"]["duractionTreatment"]){
        this.historicIntervention.completed = true;
      } else {
        this.historicIntervention.completed = false;
      }
    }

    if(historicIntervention["immunomodulator"]["checked"]){
      if( !_.isEmpty(historicIntervention["immunomodulator"]["initialResponse"]) &&
        !_.isEmpty(historicIntervention["immunomodulator"]["lossOfResponse"]) &&
        historicIntervention["immunomodulator"]["duractionTreatment"]){
        this.historicIntervention.completed = true;
      } else {
        this.historicIntervention.completed = false;
      }
    }

    if(historicIntervention["anti-tnf"]["checked"]){
      if( !_.isEmpty(historicIntervention["anti-tnf"]["initialResponse"]) &&
        !_.isEmpty(historicIntervention["anti-tnf"]["lossOfResponse"]) &&
        historicIntervention["anti-tnf"]["duractionTreatment"] &&
        !_.isEmpty(historicIntervention["anti-tnf"]["regime"])){
        this.historicIntervention.completed = true;
      } else {
        this.historicIntervention.completed = false;
      }
    }

    if(historicIntervention["anti-il"]["checked"]){
      if( !_.isEmpty(historicIntervention["anti-il"]["initialResponse"]) &&
        !_.isEmpty(historicIntervention["anti-il"]["lossOfResponse"]) &&
        historicIntervention["anti-il"]["duractionTreatment"] &&
        !_.isEmpty(historicIntervention["anti-il"]["regime"])){
        this.historicIntervention.completed = true;
      } else {
        this.historicIntervention.completed = false;
      }
    }

    if(historicIntervention["anti-integrin"]["checked"]){
      if( !_.isEmpty(historicIntervention["anti-integrin"]["initialResponse"]) &&
        !_.isEmpty(historicIntervention["anti-integrin"]["lossOfResponse"]) &&
        historicIntervention["anti-integrin"]["duractionTreatment"] &&
        !_.isEmpty(historicIntervention["anti-integrin"]["regime"])){
        this.historicIntervention.completed = true;
      } else {
        this.historicIntervention.completed = false;
      }
    }
    if(this.historicIntervention.completed == historicIntervention.completed){
      return;
    }
    createPersona({
      historicIntervention: Object.assign({}, historicIntervention, this.historicIntervention)
    });
  }

  // nextStepperProgress(e, value) {
  //   const {onChange, activeStep} = this.props;
  //   this.setState({
  //     activeStep: value
  //   });
  //   const nextActiveStep = (activeStep == 3) ? 3 : activeStep + 1;
  //   this.updateResponderProfile();
  //   onChange(nextActiveStep, "next");
  // }

  // prevStepperProgress(e, value) {
  //   const {onChange, activeStep} = this.props;
  //   this.setState({
  //     activeStep: value
  //   });
  //   const prevActiveStep = activeStep ? activeStep - 1 : 0;
  //   onChange(prevActiveStep, "back");
  // }

  handleChange(type, name, event) {
    const {historicIntervention, createPersona} = this.props;
    this.historicIntervention = _.cloneDeep(historicIntervention);
    if(name == "biologyTreatmentStatus"){
      this.historicIntervention["biologyTreatmentStatus"] = event.currentTarget.value;
      if(event.currentTarget.value == "biologic-naive"){
        this.historicIntervention["anti-tnf"] = getDefaultPharmacology();
        this.historicIntervention["anti-il"] = getDefaultPharmacology();
        this.historicIntervention["anti-integrin"] = getDefaultPharmacology();
      } else {
        this.historicIntervention["steroids"] = getDefaultPharmacology();
        this.historicIntervention["immunomodulator"] = getDefaultPharmacology();
      }
    } else if(name == "lossOfResponse") {
      this.historicIntervention[type][name] = event.currentTarget.value;
    } else {
      this.historicIntervention[type][name] = event;
      if(this.historicIntervention[type]["checked"] && this.historicIntervention[type]["initialResponse"] == "no"){
        this.historicIntervention[type]["lossOfResponse"] = "";
      }
    }
    createPersona({
      historicIntervention: Object.assign({}, historicIntervention, this.historicIntervention)
    });
  }

  onSwitchChange(type, name, event){
    const {historicIntervention, createPersona} = this.props;
    this.historicIntervention[type] = getDefaultPharmacology();
    this.historicIntervention[type][name] = event;
    //Set default duration and regime
    if((type == "steroids" && this.historicIntervention["steroids"]["checked"]) || (type == "immunomodulator" && this.historicIntervention["immunomodulator"]["checked"])){
      this.historicIntervention[type]["duractionTreatment"] = 52;
    } else if(
      (type == "anti-tnf" && this.historicIntervention["anti-tnf"]["checked"]) ||
      (type == "anti-il" && this.historicIntervention["anti-il"]["checked"]) ||
      (type == "anti-integrin" && this.historicIntervention["anti-integrin"]["checked"])){
      this.historicIntervention[type]["duractionTreatment"] = 104;
      this.historicIntervention[type]["regime"] = "induction + maintenance";
    } else {
      this.historicIntervention[type]["duractionTreatment"] = "";
      this.historicIntervention[type]["regime"] = "";
    }
    createPersona({
      historicIntervention: Object.assign({}, historicIntervention, this.historicIntervention)
    });
  }

  isDiabled(type){
    const { historicIntervention, createPersona } = this.props;
    this.historicIntervention = _.cloneDeep(historicIntervention);
    if(this.historicIntervention[type]["checked"] && this.historicIntervention[type]["initialResponse"] == "no"){
      this.historicIntervention[type]["lossOfResponse"] = "";
      createPersona({
        historicIntervention: Object.assign({}, historicIntervention, this.historicIntervention)
      });
      return true;
    } else {
      return false;
    }
    // return section["checked"] && section["lossOfResponse"] == "no" ? true : false;
  }

  getContentBySection(type){
    const { intl, historicIntervention } = this.props;
    const section = historicIntervention[type];
    const { formatMessage } = intl;
    const [yesNoOptionsIntl, antiTnfInfliximabOptionsIntl] = localOptionsIntl(
      [yesNoOptions, antiTnfInfliximabOptions],
      formatMessage
    );
    if(section["checked"]){
      return (
        <div className="section-content">
          <div className="row-align">
            <FiledLabel label={formatMessage({id: "persona.sidebar.historicIntervention.initialResponse"})} className="width-40" labelClass="text-uppercase font-size-14 radio-option-captitalize">
              <RadioGroup className="flex-row"
                config={yesNoOptionsIntl}
                value={section["initialResponse"]}
                onChange={this.handleChange.bind(this, type, "initialResponse")}
              />
            </FiledLabel>
            <FiledLabel label={formatMessage({id: "persona.sidebar.historicIntervention.lossOfResponse"})} className="width-40" labelClass="text-uppercase font-size-14 radio-option-captitalize">
              {/* <RadioGroup className="flex-row"
                config={yesNoOptionsIntl}
                value={section["lossOfResponse"]}
                disabled={this.isDiabled(section)}
                onChange={this.handleChange.bind(this, type, "lossOfResponse")}
              /> */}
              <FormControl component="fieldset" className="flex-row">
                <RadioGroupCore name="lossOfResponse" value={section["lossOfResponse"]} onChange={this.handleChange.bind(this, type, "lossOfResponse")} row>
                  {_.map(yesNoOptionsIntl, (obj, i) => {
                    return (
                      <FormControlLabel classes={{label: "font-weight-600"}} key={i} disabled={section["initialResponse"] == "no" || type === "steroids" || type === "immunomodulator"} value={obj.value} checked={obj.value == section["lossOfResponse"]} control={<CustomRadio />} label={obj.label} />
                    );
                  })}
                </RadioGroupCore>
              </FormControl>
            </FiledLabel>
          </div>
          <div className="row-align">
            <FiledLabel
              label={formatMessage({
                id: "persona.sidebar.historicIntervention.duractionTreatment"
              })}
              className="width-40" labelClass="text-uppercase font-size-14"
            >
              {/* <SelectMenu classes={{root: "width-70"}}
                ref={this.inputRefs.steroidDuration}
                value={section["duractionTreatment"]}
                config={durationList}
                onSelect={this.handleChange.bind(this, type, "duractionTreatment")}
              /> */}
              <CommonInput classes={{root: "width-70 pt-10"}}
                onChange={this.handleChange.bind(this, type, "duractionTreatment")}
                key={type}
                ref={this.inputRefs[type]["duration"]}
                value={section["duractionTreatment"]}
              />
            </FiledLabel>
            <FiledLabel
              label={formatMessage({id: "persona.sidebar.proposedTreatment.regime"})}
              className="width-40" labelClass="text-uppercase font-size-14"
            >
              {/* <SelectMenu classes={{root: "width-70", hidden: (type == "steroids" || type === "immunomodulator")}}
                ref={this.inputRefs[type]["regime"]}
                value={section["regime"]}
                config={antiTnfInfliximabOptionsIntl}
                onSelect={this.handleChange.bind(this, type, "regime")}
              /> */}
              {this.getResimeContent(section, type, antiTnfInfliximabOptionsIntl)}
            </FiledLabel>
          </div>
        </div>
      );
    }
  }

  getResimeContent(section, type, option){
    if(type == "steroids" || type === "immunomodulator"){
      return (
        <SelectMenu classes={{root: "width-70 pt-10"}}
          style={{"visibility": "hidden"}}
          ref={this.inputRefs[type]["regime"]}
          value={section["regime"]}
          config={option}
          onSelect={this.handleChange.bind(this, type, "regime")}
        />
      );
    } else {
      return (
        <SelectMenu classes={{root: "width-70 pt-10"}}
          ref={this.inputRefs[type]["regime"]}
          value={section["regime"]}
          config={option}
          onSelect={this.handleChange.bind(this, type, "regime")}
        />
      );
    }
  }

  getContentByTreatmentStatus(){
    const {intl} = this.props;
    const { formatMessage } = intl;
    const iconClass = "persona-section-icon";
    const {historicIntervention} = this.props;
    if(historicIntervention["biologyTreatmentStatus"] == "biologic-naive"){
      return(
        <Fragment>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.steroids"})}
            icon={steroidIcon}
            iconClass={iconClass}
            isOpen={historicIntervention["steroids"]["checked"]}
            onSwitchChange={this.onSwitchChange.bind(this, "steroids", "checked")}
            showSwitch={true}
          />
          {this.getContentBySection("steroids")}
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.immunomodulator.noex"})}
            icon={immunomodulatorIcon}
            iconClass={iconClass}
            isOpen={historicIntervention["immunomodulator"]["checked"]}
            onSwitchChange={this.onSwitchChange.bind(this, "immunomodulator", "checked")}
            showSwitch={true}
          />
          {this.getContentBySection("immunomodulator")}
        </Fragment>
      );
    } else if(historicIntervention["biologyTreatmentStatus"] == "previous-biologic-treatment"){
      return(
        <Fragment>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.anti-tnf.noex"})}
            icon={treatmentHistoryIcon}
            iconClass={iconClass}
            isOpen={historicIntervention["anti-tnf"]["checked"]}
            onSwitchChange={this.onSwitchChange.bind(this, "anti-tnf", "checked")}
            showSwitch={true}
          />
          {this.getContentBySection("anti-tnf")}
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.anti-integrin.noex"})}
            icon={treatmentHistoryIcon}
            iconClass={iconClass}
            isOpen={historicIntervention["anti-integrin"]["checked"]}
            onSwitchChange={this.onSwitchChange.bind(this, "anti-integrin", "checked")}
            showSwitch={true}
          />
          {this.getContentBySection("anti-integrin")}
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.treatment.anti-il.noex"})}
            icon={treatmentHistoryIcon}
            iconClass={iconClass}
            isOpen={historicIntervention["anti-il"]["checked"]}
            onSwitchChange={this.onSwitchChange.bind(this, "anti-il", "checked")}
            showSwitch={true}
          />
          {this.getContentBySection("anti-il")}
        </Fragment>
      );
    }
  }

  render() {
    const iconClass = "persona-section-icon";
    const { intl, historicIntervention } = this.props;
    const {biologyTreatmentStatus} = historicIntervention;
    const { formatMessage } = intl;
    // const {selectedTreatmentStatus, treatmentStatus} = this.state;
    const [biologicTreatmentStatusOptionsIntl] = localOptionsIntl(
      [biologicTreatmentStatusOptions],
      formatMessage
    );
    return (
      <div className="persona-container">
        <div className="header">
          <div className="header-title">{formatMessage({id: "persona.sidebar.historicIntervention.treatment.history"})}</div>
          <StepperButtons onNextStepChange={this.updateResponderProfile.bind(this)}/>
        </div>
        <div className="persona-content treatment-history">
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.historicIntervention.biologic.treatment.status"})}
            icon={biologicTreatmentStatusIcon}
            iconClass={iconClass}
          />
          <div className="section-content">
            <FormControl component="fieldset">
              <RadioGroupCore aria-label="Biologic Treatment Status" name="biologicTreatmentStatus" value={biologyTreatmentStatus} onChange={this.handleChange.bind(this, "", "biologyTreatmentStatus")} row>
                {_.map(biologicTreatmentStatusOptionsIntl, (obj, i) => {
                  return (
                    <FormControlLabel classes={{label: "font-weight-600"}} key={i} value={obj.value} control={<CustomRadio />} label={obj.label} />
                  );
                })}
              </RadioGroupCore>
            </FormControl>
          </div>
          {this.getContentByTreatmentStatus()}
        </div>
      </div>
    );
  }
}

TreatmentHistory.defaultProps = {
  subroutes: [],
  basePath: "",
  history: {},
  label: ""
};

TreatmentHistory.propTypes = {
  classes: PropTypes.object,
  subroutes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  createPersona: PropTypes.func,
  historicIntervention: PropTypes.object,
  responderProfile: PropTypes.object
};

const mapStateToProps = (state) => {
  const {newPersona: personaState} = state;
  const {historicIntervention, responderProfile} = personaState;

  return {
    historicIntervention, responderProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    createPersona: (persona) => {
      dispatch(actions.createPersona(persona));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(TreatmentHistory));
