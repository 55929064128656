import React, {Component} from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
// import { SwitchExpansionPanel } from "ui-toolkit";
// import Icon from "@material-ui/core/Icon";

const CustomSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#ee1100",
    },
    "&$checked + $track": {
      backgroundColor: "#ee1100",
    },
  },
  checked: {},
  track: {},
})(Switch);
class Section extends Component {
  constructor(props){
    super(props);
    this.state ={
      switchChecked: true
    };
  }

  handleSwitchChange(event){
    const {onSwitchChange} = this.props;
    const newStatus = event.target.checked;
    onSwitchChange(newStatus);
  }

  showSwitchElement(){
    const {isOpen, showSwitch} = this.props;
    if(showSwitch){
      return (
        <CustomSwitch
          checked={isOpen}
          onChange={this.handleSwitchChange.bind(this)}
          name="checkedA"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      );
    }
  }

  // handleAction(){
  //   const {onAction} = this.props;
  //   onAction();
  // }
  // handleReset(){
  //   const {onReset} = this.props;
  //   onReset();
  // }
  render(){
    const {title, icon, iconClass} = this.props;

    return (
      <div className="section">
        <div className="align-items-center">
          <img className={iconClass} src={icon}/>
          <div className="section-title">
            {title}
          </div>
        </div>
        {this.showSwitchElement()}
      </div>
    );
  }
}

Section.defaultProps = {
  title: "",
  icon: "",
  iconClass: ""
};

Section.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  isOpen: PropTypes.bool,
  onSwitchChange: PropTypes.func,
  showSwitch: PropTypes.bool
};

export default Section;