import {createMuiTheme} from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Helvetica, Arial, sans-serif"
  },
  palette: {
    secondary: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#333"
    },
    primary: {
      light: "#ee1100",
      main: "#ee1100",
      dark: "#ee1100",
      contrastText: "#fff"
    }
  }
});

export default theme;
