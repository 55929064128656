import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import { CommonInput } from "ui-toolkit";
import FiledLabel from "../../../../components/fieldLabel";
import {
  //numberInputValidation,
  getRangeValidation,
  loadIntl
} from "../../../../model";

class Biomarkers extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      calprotectin: React.createRef(),
      // lactoferrin: React.createRef(),
      crp: React.createRef(),
      // il_6: React.createRef(),
      albumin: React.createRef()
      // lrg: React.createRef()
    };
  }

  handleChange(name, value) {
    const { data, onChange } = this.props;

    onChange(
      Object.assign({}, data, {
        [name]: value
      })
    );
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = ref && ref.current && ref.current.getStatus();

        return result && (status === "init" ? false : status);
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, ref => {
      ref && ref.current && ref.current.reset && ref.current.reset();
    });
  }

  render() {
    const { data, intl } = this.props;
    const { formatMessage } = intl;
    const {
      calprotectin,
      // lactoferrin,
      crp,
      // il_6,
      albumin
      // lrg
    } = data;
    const calprotectinValidation = getRangeValidation([0, 5000]);
    const crpValidation = getRangeValidation([0, 300], "float");
    const albuminValidation = getRangeValidation([8, 420],"float");
    const [
      //numberInputValidationIntl,
      calprotectinValidationIntl,
      crpValidationIntl,
      albuminValidationIntl
    ] = loadIntl(
      [calprotectinValidation, crpValidation, albuminValidation],
      formatMessage
    );

    return (
      <div className="biomarkers">
        <FiledLabel
          label={formatMessage({
            id: "persona.sidebar.diseaseState.biomarker.calprotectin"
          })}
        >
          <CommonInput
            ref={this.inputRefs.calprotectin}
            value={calprotectin}
            validation={calprotectinValidationIntl}
            onChange={this.handleChange.bind(this, "calprotectin")}
          />
        </FiledLabel>
        {/*<FiledLabel
          label={formatMessage({
            id: "persona.sidebar.diseaseState.biomarker.lactoferrin"
          })}
        >
          <CommonInput
            ref={this.inputRefs.lactoferrin}
            value={lactoferrin}
            validation={numberInputValidationIntl}
            onChange={this.handleChange.bind(this, "lactoferrin")}
          />
        </FiledLabel>*/}
        <FiledLabel
          label={formatMessage({
            id: "persona.sidebar.diseaseState.biomarker.crp"
          })}
        >
          <CommonInput
            ref={this.inputRefs.crp}
            value={crp}
            validation={crpValidationIntl}
            onChange={this.handleChange.bind(this, "crp")}
          />
        </FiledLabel>
        {/*<FiledLabel
          label={formatMessage({
            id: "persona.sidebar.diseaseState.biomarker.il_6"
          })}
        >
          <CommonInput
            ref={this.inputRefs.il_6}
            value={il_6}
            validation={numberInputValidationIntl}
            onChange={this.handleChange.bind(this, "il_6")}
          />
        </FiledLabel>*/}
        <FiledLabel
          label={formatMessage({
            id: "persona.sidebar.diseaseState.biomarker.albumin"
          })}
        >
          <CommonInput
            ref={this.inputRefs.albumin}
            value={albumin}
            validation={albuminValidationIntl}
            onChange={this.handleChange.bind(this, "albumin")}
          />
        </FiledLabel>
        {/*<FiledLabel
          label={formatMessage({
            id: "persona.sidebar.diseaseState.biomarker.lrg"
          })}
        >
          <CommonInput
            ref={this.inputRefs.lrg}
            value={lrg}
            validation={numberInputValidationIntl}
            onChange={this.handleChange.bind(this, "lrg")}
          />
        </FiledLabel>*/}
      </div>
    );
  }
}

Biomarkers.defaultProps = {
  data: {},
  onChange: () => {}
};

Biomarkers.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(Biomarkers, {
  withRef: true
});
