import { connect } from "react-redux";
import Physiology from "./physiology";
import action from "../../../../flux/action";

const mapStateToProps = state => {
  const { dashboard: dashboardState } = state;
  const { forecasted, plotConfig, treatmentMonth } = dashboardState;

  const { serumTNF, Th1, Th17, serumIL22, Macrophages,plasma_drug_concentration } = forecasted;

  return {
    data: {
      Th1,
      Th17,
      serumTNF,
      serumIL22,
      Macrophages,
      "serumIL12/IL23": forecasted["serumIL12/IL23"],
      plasma_drug_concentration,
    },
    plotConfig,
    treatmentMonth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editDashboard: options => {
      dispatch(action.editDashboard(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Physiology);
