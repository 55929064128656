import {connect} from "react-redux";
import PatientInfo from "./patientInfo";
import action from "../../../../flux/action";

const mapStateToProps = (state) => {
  const {persona: personaState} = state;
  const {patientInfo} = personaState;

  return {
    patientInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editPersona: (options) => {
      dispatch(action.editPersona(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(PatientInfo);
