import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import {
  CheckExpansionPanel
} from "ui-toolkit";
import CommonField from "../../../../components/commonField";
import FiledLabel from "../../../../components/fieldLabel";
import {
  pharmacologyConfig,
  loadIntl,
  localOptionsIntl,
  yesNoOptions
} from "../../../../model";
import classnames from "classnames";
import { FormControlLabel, Radio, FormControl, RadioGroup as RadioGroupCore } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomRadio = withStyles({
  root: {
    "&$checked": {
      color: "#ee1100",
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);
class Pharmacology extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }

  handleChange(name, event) {
    const { data, onChange } = this.props;
    if(name == "lossOfResponse"){
      onChange(
        Object.assign({}, data, {
          [name]: event.currentTarget.value
        })
      );
    } else {
      onChange(
        Object.assign({}, data, {
          [name]: event
        })
      );
    }
  }

  handleSwitchChange(name, value) {
    const { data, onChange } = this.props;

    onChange(
      Object.assign({}, data, {
        [name]: value
      })
    );
  }

  getStatus() {
    const { data } = this.props;
    const { checked } = data;

    if (checked) {
      return _.reduce(
        this.inputRefs,
        (result, ref) => {
          const status = ref.current.getStatus ? ref.current.getStatus() : true;

          return result && (status === "init" ? false : status);
        },
        true
      );
    } else {
      return true;
    }
  }

  reset() {
    // _.each(this.inputRefs, ref => {
    //   if(ref.current.reset){
    //     ref.current.reset();
    //   }
    // });
  }

  render() {
    this.inputRefs = {};
    const { name, label, data, intl, biologyTreatmentStatus, forceDisable } = this.props;
    const config = pharmacologyConfig[name] || [];
    const {
      checked
    } = data;
    const { formatMessage } = intl;
    const tooltipContent = [];
    tooltipContent.push(formatMessage({ id: "persona.sidebar.content.tooltip.detail1" }));
    tooltipContent.push(formatMessage({ id: "persona.sidebar.content.tooltip.detail2" }));
    tooltipContent.push(formatMessage({ id: "persona.sidebar.content.tooltip.detail3" }));
    let disabled = true;
    if(biologyTreatmentStatus !== "biologic-naive" && (name == "steroids" || name == "immunomodulator")){
      disabled = true;
    }
    else if(biologyTreatmentStatus !== "previous-biologic-treatment" && (name == "anti-tnf" || name == "anti-integrin" || name == "anti-il")){
      disabled = true;
    } else {
      disabled = false;
    }
    return (
      <div className="pharmacology">
        <CheckExpansionPanel
          color="primary"
          label={label}
          checked={checked}
          disabled={disabled}
          onChange={this.handleSwitchChange.bind(this, "checked")}
        >
          {
            _.map(config, (field, i) => {
              const {type, label, value, options, validation, hide, disable} = field;
              const [validationIntl] = loadIntl([validation], formatMessage);
              const [optionsIntl, yesNoOptionsIntl] = localOptionsIntl([options, yesNoOptions], formatMessage);
              this.inputRefs[value] = React.createRef();
              if(hide) return null;
              if(value == "lossOfResponse"){
                return (
                  <FiledLabel label={formatMessage({id: label})} labelClass="font-size-12">
                    <FormControl component="fieldset" className="flex-row">
                      <RadioGroupCore name="lossOfResponse" value={data[value]} onChange={this.handleChange.bind(this, value)} row>
                        {_.map(yesNoOptionsIntl, (obj, i) => {
                          return (
                            <FormControlLabel key={i} disabled={forceDisable || disable} value={obj.value} checked={obj.value == data[value]} control={<CustomRadio />} label={obj.label} />
                          );
                        })}
                      </RadioGroupCore>
                    </FormControl>
                  </FiledLabel>
                );
              } else {
                return (
                  <CommonField
                    className={classnames({
                      hidden: hide
                    })}
                    ref={this.inputRefs[value]}
                    key={`common-field${i}`}
                    type={type}
                    label={formatMessage({id: label})}
                    value={data[value]}
                    options={optionsIntl}
                    validation={validationIntl}
                    disabled={forceDisable}
                    onChange={this.handleChange.bind(this, value)}
                    tooltipContent={tooltipContent}
                  />
                );
              }
            })
          }
        </CheckExpansionPanel>
      </div>
    );
  }
}

Pharmacology.defaultProps = {
  name: "",
  label: "",
  data: {},
  onChange: () => {}
};

Pharmacology.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  onChange: PropTypes.func,
  intl: intlShape.isRequired,
  biologyTreatmentStatus: PropTypes.string,
  forceDisable: PropTypes.bool
};

export default injectIntl(Pharmacology, {
  withRef: true
});
