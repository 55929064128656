import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import {injectIntl, intlShape} from "react-intl";
import actions from "../../../flux/action";
import Section from "../../../components/section";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import diseaseDurationIcon from "../../../../assets/icons/ico_disease_duration.png";
import illeumIcon from "../../../../assets/icons/ico_ileum.png";
import colonIcon from "../../../../assets/icons/ico_colon.png";
// import {Button} from "@material-ui/core";
import {
  ulcerTypeOptions,
  localOptionsIntl,
  diseaseDuration,
  surfaceAreaWithUlcers,
  surfaceInvolved,
  getDefaultIntestine} from "../../../model";
import StepperButtons from "./stepperButtons";

class DiseaseState extends Component {
  constructor(props, context) {
    super(props, context);
    this.showSwitch = true;
    this.location = {colon : {checked: false, diseaseArea: "", penetrating: "yes", stenosis: "yes", ulcerArea: "", ulcerType: ""},
      ileum: {checked: false, diseaseArea: "", penetrating: "yes", stenosis: "yes", ulcerArea: "", ulcerType: ""}
    };
    const {diseaseState}= props;
    this.diseaseState = _.cloneDeep(diseaseState);
    this.state = {};
  }

  componentDidUpdate() {
    this.checkIfCompleted();
  }

  checkIfCompleted(){
    const {diseaseState, createPersona} = this.props;
    const {location} = diseaseState;
    const {ileum, colon} = location;
    const {diseaseHistory} = diseaseState;
    this.diseaseState = _.cloneDeep(diseaseState);
    this.diseaseState.completed = diseaseState.completed;

    if(_.isEmpty(diseaseHistory.diseaseDuraction)){
      this.diseaseState.completed = false;
    }

    if(!_.isEmpty(diseaseHistory.diseaseDuraction) && !ileum.checked && !colon.checked){
      this.diseaseState.completed = false;
    }

    if(ileum.checked){
      if( !_.isEmpty(diseaseHistory.diseaseDuraction) && !_.isEmpty(ileum.diseaseArea) && !_.isEmpty(ileum.ulcerArea) && !_.isEmpty(ileum.ulcerType)){
        this.diseaseState.completed = true;
      } else {
        this.diseaseState.completed = false;
      }
    }

    if(colon.checked){
      if( !_.isEmpty(diseaseHistory.diseaseDuraction) && !_.isEmpty(colon.diseaseArea) && !_.isEmpty(colon.ulcerArea) && !_.isEmpty(colon.ulcerType)){
        this.diseaseState.completed = true;
      } else {
        this.diseaseState.completed = false;
      }
    }

    if(this.diseaseState.completed == diseaseState.completed){
      return;
    }
    createPersona({
      diseaseState: Object.assign({}, diseaseState, this.diseaseState)
    });
  }

  handleOnChangeState(type, name, event){
    const { diseaseState, createPersona } = this.props;
    this.diseaseState = _.cloneDeep(diseaseState);
    this.diseaseState.location[type][name] = event.currentTarget.value;
    createPersona({
      diseaseState: Object.assign({}, diseaseState, this.diseaseState)
    });
    // this.checkIfCompleted();
  }

  handleOnChangeDuration(event){
    const { diseaseState, createPersona } = this.props;
    this.diseaseState = _.cloneDeep(diseaseState);
    let value = event.currentTarget.value;
    this.diseaseState.diseaseHistory.diseaseDuraction = value;
    // this.diseaseState.completed = true;
    createPersona({
      diseaseState: Object.assign({}, diseaseState, this.diseaseState)
    });
    // this.checkIfCompleted();
  }

  onIlleumSwitchChange(event){
    const { diseaseState, createPersona } = this.props;
    this.diseaseState = _.cloneDeep(diseaseState);
    this.diseaseState.location.ileum = getDefaultIntestine();
    this.diseaseState.location.ileum.checked = event;
    createPersona({
      diseaseState: Object.assign({}, diseaseState, this.diseaseState)
    });
    // this.checkIfCompleted();
  }

  onColonSwitchChange(event){
    const { diseaseState, createPersona } = this.props;
    this.diseaseState = _.cloneDeep(diseaseState);
    this.diseaseState.location.colon = getDefaultIntestine();
    this.diseaseState.location.colon.checked = event;
    createPersona({
      diseaseState: Object.assign({}, diseaseState, this.diseaseState)
    });
    // this.checkIfCompleted();
  }

  getIlleumContent(){
    const {intl, diseaseState} = this.props;
    const {location} = diseaseState;
    const {ileum} = location;
    const {formatMessage} = intl;
    // const {surfaceAreaWithUlcers, surfaceInvolved,} = this.state;
    const [ulcerTypeOptionsIntl] = localOptionsIntl(
      [ulcerTypeOptions],
      formatMessage
    );
    if(ileum.checked){
      return (
        <Fragment>
          <div className="toggle-button-title">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerArea.noper"})}</div>
          <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChangeState.bind(this, "ileum", "ulcerArea")} value={ileum.ulcerArea} aria-label="text formatting">
            {_.map(surfaceAreaWithUlcers, (obj, i) => {
              return (
                <ToggleButton value={obj.value} selected={this.checkIfSelected("ulcerArea", obj, "ileum")} key={i} className="full-height toggle-button-large location" aria-label="italic">
                  <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <div className="toggle-button-title">{formatMessage({id: "persona.sidebar.diseaseState.intestine.diseaseArea.noper"})}</div>
          <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChangeState.bind(this, "ileum", "diseaseArea")} value={ileum.diseaseArea} aria-label="text formatting">
            {_.map(surfaceInvolved, (obj, i) => {
              return (
                <ToggleButton value={obj.value} key={i} selected={this.checkIfSelected("diseaseArea", obj, "ileum")} className="full-height toggle-button-large location" aria-label="italic">
                  <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <div className="toggle-button-title">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerType"})}</div>
          <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChangeState.bind(this, "ileum", "ulcerType")} value={ileum.ulcerType} aria-label="text formatting">
            {_.map(ulcerTypeOptionsIntl, (obj, i) => {
              return (
                <ToggleButton value={obj.value} key={i} className="full-height toggle-button-large location" aria-label="italic">
                  <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Fragment>
      );
    }
  }

  getColonContent(){
    const {intl, diseaseState} = this.props;
    const {location} = diseaseState;
    const {colon} = location;
    const {formatMessage} = intl;
    // const {surfaceAreaWithUlcers, surfaceInvolved} = this.state;
    const [ulcerTypeOptionsIntl] = localOptionsIntl(
      [ulcerTypeOptions],
      formatMessage
    );
    if(colon.checked){
      return (
        <Fragment>
          <div className="toggle-button-title">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerArea.noper"})}</div>
          <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChangeState.bind(this, "colon", "ulcerArea")} value={colon.ulcerArea} aria-label="text formatting">
            {_.map(surfaceAreaWithUlcers, (obj, i) => {
              return (
                <ToggleButton value={obj.value} key={i} selected={this.checkIfSelected("ulcerArea", obj, "colon")} className="full-height toggle-button-large location" aria-label="italic">
                  <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <div className="toggle-button-title">{formatMessage({id: "persona.sidebar.diseaseState.intestine.diseaseArea.noper"})}</div>
          <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChangeState.bind(this, "colon", "diseaseArea")} value={colon.diseaseArea} aria-label="text formatting">
            {_.map(surfaceInvolved, (obj, i) => {
              return (
                <ToggleButton value={obj.value} key={i} selected={this.checkIfSelected("diseaseArea", obj, "colon")} className="full-height toggle-button-large location" aria-label="italic">
                  <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <div className="toggle-button-title">{formatMessage({id: "persona.sidebar.diseaseState.intestine.ulcerType"})}</div>
          <ToggleButtonGroup className="persona-toggle-group" onChange={this.handleOnChangeState.bind(this, "colon", "ulcerType")} value={colon.ulcerType} aria-label="text formatting">
            {_.map(ulcerTypeOptionsIntl, (obj, i) => {
              return (
                <ToggleButton value={obj.value} key={i} className="full-height toggle-button-large location" aria-label="italic">
                  <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Fragment>
      );
    }
  }

  checkIfSelected(type, item, locationType){
    const {diseaseState} = this.props;
    const {diseaseHistory, location} = diseaseState;
    if(type == "diseaseDuraction"){
      return (parseFloat(diseaseHistory.diseaseDuraction) === item.value);
    } else {
      return (location[locationType][type] === item.value);
    }
  }

  onNextStep(){
    const { diseaseState } = this.props;
    console.log("diseaseState.completed", diseaseState.completed);
  }

  render() {
    const {intl, diseaseState} = this.props;
    const {diseaseHistory, location} = diseaseState;
    const {colon, ileum} = location;
    const {formatMessage} = intl;
    const iconClass = "persona-section-icon";
    // const {diseaseDuration} = this.state;
    return (
      <div className="persona-container">
        <div className="header">
          <div className="header-title">{formatMessage({id: "persona.sidebar.diseaseState.title"})}</div>
          <StepperButtons onNextStepChange={this.onNextStep.bind(this)}/>
        </div>
        <div className="persona-content">
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.diseaseState.diseaseHistory.diseaseDuration"})}
            icon={diseaseDurationIcon}
            iconClass={iconClass}
          />
          <div className="section-content">
            <ToggleButtonGroup className="persona-toggle-group"
              onChange={this.handleOnChangeDuration.bind(this)}
              value={diseaseHistory.diseaseDuraction}
              aria-label="text formatting"
            >
              {_.map(diseaseDuration, (obj, i) => {
                return (
                  <ToggleButton value={obj.value} key={i} selected={this.checkIfSelected("diseaseDuraction", obj)} className="full-height toggle-button-large duration" aria-label="italic">
                    <span className="toggle-button-text">{formatMessage({id: obj.label})}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.intestine.ileum"})}
            icon={illeumIcon}
            iconClass={iconClass}
            isOpen={ileum.checked}
            onSwitchChange={this.onIlleumSwitchChange.bind(this)}
            showSwitch={this.showSwitch}
          />
          <div className="section-content">
            {this.getIlleumContent()}
          </div>
          <Section
            className="align-items-center"
            title={formatMessage({id: "persona.sidebar.intestine.colon"})}
            icon={colonIcon}
            iconClass={iconClass}
            isOpen={colon.checked}
            onSwitchChange={this.onColonSwitchChange.bind(this)}
            showSwitch={this.showSwitch}
          />
          <div className="section-content">
            {this.getColonContent()}
          </div>
        </div>
      </div>
    );
  }
}

DiseaseState.defaultProps = {
  subroutes: [],
  basePath: "",
  history: {},
  label: ""
};

DiseaseState.propTypes = {
  classes: PropTypes.object,
  subroutes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  changeLanguage: PropTypes.func,
  createPersona: PropTypes.func,
  language: PropTypes.string,
  intl: intlShape.isRequired,
  activeStep: PropTypes.number,
  diseaseState: PropTypes.object
};

const mapStateToProps = (state) => {
  const {newPersona: personaState} = state;
  const {diseaseState} = personaState;

  return {
    diseaseState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch(actions.changeLanguage(language));
    },
    createPersona: (persona) => {
      dispatch(actions.createPersona(persona));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(DiseaseState));
