import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import InfoSection from "../../../../components/infoSection";
import Treatment from "./treatment";
import { CollapsiblePanel } from "ui-toolkit";
import { Button } from "@material-ui/core";
import { getWrappedInstance } from "../../../../utils";
import { getDefaultTreatment } from "../../../../model";

const numReg = /\d+/;

class ProposedTreatment extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }

  handleChange(name, value) {
    const { editDashboard } = this.props;

    editDashboard({
      [name]: value
    });
  }

  handleTreatmentChange(name, value) {
    const { proposedTreatment, editDashboard } = this.props;
    const newProposedTreatment = _.map(proposedTreatment, treatment => {
      const { name: treatmentName } = treatment;

      if (name === treatmentName) {
        return value;
      } else {
        return treatment;
      }
    });

    editDashboard({
      proposedTreatment: newProposedTreatment
    });
  }

  handleAddTreatment() {
    const {
      treatment,
      proposedTreatment,
      editDashboard,
      showMessage,
      intl
    } = this.props;
    const { formatMessage } = intl;

    if (proposedTreatment.length >= 5) {
      showMessage({
        message: formatMessage({
          id: "dashboard.sidebar.treatment.no.more.five"
        })
      });
      return;
    }

    const totalTreatmentDuration = _.reduce(
      proposedTreatment,
      (result, treatment) => {
        const { duractionTreatment } = treatment;
        const value = duractionTreatment ? parseInt(duractionTreatment) : 0;

        return result + value;
      },
      0
    );

    if (totalTreatmentDuration >= 1200) {
      showMessage({
        message: formatMessage({
          id: "dashboard.sidebar.treatment.dutaction.no.more.1200"
        })
      });
      return;
    }

    const num = parseInt(
      numReg.exec(proposedTreatment[proposedTreatment.length - 1].name)
    );
    const name = `treatment${num + 1}`;
    const label = `Treatment ${num + 1}`;

    editDashboard({
      proposedTreatment: [
        ...proposedTreatment,
        getDefaultTreatment({
          name,
          label,
          duractionTreatment: 104
        })
      ],
      treatment: Object.assign({}, treatment, {
        [name]: true
      })
    });
  }

  handleDeleteTreatment(name) {
    const {
      treatment,
      proposedTreatment,
      editDashboard,
      showMessage,
      intl
    } = this.props;
    const { formatMessage } = intl;

    if (proposedTreatment.length > 1) {
      editDashboard({
        proposedTreatment: _.reject(proposedTreatment, { name }),
        treatment: _.omit(treatment, name)
      });
    } else {
      showMessage({
        message: formatMessage({ id: "dashboard.sidebar.treatment.leave.one" })
      });
    }
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const instance = getWrappedInstance(ref.current);

        return result && instance.getStatus();
      },
      true
    );
  }

  getInvalidatedTreatment() {
    const status = {};

    _.each(this.inputRefs, (ref, name) => {
      const instance = getWrappedInstance(ref.current);

      status[name] = !instance.getStatus();
    });

    return status;
  }

  reset() {
    _.each(this.inputRefs, ref => {
      const instance = getWrappedInstance(ref.current);

      instance.reset();
    });
  }

  render() {
    this.inputRefs = {};
    const { treatment, proposedTreatment, intl } = this.props;
    const { formatMessage } = intl;
    const treatmentConfig = _.map(proposedTreatment, treatment => {
      const { name, label } = treatment;
      this.inputRefs[name] = React.createRef();

      return {
        name,
        label: formatMessage({id: label}),
        content: (
          <Treatment
            ref={this.inputRefs[name]}
            treatment={treatment}
            onChange={this.handleTreatmentChange.bind(this, name)}
            onDeleteTreatment={this.handleDeleteTreatment.bind(this, name)}
          />
        )
      };
    });

    return (
      <div className="proposed-treatment">
        <InfoSection
          title={formatMessage({
            id: "dashboard.sidebar.proposedTreatment.title"
          })}
        >
          <CollapsiblePanel
            labelEditable={false}
            openPanel={treatment}
            config={treatmentConfig}
            onPanelChange={this.handleChange.bind(this, "treatment")}
          />
          <Button
            color="primary"
            classes={{
              root: "add-treatment-button-root"
            }}
            onClick={this.handleAddTreatment.bind(this)}
          >
            {formatMessage({
              id: "dashboard.sidebar.proposedTreatment.addTreatment"
            })}
          </Button>
        </InfoSection>
      </div>
    );
  }
}

ProposedTreatment.defaultProps = {};

ProposedTreatment.propTypes = {
  treatment: PropTypes.object,
  proposedTreatment: PropTypes.array,
  editDashboard: PropTypes.func,
  showMessage: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(ProposedTreatment, {
  withRef: true
});
