import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import ControlPanel from "../../../components/controlPanel";
import PatientInfo from "./patientInfo";
import DiseaseState from "./diseaseState";
import HistoricIntervention from "./historicIntervention";
import ResponderProfile from "./responderProfile";
import { getDefaultPersona } from "../../../model";
import { getWrappedInstance } from "../../../utils";
import SavePersona from "../../../components/savePersona";

import "../../../../styles/persona/sidebar.scss";

class NewPersonaSidebar extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      patientInfo: React.createRef(),
      diseaseState: React.createRef(),
      historicIntervention: React.createRef(),
      responderProfile: React.createRef(),
      personaName: React.createRef(),
      personaDesc: React.createRef(),
    };
    this.state = {
      isSaveActionTriggered: false,
      personaName: "",
      personaDesc: ""
    };
    this.personaState = props.personaState;
    this.personaState.completed = false;
  }

  handleAction() {
    const { createPersona, personaState } = this.props;
    createPersona(
      Object.assign({}, personaState, {
        showSaveDialog: true,
      })
    );
  }

  handleReset() {
    const { createPersona } = this.props;

    createPersona(
      Object.assign({}, getDefaultPersona(), {
        isSelected: false,
        personaSelected: "",
        showSaveDialog: false,
        activeStep: 0,
        stepAction: "",
        name: "",
        desc: "",
        errorMessage: "",
        error: false
      })
    );
    this.reset();
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();

        return result && status;
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, ref => {
      if(ref){
        var _instance = getWrappedInstance(ref.current);
        if(_instance){
          _instance.reset();
        }
      }
    });
  }

  checkForDisableSave() {
    const { personaState } = this.props;
    const { patientInfo, diseaseState, historicIntervention, responderProfile } = personaState;
    return patientInfo.completed && diseaseState.completed && historicIntervention.completed && responderProfile.completed;
  }

  savePersona() {
    let data = {};
    const { addNewPersona, personaState } = this.props;
    const { name, desc, patientInfo, diseaseState, historicIntervention, responderProfile } = personaState;
    data.name = name;
    data.desc = desc;
    data.archetypeId = name;
    data.patientInfo = patientInfo;
    data.diseaseState = diseaseState;
    data.historicIntervention = historicIntervention;
    data.responderProfile = responderProfile;
    addNewPersona({ name, desc, data });
    this.setState({
      isSaveActionTriggered: false
    });
    // this.handleReset();
    // resetNewPersona();
  }

  cancelDialog() {
    console.log("close persona");
    this.setState({
      isSaveActionTriggered: false
    });
  }

  handleChange(type, event) {
    const { personaState, createPersona } = this.props;
    personaState[type] = event;
    createPersona({
      personaState: Object.assign({}, personaState)
    });
  }

  getErrorBlock() {
    const { personaState } = this.props;
    if (personaState["error"] && personaState["errorMessage"]) {
      return (
        <div style={{ color: "red" }}>An error occured while saving: {personaState["errorMessage"]}</div>
      );
    }
  }

  getSaveActionDialog() {
    return (
      <SavePersona />
    );
  }

  componentDidUpdate(){
    this.getSidebarAsPerActiveStep();
  }

  getSidebarAsPerActiveStep() {
    const { activePersonaSidebar, personaState } = this.props;
    const {stepAction} = personaState;
    let sidebarContentTop = document.querySelector(".contition-details").offsetTop;
    let marginTop = 20;
    if(stepAction == "next"){
      if(activePersonaSidebar == 1){
        let sectionTop = document.querySelector(".disease-state").offsetTop;
        let scrollOffset = sectionTop - sidebarContentTop - marginTop;
        document.querySelector(".contition-details").scrollTo({top: scrollOffset, behavior: "smooth"});
      } else if(activePersonaSidebar == 2){
        let sectionTop = document.querySelector(".historic-intervention").offsetTop;
        let scrollOffset = sectionTop - sidebarContentTop - marginTop;
        document.querySelector(".contition-details").scrollTo({top: scrollOffset, behavior: "smooth"});
      } else if(activePersonaSidebar == 3){
        let sectionTop = document.querySelector(".responder-profile").offsetTop;
        let scrollOffset = sectionTop - sidebarContentTop - marginTop;
        document.querySelector(".contition-details").scrollTo({top: scrollOffset, behavior: "smooth"});
      }
    } else if(stepAction == "back"){
      if(activePersonaSidebar == 0){
        let sectionTop = document.querySelector(".patient-info").offsetTop;
        let scrollOffset = sectionTop - sidebarContentTop - marginTop;
        document.querySelector(".contition-details").scrollTo({top: scrollOffset, behavior: "smooth"});
      } else if(activePersonaSidebar == 1){
        let sectionTop = document.querySelector(".disease-state").offsetTop;
        let scrollOffset = sectionTop - sidebarContentTop - marginTop;
        document.querySelector(".contition-details").scrollTo({top: scrollOffset, behavior: "smooth"});
      } else if(activePersonaSidebar == 2){
        let sectionTop = document.querySelector(".historic-intervention").offsetTop;
        let scrollOffset = sectionTop - sidebarContentTop - marginTop;
        document.querySelector(".contition-details").scrollTo({top: scrollOffset, behavior: "smooth"});
      }
    }
  }

  render() {
    const { intl, activePersonaSidebar } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="persona-sidebar new">
        <ControlPanel
          closable={false}
          title={formatMessage({ id: "persona.sidebar.control.panel.title" })}
          actionLabel={formatMessage({ id: "persona.sidebar.action.confirm" })}
          resetLabel={formatMessage({ id: "persona.sidebar.reset.label" })}
          onAction={this.handleAction.bind(this)}
          onReset={this.handleReset.bind(this)}
          actionDisabled={!(this.checkForDisableSave())}
        />
        <div className="contition-details">
          <PatientInfo ref={this.inputRefs.patientInfo} activeStep={activePersonaSidebar} />
          <DiseaseState ref={this.inputRefs.diseaseState} activeStep={activePersonaSidebar} />
          <HistoricIntervention ref={this.inputRefs.historicIntervention} activeStep={activePersonaSidebar} />
          <ResponderProfile ref={this.inputRefs.responderProfile} activeStep={activePersonaSidebar} />
        </div>
        {this.getSaveActionDialog()}
      </div>
    );
  }
}

NewPersonaSidebar.defaultProps = {};

NewPersonaSidebar.propTypes = {
  isSelected: PropTypes.bool,
  personaSelected: PropTypes.string,
  createPersona: PropTypes.func,
  resetDashboard: PropTypes.func,
  intl: intlShape.isRequired,
  activePersonaSidebar: PropTypes.number,
  addNewPersona: PropTypes.func,
  personaState: PropTypes.obj,
  progressAction: PropTypes.string,
  resetNewPersona: PropTypes.func
};

export default injectIntl(NewPersonaSidebar, { withRef: true });
