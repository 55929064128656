import { handleActions } from "redux-actions";
import action from "../action";
import dState from "./defaultState";
import _ from "lodash";

const { actionTypes } = action;
const { LOGIN_SUCCESS, GET_PRESET_PERSONAS, GET_PRESET_PERSONAS_SUCCESS, GET_PRESET_PERSONAS_FAILED, GET_CUSTOM_PERSONAS, GET_CUSTOM_PERSONAS_SUCCESS, GET_CUSTOM_PERSONAS_FAILED } = actionTypes;

const defaultState = Object.assign(dState, {
  personas: []
});

export default handleActions(
  {
    [GET_PRESET_PERSONAS]: (state, action) => {
      const { payload } = action;

      return Object.assign({}, state, {
        isLoading: true
      }, payload);
    },
    [GET_PRESET_PERSONAS_SUCCESS]: (state, action) => {
      const { payload } = action;
      return Object.assign({}, state, {
        isLoading: false
      }, payload);
    },
    [GET_PRESET_PERSONAS_FAILED]: state => {
      return Object.assign({}, state, {
        isLoading: false
      });
    },
    [GET_CUSTOM_PERSONAS]: (state, action) => {
      const { payload } = action;

      return Object.assign({}, state, {
        isLoading: true
      }, payload);
    },
    [GET_CUSTOM_PERSONAS_SUCCESS]: (state, action) => {
      const { payload } = action;
      return Object.assign({}, state, {
        isLoading: false
      }, payload);
    },
    [GET_CUSTOM_PERSONAS_FAILED]: state => {
      return Object.assign({}, state, {
        isLoading: false
      });
    },
    [LOGIN_SUCCESS]: () => {
      return _.defaultsDeep({}, defaultState);
    }
  },
  defaultState
);
