import React from "react";

function externalContent(formatMessage) {
  return (
    <div className="left-title">
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.external.Treatment"})}</li>
      </ul>
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.external.drug"})}</li>
      </ul>
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.external.Development"})}</li>
      </ul>
      <ul>
        <li className="first-level">{formatMessage({id:"model.content.external.distribution"})}</li>
      </ul>
    </div>
  );
}

export { externalContent };
