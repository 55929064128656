import { connect } from "react-redux";
import DurationSlider from "./durationSlider";
import action from "../../../../flux/action";

const mapStateToProps = state => {
  const {dashboard: dashboardState} = state;
  const {treatmentMonth, forecasted} = dashboardState;
  const {week} = forecasted;

  return {
    treatmentMonth,
    week
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editDashboard: options => {
      dispatch(action.editDashboard(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(DurationSlider);
