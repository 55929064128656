const drugs = [
  // {
  //   label: "persona.sidebar.treatment.steroids.prednisone",
  //   value: "steroids-prednisone"
  // },
  // {
  //   label: "persona.sidebar.treatment.steroids.budesonide",
  //   value: "steroids-budesonide"
  // },
  {
    label: "persona.sidebar.treatment.steroids.generic",
    value: "steroids-generic",
    // disabled: true
  },
  {
    label: "persona.sidebar.treatment.immunomodulator.generic",
    value: "immunomodulator-generic",
    // disabled: true
  },
  {
    label: "persona.sidebar.treatment.anti-tnf.adalimumab",
    value: "anti-tnf-adalimumab"
  },
  {
    label: "persona.sidebar.treatment.anti-tnf.infliximab",
    value: "anti-tnf-infliximab"
  },
  {
    label: "persona.sidebar.treatment.anti-integrin.vedolizumab",
    value: "anti-integrin-vedolizumab"
  },
  {
    label: "persona.sidebar.treatment.anti-il.ustekinumab",
    value: "anti-il-ustekinumab"
  }
];

const drugsGrouping = [
  {
    label: "persona.sidebar.treatment.steroids.generic",
    value: "steroids-generic"
  },
  {
    label: "persona.sidebar.treatment.immunomodulator.generic",
    value: "immunomodulator-generic"
  },
  {
    label: "persona.sidebar.treatment.biologic.vedolizumab",
    value: "anti-integrin-vedolizumab"
  },
  {
    label: "persona.sidebar.treatment.biologic.other",
    value: "other"
  },
];

const biologicOther = [
  {
    label: "persona.sidebar.treatment.anti-tnf.adalimumab",
    value: "anti-tnf-adalimumab"
  },
  {
    label: "persona.sidebar.treatment.anti-tnf.infliximab",
    value: "anti-tnf-infliximab"
  },
  {
    label: "persona.sidebar.treatment.anti-il.ustekinumab",
    value: "anti-il-ustekinumab"
  }
];

const doseFrequencyOptions = [
  {
    label: "dashboard.options.doseFrequency.300mg_week",
    value: "300mg_week"
  }
];

// const immunogenicityOptions = [
//   {
//     label: "dashboard.options.immunogenicity.above",
//     value: "above_average"
//   },
//   {
//     label: "dashboard.options.immunogenicity.average",
//     value: "average"
//   },
//   {
//     label: "dashboard.options.immunogenicity.below",
//     value: "below_average"
//   }
// ];

const commonRegimeOptions = [{
  label: "dashboard.options.regime.recommended",
  value: "recommended"
}];

const antiTnfAdalimumabOptions = [
  {
    label: "dashboard.options.regime.induction",
    desc: "dashboard.options.anti-tnf.adalimumab.induction",
    value: "induction"
  },
  {
    label: "dashboard.options.regime.maintenance",
    desc: "dashboard.options.anti-tnf.adalimumab.maintenance",
    value: "maintenance"
  },
  {
    label: "dashboard.options.regime.induction.maintenance",
    desc: "dashboard.options.anti-tnf.adalimumab.induction.maintenance",
    value: "induction + maintenance"
  },
  {
    label: "dashboard.options.regime.custom",
    // desc: "dashboard.options.anti-tnf.adalimumab.custom",
    value: "custom"
  }
];

const antiTnfInfliximabOptions = [
  {
    label: "dashboard.options.regime.induction",
    desc: "dashboard.options.anti-tnf.infliximab.induction",
    value: "induction"
  },
  {
    label: "dashboard.options.regime.maintenance",
    desc: "dashboard.options.anti-tnf.infliximab.maintenance",
    value: "maintenance"
  },
  {
    label: "dashboard.options.regime.induction.maintenance",
    desc: "dashboard.options.anti-tnf.infliximab.induction.maintenance",
    value: "induction + maintenance"
  },
  {
    label: "dashboard.options.regime.custom",
    // desc: "dashboard.options.anti-tnf.infliximab.custom",
    value: "custom"
  }
];

const antiIntegrinVedolizumabOptions = [
  {
    label: "dashboard.options.regime.induction",
    desc: "dashboard.options.anti-integrin.vedolizumab.induction",
    value: "induction"
  },
  {
    label: "dashboard.options.regime.maintenance",
    desc: "dashboard.options.anti-integrin.vedolizumab.maintenance",
    value: "maintenance"
  },
  {
    label: "dashboard.options.regime.induction.maintenance",
    desc: "dashboard.options.anti-integrin.vedolizumab.induction.maintenance",
    value: "induction + maintenance"
  },
  {
    label: "dashboard.options.regime.custom",
    // desc: "dashboard.options.anti-integrin.vedolizumab.custom",
    value: "custom"
  }
];

const antiIlUstekinumabOptions = [
  {
    label: "dashboard.options.regime.induction",
    desc: "dashboard.options.anti-il.ustekinumab.induction",
    value: "induction"
  },
  {
    label: "dashboard.options.regime.maintenance",
    desc: "dashboard.options.anti-il.ustekinumab.maintenance",
    value: "maintenance"
  },
  {
    label: "dashboard.options.regime.induction.maintenance",
    desc: "dashboard.options.anti-il.ustekinumab.induction.maintenance",
    value: "induction + maintenance"
  },
  {
    label: "dashboard.options.regime.custom",
    // desc: "dashboard.options.anti-il.ustekinumab.custom",
    value: "custom"
  }
];

const developmemtImmunogenicityOptions = [
  {
    label: "dashboard.options.developmemt.immunogenicity.none",
    value: "none"
  },
  {
    label: "dashboard.options.developmemt.immunogenicity.below",
    value: "below-average"
  },
  {
    label: "dashboard.options.developmemt.immunogenicity.average",
    value: "average"
  },
  {
    label: "dashboard.options.developmemt.immunogenicity.above",
    value: "above-average"
  }
];

export {
  drugs,
  drugsGrouping,
  biologicOther,
  doseFrequencyOptions,
  // immunogenicityOptions,
  commonRegimeOptions,
  antiTnfAdalimumabOptions,
  antiTnfInfliximabOptions,
  antiIntegrinVedolizumabOptions,
  antiIlUstekinumabOptions,
  developmemtImmunogenicityOptions
};
