import { createActions } from "redux-actions";
import { actionTypes } from "./actionTypes";
import { createRequest, naviTo } from "../../utils";

const { GET_PRESET_PERSONAS, GET_PRESET_PERSONAS_SUCCESS, GET_PRESET_PERSONAS_FAILED, GET_CUSTOM_PERSONAS, GET_CUSTOM_PERSONAS_SUCCESS, GET_CUSTOM_PERSONAS_FAILED } = actionTypes;

const commonActions = createActions({}, GET_PRESET_PERSONAS, GET_PRESET_PERSONAS_SUCCESS, GET_PRESET_PERSONAS_FAILED, GET_CUSTOM_PERSONAS, GET_CUSTOM_PERSONAS_SUCCESS, GET_CUSTOM_PERSONAS_FAILED);

const getPresetPersonas = options => {
  return dispatch => {
    dispatch(commonActions.getPresetPersonas(options));

    const successCallback = res => {
      dispatch(commonActions.getPresetPersonasSuccess(res));
      if(res.success){
        return res;
      }
    };

    const failedCallback = (res) => {
      dispatch(commonActions.getPresetPersonasFailed());
      if(res.contains("Session expired, please login again.")){
        naviTo("/app/persona/presetPersonas");
      }
      naviTo("/error");
    };

    const opts = {
      pathName: "getPresetPersonas",
      method: "GET",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};

const getCustomPersonas = options => {
  return dispatch => {
    dispatch(commonActions.getCustomPersonas(options));

    const successCallback = res => {
      dispatch(commonActions.getCustomPersonasSuccess(res));
      if(res.success){
        return res;
      }
    };

    const failedCallback = (res) => {
      dispatch(commonActions.getCustomPersonasFailed());
      if(res.contains("Session expired, please login again.")){
        naviTo("/app/persona/presetPersonas");
      }
      naviTo("/error");
    };

    const opts = {
      pathName: "getCustomPersonas",
      method: "GET",
      ...options
    };

    return createRequest(opts, dispatch)
      .then(successCallback)
      .catch(failedCallback);
  };
};

export default Object.assign({}, commonActions, {getPresetPersonas, getCustomPersonas});
