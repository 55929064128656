import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import ControlPanel from "../../../components/controlPanel";
import PatientInfo from "./patientInfo";
import DiseaseState from "./diseaseState";
import HistoricIntervention from "./historicIntervention";
import ResponderProfile from "./responderProfile";
import { getDefaultPersona } from "../../../model";
import { getWrappedInstance } from "../../../utils";
import { naviTo } from "../../../utils";

import "../../../../styles/persona/sidebar.scss";

class PersonaSidebar extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      patientInfo: React.createRef(),
      diseaseState: React.createRef(),
      historicIntervention: React.createRef(),
      responderProfile: React.createRef()
    };
    this.defaultPatientData = _.cloneDeep(props.personaState);
  }

  handleAction() {
    const { editPersona, personaSelected, resetDashboard} = this.props;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "persona_name": personaSelected,
      "event": "persona_selected"
    });

    if (this.getStatus()) {
      editPersona({
        isSelected: true
      });
      resetDashboard();
      naviTo("/app/dashboard");
    }
  }

  handleReset() {
    const { editPersona } = this.props;

    editPersona(
      Object.assign({}, getDefaultPersona(), this.defaultPatientData)
    );
    this.reset();
  }

  handleClose() {
    const { editPersona } = this.props;

    editPersona(
      Object.assign({}, getDefaultPersona(), {
        isSelected: false,
        personaSelected: ""
      })
    );
    this.reset();
  }

  getStatus() {
    return _.reduce(
      this.inputRefs,
      (result, ref) => {
        const status = getWrappedInstance(ref.current).getStatus();

        return result && status;
      },
      true
    );
  }

  reset() {
    _.each(this.inputRefs, ref => {
      getWrappedInstance(ref.current).reset();
    });
  }

  componentDidUpdate() {
    const { personaSelected } = this.props;

    if (personaSelected !== "custom") {
      // this.reset();
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="persona-sidebar">
        <ControlPanel
          closable={true}
          title={formatMessage({ id: "persona.sidebar.control.panel.title" })}
          actionLabel={formatMessage({ id: "persona.sidebar.action.label" })}
          resetLabel={formatMessage({ id: "persona.sidebar.reset.label" })}
          onAction={this.handleAction.bind(this)}
          onReset={this.handleReset.bind(this)}
          onClose={this.handleClose.bind(this)}
        />
        <div className="contition-details">
          <PatientInfo ref={this.inputRefs.patientInfo} />
          <DiseaseState ref={this.inputRefs.diseaseState} />
          <HistoricIntervention ref={this.inputRefs.historicIntervention} />
          <ResponderProfile ref={this.inputRefs.responderProfile} />
        </div>
      </div>
    );
  }
}

PersonaSidebar.defaultProps = {};

PersonaSidebar.propTypes = {
  isSelected: PropTypes.bool,
  personaSelected: PropTypes.string,
  editPersona: PropTypes.func,
  resetDashboard: PropTypes.func,
  intl: intlShape.isRequired,
  personaState: PropTypes.object
};

export default injectIntl(PersonaSidebar, { withRef: true });
