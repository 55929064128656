import React, { Component } from "react";
import PropTypes from "prop-types";
// import ClinicalAssessment from "./clinicalAssessment";
// import PlasmaDrugConcentration from "./plasmaDrugConcentration";
import Biomarkers from "./biomarkers";
import MucosalHealingSection from "./mucosalHealingSection";
import Physiology from "./physiology";
import DurationSlider from "./durationSlider";
import DiarrheaSymptoms from "./diarrheaSymptoms";
import Disclaimer from "../../../components/disclaimer";
import _ from "lodash";
import {validateLogin} from "../../../utils";
import AppFooter from "../../../container/appFooter";

import "../../../../styles/dashboard/main.scss";

class DashboardMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // const {checkSession} = this.props;
    validateLogin();
    // checkSession();
  }

  componentDidUpdate(prevProps) {
    if (this.props.forecasted !== prevProps.forecasted) {
      this.getImageSignedURLEndoImages(this.props.forecasted);
    }
  }

  getImageSignedURLEndoImages(data) {
    const {getImageSignedURL} = this.props;
    let sorted_endoscopicImagesColon =  _.sortedUniq(data.endoscopic_image_Colon);
    let sorted_endoscopicImagesIleum =  _.sortedUniq(data.endoscopic_image_Ileum);
    let sorted_endoscopic_Images = _.concat(sorted_endoscopicImagesColon, sorted_endoscopicImagesIleum);
    if (_.isArray(sorted_endoscopic_Images) === true) {
      getImageSignedURL({
        sorted_endoscopic_Images
      });
    }
  }

  handleDisplayDom() {
    const { forecasted } = this.props;
    if (_.isEmpty(forecasted)) {
      return null;
    } else {
      return <div className="position-dom"></div>;
    }
  }

  render() {
    return (
      <div className="dashboard-main">
        <DurationSlider />
        <div className="dashboard-main-content">
          <MucosalHealingSection />
          <DiarrheaSymptoms />
          {/* <div className="clinical-plasma">
            <ClinicalAssessment />
            <PlasmaDrugConcentration />
          </div> */}
          <Biomarkers />
          <Physiology />
          <Disclaimer />
          <AppFooter />
        </div>
      </div>
    );
  }
}

DashboardMain.defaultProps = {};

DashboardMain.propTypes = {
  treatmentMonth: PropTypes.number,
  forecasted: PropTypes.object,
  plotConfig: PropTypes.object,
  getImageSignedURL: PropTypes.func,
  imageSignedUrls: PropTypes.array
  // checkSession: PropTypes.func
};

export default DashboardMain;
