import PersonaMain from "../modules/persona/main";
import PersonaSidebar from "../modules/persona/sidebar";
// import NewPersonaSidebar from "../modules/persona/newPersona/sidebar";
import DashboardMain from "../modules/dashboard/main";
import DashboardSidebar from "../modules/dashboard/sidebar";
import ModelMain from "../modules/model/main";
import AdminMain from "../modules/admin/main";
import NewPersona from "../modules/persona/newPersona/newPersona";

const routes = [
  {
    path: "persona",
    label: "header.tab.persona",
    main: PersonaMain,
    sidebar: PersonaSidebar
  },
  {
    path: "dashboard",
    label: "header.tab.dashboard",
    main: DashboardMain,
    sidebar: DashboardSidebar
  },
  {
    path: "model",
    label: "header.tab.model",
    main: ModelMain
  }
];

function getRoutes(isSelected, isAdmin) {
  const routes = [
    {
      path: "persona",
      label: "header.tab.persona",
      main: PersonaMain,
      sidebar: PersonaSidebar
    },
    {
      path: "dashboard",
      label: "header.tab.dashboard",
      main: DashboardMain,
      sidebar: DashboardSidebar,
      disabled: !isSelected
    },
    {
      path: "model",
      label: "header.tab.model",
      main: ModelMain
    }
  ];

  if (isAdmin) {
    routes.push({
      path: "admin",
      label: "header.tab.admin",
      main: AdminMain
    });
  }

  return routes;
}

function getPersonaSubRoutes() {
  const subroutes = [
    {
      path: "presetPersona",
      label: "persona.header.subtab.preset",
      desc: "persona.header.subtab.desc.preset",
      sidebar: PersonaSidebar
      // main: PresetPersona
    },
    {
      path: "customPatients",
      label: "persona.header.subtab.custom.patiets",
      desc: "persona.header.subtab.desc.custom.patiets",
      sidebar: PersonaSidebar
      // main: CustomPersona
    },
    {
      path: "newPatient",
      label: "persona.header.subtab.new.patient",
      desc: "persona.header.subtab.desc.new.preset",
      main: NewPersona
    }
  ];

  return subroutes;
}

export { routes, getRoutes, getPersonaSubRoutes };
