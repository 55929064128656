import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import MucosalHealingContent from "./mucosalHealingContent";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import SesCdContent from "./sesCdContent";
import diseaseDurationIcon from "../../../../../assets/icons/ico_disease_state.png";
import illeumIcon from "../../../../../assets/icons/ico_ileum.png";
import colonIcon from "../../../../../assets/icons/ico_colon.png";
import defaultDummyImageColon from "../../../../../assets/Dummy_Images_Illeum_Colon/endo_dummy_img_30000000.jpg";
import defaultDummyImageIleum from "../../../../../assets/Dummy_Images_Illeum_Colon/endo_dummy_img_20000000.jpg";
import loadingColon from "../../../../../assets/Dummy_Images_Illeum_Colon/endoscopic_images_final_hd_images_endo_img_colon_loading.jpg";
import loadingIleum from "../../../../../assets/Dummy_Images_Illeum_Colon/endoscopic_images_final_hd_images_endo_img_ileum_loading.jpg";

class Treatment extends Component {
  constructor(props) {
    super(props);
  }

  getMappedImageColon(imagevalue, type) {
    let mapped_array = [];
    if(type === "Colon" && this.props.imageSignedUrls){
      if(_.isEmpty(this.props.imageSignedUrls) === false){
        mapped_array = _.filter(this.props.imageSignedUrls, imagevalue);
        if(_.isEmpty(mapped_array) === true){
          let sampleImageArray = {[imagevalue] : defaultDummyImageColon};
          mapped_array .push(sampleImageArray);
        }
      }
    }
    return mapped_array;
  }

  getMappedImageIleum(imagevalue, type) {
    let mapped_array = [];
    if(type === "Ileum" && this.props.imageSignedUrls){
      if(_.isEmpty(this.props.imageSignedUrls) === false){
        mapped_array = _.filter(this.props.imageSignedUrls, imagevalue);
        if(_.isEmpty(mapped_array) === true){
          let sampleImageArray = {[imagevalue] : defaultDummyImageIleum};
          mapped_array .push(sampleImageArray);
        }
      }
    }
    return mapped_array;
  }

  handleChange(name, value) {
    const { editDashboard } = this.props;

    editDashboard({
      [name]: value
    });
  }

  getData(data, index) {
    if (_.isArray(data) && data[index] != undefined) {
      return data[index];
    } else {
      return "";
    }
  }

  showNoData(){
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <span className="no-data">
        {formatMessage({ id: "dashboard.content.nodata" })}
      </span>
    );
  }

  showOverallCard(){
    const { treatmentMonth, sescd, sescdDesc, forecasted } = this.props;
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="mucosal-healing-container">
        <SesCdContent
          sescd={this.getData(sescd, treatmentMonth)}
          sescdDesc={this.getData(sescdDesc, treatmentMonth)}
          treatmentMonth={treatmentMonth}
          endoscopicRemission={this.getData(forecasted["endoscopic_remission"], treatmentMonth)}
          endoscopicResponse={this.getData(forecasted["endoscopic_response"], treatmentMonth)}
          title={formatMessage({id: "dashboard.content.mucosal.healing.overall.title"})}
        />
      </div>
    );
  }

  showColonCard(){
    const { forecasted, treatmentMonth} = this.props;
    const { Colon, endoscopic_image_Colon } = forecasted;
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="mucosal-healing-container">
        <MucosalHealingContent
          content="Colon"
          loadingImage={loadingColon}
          imagevalue={endoscopic_image_Colon[treatmentMonth]}
          image={this.getMappedImageColon(endoscopic_image_Colon[treatmentMonth], "Colon")}
          data={Colon[treatmentMonth]}
          title={formatMessage({id: "dashboard.content.mucosal.healing.Colon.title"})}
        />
      </div>
    );
  }

  showIleumCard(){
    const { forecasted, treatmentMonth} = this.props;
    const { Ileum, endoscopic_image_Ileum } = forecasted;
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="mucosal-healing-container">
        <MucosalHealingContent
          content="Ileum"
          loadingImage={loadingIleum}
          imagevalue={endoscopic_image_Ileum[treatmentMonth]}
          image={this.getMappedImageIleum(endoscopic_image_Ileum[treatmentMonth], "Ileum")}
          data={Ileum[treatmentMonth]}
          title={formatMessage({id: "dashboard.content.mucosal.healing.Ileum.title"})}
        />
      </div>
    );
  }

  getNodeDesc(){
    const { forecasted, intl} = this.props;
    const { Ileum } = forecasted;
    const { formatMessage } = intl;
    if(Ileum){
      return (
        <div className="note">
          <div className="note-header">{formatMessage({id: "dashboard.contents.mucosal.healing.note1"})}</div>
          <ul className="note-desc">
            <li>{formatMessage({id: "dashboard.contents.mucosal.healing.note2"})}</li>
            <li>{formatMessage({id: "dashboard.contents.mucosal.healing.note3"})}</li>
          </ul>
        </div>
      );
    }
  }

  render() {
    const { intl, forecasted } = this.props;
    const { Colon, Ileum } = forecasted;
    const { formatMessage } = intl;

    return (
      <div className="mucosal-healing-section">
        <div className="title">
          {formatMessage({ id: "dashboard.content.mucosal.healing.title" })}
        </div>
        <div className="mucosal-section">
          <Fragment>
            <div className="ileum-colon-display">
              <div className="overallcard section-container mucosal-card">
                <div className="mucosal-healing mucosal-overall overall">
                  <div className="header">
                    <img className="section-icon pr-10" src={diseaseDurationIcon}/>
                    {formatMessage({ id: "dashboard.content.mucosal.healing.overall.title" })}
                  </div>
                  <div className="content overall-content">
                    {_.isEmpty(Colon) ? this.showNoData() : this.showOverallCard()}
                  </div>
                </div>
              </div>
              <div className="illeumcoloncard mucosal-illeum-card mucosal-card">
                <div className="section-container ileum">
                  <div className="mucosal-healing mucosal-ileum">
                    <div className="header">
                      <img className="section-icon pr-10" src={illeumIcon}/>
                      {formatMessage({ id: "dashboard.content.mucosal.healing.Ileum.title" })}
                    </div>
                    <div className="content">
                      {_.isEmpty(Ileum) ? this.showNoData() : this.showIleumCard()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="illeumcoloncard mucosal-colon-card mucosal-card">
                <div className="section-container colon">
                  <div className="mucosal-healing mucosal-colon">
                    <div className="header">
                      <img className="section-icon pr-10" src={colonIcon}/>
                      {formatMessage({ id: "dashboard.content.mucosal.healing.Colon.title" })}
                    </div>
                    <div className="content">
                      {_.isEmpty(Colon) ? this.showNoData() : this.showColonCard()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
        <div>
          {this.getNodeDesc()}
        </div>
      </div>
    );
  }
}

Treatment.defaultProps = {};

Treatment.propTypes = {
  forecasted: PropTypes.object,
  treatmentMonth: PropTypes.number,
  sescd: PropTypes.number,
  sescdDesc: PropTypes.array,
  editDashboard: PropTypes.func,
  getImageSignedURL: PropTypes.func,
  intl: intlShape.isRequired,
  imageSignedUrls: PropTypes.array
};

export default injectIntl(Treatment, { withRef: true });
