import { connect } from "react-redux";
import MucosalHealing from "./mucosalHealing";
import action from "../../../../flux/action";

const mapStateToProps = state => {
  const { dashboard: dashboardState } = state;
  const { treatmentMonth, forecasted, imageSignedUrls } = dashboardState;
  const sescd = forecasted["SES-CD"];
  const sescdDesc = forecasted["SES-CD_desc"];
  const endoscopicRemission = forecasted["endoscopic_remission"];
  const endoscopicResponse = forecasted["endoscopic_response"];
  const endoscopicImageColon = forecasted["endoscopic_image_Colon"];
  const endoscopicImageIleum = forecasted["endoscopic_image_Ileum"];

  return {
    treatmentMonth,
    forecasted,
    sescd,
    sescdDesc,
    endoscopicRemission,
    endoscopicResponse,
    endoscopicImageColon,
    endoscopicImageIleum,
    imageSignedUrls
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editDashboard: options => {
      dispatch(action.editDashboard(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(MucosalHealing);
